import { Collection, fromJS, Record } from "immutable";
import { Program } from "nala-shared/typings/general/program";
import { AnyAction } from "redux";

import * as t from "./action-types";
import { PairingPractitioners } from "./actions";

export interface NewPatientForm {
  dateOfBirth: string;
  name: string;
  phoneNumber: string;
  programs: { [key in Program]: false | string };
  gender: "male" | "female";
  nationalId: string;
  wasfatyPrescriptionID: string | undefined;
  loaded: boolean;
  hbA1C?: number;
}

export type InviteStatus =
  | "Created"
  | "SMS Sent"
  | "Failed Sending SMS"
  | "Signed Up"
  | "Welcome Call Made";

interface Invite {
  dateOfBirth?: string;
  gender?: "male" | "female";
  healthcareService: string;
  history?: { [key: string]: { dateTime: number; status: InviteStatus } };
  isExistingUser?: boolean;
  name: string;
  nationalId?: string;
  phoneNumber: string;
  programs?: { [key in Program]: false | string };
  sent: number;
  status: InviteStatus;
}

export interface NewPatientsReducerState {
  invites: Collection<unknown, unknown>;
  loaded: boolean;
  formValues: null | NewPatientForm;
  pairingPractitioners: null | PairingPractitioners;
}

export const NewPatientsState = Record({
  invites: Collection(),
  loaded: false,
  formValues: null,
  pairingPractitioners: null,
});

// extract invites linked with the active healthcare service
const filterInvites = ({
  invites,
  activeHealthcareService,
}: {
  invites: { [key: string]: Invite };
  activeHealthcareService: string;
}) => {
  const filteredInvites = {} as { [key: string]: Invite };
  if (invites === null || invites === undefined) {
    return null;
  }
  Object.keys(invites).forEach((inviteKey) => {
    if (invites[inviteKey].healthcareService === activeHealthcareService) {
      filteredInvites[inviteKey] = invites[inviteKey];
    }
  });
  return filteredInvites;
};

export function newPatientsReducer(
  state = new NewPatientsState(),
  { payload, type }: AnyAction
) {
  switch (type) {
    case t.LOAD_INVITES:
      return state.merge({
        invites: fromJS(
          filterInvites({
            invites: payload.invites,
            activeHealthcareService: payload.activeHealthcareService,
          })
        ),
      });

    case t.USER_ALREADY_EXISTS:
      return state.merge({ formValues: { ...payload } });

    case t.RESET_FORM_VALUES:
      return state.merge({ formValues: null });

    case t.LOADED_DATA:
      return state.merge({ loaded: true });

    case t.LOAD_PAIRING_PRACTITIONERS:
      return state.merge({ pairingPractitioners: { ...payload } });

    default:
      return state;
  }
}
