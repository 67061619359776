import React, { useState } from "react";
import {
  Intent,
  Tag,
  ProgressBar,
  Button,
  Callout,
  Dialog,
  HTMLTable,
} from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import moment from "moment";

const ConfirmableButton = ({
  tooltip,
  disabled,
  loading,
  onClick,
  style,
  icon,
  intent,
  confirmIntent,
}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const getIntent = () => {
    if (isConfirming || loading) {
      if (confirmIntent) {
        return confirmIntent;
      }
      return "success";
    }
    if (intent) {
      return intent;
    }
    return "none";
  };

  return (
    <Tooltip2 hoverOpenDelay={500} content={tooltip} placement="top">
      <Button
        disabled={disabled}
        loading={loading}
        icon={isConfirming ? null : icon}
        style={style}
        text={isConfirming ? "Click Again to Confirm" : ""}
        intent={getIntent()}
        onClick={() => {
          if (isConfirming) {
            setIsConfirming(false);
            onClick();
          } else {
            setIsConfirming(true);
            setTimeout(() => {
              setIsConfirming(false);
            }, 2000);
          }
        }}
      />
    </Tooltip2>
  );
};

const InviteRow = ({
  invite,
  resendInvite,
  updateWelcomeCallStatus,
  removeInvite,
}) => {
  const [isResendingSMS, setIsResendingSMS] = useState(false);
  const [updatingWelcomeCall, setUpdatingWelcomeCall] = useState(false);
  const [removingInvite, setRemovingInvite] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const status = invite[1].get("status");
  const history = invite[1].get("history") && invite[1].get("history").toJS();
  const compareDateTime = (statusA, statusB) =>
    statusA[1].dateTime - statusB[1].dateTime;

  const statusProgress = {
    Created: 0,
    "Failed Sending SMS": 0,
    "SMS Sent": 1,
    "Signed Up": 2,
    "Welcome Call Made": 3,
  };

  const getStatusIntent = () => {
    switch (status) {
      case "Created":
      case "SMS Sent":
        return Intent.NONE;
      case "Awaiting Acceptance":
        return Intent.PRIMARY;
      case "Linked":
        return Intent.SUCCESS;
      case "Failed Sending SMS":
        return Intent.DANGER;
      default:
        return Intent.NONE;
    }
  };

  return (
    <tr key={invite[1].get("sent")}>
      <td>{invite[1].get("name")}</td>
      <td>{invite[1].get("phoneNumber")}</td>
      <td>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 5,
          }}
        >
          <div style={{ flex: 1, maxWidth: 300 }}>
            <ProgressBar
              animate={false}
              stripes={false}
              value={statusProgress[invite[1].get("status")] / 3}
              intent={Intent.SUCCESS}
            />
          </div>
          <div style={{ width: 200 }}>
            <Tag round intent={getStatusIntent()} style={{ marginLeft: 20 }}>
              {invite[1].get("status")}
            </Tag>
          </div>
        </div>
      </td>
      <td>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ConfirmableButton
            tooltip="Resend SMS"
            loading={isResendingSMS}
            disabled={isResendingSMS}
            style={{ marginRight: 10 }}
            icon="notifications"
            onClick={async () => {
              setIsResendingSMS(true);
              await resendInvite({
                phoneNumber: invite[1].get("phoneNumber"),
                inviteKey: invite[0],
                status,
              });
              setIsResendingSMS(false);
            }}
          />
          <ConfirmableButton
            tooltip="Call Patient"
            loading={updatingWelcomeCall}
            disabled={updatingWelcomeCall || !["Signed Up"].includes(status)}
            // intent={
            //   status === "Welcome Call Made" ? Intent.SUCCESS : Intent.PRIMARY
            // }
            icon="phone"
            onClick={async () => {
              setUpdatingWelcomeCall(true);
              await updateWelcomeCallStatus({
                inviteKey: invite[0],
                toggle: status === "Welcome Call Made",
              });
              setUpdatingWelcomeCall(false);
            }}
            style={{ marginRight: 10 }}
          />
          <Button
            icon="history"
            onClick={() => setIsHistoryOpen(true)}
            disabled={!history}
          />
          {(status === "SMS Sent" ||
            status === "Created" ||
            status === "Failed Sending SMS") && (
            <ConfirmableButton
              tooltip="Call Patient"
              icon="cross"
              style={{ marginLeft: 10 }}
              intent={Intent.DANGER}
              loading={removingInvite}
              confirmIntent={Intent.DANGER}
              onClick={async () => {
                setRemovingInvite(true);
                await removeInvite({ inviteKey: invite[0] });
                setRemovingInvite(false);
              }}
            />
          )}
        </div>
      </td>

      {history && (
        <Dialog
          style={{ width: "100%", maxWidth: 500, paddingBottom: 20 }}
          isOpen={isHistoryOpen}
          onClose={() => setIsHistoryOpen(false)}
          className="bp3-dark"
          title="Status History"
          icon="timeline-line-chart"
        >
          <div style={{ marginTop: 20, marginRight: 20, marginLeft: 20 }}>
            <Callout>
              <HTMLTable style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(history)
                    .sort(compareDateTime)
                    .map(([key, statusInstant]) => {
                      // console.log("***", x);
                      return (
                        <tr key={key}>
                          <td>{statusInstant.status}</td>
                          <td>{moment(statusInstant.dateTime).fromNow()}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </HTMLTable>
            </Callout>
          </div>
        </Dialog>
      )}
    </tr>
  );
};

export default InviteRow;
