import { Map } from "immutable";

export function getPatientsState(state) {
  return state.patients;
}

export function getProfilesList(state) {
  return getPatientsState(state).profiles;
}

export function getPractitioners(state) {
  return state.practitioners.practitionersList;
}

export function getObservationTypes(state) {
  return state.observationTypes.list.toJS();
}

function getQuestionPractitioner(state, question) {
  let practitioners = getPractitioners(state);
  if (Map.isMap(practitioners)) {
    practitioners = getPractitioners(state).toJS();
  } else {
    practitioners = getPractitioners(state);
  }

  const practitioner = practitioners[question.respondedBy];

  if (
    Boolean(practitioner) &&
    Boolean(practitioner.name) &&
    Boolean(practitioner.name.text)
  ) {
    return practitioner.name.text;
  }
  return undefined;
}

function getSessionPractitioners(state, session) {
  const practitioners = getPractitioners(state);

  let allPractitioners;
  let lastEncounteredBy;
  if (Boolean(session.questions) && practitioners.size !== 0) {
    const questionsArray = Object.values(session.questions);

    allPractitioners = questionsArray
      .map((question) => {
        return getQuestionPractitioner(state, question);
      })
      .filter((practitionerName) => {
        return practitionerName !== undefined && practitionerName !== null;
      })
      .sort()
      .filter((item, pos, ary) => {
        return !pos || item !== ary[pos - 1];
      }); // remove not unique elements
  }

  return { allPractitioners, lastEncounteredBy };
}

export function getSelectedPatient(state) {
  const { selectedPatient } = getPatientsState(state);
  if (!Map.isMap(selectedPatient)) {
    return selectedPatient;
  }

  const selectedPatientObject = selectedPatient.toJS();

  if (
    Boolean(selectedPatientObject.asknalaAccount) &&
    Boolean(selectedPatientObject.asknalaAccount.sessions)
  ) {
    Object.keys(selectedPatientObject.asknalaAccount.sessions).forEach(
      (sessionKey) => {
        const session =
          selectedPatientObject.asknalaAccount.sessions[sessionKey];
        const sessionPractitioners = getSessionPractitioners(state, session);
        // append practitioners list to every session
        selectedPatientObject.asknalaAccount.sessions[
          sessionKey
        ].practitioners = sessionPractitioners.allPractitioners;
      }
    );
  }
  return selectedPatientObject;
}

export function getIsProfilesLoaded(state) {
  return getPatientsState(state).loaded;
}

export function getPairedPatients(state) {
  return getPatientsState(state).pairedPatients;
}
