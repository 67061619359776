import moment from "moment";
import { Map } from "immutable";

/**
 * Calculates age from dateOfBirth timestamp
 * @param  {String} dateOfBirth [a timestamp]
 * @return {Number}
 */
export function getAge(dateOfBirth) {
  return new Date(Date.now() - new Date(dateOfBirth)).getUTCFullYear() - 1970;
}

export function formatTime(timestamp) {
  return moment(timestamp).utcOffset(180).format("MMMM Do YYYY, h:mm:ss a");
}

/**
 * Extracts the name of a practitioner from a practitionersList
 * @param  {Array} practitionersList
 * @param  {String} practitionerKey [id]
 * @return {String}
 */
export function getPractitionerNameFromPractitionersList(
  practitionersList,
  practitionerKey
) {
  if (!practitionerKey || !practitionersList) {
    return "";
  }
  let practitioner;
  if (Map.isMap(practitionersList)) {
    practitioner = practitionersList.get(`${practitionerKey}`);
  } else {
    practitioner = practitionersList[practitionerKey];
  }

  const practitionerName =
    Boolean(practitioner) &&
    Boolean(practitioner.name) &&
    Boolean(practitioner.name.text)
      ? practitioner.name.text
      : "";

  return practitionerName;
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}
