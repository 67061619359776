export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";

export const REMOVE_USER_ERROR = "REMOVE_USER_ERROR";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";

export const UNDELETE_USER_ERROR = "UNDELETE_USER_ERROR";

export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_ERROR = "LOAD_USERS_ERROR";

export const UNLOAD_USERS_SUCCESS = "UNLOAD_USERS_SUCCESS";
