import React, { useEffect, useState } from "react";

import TimeCard from "./TimeCard";

const moment = require("moment");

const Slots = ({
  slots,
  dayOffset,
  toggleSlotAvailability,
  showOnlyActiveSlots,
}) => {
  const [activeSlot, setActiveSlot] = useState(null);
  const checkInterval = 60000;

  slots.sort((slotA, slotB) =>
    slotA.startMoment.diff(slotB.startMoment, "minutes")
  );

  useEffect(() => {
    const checkActiveSlot = () => {
      if (dayOffset === 0) {
        const now = moment();
        slots.forEach((slot, index) => {
          const endMoment = slot.startMoment
            .clone()
            .add(slot.duration, "minutes");
          if (now.isBetween(slot.startMoment, endMoment)) {
            setActiveSlot(index);
          }
        });
      }
    };
    checkActiveSlot();
    setInterval(checkActiveSlot, checkInterval);

    return () => clearInterval(checkActiveSlot);
  }, [slots, dayOffset]);

  const hideTimeCard = (slot) => showOnlyActiveSlots && !slot.isAvailable;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {slots.map((slot, index) =>
        !hideTimeCard(slot) ? (
          <TimeCard
            activeSlot={activeSlot}
            slot={slot}
            index={index}
            key={slot.id}
            toggleSlotAvailability={toggleSlotAvailability}
          />
        ) : (
          <></>
        )
      )}
    </div>
  );
};

const memoizeSlots = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.slots) === JSON.stringify(nextProps.slots) &&
    prevProps.dayOffset === nextProps.dayOffset &&
    prevProps.toggleSlotAvailability === nextProps.toggleSlotAvailability &&
    prevProps.showOnlyActiveSlots === nextProps.showOnlyActiveSlots
  );
};

export default React.memo(Slots, memoizeSlots);
