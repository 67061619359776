import { firebaseAuth } from "../firebase";
import * as authActions from "./actions";

export default function initAuth(dispatch, getState) {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(
      (authUser) => {
        authActions.initAuth(authUser)(dispatch, getState);
        unsubscribe();
        resolve();
      },
      (error) => reject(error)
    );
  });
}
