/* eslint-disable no-console */
import React from "react";

import packageJson from "../package.json";

global.appVersion = packageJson.version;

/**
 * Helper function that compare 2 Simvor versions
 * simvor example "0.13.2"
 *
 * @param { string } v1 first simvor version
 * @param { string } v2 second simvor verstion
 * @returns { boolean }
 */
function isVersionLessThan(v1, v2) {
  // check if all versions are valid
  if (
    typeof v1 === "string" &&
    v1.split(".").length === 3 &&
    typeof v2 === "string" &&
    v2.split(".").length === 3
  ) {
    try {
      const v1Arr = v1.split(".");
      const v2Arr = v2.split(".");
      for (let i = 0; i < v1Arr.length; i += 1) {
        if (v1Arr[i] !== v2Arr[i]) {
          if (v1Arr[i] > v2Arr[i]) return false;
          if (v1Arr[i] < v2Arr[i]) return true;
        }
      }
    } catch (error) {
      return false;
    }
  }
  return false;
}

export default class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log("Clearing cache and hard reloading...");
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then((names) => {
            names.forEach((name) => caches.delete(name));
          });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
      },
    };
  }

  componentDidMount() {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = isVersionLessThan(
          currentVersion,
          latestVersion
        );

        console.log(
          `shouldForceRefresh -> isVersionLessThan(${currentVersion}, ${latestVersion} -> shouldForceRefresh: ${shouldForceRefresh}`
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    const { children } = this.props;
    return children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
    });
  }
}
