import { firestore, firestoreTimestamp, firebaseDb } from "../firebase";

const getLabTests = async () => {
  let labTestsDataArray = [];
  const labTestsRef = firestore.collection("labTests");
  const snapshot = await labTestsRef.get();
  snapshot.forEach((doc) => {
    const category = doc.data();
    const result = category.items.map((item) => {
      return { categoryName: category.categoryName, labTest: item };
    });
    labTestsDataArray = [...labTestsDataArray, ...result];
  });

  return labTestsDataArray;
};

const addServiceRequestToProfile = async (uid, serviceRequest) => {
  const serviceRequestPushRequest = await firestore
    .collection("serviceRequests")
    .add({
      ...serviceRequest,
      authoredOn: firestoreTimestamp.fromDate(new Date()),
    });

  return serviceRequestPushRequest.id;
};

const getMedications = async () => {
  const medicationsRef = firestore.collection("medications");
  const snapshot = await medicationsRef.get();
  const medications = [];
  snapshot.forEach((doc) => {
    const medication = doc.data();
    medications.push(medication);
  });
  return medications;
};

const addPrescription = async (uid, _prescription) => {
  const prescription = await firestore.collection("prescriptions").add({
    ..._prescription,
  });
  return prescription.id;
};

async function setNewPrescription({ userID }) {
  await firebaseDb.ref(`profiles/${userID}/hasNewPrescriptions`).set(true);
}

const getPrescription = ({ prescriptionRef }) => {
  return firestore.collection("prescriptions").doc(prescriptionRef).get();
};

const createMedicationRequests = async ({
  medicationsList,
  prescriptionRef,
  patientID,
  requester,
  requesterType = "practitionerRole",
  status = "active",
  category = "community",
  priority = "routine",
  reported = {
    reportedBoolean: false,
    reportedReference: null,
  },
  courseOfTherapyType = "acute",
  insurance = null,
  priorPrescription = null,
}) => {
  const batch = firestore.batch();
  medicationsList.forEach((medication) => {
    const medicationRequestRef = firestore
      .collection("medicationRequests")
      .doc();

    batch.set(medicationRequestRef, {
      groupID: prescriptionRef,
      status,
      category,
      priority,
      reported,
      medication: medication.medication,
      subject: patientID,
      authoredOn: new Date().getTime(),
      requester,
      requesterType,
      courseOfTherapyType,
      insurance,
      note: medication.doctorNote,
      dosageInstructions: medication.dosageInstructions,
      substitution: {
        allowed: medication.isSubstitutionAllowed,
      },
      priorPrescription,
      dispenseRequest: {
        initialFill: {
          quantity: {
            value: medication.quantity,
          },
          duration: medication.duration,
        },
      },
    });
  });

  await batch.commit();
};

export async function getMedicationRequestsFromPrescription({
  prescriptionID,
}) {
  const medicationRequestsRef = await firestore
    .collection("medicationRequests")
    .where("groupID", "==", prescriptionID)
    .get()
    .catch(() => {
      return [];
    });

  const medicationRequests = [];
  medicationRequestsRef.forEach((result) => {
    medicationRequests.push({
      key: result.id,
      ...result.data(),
    });
  });

  return medicationRequests;
}

export async function getMedicationRequestForPatient({ userID }) {
  const medicationRequestsRef = await firestore
    .collection("medicationRequests")
    .where("subject", "==", userID)
    .get()
    .catch(() => {
      return [];
    });

  const medicationRequests = [];
  medicationRequestsRef.forEach((result) => {
    medicationRequests.push({
      key: result.id,
      ...result.data(),
    });
  });

  return medicationRequests;
}

export default {
  getLabTests,
  addServiceRequestToProfile,
  addPrescription,
  setNewPrescription,
  getPrescription,
  getMedications,
  createMedicationRequests,
  getMedicationRequestsFromPrescription,
  getMedicationRequestForPatient,
};
