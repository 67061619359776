import React from "react";
import { Tabs, Tab } from "@blueprintjs/core";

const AvailabilityTabs = ({ selectedTab, setSelectedTab }) => {
  return (
    <div
      style={{
        overflowX: "auto",
        padding: "6px 20px",
        paddingBottom: 10,
        backgroundColor: "#2A3742",
      }}
    >
      <Tabs
        large
        id="AvailabilityTabs"
        selectedTabId={selectedTab}
        onChange={(tabKey) => {
          setSelectedTab(tabKey);
        }}
      >
        <Tab id="shifts">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Shifts
          </div>
        </Tab>
        <Tab id="bookings">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Bookings
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AvailabilityTabs;
