import { Intent, Spinner, Tab, Tabs, Tag } from "@blueprintjs/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import {
  dispenseRequestsActions,
  getDispenseRequests,
  getIsDispenseRequestsLoaded,
  getPharmacyOrders,
  getIsPharmacyOrdersLoaded,
} from "../../../dispenseRequests";
import DispenseRequestUsersCards from "../../components/DispenseRequestsTabs/DispenseRequestUsersCards";

const DispenseRequestsPage = ({
  loadDispenseRequests,
  loadPharmacyOrderInvoices,
  dispenseRequests,
  loaded,
  pharmacyOrders,
  isPharmacyOrdersLoaded,
}) => {
  useEffect(() => {
    loadDispenseRequests();
    loadPharmacyOrderInvoices();
  }, [loadDispenseRequests, loadPharmacyOrderInvoices]);

  return (
    <div className="respond-page-container scrollable">
      <div style={{}}>
        {!(loaded && isPharmacyOrdersLoaded) ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner size={70} />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: 40,
              padding: "0px 1.5%",
              marginTop: 10,
            }}
          >
            <DispenseRequestsTabs
              dispenseRequests={dispenseRequests}
              pharmacyOrders={pharmacyOrders}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const DispenseRequestsTabs = ({ dispenseRequests, pharmacyOrders }) => {
  const [activeTab, setActiveTab] = useState();

  return (
    <Tabs
      id="TabsExample"
      onChange={(e) => {
        setActiveTab(e);
      }}
      selectedTabId={activeTab}
    >
      <Tab
        id="dispenseRequests"
        panel={
          <DispenseRequestUsersCards
            usersWithDispenseRequests={dispenseRequests.pending}
            pharmacyOrders={pharmacyOrders.pending}
            isSearchable
            status="pending"
            tab="pending"
          />
        }
      >
        <TabLabel
          dispenseRequests={dispenseRequests}
          pharmacyOrders={pharmacyOrders}
          title="Dispense Request"
          group="pending"
        />
      </Tab>
      <Tab
        id="readyForPickup"
        panel={
          <DispenseRequestUsersCards
            usersWithDispenseRequests={dispenseRequests.readyForPickup}
            pharmacyOrders={pharmacyOrders.readyForPickup}
            isSearchable
            status="ready for pickup"
            tab="readyForPickup"
          />
        }
        panelClassName="ember-panel"
      >
        <TabLabel
          dispenseRequests={dispenseRequests}
          pharmacyOrders={pharmacyOrders}
          title="Ready for pickup"
          group="readyForPickup"
        />
      </Tab>
      <Tab
        id="readyForDelivery"
        panel={
          <DispenseRequestUsersCards
            usersWithDispenseRequests={dispenseRequests.readyForDelivery}
            pharmacyOrders={pharmacyOrders.readyForDelivery}
            isSearchable
            status="ready for delivery"
            tab="readyForDelivery"
          />
        }
        panelClassName="ember-panel"
      >
        <TabLabel
          dispenseRequests={dispenseRequests}
          pharmacyOrders={pharmacyOrders}
          title="Ready for delivery"
          group="readyForDelivery"
        />
      </Tab>
      <Tab
        id="cancelled"
        panel={
          <DispenseRequestUsersCards
            usersWithDispenseRequests={dispenseRequests.cancelled}
            pharmacyOrders={pharmacyOrders.cancelled}
            isSearchable
            status="cancelled"
            tab="cancelled"
          />
        }
      >
        <TabLabel
          dispenseRequests={dispenseRequests}
          pharmacyOrders={pharmacyOrders}
          title="Cancelled"
          group="cancelled"
          intent={Intent.NONE}
        />
      </Tab>
      <Tab
        id="completed"
        panel={
          <DispenseRequestUsersCards
            usersWithDispenseRequests={dispenseRequests.completed}
            pharmacyOrders={pharmacyOrders.completed}
            isSearchable
            status="completed"
            tab="completed"
          />
        }
      >
        <TabLabel
          dispenseRequests={dispenseRequests}
          pharmacyOrders={pharmacyOrders}
          title="Completed"
          group="completed"
          intent={Intent.NONE}
        />
      </Tab>
    </Tabs>
  );
};

const TabLabel = ({
  dispenseRequests,
  group,
  title,
  intent = Intent.PRIMARY,
  pharmacyOrders,
}) => {
  const getTabCounter = (g) => {
    let count = 0;
    Object.values(dispenseRequests[g]).forEach((drs) => {
      drs.forEach(() => {
        count += 1;
      });
    });
    count += Object.values(pharmacyOrders[g]).length;
    return count;
  };

  return (
    <div style={{ marginBottom: 3 }}>
      {title}{" "}
      {getTabCounter(group) !== 0 && (
        <Tag style={{ marginLeft: 3 }} round intent={intent}>
          {getTabCounter(group)}
        </Tag>
      )}
    </div>
  );
};

const mapStateToProps = createSelector(
  getDispenseRequests,
  getIsDispenseRequestsLoaded,
  getPharmacyOrders,
  getIsPharmacyOrdersLoaded,
  (dispenseRequests, loaded, pharmacyOrders, isPharmacyOrdersLoaded) => ({
    dispenseRequests,
    loaded,
    pharmacyOrders,
    isPharmacyOrdersLoaded,
  })
);

const mapDispatchToProps = {
  ...dispenseRequestsActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DispenseRequestsPage);
