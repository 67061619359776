import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { createSelector } from "reselect";
import { Button, H1 } from "@blueprintjs/core";

import {
  getInvites,
  newPatientsActions,
  getLoadedState,
  getPairingPractitioners,
  getAuthId,
} from "../../../newPatients";
import AddPatientOverlay from "../../components/Patients/AddPatientOverlay";
import InvitesTable from "../../components/Patients/InvitesTable";

const mapStateToProps = createSelector(
  getInvites,
  getLoadedState,
  getPairingPractitioners,
  getAuthId,
  (invites, loaded, pairingPractitioners, authId) => ({
    invites,
    loaded,
    pairingPractitioners,
    authId,
  })
);

const mapDispatchToProps = { ...newPatientsActions };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const NewPatientsPage = ({
  authId,
  invites,
  loadData,
  loaded,
  pairingPractitioners,
  resendInvite,
  sendInvite,
  sendInviteToExistingUser,
  updateWelcomeCallStatus,
  removeInvite,
}: PropsFromRedux) => {
  const [showAddPatientOverlay, setShowPatientOverlay] = useState(false);
  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="main-container respond-page-container scrollable">
      <div
        style={{
          marginBottom: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <H1>New Patients</H1>
        <Button
          icon="new-person"
          style={{ height: 33 }}
          text="Add Patient"
          disabled={!loaded}
          loading={!loaded}
          onClick={() => {
            setShowPatientOverlay(true);
          }}
        />
      </div>

      {showAddPatientOverlay && (
        <AddPatientOverlay
          invites={invites}
          sendInvite={sendInvite}
          sendInviteToExistingUser={sendInviteToExistingUser}
          showOverlay={showAddPatientOverlay}
          onClose={() => {
            setShowPatientOverlay(false);
          }}
          pairingPractitioners={pairingPractitioners}
          authId={authId}
        />
      )}

      <InvitesTable
        invites={invites}
        resendInvite={resendInvite}
        updateWelcomeCallStatus={updateWelcomeCallStatus}
        removeInvite={removeInvite}
      />
    </div>
  );
};

export default connector(NewPatientsPage);
