import { Position, Toaster, Intent } from "@blueprintjs/core";

/** Singleton toaster instance. Create separate instances for different options. */
const Notification = Toaster.create({
  position: Position.TOP,
  intent: Intent.DANGER,
});

const Error = (message) => {
  Notification.show({
    message,
    intent: Intent.WARNING,
    icon: "warning-sign",
  });
};

const Success = (message) => {
  Notification.show({
    message,
    intent: Intent.SUCCESS,
    icon: "check-mark",
  });
};

export default {
  Error,
  Success,
};
