import { Radio, RadioGroup } from "@blueprintjs/core";
import React from "react";

const ExpressionRadioButtons = ({
  expressionTypes,
  valueType,
  setSelectedExpression,
  selectedExpression,
}) => {
  const onExpressionChange = (event) => {
    setSelectedExpression(event.currentTarget.value);
  };

  return (
    <RadioGroup
      onChange={onExpressionChange}
      selectedValue={selectedExpression}
    >
      <Radio
        label={expressionTypes.equalTo.text}
        value={expressionTypes.equalTo.key}
      />
      <Radio
        disabled={["boolean", "string"].includes(valueType)}
        label={expressionTypes.largerThan.text}
        value={expressionTypes.largerThan.key}
      />
      <Radio
        disabled={["boolean", "string"].includes(valueType)}
        label={expressionTypes.lessThan.text}
        value={expressionTypes.lessThan.key}
      />
      <Radio
        disabled={["boolean"].includes(valueType)}
        label={expressionTypes.in.text}
        value={expressionTypes.in.key}
      />
    </RadioGroup>
  );
};

export default ExpressionRadioButtons;
