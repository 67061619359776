import api from "./api";

export const getDoctorInfo = () => (dispatch, getState) => {
  const { id, username } = getState().auth;
  const { practitionersList } = getState().practitioners.toJS();
  return { ...practitionersList[id], email: username };
};

export function buildPrescription({
  session,
  sessionKey,
  userID,
  action,
  practitionerRole,
}) {
  return async (dispatch, getState) => {
    const doctor = await getDoctorInfo()(dispatch, getState);
    const prescription = {
      issued: new Date().getTime(),
      issuedFor: session?.patientID || userID,
      serviceProvider: doctor,
      sessionKey,
    };

    const prescriptionRef = await api.addPrescription(userID, prescription);

    await api.setNewPrescription({ userID });

    const { medicationsList } = action.prescription;

    await api.createMedicationRequests({
      medicationsList,
      patientID: session?.patientID || userID,
      prescriptionRef,
      requester: practitionerRole.toJS().key,
    });

    return prescriptionRef;
  };
}

export function sendText() {}

export function getLastQuestionKey({ questions }) {
  return Object.keys(questions)
    .sort((questionKeyA, questionKeyB) => {
      return questions[questionKeyA].sent - questions[questionKeyB].sent;
    })
    .slice(-1)[0];
}

export function getPractitionerTitle({ session }) {
  if (session.speciality === "mental-health") {
    return "أخصائي";
  }
  return "طبيب";
}
