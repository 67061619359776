import { firestore, firestoreTimestamp } from "../firebase";
import * as t from "./action-types";

export function buildRecipientObject({
  reminderType,
  healthcareService,
  practitionerRole,
  speciality,
}) {
  let recipient;

  switch (reminderType) {
    case "me":
      if (!healthcareService || !practitionerRole) {
        throw new Error("Not enough data for (remindMe) reminder type");
      } else {
        recipient = {
          healthcareService,
          practitionerRole,
        };
      }
      break;

    case "healthcareService":
      if (!healthcareService) {
        throw new Error(
          "Not enough data for (remindHealthcareService) reminder type"
        );
      } else {
        recipient = {
          healthcareService,
        };
      }
      break;

    case "speciality":
      if (!healthcareService || !speciality) {
        throw new Error("Not enough data for (remindSpeciality) reminder type");
      } else {
        recipient = {
          healthcareService,
          speciality,
        };
      }
      break;

    default:
      throw new Error("reminderType not found");
  }

  return recipient;
}

export async function createCommunicationRequest({
  reminderType,
  reminderTime,
  note,
  category,
  priority,
  practitionerID,
  patientID,
  healthcareService,
  practitionerRole,
  speciality,
  payload = {},
}) {
  const recipient = buildRecipientObject({
    reminderType,
    healthcareService,
    practitionerRole,
    speciality,
  });

  await firestore.collection(`communicationRequests`).add({
    occurrenceDateTime: firestoreTimestamp.fromDate(reminderTime),
    note,
    category,
    priority,
    recipient,
    requester: practitionerID,
    subject: patientID,
    status: "active",
    payload,
  });
}

export function loadCommunicationRequests() {
  return (dispatch, getState) => {
    const { activeHealthcareService, practitionerRole } = getState().auth;
    if (
      activeHealthcareService !== null &&
      activeHealthcareService !== undefined
    ) {
      const practitionerRoleKey = practitionerRole
        ? practitionerRole.get("key")
        : undefined;
      const practitionerSpeciality = practitionerRole
        ? practitionerRole.get("speciality")
        : undefined;

      const isValidCommunicationRequest = ({ communicationRequest }) => {
        const { recipient } = communicationRequest;
        if (
          recipient.speciality !== undefined &&
          recipient.speciality !== practitionerSpeciality
        ) {
          return false;
        }
        if (
          recipient.practitionerRole !== undefined &&
          recipient.practitionerRole !== practitionerRoleKey
        ) {
          return false;
        }
        return true;
      };

      let isFirstRun = true;
      firestore
        .collection("communicationRequests")
        .where("recipient.healthcareService", "==", activeHealthcareService)
        .where("status", "==", "active")
        .onSnapshot((querySnapshot) => {
          if (isFirstRun) {
            isFirstRun = false;
            const communicationRequests = [];
            querySnapshot.forEach((doc) => {
              if (
                isValidCommunicationRequest({
                  communicationRequest: doc.data(),
                })
              ) {
                communicationRequests.push({ ...doc.data(), key: doc.id });
              }
            });
            dispatch({
              type: t.LOAD_COMMUNICATION_REQUESTS_SUCCESS,
              payload: communicationRequests,
            });
          } else {
            querySnapshot.docChanges().forEach((change) => {
              if (
                !isValidCommunicationRequest({
                  communicationRequest: change.doc.data(),
                })
              ) {
                return;
              }

              if (change.type === "added") {
                dispatch({
                  type: t.CREATE__COMMUNICATION_REQUESTS_SUCCESS,
                  payload: { ...change.doc.data(), key: change.doc.id },
                });
              }
              if (change.type === "modified") {
                dispatch({
                  type: t.UPDATE__COMMUNICATION_REQUESTS_SUCCESS,
                  payload: { ...change.doc.data(), key: change.doc.id },
                });
              }
              if (change.type === "removed") {
                dispatch({
                  type: t.REMOVE_COMMUNICATION_REQUESTS_SUCCESS,
                  payload: { ...change.doc.data(), key: change.doc.id },
                });
              }
            });
          }
        });
    }
  };
}

async function updateCommunicationRequestStatus({
  communicationRequestKey,
  status,
}) {
  await firestore
    .collection("communicationRequests")
    .doc(communicationRequestKey)
    .update({
      status,
    });
}

/**
 * Update appointment and encounter status
 */
async function updateEncounter({ communicationRequest, status: action }) {
  const { appointmentID, encounterID } = communicationRequest.payload;
  let status;
  switch (action) {
    case "completed":
      status = "finished";
      break;
    case "revoked":
      status = "cancelled";
      break;
    default:
      throw new Error("Unhandled status");
  }
  const statusUpdate = { status };
  statusUpdate[`statusHistory.${status}`] = new Date();
  if (status === "finished") {
    statusUpdate[`period.end`] = new Date();
  }

  if (encounterID) {
    await firestore
      .collection("encounters")
      .doc(encounterID)
      .update(statusUpdate);
  }

  if (appointmentID && status === "cancelled") {
    await firestore
      .collection("appointments")
      .doc(appointmentID)
      .update(statusUpdate);
  }
}

export function communicationRequestAction({ communicationRequest, action }) {
  return async () => {
    if (!communicationRequest || !communicationRequest.key) {
      throw new Error("communication request not found");
    }
    const shouldUpdateEncounter =
      communicationRequest.payload &&
      communicationRequest.payload.isVideoCallReminder;

    let status;
    switch (communicationRequest.category) {
      case "reminder":
      case "alert":
        if (action === "markAsDone") {
          status = "completed";
        } else if (action === "revoke") {
          status = "revoked";
        } else {
          throw new Error("action not handled");
        }
        await updateCommunicationRequestStatus({
          communicationRequestKey: communicationRequest.key,
          status,
        });
        if (shouldUpdateEncounter)
          await updateEncounter({
            communicationRequest,
            status,
          });
        return;

      default:
        throw new Error("category not handled");
    }
  };
}

export function createPatientReminder({
  reminderType,
  reminderTime,
  note,
  patientID,
  payload = {},
}) {
  return async (dispatch, getState) => {
    const { id, activeHealthcareService, practitionerRole } = getState().auth;

    const practitionerRoleKey = practitionerRole
      ? practitionerRole.get("key")
      : undefined;
    const practitionerSpeciality = practitionerRole
      ? practitionerRole.get("speciality")
      : undefined;

    if (reminderType === "speciality" && !practitionerSpeciality) {
      throw new Error("practitioner has no speciality");
    }

    if (reminderType === "me" && !practitionerRoleKey) {
      throw new Error("practitioner has no practitionerRole");
    }

    await createCommunicationRequest({
      reminderTime,
      reminderType,
      practitionerRole: practitionerRoleKey,
      healthcareService: activeHealthcareService,
      speciality: practitionerSpeciality,
      note,
      practitionerID: id,
      priority: "routine",
      category: "reminder",
      patientID,
      payload,
    });
  };
}
