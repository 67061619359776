import React, { useState } from "react";
import Validator from "../../utils/Validator";

const InputChannel = ({ channel, onChange, placeholder }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [state, setState] = useState("");

  const validate = (val) => {
    setState("");
    setErrorMsg("");
    if (Validator.isNonEmpty(val.trim())) {
      setErrorMsg("Cannot be empty!");
      setState("is-danger");
      return false;
    }
    if (Validator.minLength(val.trim(), 1)) {
      setErrorMsg("No shorter than 1!");
      setState("is-danger");
      return false;
    }
    if (Validator.maxLength(val.trim(), 16)) {
      setErrorMsg("No longer than 16!");
      setState("is-danger");
      return false;
    }
    if (Validator.validChar(val.trim())) {
      setErrorMsg(
        'Only capital or lower-case letter, number and "_" are permitted!'
      );
      setState("is-danger");
      return false;
    }
    setState("is-success");
    return true;
  };

  const handleChange = (e) => {
    const validation = validate(e.target.value);
    onChange(e.target.value, validation);
  };

  let validateIcon = "";
  switch (state) {
    default:
    case "":
      validateIcon = "";
      break;
    case "is-success":
      validateIcon = <i className="ag-icon ag-icon-valid" />;
      break;
    case "is-danger":
      validateIcon = <i className="ag-icon ag-icon-invalid" />;
      break;
  }

  return (
    <div className="channel-wrapper control has-icons-left">
      <input
        onInput={handleChange}
        value={channel}
        id="channel"
        className={`ag-rounded input ${state}`}
        type="text"
        placeholder={placeholder}
        disabled
      />
      <span className="icon is-small is-left">
        {/* eslint-disable-next-line global-require */}
        <img src={require("../../assets/images/ag-login.png")} alt="" />
      </span>
      <span className="validate-icon">{validateIcon}</span>
      <div className="validate-msg">{errorMsg}</div>
    </div>
  );
};

export default InputChannel;
