import { Record } from "immutable";

const User = new Record({
  key: null,
  uid: null,
  username: null,
  subscription: null,
  roles: null,
  online: null,
});

export default User;
