import { Program } from "nala-shared/typings/general/program";
import * as t from "./action-types";
import * as api from "./api";
import { authActions } from "../auth";
import { ReduxDispatch, ReduxGetState } from "../types/redux";

export function loadProfile() {
  return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    const { id } = getState().auth;
    const profile = await api.getPractitionerProfile(id);
    dispatch({
      type: t.LOAD_PROFILE,
      payload: { profile },
    });
  };
}

export function loadAssignedPatients() {
  return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    const { key, speciality } = getState().auth.practitionerRole.toJSON();
    const healthcareServiceID = getState().auth.activeHealthcareService;

    function loadNumberOfAssignedPatients(
      assignedPatients: api.AssignedPatients
    ) {
      dispatch({
        type: t.LOAD_ASSIGNED_PATIENTS,
        payload: { assignedPatients },
      });
    }

    await api.subscribeAssignedPatients({
      loadNumberOfAssignedPatients,
      practitionerRoleID: key,
      healthcareServiceID,
      speciality,
    });
  };
}

function loadProgramConfigs() {
  return async (dispatch: ReduxDispatch) => {
    const programConfigs = await api.getProgramConfigs();
    dispatch({
      type: t.LOAD_PROGRAM_CONFIGS,
      payload: { programConfigs },
    });
  };
}

export function loadData() {
  return (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    loadProfile()(dispatch, getState);
    loadProgramConfigs()(dispatch);
    if (getState().auth.practitionerRole)
      loadAssignedPatients()(dispatch, getState);
  };
}

export function unloadData() {
  return () => {
    api.unsubscribeAssignedPatients();
  };
}

export type UpdateProfileForm = {
  name: {
    ar: string;
    en: string;
  };
  givenName: {
    ar: string;
    en: string;
  };
};

export function updateProfile(values: UpdateProfileForm) {
  return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    dispatch({
      type: t.UPDATING_PROFILE,
    });
    const updateValues: api.ProfileUpdate = {
      "/name/ar/": values.name.ar,
      "/name/en/": values.name.en,
      "/name/givenName/ar/": values.givenName.ar,
      "/name/givenName/en/": values.givenName.en,
    };
    const { id: practitionerKey } = getState().auth;
    await api.updatePractitionerProfile({
      practitionerKey,
      values: updateValues,
    });

    const profile = await api.getPractitionerProfile(practitionerKey);

    dispatch({
      type: t.UPDATED_PROFILE,
      payload: { profile },
    });
  };
}

export type ParticipatingPrograms = {
  [key in Program]?: boolean;
};

export function updateParticipatingPrograms(
  participatingPrograms: ParticipatingPrograms
) {
  return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    dispatch({
      type: t.UPDATING_PARTICIPATING_PROGRAM,
      payload: { isUpdatingRole: true },
    });
    const { key: practitionerRole } = getState().auth.practitionerRole.toJSON();
    const { id: uid, activeHealthcareService: healthcareService } =
      getState().auth;

    await api.updateParticipatingPrograms({
      participatingPrograms,
      practitionerRole,
      healthcareService,
    });

    const user = { uid };
    authActions.updateRolesAndLinkedHealthCareServices(user)(dispatch);
    dispatch({
      type: t.UPDATING_PARTICIPATING_PROGRAM,
      payload: { isUpdatingRole: false },
    });
  };
}
