// eslint-disable-next-line import/prefer-default-export
export const getTimes = () => {
  const minutes = ["00", "30"];
  const hoursAM = [
    "12",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
  ];
  const hoursPM = [
    "12",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
  ];
  const times = [];
  hoursAM.forEach((hour) =>
    minutes.forEach((minute) => {
      times.push(`${hour}:${minute}am`);
    })
  );
  hoursPM.forEach((hour) =>
    minutes.forEach((minute) => {
      times.push(`${hour}:${minute}pm`);
    })
  );
  return times;
};
