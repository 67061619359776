import { firebaseAuth, firebaseDb } from "../firebase";

const ONLINESTATUS = {
  isOnline: true,
};
const OFFLINESTATUS = {
  isOnline: false,
};

// Switch up the online presence flag
export function goOnlineAPI(user) {
  const userStatusDatabaseRef = firebaseDb
    .ref("users")
    .child(user.uid)
    .child("online/respondblue");
  firebaseDb.ref(".info/connected").on("value", () => {
    userStatusDatabaseRef
      .onDisconnect()
      .set(OFFLINESTATUS)
      .then(() => {
        userStatusDatabaseRef.set(ONLINESTATUS);
      });
  });
}
// Switch down the online presence flag
export function goOfflineAPI(user) {
  const userStatusDatabaseRef = firebaseDb
    .ref("users")
    .child(user.uid)
    .child("online/respondblue");
  userStatusDatabaseRef.set(OFFLINESTATUS);
  userStatusDatabaseRef.onDisconnect().cancel();
  firebaseDb.ref(".info/connected").off(); // turns off the connection listener
}

export function goOnline() {
  return () => {
    const user = firebaseAuth.currentUser;
    if (Boolean(user) && Boolean(user.uid)) {
      goOnlineAPI(user);
    }
  };
}

export function goOffline() {
  return () => {
    const user = firebaseAuth.currentUser;
    if (Boolean(user) && Boolean(user.uid)) {
      goOfflineAPI(user);
    }
  };
}
