import { List, Record, Map, fromJS } from "immutable";
import * as t from "./action-types";

export const BookingState = new Record({
  bookingSlots: new List(),
  loaded: false,
  isCreating: false,
  isUpdatingAvailability: false,
  isChangingDuration: false,
  isUpdatingShifts: false,
  toggledSlots: new Map(),
});

export function bookingsReducer(state = new BookingState(), { payload, type }) {
  const toggleSlotAvailability = (id, availability) => {
    const { toggledSlots } = state;
    const newToggledSlots = toggledSlots.toJS();

    if (newToggledSlots[id] === undefined) newToggledSlots[id] = !availability;
    else delete newToggledSlots[id];

    return newToggledSlots;
  };

  switch (type) {
    case t.LOAD_BOOKING_SLOTS:
      return state.merge({
        bookingSlots: new List(payload.bookingSlotsData),
        loaded: true,
      });

    case t.CREATE_BOOKINGS:
      return state.merge({
        isCreating: payload.isCreating,
      });

    case t.UPDATING_AVAILABILITY:
      return state.merge({
        isUpdatingAvailability: payload.isUpdatingAvailability,
      });

    case t.CHANGING_DURATION:
      return state.merge({
        isChangingDuration: payload.isChangingDuration,
      });

    case t.TOGGLE_SLOT_AVAILABILITY: {
      return state.merge(
        fromJS({
          toggledSlots: toggleSlotAvailability(
            payload.slotID,
            payload.availability
          ),
        })
      );
    }

    case t.UPDATED_AVAILABILITY:
      return state.merge(
        fromJS({
          toggledSlots: {},
          isUpdatingAvailability: false,
        })
      );

    case t.UPDATING_SHIFTS:
      return state.merge({
        isUpdatingShifts: payload.isUpdatingShifts,
      });

    default:
      return state;
  }
}
