import React from "react";
import {
  NumericInput,
  Icon,
  RadioGroup,
  Radio,
  InputGroup,
  Tag,
} from "@blueprintjs/core";

const ObservationInput = ({ type, newValue, setNewValue, preferredUnit }) => {
  const unitTag = <Tag minimal>{preferredUnit}</Tag>;

  if (type === "number") {
    return (
      <NumericInput
        allowNumericCharactersOnly
        name="numericObservation"
        id="numericObservation"
        placeholder={preferredUnit ? `Enter value in ${preferredUnit}` : ""}
        onValueChange={(_valueAsNumber, valueAsString) =>
          setNewValue(valueAsString.split("-").join(""))
        }
        value={newValue}
        buttonPosition="none"
        minorStepSize={0.001}
        rightElement={preferredUnit ? unitTag : null}
        style={{ textAlign: preferredUnit ? "right" : "left" }}
      />
    );
  }
  if (type === "boolean") {
    return (
      <RadioGroup
        inline
        onChange={(event) => {
          if (event.currentTarget.value === "false") {
            setNewValue(false);
          } else if (event.currentTarget.value === "true") {
            setNewValue(true);
          }
        }}
        selectedValue={newValue}
      >
        <Radio
          labelElement={
            <Icon icon="small-tick" iconSize={16} intent="success" />
          }
          value
        />
        <Radio
          labelElement={<Icon icon="cross" iconSize={16} intent="danger" />}
          value={false}
        />
      </RadioGroup>
    );
  }
  return (
    <InputGroup
      name="stringObservation"
      id="stringObservation"
      placeholder={preferredUnit ? `Enter value in ${preferredUnit}` : ""}
      onChange={(event) => setNewValue(event.target.value)}
      value={newValue}
      rightElement={preferredUnit ? unitTag : null}
      style={{ textAlign: preferredUnit ? "right" : "left" }}
    />
  );
};

export default ObservationInput;
