import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { List } from "immutable";
import { Button } from "@blueprintjs/core";
import SessionsHistoryCard from "./SessionsHistoryCard";
import AppointmentHistoryCard from "./AppointmentHistoryCard";

const HistoryList = ({
  sessions = {},
  selectedProfileID = undefined,
  initialDisplayedSessions = 5,
  selectedSession = undefined,
  storagePathResolver,
  conditions,
  medications,
  specialties,
  sendAnswerToHistorySession,
  inverted = false,
  practitioners,
  activeHealthcareService,
  practitionerSpeciality,
  labTests,
  appointments = [],
  encounters = [],
}) => {
  const [numberOfHistorySessions, setNumberOfHistorySessions] = useState(
    initialDisplayedSessions
  );

  const showMoreClicked = () => {
    setNumberOfHistorySessions(numberOfHistorySessions + 4);
  };
  const isShowMoreVisible = sessions.size > numberOfHistorySessions;

  const getHistory = useCallback(() => {
    const asknalaHistory = sessions
      .filter((session) => {
        return (
          session.patientID === selectedProfileID ||
          session.patientID === undefined ||
          selectedProfileID === undefined ||
          (selectedSession && session.sessionKey === selectedSession)
        );
      })
      .map((session) => ({
        ...session,
        type: "chat",
      }));

    const appointmentsHistory = appointments.map((appointment) => {
      const appointmentItem = {
        appointment,
        started: appointment.startTime.toDate().getTime(),
        type: "appointment",
      };
      let appointmentEncounter;
      if (appointment.encounterID) {
        appointmentEncounter = encounters.find(
          (encounter) => encounter.id === appointment.encounter
        );
      }
      if (appointmentEncounter) {
        appointmentItem.encounter = appointmentEncounter;
      }
      return appointmentItem;
    });

    return [...asknalaHistory, ...appointmentsHistory].sort(
      (historyItemA, historyItemB) =>
        historyItemA.started - historyItemB.started
    );
  }, [sessions, appointments, selectedProfileID, selectedSession, encounters]);

  if (sessions.size === 0) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="bp3-text-muted">Patient has no sessions</div>
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: inverted ? "column-reverse" : "column",
      }}
    >
      {isShowMoreVisible && (
        <Button
          style={{ marginBottom: "10px" }}
          icon="refresh"
          fill
          large
          minimal
          onClick={() => showMoreClicked()}
          text="Load earlier sessions..."
        />
      )}
      {getHistory()
        .slice(-numberOfHistorySessions)
        .map((historyItem) => {
          if (historyItem.type === "chat")
            return (
              <SessionsHistoryCard
                key={historyItem.key}
                session={historyItem}
                storagePathResolver={storagePathResolver}
                conditions={conditions}
                medications={medications}
                specialties={specialties}
                sendAnswerToHistorySession={sendAnswerToHistorySession}
                practitioners={practitioners}
                activeHealthcareService={activeHealthcareService}
                practitionerSpeciality={practitionerSpeciality}
                labTests={labTests}
              />
            );
          return (
            <AppointmentHistoryCard
              key={historyItem.appointment.id}
              appointment={historyItem.appointment}
              encounter={historyItem.encounter}
            />
          );
        })}
    </div>
  );
};

HistoryList.propTypes = {
  sessions: PropTypes.instanceOf(List),
};

export default HistoryList;
