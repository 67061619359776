import * as t from "./action-types";
// eslint-disable-next-line import/no-cycle
import api from "./api";
import { authActions } from "../auth";

const moment = require("moment-timezone");

export function loadBookingSlots() {
  return async (dispatch, getState) => {
    const { key, speciality } = getState().auth.practitionerRole.toJSON();
    const healthcareServiceID = getState().auth.activeHealthcareService;

    function loadBookings(bookingSlotsData) {
      dispatch({
        type: t.LOAD_BOOKING_SLOTS,
        payload: { bookingSlotsData },
      });
    }
    await api.subscribeBookingSlots({
      loadBookings,
      practitionerRoleID: key,
      healthcareServiceID,
      speciality,
    });
  };
}

export function loadData() {
  return (dispatch, getState) => {
    if (getState().auth.practitionerRole)
      loadBookingSlots()(dispatch, getState);
  };
}

export function unloadData() {
  return () => {
    api.unsubscribeBookingSlots();
  };
}

function getIntervalsInDay(duration, dailyShifts, daysOffset) {
  const intervals = [];
  dailyShifts.forEach((shift) => {
    const startTime = moment
      .utc(shift.startAt, "HH:mm")
      .add(daysOffset, "days");
    const endTime = moment.utc(shift.endAt, "HH:mm").add(daysOffset, "days");
    if (endTime.isBefore(startTime) || endTime.isSame(startTime)) {
      endTime.add(1, "d");
    }
    const current = startTime.clone();
    while (
      current.clone().add(duration, "minutes").isSame(endTime) ||
      current.clone().add(duration, "minutes").isBefore(endTime)
    ) {
      intervals.push(current.toDate());
      current.add(duration, "minutes");
    }
  });
  return intervals;
}

function getIntervals({ startDate, duration, days = 15, shifts }) {
  if (!(shifts && Object.keys(shifts).length)) return [];
  const day = startDate.clone();
  const endDate = startDate.clone().add(days, "days");

  let daysOffset = startDate.diff(moment.utc().startOf("d"), "days");
  const intervals = [];
  while (day.isBefore(endDate)) {
    const dailyShifts = shifts[day.format("dddd")];
    if (dailyShifts && dailyShifts.length > 0) {
      intervals.push(getIntervalsInDay(duration, dailyShifts, daysOffset));
    }
    day.add(1, "days");
    daysOffset += 1;
  }
  return intervals.flat();
}

function isNumberGreaterThanTen(value) {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) return false;
  const numericValue = parseInt(value, 10);
  if (numericValue >= 10) return true;
  return false;
}

export function createTimeSlots(duration) {
  return async (dispatch, getState) => {
    const { practitionerRole } = getState().auth.toJS();
    if (!practitionerRole) return null;
    const { shifts } = practitionerRole;

    if (!isNumberGreaterThanTen(duration)) return false;
    dispatch({
      type: t.CREATE_BOOKINGS,
      payload: {
        isCreating: true,
      },
    });
    const { key, speciality } = getState().auth.practitionerRole.toJSON();
    const healthcareServiceID = getState().auth.activeHealthcareService;

    const today = moment.utc();
    const intervals = getIntervals({
      startDate: today,
      duration,
      shifts,
    });

    const bookingSlots = intervals.map((interval) => {
      return {
        practitionerRoleID: key,
        healthcareServiceID,
        speciality,
        duration,
        startDate: interval.getTime(),
        isAvailable: true,
      };
    });
    await api.updateDurationPerPatient({
      practitionerRoleID: key,
      healthcareServiceID,
      duration,
    });
    const result = await api.createBookingSlots({ bookingSlots });
    if (result.created) loadBookingSlots()(dispatch, getState);

    return dispatch({
      type: t.CREATE_BOOKINGS,
      payload: {
        isCreating: false,
      },
    });
  };
}

export function updateAvailability() {
  return async (dispatch, getState) => {
    dispatch({
      type: t.UPDATING_AVAILABILITY,
      payload: { isUpdatingAvailability: true },
    });

    const toggledSlots = getState().bookings.toggledSlots.toJS();
    await api.updateSlotAvailability({
      toggledSlots,
    });

    dispatch({
      type: t.UPDATED_AVAILABILITY,
      payload: { isUpdatingAvailability: false },
    });
  };
}

export function changeSlotDuration(duration) {
  return async (dispatch, getState) => {
    const { practitionerRole } = getState().auth.toJS();
    if (!practitionerRole) return null;
    const { shifts } = practitionerRole;

    if (!isNumberGreaterThanTen(duration)) return false;
    const { bookingSlots } = getState().bookings;
    const { key, speciality } = getState().auth.practitionerRole.toJSON();
    const healthcareServiceID = getState().auth.activeHealthcareService;

    let dateOfLatestAppointment = moment.utc().add(-1, "days");
    bookingSlots.forEach((slot) => {
      // Update slots only for days after booked appointment
      const slotDate = moment.utc(slot.startDate.toDate());
      if (
        slot.appointmentID &&
        dateOfLatestAppointment &&
        slotDate.isAfter(dateOfLatestAppointment)
      ) {
        dateOfLatestAppointment = slotDate.clone();
      } else if (slot.appointmentID && !dateOfLatestAppointment) {
        dateOfLatestAppointment = slotDate.clone();
      }
    });
    const today = moment.utc().startOf("day");
    const dayToUpdateFrom = dateOfLatestAppointment
      .clone()
      .add("1", "days")
      .startOf("day");

    const bufferDays = 15;
    const daysToGenerate = bufferDays - dayToUpdateFrom.diff(today, "days");
    const intervals = getIntervals({
      startDate: dayToUpdateFrom,
      duration,
      shifts,
      days: daysToGenerate,
    });

    dispatch({
      type: t.CHANGING_DURATION,
      payload: { isChangingDuration: true },
    });

    const bookingSlotsNew = intervals.map((interval) => {
      return {
        practitionerRoleID: key,
        healthcareServiceID,
        speciality,
        duration,
        startDate: interval.getTime(),
        isAvailable: true,
      };
    });
    await api.updateDurationPerPatient({
      practitionerRoleID: key,
      healthcareServiceID,
      duration,
    });
    const result = await api.updateSlotDuration({
      practitionerRoleID: key,
      healthcareServiceID,
      speciality,
      dayToUpdateFrom: dayToUpdateFrom.toDate(),
      bookingSlots: bookingSlotsNew,
    });

    if (result.created) loadBookingSlots()(dispatch, getState);

    return dispatch({
      type: t.CHANGING_DURATION,
      payload: { isChangingDuration: false },
    });
  };
}

export function toggleSlotAvailability(id, availability) {
  return (dispatch) => {
    dispatch({
      type: t.TOGGLE_SLOT_AVAILABILITY,
      payload: {
        slotID: id,
        availability,
      },
    });
  };
}

export function updateShifts(shifts) {
  return async (dispatch, getState) => {
    dispatch({
      type: t.UPDATING_SHIFTS,
      payload: { isUpdatingShifts: true },
    });
    const { key: practitionerRole, durationPerPatient } =
      getState().auth.practitionerRole.toJSON();
    const { id: uid, activeHealthcareService: healthcareService } =
      getState().auth;

    await api.updateShifts({
      shifts,
      practitionerRole,
      healthcareService,
    });

    const user = { uid };
    await authActions.loadPractitionerRoleData(user)(dispatch, getState);
    // Update appointment slots with new shift
    await changeSlotDuration(durationPerPatient)(dispatch, getState);

    dispatch({
      type: t.UPDATING_SHIFTS,
      payload: { isUpdatingShifts: false },
    });
  };
}
