import React, { useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import {
  Icon,
  Button,
  RadioGroup,
  Radio,
  Intent,
  Callout,
  Switch,
  Card,
} from "@blueprintjs/core";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useWindowWidth } from "@react-hook/window-size";

import Date from "./Date";

const moment = require("moment-timezone");

const BookingsCalendar = ({
  bookingSlots,
  updateAvailability,
  isUpdatingAvailability,
  changeSlotDuration,
  isChangingDuration,
  toggledSlots,
  toggleSlotAvailability,
}) => {
  const windowWidth = useWindowWidth();
  const daysToShow = 15;

  const [newDuration, setNewDuration] = useState(0);
  const [showOnlyActiveSlots, setShowOnlyActiveSlots] = useState(false);

  const getSlotsByDay = () => {
    const slotsByDay = [];
    bookingSlots.forEach((slot) => {
      const startDate = moment(slot.startDate.toDate());
      const today = moment().startOf("day");
      const dayOffset = startDate.clone().startOf("day").diff(today, "days");

      const updatedSlot = { ...slot };
      if (toggledSlots.has(slot.id))
        updatedSlot.toggled = toggledSlots.get(slot.id);

      if (dayOffset >= 0 && dayOffset < daysToShow)
        if (slotsByDay[dayOffset])
          slotsByDay[dayOffset].push({
            ...updatedSlot,
            startMoment: startDate,
          });
        else
          slotsByDay[dayOffset] = [{ ...updatedSlot, startMoment: startDate }];
    });
    return slotsByDay;
  };

  const slotsByDay = getSlotsByDay();

  const hasToggled = () => {
    return toggledSlots.size > 0;
  };

  const getNumOfSlides = () => {
    const numberOfAvailableDays = Object.keys(slotsByDay).length;
    if (windowWidth > 1024) return Math.min(7, numberOfAvailableDays);
    return Math.min(windowWidth > 768 ? 5 : 3, numberOfAvailableDays);
  };

  const submitDurationHandler = () => {
    if (newDuration) changeSlotDuration(newDuration);
  };

  const hasAvailableSlots = () =>
    bookingSlots && bookingSlots.some((slot) => slot.isAvailable);

  const showNavigationButton = () => {
    const totalSlides = Object.keys(slotsByDay).length;
    const visibleSlides = getNumOfSlides();
    return visibleSlides < totalSlides;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div
        style={{
          overflowY: "auto",
        }}
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={Object.keys(slotsByDay).length}
          visibleSlides={getNumOfSlides()}
          isIntrinsicHeight
          step={getNumOfSlides()}
        >
          <div style={{ display: "flex" }}>
            {showNavigationButton() && (
              <ButtonBack
                style={{
                  borderColor: "rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  paddingTop: 100,
                }}
              >
                <Icon icon="chevron-left" iconSize={66} color="#2B95D6" />
              </ButtonBack>
            )}
            <Slider>
              {slotsByDay.map((slots, dayOffset) => (
                <Slide
                  index={dayOffset}
                  key={slots[0].startMoment.format("DD-MM-YYYY")}
                >
                  <Date
                    slots={slots}
                    dayOffset={dayOffset}
                    toggleSlotAvailability={toggleSlotAvailability}
                    showOnlyActiveSlots={showOnlyActiveSlots}
                  />
                </Slide>
              ))}
            </Slider>
            {showNavigationButton() && (
              <ButtonNext
                style={{
                  borderColor: "rgba(0, 0, 0, 0.1)",
                  backgroundColor: "rgba(0, 0, 0, 0.01)",
                  display: "flex",
                  paddingTop: 100,
                }}
              >
                <Icon
                  icon="chevron-right"
                  iconSize={66}
                  intent={Intent.PRIMARY}
                />
              </ButtonNext>
            )}
          </div>
        </CarouselProvider>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          // flex: 1,
          flexBasis: "auto",
          flexDirection: "horizontal",
          alignSelf: "flex-start",
          paddingLeft: 15,
          marginTop: 20,
          // justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "horizontal",
            alignItems: "center",
            marginLeft: 15,
            justifyContent: "center",
            width: "35%",
          }}
        >
          <div style={{ marginBottom: 10, fontSize: 12 }}>
            <RadioGroup
              label="Change slots duration (minutes) per patient:"
              // label="Change duration for each patient"
              labelFor="duration"
              onChange={(event) => {
                setNewDuration(Number(event.target.value));
              }}
              selectedValue={newDuration}
              inline
            >
              <Radio label="10 min (6 patients per hour)" value={10} />
              <Radio label="15 min (4 patients per hour)" value={15} />
              <Radio label="20 min (3 patients per hour)" value={20} />
              <Radio label="30 min (2 patients per hour)" value={30} />
              <Radio label="60 min (1 patient per hour)" value={60} />
            </RadioGroup>
            <Button
              text="Save Duration"
              intent={Intent.SUCCESS}
              type="submit"
              loading={isChangingDuration}
              onClick={() => {
                submitDurationHandler();
                setNewDuration(0);
              }}
              style={{ height: 35, marginVertical: 30 }}
              disabled={newDuration === 0}
            />
          </div>
        </div>

        <div
          style={{
            marginLeft: 15,
            alignSelf: "center",
            justifySelf: "stretch",
          }}
        >
          <Callout intent="primary" title="Please Note">
            <ul
              style={{
                listStyle: "circle",
                marginLeft: 15,
                fontSize: 10,
                paddingRight: 10,
              }}
            >
              <li>
                To change slot availability, select the slots to change and
                click on Save Availability button{" "}
                <span role="img" aria-label="Tick">
                  ✅
                </span>
              </li>
              <li>
                New slots will be added to your calendar daily, having the same
                available slots you previously set{" "}
                <span role="img" aria-label="Puzzle Piece">
                  🧩
                </span>
                . Kindly make sure to change them if needed.
              </li>
              <li>
                Slots duration can be changed starting from the last day that
                contains a booked slot{" "}
                <span role="img" aria-label="Bookmark">
                  🔖
                </span>
              </li>
            </ul>
          </Callout>
        </div>

        {hasToggled() && (
          <div
            style={{
              marginLeft: 15,
              marginRight: 30,
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              text="Save Availability"
              intent={Intent.SUCCESS}
              type="submit"
              style={{
                minWidth: 140,
                height: 35,
                alignSelf: "center",
              }}
              onClick={() => updateAvailability(toggledSlots)}
              loading={isUpdatingAvailability}
            />
          </div>
        )}
        {!hasToggled() && hasAvailableSlots() && (
          <div
            style={{
              marginLeft: 15,
              marginRight: 30,
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Card
              style={{
                margin: 10,
                paddingHorizontal: 15,
                display: "flex",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Switch
                style={{ marginBottom: 0, fontSize: 14 }}
                label="Show only available slots"
                large
                defaultChecked={showOnlyActiveSlots}
                onChange={(event) => {
                  setShowOnlyActiveSlots(event.target.checked);
                }}
              />
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingsCalendar;
