import React, { useEffect, useState } from "react";
import DispenseRequestsFilter from "./DispenseRequestsFilter";
import NoDispenseRequests from "./NoDispenseRequests";
import UserDispenseRequestsCard from "./UserDispenseRequestsCard";

const DispenseRequestUsersCards = ({
  usersWithDispenseRequests,
  pharmacyOrders,
  status,
  isSearchable = false,
  tab,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState("all");
  const [branchFilter, setBranchFilter] = useState("all");
  const [listOfBranches, setListOfBranches] = useState([]);
  const [showOnlyWasfatyPrescriptions, setShowOnlyWasfatyPrescriptions] =
    useState(false);

  useEffect(() => {
    let listOfBranchesArray = [
      ...Object.values(usersWithDispenseRequests),
      ...Object.values(pharmacyOrders),
    ]
      .filter((userWithDispenseRequests) => {
        const { destination } = userWithDispenseRequests[0].dispenseRequest;
        return destination && destination.branchName;
      })
      .map((userWithDispenseRequestsAndBranchName) => {
        if (
          userWithDispenseRequestsAndBranchName[0].dispenseRequest.destination
            .branchCode
        ) {
          return `${userWithDispenseRequestsAndBranchName[0].dispenseRequest.destination.branchName} - ${userWithDispenseRequestsAndBranchName[0].dispenseRequest.destination.branchCode}`;
        }
        return userWithDispenseRequestsAndBranchName[0].dispenseRequest
          .destination.branchName;
      });

    // removing duplicates
    listOfBranchesArray = listOfBranchesArray.filter((item, pos) => {
      return listOfBranchesArray.indexOf(item) === pos;
    });

    if (listOfBranchesArray.length > 0) {
      setListOfBranches(listOfBranchesArray);
    }
  }, [usersWithDispenseRequests, pharmacyOrders]);

  const filteredUsersWithDispenseRequests = [
    ...Object.values(usersWithDispenseRequests),
    ...Object.values(pharmacyOrders),
  ]
    .filter((userWithDispenseRequests) => {
      const { userInfo } = userWithDispenseRequests[0].dispenseRequest;
      if (
        userInfo.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        userInfo.phoneNumber.includes(searchTerm)
      ) {
        return true;
      }
      return false;
    })
    .filter((userWithDispenseRequests) => {
      const { type } = userWithDispenseRequests[0].dispenseRequest;
      if (typeFilter === "all") {
        return true;
      }
      if (typeFilter === "storePickup") {
        return type === "storePickup";
      }
      if (typeFilter === "delivery") {
        return type === "delivery";
      }
      return false;
    })
    .filter((userWithDispenseRequests) => {
      const { destination, type } = userWithDispenseRequests[0].dispenseRequest;
      if (branchFilter === "all") {
        return true;
      }
      if (
        destination &&
        type === "storePickup" &&
        (`${destination.branchName} - ${destination.branchCode}` ===
          branchFilter ||
          destination.branchName === branchFilter)
      ) {
        return true;
      }
      return false;
    })
    .filter((userWithDispenseRequests) => {
      const requester =
        userWithDispenseRequests[0].medicationRequest?.requester;
      if (showOnlyWasfatyPrescriptions) {
        if (requester === "wasfaty") {
          return true;
        }
        return false;
      }
      return true;
    });

  const userCards = Object.values(filteredUsersWithDispenseRequests)
    .sort((userWithDispenseRequestsA, userWithDispenseRequestsB) => {
      return (
        userWithDispenseRequestsB[0].dispenseRequest.whenCreated -
        userWithDispenseRequestsA[0].dispenseRequest.whenCreated
      );
    })
    .map((userWithDispenseRequests) => {
      const { orderID } = userWithDispenseRequests[0].dispenseRequest;
      const isPharmacyItem = Boolean(
        userWithDispenseRequests[0].pharmacyItem?.sku
      );
      const key = `${orderID}${status}${isPharmacyItem ? "pharmacyItems" : ""}`;

      return (
        <UserDispenseRequestsCard
          key={key}
          userWithDispenseRequests={userWithDispenseRequests}
          tab={tab}
          isPharmacyItem={isPharmacyItem}
        />
      );
    });

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <DispenseRequestsFilter
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isSearchable={isSearchable}
          setTypeFilter={setTypeFilter}
          listOfBranches={listOfBranches}
          setBranchFilter={setBranchFilter}
          showOnlyWasfatyPrescriptions={showOnlyWasfatyPrescriptions}
          setShowOnlyWasfatyPrescriptions={setShowOnlyWasfatyPrescriptions}
        />
        {Object.keys(filteredUsersWithDispenseRequests).length === 0 ? (
          <NoDispenseRequests
            message={`There are no ${status} dispense requests`}
          />
        ) : (
          <>{userCards}</>
        )}
      </div>
    </>
  );
};

export default DispenseRequestUsersCards;
