import React, { useState } from "react";
import { Button, Intent } from "@blueprintjs/core";
import ShiftSlot from "./ShiftSlot";

const moment = require("moment-timezone");

const ShiftSlots = ({
  addShift,
  editShift,
  deleteShift,
  day,
  dayName,
  utcShifts,
  shifts,
  updateShifts,
  disableSaveButton,
  isUpdatingShifts,
  checkShiftValidity,
}) => {
  const [addingShift, setAddingShift] = useState(false);

  const compareShifts = (shiftA, shiftB) => {
    const shiftStartA = moment(shiftA.startAt, "HH:mma");
    const shiftStartB = moment(shiftB.startAt, "HH:mma");
    return shiftStartA.isAfter(shiftStartB) ? 1 : -1;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        minWidth: 280,
        marginLeft: 10,
      }}
    >
      {shifts[dayName] &&
        shifts[dayName].length > 0 &&
        Object.values([...shifts[dayName]].sort(compareShifts)).map((shift) => (
          <div style={{ marginBottom: 10 }} key={shift.startAt}>
            <ShiftSlot
              startAt={moment(shift.startAt, "HH:mm").format("hh:mma")}
              endAt={moment(shift.endAt, "HH:mm").format("hh:mma")}
              type="added"
              addShift={addShift}
              day={day}
              editShift={editShift}
              deleteShift={deleteShift}
              checkShiftValidity={checkShiftValidity}
            />
          </div>
        ))}
      {addingShift && (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <ShiftSlot
            startAt="08:30am"
            endAt="09:30am"
            type="add"
            addShift={addShift}
            day={day}
            setAddingShift={setAddingShift}
            editShift={editShift}
            deleteShift={deleteShift}
            checkShiftValidity={checkShiftValidity}
          />
        </div>
      )}
      <div
        style={{
          marginTop: "auto",
          alignSelf: "center",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          disabled={addingShift}
          text="Add a shift"
          intent={Intent.SUCCESS}
          onClick={() => setAddingShift(true)}
          style={{ alignSelf: "center", width: 100, marginRight: 5 }}
        />

        <Button
          disabled={disableSaveButton}
          text="Save"
          intent={Intent.SUCCESS}
          type="submit"
          loading={isUpdatingShifts}
          style={{ width: 100, marginLeft: 5 }}
          onClick={() => updateShifts(utcShifts)}
        />
      </div>
    </div>
  );
};

export default ShiftSlots;
