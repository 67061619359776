import React from "react";
import { Intent } from "@blueprintjs/core";
import { VideoCallCard } from "../VideoCallCard";
import {
  formatTime,
  getPractitionerNameFromPractitionersList,
} from "../../utils";
import UserConversationText from "./UserConversationText";
import ServiceProviderConversationText from "./ServiceProviderConversationText";
import PrescriptionCard from "../PrescriptionCard";

const ConversationUnit = ({
  question = {},
  storagePathResolver,
  practitioners,
  practitionerSpeciality,
}) => {
  const hasResponse = question && question.responded;
  const emergency = !!question.action && question.action.emergency;
  const hasAction = Boolean(question.action);

  const serviceProviderResponse = () => {
    if (question) {
      if (hasAction) {
        if (emergency) {
          return "Sent to emergency";
        }

        const { videoCall, treatmentPlan, prescription } = question.action;
        if (videoCall) {
          return (
            <VideoCallCard
              // videoCall={videoCall}
              practitionerSpeciality={practitionerSpeciality}
            />
          );
        }
        if (prescription) {
          return (
            <PrescriptionCard
              answer={question.answer}
              prescriptionRef={prescription.prescriptionRef}
            />
          );
        }
        if (treatmentPlan) {
          return `${
            treatmentPlan && treatmentPlan.name && treatmentPlan.name.arabic
              ? `${treatmentPlan.name.arabic}  :`
              : ""
          } ${treatmentPlan.brief}`;
        }
      }
    }
    return question.answer;
  };

  const getResponseIntent = () => {
    if (emergency) {
      return Intent.DANGER;
    }
    if (hasAction) {
      return Intent.SUCCESS;
    }
    return Intent.NONE;
  };

  const isSymptom = typeof question.question !== "object";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "2px",
      }}
    >
      <UserConversationText
        text={isSymptom ? question.question : "Symptom"}
        attachment={question.attachment}
        storagePathResolver={storagePathResolver}
        time={formatTime(question.sent)}
        appendedQuestions={question.appendedQuestions}
      />
      {hasResponse && (
        <ServiceProviderConversationText
          text={serviceProviderResponse()}
          intent={getResponseIntent()}
          practitioner={getPractitionerNameFromPractitionersList(
            practitioners,
            question.respondedBy
          )}
          time={formatTime(question.responded)}
        />
      )}
    </div>
  );
};

export default ConversationUnit;
