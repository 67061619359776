// import accountList from "./account-list";
import { FirebaseList } from "../firebase";
import Account from "./account";

import {
  UPDATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_SUCCESS,
  REMOVE_ACCOUNT_SUCCESS,
  LOAD_ACCOUNTS_SUCCESS,
  UNLOAD_ACCOUNTS_SUCCESS,
} from "./action-types";

import api from "./api";

export function createAccountSuccess(account) {
  return {
    type: CREATE_ACCOUNT_SUCCESS,
    payload: account,
  };
}

export function removeAccountSuccess(account) {
  return {
    type: REMOVE_ACCOUNT_SUCCESS,
    payload: account,
  };
}

export function updateAccountSuccess(account) {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
    payload: account,
  };
}

export function loadAccountsSuccess(accounts) {
  return {
    type: LOAD_ACCOUNTS_SUCCESS,
    payload: accounts,
  };
}

/** account-list */

const accountList = new FirebaseList(
  {
    onAdd: createAccountSuccess,
    onChange: updateAccountSuccess,
    onRemove: removeAccountSuccess,
    onLoad: loadAccountsSuccess,
  },
  Account,
  null,
  {
    path: "activeSession",
    operation: "startAt",
    value: true,
  }
);

/** */

export function loadAccounts() {
  return (dispatch) => {
    accountList.path = `asknala/`;
    accountList.subscribe(dispatch);
  };
}

export function addTypeLabel(userID, sessionKey, newLabel) {
  return () => {
    api.addTypeLabel(userID, sessionKey, newLabel);
  };
}

export function unloadAccounts() {
  accountList.unsubscribe();
  return {
    type: UNLOAD_ACCOUNTS_SUCCESS,
  };
}
