import { Record } from "immutable";

const Account = new Record({
  key: null,
  sessions: null,
  activeSession: null,
  activeQuestion: null,
  needsProvider: null,
  isOnline: null,
  sessionsThatNeedProvider: null,
});

export default Account;
