//= ====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export function getAuth(state) {
  return state.auth.toJS();
}

// ==========================

export function isAuthenticated(state) {
  return getAuth(state).authenticated;
}

export function getActiveHealthcareService(state) {
  return getAuth(state).activeHealthcareService;
}

export function getPractitionerSpeciality(state) {
  const { practitionerRole } = getAuth(state);
  if (practitionerRole && practitionerRole.speciality) {
    return practitionerRole.speciality;
  }
  return undefined;
}
