export const CREATE_SPECIALTY_ERROR = "CREATE_SPECIALTY_ERROR";
export const CREATE_SPECIALTY_SUCCESS = "CREATE_SPECIALTY_SUCCESS";

export const REMOVE_SPECIALTY_ERROR = "REMOVE_SPECIALTY_ERROR";
export const REMOVE_SPECIALTY_SUCCESS = "REMOVE_SPECIALTY_SUCCESS";

export const UNDELETE_SPECIALTY_ERROR = "UNDELETE_SPECIALTY_ERROR";

export const UPDATE_SPECIALTY_ERROR = "UPDATE_SPECIALTY_ERROR";
export const UPDATE_SPECIALTY_SUCCESS = "UPDATE_SPECIALTY_SUCCESS";

export const LOAD_SPECIALTIES_SUCCESS = "LOAD_SPECIALTIES_SUCCESS";
export const LOAD_SPECIALTIES_ERROR = "LOAD_SPECIALTIES_ERROR";

export const UNLOAD_SPECIALTIES = "UNLOAD_SPECIALTIES";
