import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Particles from "react-particles-js";

const SignInPage = () => {
  return (
    <div className="g-row sign-in">
      <Particles
        color="red"
        width="100%"
        height="100%"
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          color: "red",
          zIndex: "10",
        }}
        params={{ particles: { color: { value: "#67B9C7" } } }}
      />
    </div>
  );
};

//= ====================================
//  CONNECT
//-------------------------------------

const mapDispatchToProps = {};

export default withRouter(connect(null, mapDispatchToProps)(SignInPage));
