/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
function getDispenseRequestsReducer(state) {
  return state.dispenseRequests;
}

export function getDispenseRequests(state) {
  const allMedicationRequests =
    getDispenseRequestsReducer(state).dispenseRequestsList.toJS();

  const dispenseRequestsGroups = {
    pending: {},
    cancelled: {},
    readyForPickup: {},
    readyForDelivery: {},
    completed: {},
  };

  // looping throw the medication requests
  allMedicationRequests.forEach((medicationRequest) => {
    const { dispenseRequests, subject, requesterType } = medicationRequest;

    // looping throw the dispenseRequests inside the medication request
    for (const dispenseRequestKey in dispenseRequests) {
      const dispenseRequest = {
        ...dispenseRequests[dispenseRequestKey],
        key: dispenseRequestKey,
      };

      const { orderID } = dispenseRequest;

      const addDispenseRequestToGroup = ({ group }) => {
        const key = `${subject}${orderID}${requesterType}`;
        if (!Array.isArray(dispenseRequestsGroups[group][key])) {
          dispenseRequestsGroups[group][key] = [];
        }
        dispenseRequestsGroups[group][key].push({
          dispenseRequest,
          medicationRequest,
        });
      };

      if (dispenseRequest.status === "preparation") {
        addDispenseRequestToGroup({ group: "pending" });
      }

      if (dispenseRequest.status === "on-hold") {
        if (dispenseRequest.type === "storePickup") {
          addDispenseRequestToGroup({ group: "readyForPickup" });
        }
        if (dispenseRequest.type === "delivery") {
          addDispenseRequestToGroup({ group: "readyForDelivery" });
        }
      }

      if (dispenseRequest.status === "completed") {
        addDispenseRequestToGroup({ group: "completed" });
      }

      if (dispenseRequest.status === "cancelled") {
        addDispenseRequestToGroup({ group: "cancelled" });
      }
    }
  });

  return dispenseRequestsGroups;
}

export function getIsDispenseRequestsLoaded(state) {
  return getDispenseRequestsReducer(state).loaded;
}

export function getIsPharmacyOrdersLoaded(state) {
  return getDispenseRequestsReducer(state).loadedPharmacyOrderInvoices;
}

export function getPharmacyOrders(state) {
  const invoices =
    getDispenseRequestsReducer(state).pharmacyOrderInvoices.toJS();
  const { activeHealthcareService } = state.auth.toJS();
  const dispenseRequestsGroups = {
    pending: {},
    cancelled: {},
    readyForPickup: {},
    readyForDelivery: {},
    completed: {},
  };

  invoices.forEach((invoice) => {
    const { items, orderID, subject } = invoice;
    items.forEach((item) => {
      if (item.sku && item.performer === activeHealthcareService) {
        const { dispenseRequests } = item;
        for (const dispenseRequestKey in dispenseRequests) {
          const dispenseRequest = {
            key: dispenseRequestKey,
            ...dispenseRequests[dispenseRequestKey],
          };
          const addDispenseRequestToGroup = ({ group }) => {
            if (!Array.isArray(dispenseRequestsGroups[group][orderID])) {
              dispenseRequestsGroups[group][orderID] = [];
            }
            dispenseRequestsGroups[group][orderID].push({
              dispenseRequest,
              pharmacyItem: {
                dispenseRequest: {
                  initialFill: {
                    quantity: {
                      value: item.quantity,
                    },
                  },
                },
                invoiceKey: invoice.key,
                subject,
                ...item,
              },
            });
          };

          if (dispenseRequest.status === "preparation") {
            addDispenseRequestToGroup({ group: "pending" });
          }
          if (dispenseRequest.status === "on-hold") {
            if (dispenseRequest.type === "storePickup") {
              addDispenseRequestToGroup({ group: "readyForPickup" });
            }
            if (dispenseRequest.type === "delivery") {
              addDispenseRequestToGroup({ group: "readyForDelivery" });
            }
          }
          if (dispenseRequest.status === "completed") {
            addDispenseRequestToGroup({ group: "completed" });
          }
          if (dispenseRequest.status === "cancelled") {
            addDispenseRequestToGroup({ group: "cancelled" });
          }
        }
      }
    });
  });
  return dispenseRequestsGroups;
}
