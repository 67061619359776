import { firebaseDb } from "../firebase";

export function toggleActive(inviteKey, value) {
  const done = firebaseDb
    .ref("promocodes")
    .child("invites")
    .child(inviteKey)
    .update({
      isActive: Boolean(value),
    });

  return done;
}

export function updateInvite(inviteKey, values) {
  const done = firebaseDb
    .ref("promocodes")
    .child("invites")
    .child(inviteKey.toLowerCase())
    .update({
      ...values,
    });

  return done;
}
