import { Record } from "immutable";

const Invite = new Record({
  key: null,
  isActive: false,
  isArchived: false,
  startTime: null,
  endTime: null,
  discount: null,
  usageLimit: null,
  usedBy: null,
});

export default Invite;
