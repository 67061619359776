import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Card, Elevation, H5, Intent, Tag } from "@blueprintjs/core";

const AppointmentHistoryCard = ({ appointment, encounter }) => {
  const getDay = () => {
    return moment(appointment.startTime.toDate()).format("D");
  };
  const getMonthAndYear = () => {
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = appointment.startTime.toDate();
    return `${MONTHS[date.getMonth()]}. ${`${date.getFullYear()}`.slice(
      2,
      4
    )}'`;
  };

  const getTime = () => {
    return moment(appointment.startTime.toDate()).format("hh:mm A");
  };

  const renderPractitioner = () => {
    let result = "";
    const practitionerName = appointment?.serviceProvider?.profile?.name?.text;
    if (practitionerName) {
      result = `with ${practitionerName}`;
    }
    return result;
  };

  const getSessionSpeciality = () => {
    if (appointment.speciality === "mental-health") {
      return "Mental Health";
    }
    return "Primary Care";
  };

  const getStatus = () => {
    switch (appointment.status) {
      case "booked":
        return {
          text: "Booked",
          intent: Intent.PRIMARY,
        };
      case "arrived":
        return {
          text: "Patient Checked In",
          intent: Intent.PRIMARY,
        };
      case "fulfilled":
        if (encounter) {
          if (encounter.status === "in-progress") {
            return {
              text: "In Progress",
              intent: Intent.PRIMARY,
            };
          }
          if (encounter.status === "finished") {
            return {
              text: "Fulfilled",
              intent: Intent.SUCCESS,
            };
          }
        }
        return {
          text: "Fulfilled",
          intent: Intent.SUCCESS,
        };
      case "noshow":
        return {
          text: "Patient No Show",
          intent: Intent.WARNING,
        };
      case "provider-noshow":
        return {
          text: "Provider No Show",
          intent: Intent.WARNING,
        };
      case "cancelled":
        return {
          text: "Revoked",
          intent: Intent.DANGER,
        };
      default:
        return null;
    }
  };

  return (
    <Card style={{ marginBottom: "10px" }} elevation={Elevation.TWO}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          <H5
            style={{
              marginBottom: 2,
            }}
          >
            {getDay()}
          </H5>
          <div
            className="bp3-text-muted"
            style={{ textTransform: "uppercase" }}
          >
            {getMonthAndYear()}
          </div>
        </div>
        <div
          style={{
            marginLeft: "16px",
          }}
        >
          <div
            style={{
              marginBottom: 3,
            }}
          >
            {getSessionSpeciality()} Appointment {renderPractitioner()}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              className="bp3-text-muted bp3-text-small"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 5,
              }}
            >
              <p>{getTime()}</p>
            </div>
            <Tag
              style={{ marginTop: 5, marginLeft: 8 }}
              intent={getStatus().intent}
              minimal
              round
            >
              <p>{getStatus().text}</p>
            </Tag>
          </div>
        </div>
      </div>
    </Card>
  );
};

AppointmentHistoryCard.propTypes = {
  session: PropTypes.shape({}),
};

export default AppointmentHistoryCard;
