import { Button, H5, Intent } from "@blueprintjs/core";
import React from "react";

const SelectedObservationsTable = ({ selectedObservations }) => {
  if (selectedObservations.length > 0) {
    return selectedObservations.map((selectedObservationType, index) => {
      return (
        <div
          key={selectedObservationType.observationType.key}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div style={{ flex: 1, marginRight: 10 }}>
            <H5 className="bp3-text-muted">
              {index + 1}){" "}
              {selectedObservationType.observationType.displayName.en}
            </H5>
          </div>
          <div style={{ flex: 1, marginRight: 10 }}>
            <H5 className="bp3-text-muted">
              {selectedObservationType.newValue.toString()}
            </H5>
          </div>
          <div style={{ flex: 1, marginRight: 5 }}>
            <H5 className="bp3-text-muted">
              {selectedObservationType.newUnit}
            </H5>
          </div>
          <div style={{ visibility: "hidden", marginLeft: 5 }}>
            <Button icon="plus" intent={Intent.SUCCESS} type="submit" />
          </div>
        </div>
      );
    });
  }
  return <></>;
};

export default SelectedObservationsTable;
