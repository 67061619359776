import { Speciality } from "nala-shared/typings/general/speciality";
import {
  Program,
  ProgramDefinition,
} from "nala-shared/typings/general/program";
import { firebaseDb, firestore } from "../firebase";

export async function getPractitionerProfile(practitionerKey: string) {
  const profileSnapshot = await firebaseDb
    .ref("practitioners")
    .child(practitionerKey)
    .once("value");

  return profileSnapshot.val();
}

export type ProfileUpdate = {
  "/name/ar/": string;
  "/name/en/": string;
  "/name/givenName/ar/": string;
  "/name/givenName/en/": string;
};

export function updatePractitionerProfile({
  practitionerKey,
  values,
}: {
  practitionerKey: string;
  values: ProfileUpdate;
}) {
  return firebaseDb
    .ref("practitioners")
    .child(practitionerKey)
    .update({ ...values });
}

export function updateParticipatingPrograms({
  healthcareService,
  practitionerRole,
  participatingPrograms,
}: {
  healthcareService: string;
  practitionerRole: string;
  participatingPrograms: {
    [key in Program]?: boolean;
  };
}) {
  return firebaseDb
    .ref("healthcareServices")
    .child(healthcareService)
    .child("practitionerRoles")
    .child(practitionerRole)
    .update({ participatingPrograms });
}

let unsubscribe: () => void;

export type AssignedPatients = {
  [key in Program]?: number;
};

export async function subscribeAssignedPatients({
  loadNumberOfAssignedPatients,
  practitionerRoleID,
  healthcareServiceID,
  speciality,
}: {
  loadNumberOfAssignedPatients: (value: AssignedPatients) => void;
  practitionerRoleID: string;
  healthcareServiceID: string;
  speciality: Speciality;
}) {
  const assignedQuery = firestore
    .collection("patientPractitionerRelations")
    .where("practitionerRoleID", "==", practitionerRoleID)
    .where("healthcareServiceID", "==", healthcareServiceID)
    .where("speciality", "==", speciality)
    .where("active", "==", true);
  unsubscribe = assignedQuery.onSnapshot(
    (snapshot) => {
      const assignedPatients = {} as AssignedPatients;
      snapshot.forEach((doc) => {
        const { program }: { program: Program } = doc.data() as any;
        const assignedPatientsForProgram = assignedPatients[program];
        if (assignedPatientsForProgram) {
          assignedPatients[program] = assignedPatientsForProgram + 1;
        } else assignedPatients[program] = 1;
      });
      loadNumberOfAssignedPatients(assignedPatients);
    },
    () => {
      // console.error(`Error fetching patientPractitionerRelations: ${error}`)
    }
  );
}

export async function unsubscribeAssignedPatients() {
  if (unsubscribe) unsubscribe();
}

export async function getProgramConfigs() {
  const programDefinitionsSnapshot = await firestore
    .collection("programDefinitions")
    .get();

  type ProgramConfigs = {
    [key in Program]?: {
      acceptableSpecialities: Speciality[];
      patientPractitionerPairing: boolean;
    };
  };

  const programConfigs = {} as ProgramConfigs;
  programDefinitionsSnapshot.forEach((program) => {
    const { acceptableSpecialities, patientPractitionerPairing } =
      program.data() as ProgramDefinition;
    programConfigs[program.id as Program] = {
      acceptableSpecialities,
      patientPractitionerPairing,
    };
  });

  return programConfigs;
}
