import { FirebaseList } from "../firebase";

import Invite from "./invite";

import {
  CREATE_INVITE_SUCCESS,
  REMOVE_INVITE_SUCCESS,
  UPDATE_INVITE_SUCCESS,
  LOAD_INVITES_SUCCESS,
  UNLOAD_INVITES_SUCCESS,
  CLEAR_DRAFT_INVITE,
  START_EDIT,
  START_NEW,
  TOGGLE_ACTIVE,
} from "./action-types";

import {
  toggleActive as apiToggleActive,
  updateInvite as apiUpdateInvite,
} from "./api";

export function clearDraftInvite() {
  return {
    type: CLEAR_DRAFT_INVITE,
  };
}

export function startEdit(invite) {
  return {
    type: START_EDIT,
    payload: {
      invite,
    },
  };
}

export function startNew() {
  return {
    type: START_NEW,
  };
}

export function updateInvite(inviteKey, values) {
  apiUpdateInvite(inviteKey, values);
  return {
    type: CLEAR_DRAFT_INVITE,
  };
}

export function toggleActive(inviteKey, value) {
  apiToggleActive(inviteKey, value);
  return {
    type: TOGGLE_ACTIVE,
  };
}

export function createInviteSuccess(invite) {
  return {
    type: CREATE_INVITE_SUCCESS,
    payload: invite,
  };
}

export function removeInviteSuccess(invite) {
  return {
    type: REMOVE_INVITE_SUCCESS,
    payload: invite,
  };
}

export function updateInviteSuccess(invite) {
  return {
    type: UPDATE_INVITE_SUCCESS,
    payload: invite,
  };
}

export function loadInvitesSuccess(invites) {
  return {
    type: LOAD_INVITES_SUCCESS,
    payload: invites,
  };
}

/** invite-list */

const inviteList = new FirebaseList(
  {
    onAdd: createInviteSuccess,
    onChange: updateInviteSuccess,
    onRemove: removeInviteSuccess,
    onLoad: loadInvitesSuccess,
  },
  Invite
);

/** */

export function loadInvites() {
  return (dispatch) => {
    inviteList.path = `promocodes/invites`;
    inviteList.subscribe(dispatch);
  };
}

export function unloadInvites() {
  inviteList.unsubscribe();
  return {
    type: UNLOAD_INVITES_SUCCESS,
  };
}
