import React, { useState, useEffect } from "react";
import {
  Dialog,
  InputGroup,
  FormGroup,
  Button,
  Intent,
  Checkbox,
  Alignment,
  MenuItem,
  Menu,
  RadioGroup,
  Radio,
  NumericInput,
} from "@blueprintjs/core";
import { DatePicker } from "@blueprintjs/datetime";
import moment from "moment";
import { Select } from "@blueprintjs/select";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import Notification from "../Notification";
import { getProgramLabel } from "../../../utils";
import PhoneNumberInput from "./PhoneNumberInput";

function AddPatientOverlay({
  showOverlay = false,
  onClose,
  sendInvite,
  sendInviteToExistingUser,
  invites,
  pairingPractitioners,
  authId,
}) {
  const [isSendingInvite, setIsSendingInvite] = useState("");
  const [isInvitingExistingUser, setIsInvitingExistingUser] = useState(false);
  const [confirmInvite, setConfirmInvite] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [name, setName] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [nationalId, setNationalId] = useState("");
  const [wasfatyPrescriptionID, setWasfatyPrescriptionID] = useState("");
  const [programDuration, setProgramDuration] = useState({
    "primary-care": "12 months",
    "mental-health": "12 months",
    "wasfaty-program": "12 months",
  });
  const [programPairing, setProgramPairing] = useState({});
  const [gender, setGender] = useState("male");
  const [activePrograms, setActivePrograms] = useState([]);
  const [hbA1C, setHbA1C] = useState("");

  const programs = ["primary-care", "mental-health", "wasfaty-program"];

  const getPractitionerName = (practitioner) =>
    (practitioner.name && (practitioner.name.ar || practitioner.name.en)) ||
    practitioner.practitionerRoleID;

  useEffect(() => {
    Object.keys(pairingPractitioners).forEach((program) => {
      const practitioners = pairingPractitioners[program];
      const currentPractitioner = practitioners.find(
        (practitioner) => practitioner.practitionerKey === authId
      );
      if (currentPractitioner) {
        setProgramPairing((prev) => ({
          ...prev,
          [program]: currentPractitioner,
        }));
      } else {
        setProgramPairing((prev) => ({
          ...prev,
          [program]: pairingPractitioners[program][0],
        }));
      }
    });
  }, [authId, pairingPractitioners]);

  // formate the phone number to be compatible with profile phone number object
  const formatPhoneNumber = (phonenumber) => {
    if (!phonenumber) return undefined;
    // ex: +966551292881
    if (phonenumber.length === 13 && phonenumber.slice(0, 5) === "+9665")
      return phonenumber;

    return undefined;
  };

  const validateInvite = (phonenumber) => {
    // no invites are available
    if (invites == null) {
      return true;
    }

    return invites
      .toArray()
      .every(
        (invite) =>
          invite[1].get("phoneNumber") !== formatPhoneNumber(phonenumber)
      );
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (validateInvite(phoneNumber) === false) {
      Notification.Error(
        "An invite has already been sent to this Phone Number"
      );
    } else if (
      !programs.some(
        (program) => event.target[program] && event.target[program].checked
      )
    ) {
      Notification.Error("Please select at least one program");
    } else {
      setIsSendingInvite(true);

      const programValues = programs.reduce((programsValue, program) => {
        const programValue = {
          [program]: event.target[program].checked && {
            duration: programDuration[program],
          },
        };
        if (pairingPractitioners[program] && activePrograms.includes(program)) {
          const pairing = {
            practitionerKey: programPairing[program].practitionerKey,
            practitionerRoleID: programPairing[program].practitionerRoleID,
            speciality: programPairing[program].speciality,
          };
          programValue[program].pairing = pairing;
        }
        return {
          ...programsValue,
          ...programValue,
        };
      }, {});

      const values = {
        name,
        phoneNumber,
        dateOfBirth: moment(dateOfBirth).format("M/D/YYYY"),
        programs: programValues,
        gender: event.target.gender.value,
        nationalId,
      };
      if (wasfatyPrescriptionID && wasfatyPrescriptionID !== "") {
        values.wasfatyPrescriptionID = wasfatyPrescriptionID;
      }
      if (hbA1C) values.hbA1C = Number(hbA1C);
      const result = await sendInvite(values);
      setIsSendingInvite(false);
      if (result && result.success) onClose();
      if (result && result.status === "user-exists") {
        setConfirmInvite(true);
      }
    }
  };

  const isFormInvalid = () => {
    return (
      invites === undefined ||
      !isValidNumber ||
      !dateOfBirth ||
      !nationalId ||
      !name
    );
  };

  const renderDuration = (duration, { handleClick }) => {
    return <MenuItem key={duration} onClick={handleClick} text={duration} />;
  };

  const renderDurationsList = ({ items, itemsParentRef, renderItem }) => {
    const renderedItems = items.map(renderItem).filter((item) => item != null);
    return (
      <Menu ulRef={itemsParentRef} style={{ minWidth: 0 }}>
        {renderedItems}
      </Menu>
    );
  };

  const DURATIONS = [
    "1 month",
    "2 months",
    "3 months",
    "4 months",
    "5 months",
    "6 months",
    "7 months",
    "8 months",
    "9 months",
    "10 months",
    "11 months",
    "12 months",
  ];

  const renderPractitioner = (practitioner, { handleClick }) => {
    return (
      <MenuItem
        key={practitioner.practitionerRoleID}
        onClick={handleClick}
        text={getPractitionerName(practitioner)}
      />
    );
  };

  return (
    <Dialog
      style={{ width: "100%", maxWidth: 500, paddingBottom: 0 }}
      className="bp3-dark"
      isOpen={showOverlay}
      onClose={onClose}
      icon="new-person"
      title="Add Patient"
    >
      <div>
        <form
          style={{
            margin: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
          onSubmit={onSubmit}
        >
          <FormGroup label="Name" labelFor="name" style={{ width: "100%" }}>
            <InputGroup
              name="Name"
              id="name"
              placeholder="Enter Full Name"
              onChange={(event) => setName(event.target.value)}
            />
          </FormGroup>

          <FormGroup label="Phone Number" labelFor="phoneNumber">
            <PhoneInput
              international
              defaultCountry="SA"
              value={phoneNumber}
              onChange={(phonenumber) => {
                setPhoneNumber(phonenumber);
                setIsValidNumber(formatPhoneNumber(phonenumber) !== undefined);
              }}
              inputComponent={PhoneNumberInput}
            />
          </FormGroup>

          <RadioGroup
            name="gender"
            label="Gender"
            style={{ width: "100%" }}
            onChange={(event) => {
              setGender(event.target.value);
            }}
            selectedValue={gender}
            inline
          >
            <Radio label="Male" value="male" style={{ fontWeight: 400 }} />
            <Radio label="Female" value="female" style={{ fontWeight: 400 }} />
          </RadioGroup>

          <FormGroup label="Date of birth">
            {!showDatePicker ? (
              <button
                type="button"
                className="bp3-button"
                onClick={() => {
                  setShowDatePicker(true);
                }}
              >
                {!dateOfBirth
                  ? "Choose Date of Birth"
                  : moment(dateOfBirth).format("D MMM YYYY")}
              </button>
            ) : (
              <DatePicker
                maxDate={new Date()}
                minDate={new Date("1900", "1", "1")}
                className="centered"
                name="confirmedDatetime"
                onChange={(newDate) => {
                  setDateOfBirth(newDate);
                  setShowDatePicker(false);
                }}
                value={dateOfBirth}
              />
            )}
          </FormGroup>

          <FormGroup
            label="National ID"
            labelFor="nationalId"
            style={{ width: "100%" }}
          >
            <InputGroup
              name="National ID"
              id="nationalId"
              placeholder="Enter National ID"
              onChange={(event) => {
                setNationalId(event.target.value);
              }}
              value={nationalId}
            />
          </FormGroup>

          <FormGroup
            label="Wasfaty Prescription Number (Optional)"
            labelFor="wasfatyPrescription"
            style={{ width: "100%" }}
          >
            <InputGroup
              name="Wasfaty Prescription"
              id="wasfatyPrescription"
              placeholder="(Example: i6386158)"
              onChange={(event) => {
                setWasfatyPrescriptionID(event.target.value);
              }}
              value={wasfatyPrescriptionID}
            />
          </FormGroup>

          <FormGroup
            label="HbA1C (Optional)"
            labelFor="hba1c"
            style={{ width: "100%" }}
          >
            <NumericInput
              allowNumericCharactersOnly
              name="HbA1C"
              id="hba1c"
              placeholder="Enter HbA1C in %"
              onValueChange={(_valueAsNumber, valueAsString) =>
                setHbA1C(valueAsString.split("-").join(""))
              }
              value={hbA1C}
              buttonPosition="none"
              leftIcon="percentage"
              minorStepSize={0.001}
            />
          </FormGroup>

          <FormGroup
            label="Programs"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {programs.map((program) => (
              <div key={program} style={{ marginTop: 10, marginBottom: 10 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <Checkbox
                    defaultChecked={false}
                    label={getProgramLabel(program)}
                    name={program}
                    alignIndicator={Alignment.LEFT}
                    style={{ fontWeight: 400 }}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setActivePrograms((prev) => [...prev, program]);
                      } else {
                        setActivePrograms((prev) =>
                          prev.filter((p) => !(p === program))
                        );
                      }
                    }}
                  />
                  {activePrograms.includes(program) && (
                    <>
                      <p style={{ margin: 10 }}> - </p>
                      <Select
                        filterable={false}
                        name={`${program}-duration`}
                        id={`${program}-duration`}
                        items={DURATIONS}
                        onItemSelect={(duration) => {
                          setProgramDuration((prev) => ({
                            ...prev,
                            [program]: duration,
                          }));
                        }}
                        itemRenderer={renderDuration}
                        itemListRenderer={renderDurationsList}
                        popoverProps={{
                          minimal: true,
                          inner: true,
                          boundary: "window",
                          hasBackdrop: true,
                          popoverClassName: "conditions-popover",
                        }}
                      >
                        <Button
                          fill
                          style={{ minWidth: 80 }}
                          large={false}
                          alignText="left"
                          text={programDuration[program]}
                        />
                      </Select>
                    </>
                  )}
                </div>
                {pairingPractitioners[program] &&
                  activePrograms.includes(program) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: 25,
                      }}
                    >
                      <p style={{ marginRight: 10 }}>
                        Select Practitioner to pair
                      </p>
                      <p style={{ marginRight: 10 }}> - </p>
                      <Select
                        filterable={false}
                        name={`${program}-practitioner`}
                        id={`${program}-practitioner`}
                        items={pairingPractitioners[program]}
                        onItemSelect={(pairingPractitioner) => {
                          setProgramPairing((prev) => ({
                            ...prev,
                            [program]: pairingPractitioner,
                          }));
                        }}
                        itemRenderer={renderPractitioner}
                        itemListRenderer={renderDurationsList}
                        popoverProps={{
                          minimal: true,
                          inner: true,
                          boundary: "window",
                          hasBackdrop: true,
                          popoverClassName: "conditions-popover",
                        }}
                      >
                        <Button
                          fill
                          style={{ minWidth: 80 }}
                          large={false}
                          text={
                            programPairing[program] &&
                            getPractitionerName(programPairing[program])
                          }
                        />
                      </Select>
                    </div>
                  )}
              </div>
            ))}
          </FormGroup>

          <Button
            text="Send Invite"
            icon="arrow-right"
            intent={Intent.SUCCESS}
            type="submit"
            loading={isSendingInvite}
            disabled={isFormInvalid()}
            style={{ width: 200, margin: "auto" }}
          />
        </form>
      </div>

      <Dialog
        style={{ width: "100%", maxWidth: 400, padding: 20 }}
        className="bp3-dark"
        isOpen={confirmInvite}
      >
        <div>
          <p>
            {`Are you sure you want to send the invite? The user
            already exists`}
          </p>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              text="Cancel"
              disabled={isInvitingExistingUser}
              onClick={() => setConfirmInvite(false)}
              style={{ width: 120 }}
              minimal
            />
            <Button
              text="Confirm"
              loading={isInvitingExistingUser}
              style={{ width: 120 }}
              onClick={async () => {
                setIsInvitingExistingUser(true);
                const result = await sendInviteToExistingUser();
                setIsInvitingExistingUser(false);
                setConfirmInvite(false);
                if (result.success) {
                  onClose();
                }
              }}
            />
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
}

export default AddPatientOverlay;
