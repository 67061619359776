import { Callout } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { getMedicationRequestForPatient } from "../../../inbox/actions";
import MedicationsList from "../InputBox/PrescriptionInput/MedicationsList";

const MedicationRequestsHistory = ({ patientID }) => {
  const [medicationRequests, setMedicationRequests] = useState();

  useEffect(() => {
    (async () => {
      const medicationRequestsResponse = await getMedicationRequestForPatient({
        userID: patientID,
      })();
      setMedicationRequests(medicationRequestsResponse);
    })();
  }, [patientID]);

  if (medicationRequests && medicationRequests.length > 0) {
    return (
      <Callout
        style={{
          overflowX: "scroll",
        }}
        icon="prescription"
        title="Medication Requests"
      >
        <MedicationsList
          medicationsList={medicationRequests}
          showOnly
          showIssuedTime
          showSubstitutionAllowed={false}
        />
      </Callout>
    );
  }
  return <></>;
};

export default MedicationRequestsHistory;
