import React, { useState, useMemo } from "react";
import { List } from "immutable";
import { H3, Divider, Button } from "@blueprintjs/core";

import PatientInformationCard from "../PatientInformationCard";
import HistoryList from "../History";
import PatientActions from "./PatientActions";
import SkeletonList from "../SkeletonList";
import { getAge } from "../../utils";
import PatientCommunicationRequests from "./PatientCommunicationRequests";
import { ProcessDiagnosticReportOverlay } from "./ProcessDiagnosticReportOverlay";

const PatientProfile = ({
  selectedPatient,
  sendMessageToPatient,
  storagePathResolver,
  medications,
  conditions,
  specialties,
  sendAnswerToHistorySession,
  changeSpeciality,
  practitioners,
  createPatientReminder,
  createObservationAlert,
  removeObservationAlert,
  activeHealthcareService,
  practitionerSpeciality,
  patientCommunicationRequests,
  communicationRequestAction,
  observationTypes,
  processDiagnosticReport,
  labTests,
  pairedPatients,
  addObservation,
}) => {
  const { asknalaAccount, profile, loaded } = selectedPatient;
  const isPairedPatient = useMemo(
    () =>
      pairedPatients
        .toJS()
        .some((patient) => patient.patientID === selectedPatient.profile.key),
    [pairedPatients, selectedPatient.profile.key]
  );

  const [selectedDiagnosticReportID, setSelectedDiagnosticReportID] =
    useState(undefined);
  const closeDiagnosticReportOverlay = () => {
    setSelectedDiagnosticReportID(undefined);
  };
  const openDiagnosticReportOverlay = ({ diagnosticReportKey }) => {
    setSelectedDiagnosticReportID(diagnosticReportKey);
  };

  const historySessions = () => {
    const sessionsKeys = Object.keys(asknalaAccount.sessions);
    const sessions = Object.values(asknalaAccount.sessions).map(
      (session, index) => {
        const sessionResult = { ...session };
        sessionResult.key = sessionsKeys[index];
        return sessionResult;
      }
    );
    return new List(sessions);
  };

  return (
    <div
      style={{
        marginTop: "8px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
      }}
    >
      {isPairedPatient && (
        <PatientProfileHeader
          email={profile.email}
          fullName={profile.fullName}
          phoneNumber={profile.phoneNumber}
        />
      )}
      <Divider />
      <PatientCommunicationRequests
        patientCommunicationRequests={patientCommunicationRequests}
        practitioners={practitioners}
        communicationRequestAction={communicationRequestAction}
        openDiagnosticReportOverlay={openDiagnosticReportOverlay}
        profile={profile}
        diagnosticReports={selectedPatient.diagnosticReports}
      />
      <PatientInformationCard
        practitioners={practitioners}
        createPatientReminder={createPatientReminder}
        createObservationAlert={createObservationAlert}
        removeObservationAlert={removeObservationAlert}
        activeHealthcareService={activeHealthcareService}
        practitionerSpeciality={practitionerSpeciality}
        patient={{ age: getAge(profile.dateOfBirth), ...profile }}
        observationTypes={observationTypes}
        addObservation={addObservation}
      />
      {loaded === false && <SkeletonList height={58} numOfElements={3} />}
      {Boolean(asknalaAccount) && Boolean(asknalaAccount.sessions) && (
        <div
          style={{
            overflowY: "auto",
            maxHeight: "100%",
            minHeight: 400,
            marginBottom: 10,
          }}
        >
          <HistoryList
            sessions={historySessions()}
            selectedProfileID={profile.key}
            initialDisplayedSessions={12}
            storagePathResolver={storagePathResolver}
            conditions={conditions}
            medications={medications}
            specialties={specialties}
            labTests={labTests}
            sendAnswerToHistorySession={sendAnswerToHistorySession}
            inverted
            changeSpeciality={changeSpeciality}
            practitioners={practitioners}
            activeHealthcareService={activeHealthcareService}
            practitionerSpeciality={practitionerSpeciality}
            appointments={selectedPatient.appointments}
            encounters={selectedPatient.encounters}
          />
        </div>
      )}
      <div style={{ marginBottom: 10 }}>
        <PatientActions
          patientID={profile.key}
          sendMessageToPatient={sendMessageToPatient}
          activeHealthcareService={activeHealthcareService}
          practitionerSpeciality={practitionerSpeciality}
          conditions={conditions}
          medications={medications}
          specialties={specialties}
          labTests={labTests}
        />
      </div>
      <ProcessDiagnosticReportOverlay
        observationTypes={observationTypes}
        isOpen={selectedDiagnosticReportID !== undefined}
        diagnosticReportID={selectedDiagnosticReportID}
        profile={selectedPatient.profile}
        serviceRequests={selectedPatient.serviceRequests}
        diagnosticReports={selectedPatient.diagnosticReports}
        onClose={() => {
          closeDiagnosticReportOverlay();
        }}
        processDiagnosticReport={processDiagnosticReport}
        storagePathResolver={storagePathResolver}
      />
    </div>
  );
};

const PatientProfileHeader = ({
  fullName = "",
  email = "",
  phoneNumber = "",
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <H3>{fullName}</H3>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {email && (
          <Button icon="envelope" text={email} minimal small disabled />
        )}
        {phoneNumber && (
          <Button icon="phone" text={phoneNumber} minimal small disabled />
        )}
      </div>
    </div>
  );
};

export default PatientProfile;
