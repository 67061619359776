import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import "bulma/css/bulma.css";

import "./App.css";
import Index from "./index/index";
import Meeting from "./meeting";

import callActions from "../../../../call";

const App = ({
  communicationRequest,
  loadData,
  unloadData,
  updateProviderJoinedStatus,
}) => {
  return (
    <Router>
      <div className="full">
        <Route
          exact
          path="/:communicationRequestID"
          render={(props) => (
            <Index
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              communicationRequest={communicationRequest}
              loadData={loadData}
              unloadData={unloadData}
              updateProviderJoinedStatus={updateProviderJoinedStatus}
            />
          )}
        />
        <Route path="/:channelName/meeting" component={Meeting} />
      </div>
    </Router>
  );
};

const mapStateToProps = (state) => {
  const { communicationRequests } = state;
  return {
    communicationRequest: communicationRequests.appointmentCommunicationRequest,
  };
};

const mapDispatchToProps = {
  ...callActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
