import { Record } from "immutable";

const Practitioner = new Record({
  key: null,
  active: null,
  communication: null,
  gender: null,
  name: null,
});

export default Practitioner;
