import React from "react";

export default function SkeletonList({
  height = 58,
  width = "auto",
  numOfElements = 1,
}) {
  const renderSkeletons = () => {
    return Array.from(Array(numOfElements).keys()).map((key) => {
      return (
        <div
          key={key}
          className="bp3-skeleton"
          style={{ height, marginBottom: 10 }}
        />
      );
    });
  };
  return <div style={{ minWidth: width }}>{renderSkeletons()}</div>;
}
