import React, { useState } from "react";
import { Spinner, Callout, H1 } from "@blueprintjs/core";
import { firebaseDb } from "../../../firebase";
import SessionCard from "../../components/SessionsList/SessionCard";

function SessionPage({ match }) {
  // example > http://localhost:3000/sessions/Z5CHFPparCgPitrtKQtuvNav1oX2/-Lc2eU-rWxIxYfSbH4hZ
  const [session, setSession] = useState(null);
  const { userID, sessionID } = match.params;

  if (session == null) {
    firebaseDb
      .ref("asknala")
      .child(userID)
      .child("sessions")
      .once("value")
      .then((snapshot) => {
        const sessions = snapshot.val();
        const sessionObject = sessions[sessionID];
        if (session == null) {
          throw new Error();
        } else {
          sessionObject.key = sessionID;
          sessionObject.userID = userID;
          setSession(sessionObject);
        }
      })
      .catch(() => {
        setSession("error");
      });
  }

  return (
    <div className="container scrollable">
      {session == null && (
        <div style={{ margin: "100px" }}>
          <Spinner size="200" />
        </div>
      )}
      {session === "error" && (
        <Callout
          intent="danger"
          title={`Cann't find a session with ID (${sessionID})`}
        >
          Make sure you entered the correct user ID and session ID
        </Callout>
      )}
      {session !== null && session !== "error" && (
        <div>
          <H1>Session Details</H1>
          <SessionCard key={session.key} session={session} isDetailsPage />
        </div>
      )}
    </div>
  );
}

export default SessionPage;
