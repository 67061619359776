import React, { useEffect } from "react";

function ObservationTypeSelect({ setObservation, observations }) {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderObservationOptions = () => {
    return Object.keys(observations).map((observation) => {
      return (
        <option key={observation} value={observation}>
          {capitalizeFirstLetter(observation)}
        </option>
      );
    });
  };

  const defaultValue =
    observations && Object.keys(observations).length > 0
      ? Object.keys(observations)[0]
      : undefined;

  useEffect(() => {
    setObservation(defaultValue);
    return () => {};
  }, [defaultValue, setObservation]);

  return (
    <div className="bp3-select bp3-fill .modifier">
      <select
        defaultChecked={defaultValue}
        onChange={(e) => {
          setObservation(e.target.value);
        }}
      >
        {renderObservationOptions()}
      </select>
    </div>
  );
}

export default ObservationTypeSelect;
