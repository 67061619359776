import {
  Program,
  ProgramDefinition,
} from "nala-shared/typings/general/program";
import { PractitionerRole } from "nala-shared/typings/general/practitionerRole";

import * as t from "./action-types";
import { ReduxDispatch, ReduxGetState } from "../types/redux";
import { firebaseDb, firestore, functions } from "../firebase";
import Notification from "../views/components/Notification";
import { InviteStatus, NewPatientForm } from "./reducer";

export function sendInvite(values: NewPatientForm) {
  return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    const healthCareServiceKey = getState().auth.activeHealthcareService;
    const onboardingSpecialistKey = getState().auth.id;
    const checkUserExistsFunction = functions.httpsCallable("checkUserExists");

    const result = await checkUserExistsFunction(values);
    if (result && result.data && result.data.userExists) {
      dispatch({
        type: t.USER_ALREADY_EXISTS,
        payload: {
          ...values,
          uid: result.data.uid,
        },
      });
      return { success: false, status: "user-exists" };
    }

    const inviteKey = await firebaseDb
      .ref(`invites`)
      .push({
        status: "Created",
        ...values,
        healthcareService: healthCareServiceKey,
        onboardingSpecialistKey,
        sent: new Date().getTime(),
      })
      // @ts-ignore
      .getKey();

    await firebaseDb.ref(`invites/${inviteKey}/history`).push({
      dateTime: new Date().getTime(),
      status: "Created",
    });
    Notification.Success("Invite has been sent to patient");
    return { success: true };
  };
}

export function sendInviteToExistingUser() {
  return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    const healthcareService = getState().auth.activeHealthcareService;
    const onboardingSpecialistKey = getState().auth.id;
    const { formValues } = getState().newPatients;
    try {
      const inviteKey = await firebaseDb
        .ref(`invites`)
        .push({
          status: "Created",
          ...formValues,
          isExistingUser: true,
          healthcareService,
          onboardingSpecialistKey,
          sent: new Date().getTime(),
        })
        // @ts-ignore
        .getKey();

      await firebaseDb.ref(`invites/${inviteKey}/history`).push({
        dateTime: new Date().getTime(),
        status: "Created",
      });
      const inviteExistingPatient = functions.httpsCallable(
        "inviteExistingPatient"
      );

      await inviteExistingPatient({
        ...formValues,
        inviteKey,
        healthcareService,
        onboardingSpecialistKey,
      });
      dispatch({ type: t.RESET_FORM_VALUES });
      Notification.Success("Invite has been sent to patient");
      return { success: true };
      // eslint-disable-next-line prettier/prettier
    } catch (error: any) {
      if (error?.message) Notification.Error(error.message);
      return { success: false, error };
    }
  };
}

export function loadInvites() {
  return (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    const { activeHealthcareService } = getState().auth;

    firebaseDb.ref(`invites`).on("value", (snapshot) => {
      dispatch({
        type: t.LOAD_INVITES,
        payload: { invites: snapshot.val(), activeHealthcareService },
      });
    });
  };
}

export function resendInvite({
  inviteKey,
  phoneNumber,
  status,
}: {
  inviteKey: string;
  phoneNumber: string;
  status: InviteStatus;
}) {
  return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    try {
      const resendInviteFunction = functions.httpsCallable("resendInvite");
      const healthcareService = getState().auth.activeHealthcareService;
      const result = await resendInviteFunction({
        inviteKey,
        phoneNumber,
        healthcareService,
        status,
      });

      if (result && result.data && !result.data.isError) {
        Notification.Success("Invite SMS has been sent to patient");
      } else {
        // console.log(result.data);
        Notification.Error("Error sending SMS to patient");
      }
    } catch (err) {
      // console.log(err);
    }
  };
}

export function updateWelcomeCallStatus({
  inviteKey,
  toggle,
}: {
  inviteKey: string;
  toggle: Boolean;
}) {
  return async () => {
    try {
      const status = toggle ? "Signed Up" : "Welcome Call Made";

      await firebaseDb.ref(`invites/${inviteKey}`).update({
        status,
      });
      await firebaseDb.ref(`invites/${inviteKey}/history`).push({
        dateTime: new Date().getTime(),
        status,
      });
      Notification.Success("Updated invite status");
    } catch (err) {
      Notification.Error("Error updating invite status");
    }
  };
}

export type PairingPractitioners = {
  [key in Program]: PractitionerRole[];
};

export function loadPairingPractitioners() {
  return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    const healthcareService = getState().auth.activeHealthcareService;

    const practitionersRef = firebaseDb.ref(
      `healthcareServices/${healthcareService}/practitionerRoles`
    );
    const practitionerRoles = [] as PractitionerRole[];
    const practitionerRolesSnapshot = await practitionersRef.once("value");
    practitionerRolesSnapshot.forEach((snapshot) => {
      practitionerRoles.push({
        ...snapshot.val(),
        practitionerRoleID: snapshot.key,
      });
    });

    const programDefinitionsSnapshot = await firestore
      .collection("programDefinitions")
      .get();
    const pairingPrograms: Program[] = [];
    programDefinitionsSnapshot.forEach((program) => {
      const { patientPractitionerPairing } =
        program.data() as ProgramDefinition;
      if (patientPractitionerPairing) {
        pairingPrograms.push(program.id as Program);
      }
    });

    const pairingPractitioners = {} as PairingPractitioners;
    const practitionerRolesWithName = await Promise.all(
      practitionerRoles.map((practitionerRole) =>
        getPractitionerName(practitionerRole)
      )
    );
    practitionerRolesWithName.forEach((practitionerRole) => {
      const { participatingPrograms } = practitionerRole;
      if (participatingPrograms) {
        (Object.keys(participatingPrograms) as Program[]).forEach(
          (program: Program) => {
            if (
              pairingPrograms.includes(program) &&
              participatingPrograms[program]
            ) {
              if (pairingPractitioners[program]) {
                pairingPractitioners[program].push(practitionerRole);
              } else {
                pairingPractitioners[program] = [practitionerRole];
              }
            }
          }
        );
      }
    });
    dispatch({
      type: t.LOAD_PAIRING_PRACTITIONERS,
      payload: pairingPractitioners,
    });
  };
}

export function removeInvite({ inviteKey }: { inviteKey: string }) {
  return async () => {
    await firebaseDb.ref("invites").child(inviteKey).remove();
  };
}

async function getPractitionerName(practitionerRole: PractitionerRole) {
  const snapshot = await firebaseDb
    .ref(`practitioners/${practitionerRole.practitionerKey}`)
    .once("value");
  const profile = snapshot.val();
  return {
    ...practitionerRole,
    name: profile && profile.name,
  };
}

export function loadData() {
  return async (dispatch: ReduxDispatch, getState: ReduxGetState) => {
    loadInvites()(dispatch, getState);
    await loadPairingPractitioners()(dispatch, getState);
    dispatch({ type: t.LOADED_DATA });
  };
}
