import React from "react";
import PropTypes from "prop-types";
import { H1, Button, Intent } from "@blueprintjs/core";
import { CSVLink } from "react-csv";

import { DateRangeInput, TimePrecision } from "@blueprintjs/datetime";

// TODO: Enable search
const TableHeader = ({
  title,
  newClick,
  csvData,
  dateRangePick,
  // startDate,
  // endDate,
}) => (
  <div style={{ marginBottom: "1.23em" }}>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <H1>{title}</H1>
      <div>
        {Boolean(csvData) && (
          <CSVLink
            data={csvData}
            filename="list.csv"
            className="bp3-button bp3-large bp3-minimal"
            target="_blank"
          >
            <span className="bp3-icon-standard bp3-icon-cloud-download" />
            &nbsp;CSV
          </CSVLink>
        )}
        {Boolean(newClick) && (
          <Button
            disabled={false}
            large
            text="New"
            minimal={false}
            icon="plus"
            intent={Intent.PRIMARY}
            onClick={newClick}
          />
        )}
        {Boolean(dateRangePick) && (
          <DateRangeInput
            large
            singleMonthOnly
            timePrecision={TimePrecision.MINUTE}
            formatDate={(date) => date.toLocaleString()}
            onChange={dateRangePick}
            parseDate={(str) => new Date(str)}
          />
        )}
      </div>
    </div>
  </div>
);

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TableHeader;
