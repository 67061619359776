import React from "react";
import PropTypes from "prop-types";

import PatientInformationCard from "../../PatientInformationCard";
import Conversation from "../../Conversation";
import SessionsHistoryList from "../../History";

const SessionBox = ({
  questions = [],
  selectedSession,
  storagePathResolver,
  previousSessions,
  patient,
  practitioners,
  createPatientReminder,
  activeHealthcareService,
  practitionerSpeciality,
  createObservationAlert,
  labTests,
  observationTypes,
  addObservation,
}) => {
  return (
    <div className="chatbox-container scrollable">
      <PatientInformationCard
        practitioners={practitioners}
        patient={patient}
        createPatientReminder={createPatientReminder}
        createObservationAlert={createObservationAlert}
        activeHealthcareService={activeHealthcareService}
        practitionerSpeciality={practitionerSpeciality}
        observationTypes={observationTypes}
        addObservation={addObservation}
      />
      <SessionsHistoryList
        sessions={previousSessions}
        storagePathResolver={storagePathResolver}
        selectedSession={selectedSession}
        initialDisplayedSessions={3}
        inverted={false}
        practitioners={practitioners}
        activeHealthcareService={activeHealthcareService}
        practitionerSpeciality={practitionerSpeciality}
        labTests={labTests}
      />
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div />
        <Conversation
          questions={questions.reverse()}
          storagePathResolver={storagePathResolver}
          shouldScrollToBottomAfterStateChanges
          practitioners={practitioners}
          practitionerSpeciality={practitionerSpeciality}
        />
      </div>
    </div>
  );
};

SessionBox.propTypes = {
  // patient: PropTypes.object,
  // onSave: PropTypes.func,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string,
      sent: PropTypes.number.isRequired,
      responded: PropTypes.number,
    })
  ),
};

export default SessionBox;
