import { firestore, functions } from "../firebase";

export function markDispenseRequestAsCancelled({
  dispenseItem,
  medicationRequestKey,
  dispenseRequestKey,
  statusReason,
  pharmacistInformation,
}) {
  const isPharmacyItem = Boolean(dispenseItem.sku);
  if (isPharmacyItem) {
    const invoiceRef = firestore
      .collection("invoices")
      .doc(dispenseItem.invoiceKey);
    return firestore.runTransaction(async (transaction) => {
      const invoiceDoc = await transaction.get(invoiceRef);
      const invoice = invoiceDoc.data();
      const updatedItems = invoice.items.map((item) => {
        if (item.sku !== dispenseItem.sku) {
          return item;
        }
        return {
          ...item,
          dispenseRequests: {
            ...item.dispenseRequests,
            [dispenseRequestKey]: {
              ...item.dispenseRequests[dispenseRequestKey],
              status: "cancelled",
              whenCancelled: new Date().getTime(),
              cancelledBy: { ...pharmacistInformation },
              statusReason,
            },
          },
        };
      });
      transaction.update(invoiceRef, { items: updatedItems });
    });
  }
  return firestore
    .collection("medicationRequests")
    .doc(medicationRequestKey)
    .set(
      {
        dispenseRequests: {
          [dispenseRequestKey]: {
            status: "cancelled",
            whenCancelled: new Date().getTime(),
            cancelledBy: { ...pharmacistInformation },
            statusReason,
          },
        },
      },
      { merge: true }
    );
}

export function markDispenseRequestAsOnHold({
  dispenseItem,
  quantity,
  dispenseRequestKey,
  pharmacistInformation,
  isWasfatyPartiallyDispensed,
}) {
  const updatedDispenseRequest = {
    status: "on-hold",
    whenOnHold: new Date().getTime(),
    onHoldBy: { ...pharmacistInformation },
    quantity,
  };
  const isPharmacyItem = Boolean(dispenseItem.sku);
  if (isPharmacyItem) {
    const invoiceRef = firestore
      .collection("invoices")
      .doc(dispenseItem.invoiceKey);
    return firestore.runTransaction(async (transaction) => {
      const invoiceDoc = await transaction.get(invoiceRef);
      const invoice = invoiceDoc.data();
      const updatedItems = invoice.items.map((item) => {
        if (item.sku !== dispenseItem.sku) {
          return item;
        }
        return {
          ...item,
          dispenseRequests: {
            ...item.dispenseRequests,
            [dispenseRequestKey]: {
              ...item.dispenseRequests[dispenseRequestKey],
              ...updatedDispenseRequest,
            },
          },
        };
      });
      return transaction.update(invoiceRef, { items: updatedItems });
    });
  }
  return firestore
    .collection("medicationRequests")
    .doc(dispenseItem.key)
    .set(
      {
        dispenseRequests: {
          [dispenseRequestKey]: {
            ...updatedDispenseRequest,
            ...(isWasfatyPartiallyDispensed
              ? {
                  isWasfatyPartiallyDispensed,
                }
              : {}),
          },
        },
      },
      { merge: true }
    );
}

export function markDispenseRequestAsCompleted({
  dispenseItem,
  medicationRequestKey,
  dispenseRequestKey,
  pharmacistInformation,
}) {
  const isPharmacyItem = Boolean(dispenseItem.sku);
  if (isPharmacyItem) {
    const invoiceRef = firestore
      .collection("invoices")
      .doc(dispenseItem.invoiceKey);
    return firestore.runTransaction(async (transaction) => {
      const invoiceDoc = await transaction.get(invoiceRef);
      const invoice = invoiceDoc.data();
      const updatedItems = invoice.items.map((item) => {
        if (item.sku !== dispenseItem.sku) {
          return item;
        }
        return {
          ...item,
          dispenseRequests: {
            ...item.dispenseRequests,
            [dispenseRequestKey]: {
              ...item.dispenseRequests[dispenseRequestKey],
              status: "completed",
              whenHandedOver: new Date().getTime(),
              handedOverBy: { ...pharmacistInformation },
            },
          },
        };
      });
      return transaction.update(invoiceRef, { items: updatedItems });
    });
  }
  return firestore
    .collection("medicationRequests")
    .doc(medicationRequestKey)
    .set(
      {
        dispenseRequests: {
          [dispenseRequestKey]: {
            status: "completed",
            whenHandedOver: new Date().getTime(),
            handedOverBy: { ...pharmacistInformation },
          },
        },
      },
      { merge: true }
    );
}

export async function createDispenseRequest({
  dispenseItem,
  medicationRequestKey,
  type,
  destination,
  orderID,
  userInfo,
}) {
  const generatedID = firestore.collection("medicationRequests").doc().id;
  const newDispenseRequest = {
    destination,
    orderID,
    status: "preparation",
    type,
    userInfo,
    whenCreated: new Date().getTime(),
  };
  const isPharmacyItem = Boolean(dispenseItem.sku);
  if (isPharmacyItem) {
    const invoiceRef = firestore
      .collection("invoices")
      .doc(dispenseItem.invoiceKey);
    return firestore.runTransaction(async (transaction) => {
      const invoiceDoc = await transaction.get(invoiceRef);
      const invoice = invoiceDoc.data();
      const updatedItems = invoice.items.map((item) => {
        if (item.sku !== dispenseItem.sku) {
          return item;
        }
        return {
          ...item,
          dispenseRequests: {
            ...item.dispenseRequests,
            [generatedID]: newDispenseRequest,
          },
        };
      });
      return transaction.update(invoiceRef, { items: updatedItems });
    });
  }
  return firestore
    .collection("medicationRequests")
    .doc(medicationRequestKey)
    .set(
      {
        dispenseRequests: {
          [generatedID]: newDispenseRequest,
        },
      },
      { merge: true }
    );
}

export async function dispenseRequestStatusChanged({
  destinationType,
  isPharmacyOrder,
  newStatus,
  performer,
  subject,
}) {
  const onDispenseRequestStatusChange = functions.httpsCallable(
    "onDispenseRequestStatusChange"
  );
  try {
    await onDispenseRequestStatusChange({
      destinationType,
      isPharmacyOrder,
      newStatus,
      performer,
      subject,
    });
  } catch (err) {
    // console.log({ err });
  }
}
