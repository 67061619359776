import { fromJS, List, Record } from "immutable";

import * as t from "./action-types";

export const PatientState = new Record({
  selectedPatient: {
    profile: null,
    asknalaAccount: null,
    serviceRequests: null,
    diagnosticReports: null,
    loaded: false,
  },
  profiles: null,
  pairedPatients: List(),
  loaded: false,
});

export function patientReducer(state = new PatientState(), { payload, type }) {
  switch (type) {
    case t.LOAD_PROFILES:
      return state.merge({
        profiles: {
          ...state.profiles,
          ...payload,
        },
        loaded: true,
      });

    case t.LOAD_PROFILE:
      return state.merge({
        profiles: {
          ...state.profiles,
          ...payload,
        },
      });

    // case t.UNLOAD_PROFILES_SUCCESS:
    //   return state.merge(
    //     fromJS({
    //       profilesList: new List(),
    //       loaded: false,
    //       selectedPatient: {
    //         profile: null,
    //         asknalaAccount: null,
    //         loaded: false,
    //       },
    //     })
    //   );

    case t.SELECT_PATIENT:
      return state.merge(
        fromJS({
          selectedPatient: {
            ...state.toJS().selectedPatient,
            loaded: false,
            profile: payload,
          },
        })
      );

    case t.LOAD_ASKNALA_ACCOUNT:
      return state.merge(
        fromJS({
          selectedPatient: {
            ...state.toJS().selectedPatient,
            asknalaAccount: payload,
            loaded: true,
          },
        })
      );

    case t.LOAD_SERVICE_REQUESTS:
      return state.merge(
        fromJS({
          selectedPatient: {
            ...state.toJS().selectedPatient,
            serviceRequests: payload,
          },
        })
      );

    case t.LOAD_DIAGNOSTIC_REPORTS:
      return state.merge(
        fromJS({
          selectedPatient: {
            ...state.toJS().selectedPatient,
            diagnosticReports: payload,
          },
        })
      );

    case t.LOAD_PAIRED_PATIENTS:
      return state.merge({
        pairedPatients: List(payload),
      });

    case t.CREATE_PAIRED_PATIENTS:
      return state.merge({
        pairedPatients: state.pairedPatients.unshift(payload),
      });

    case t.UPDATE_PAIRED_PATIENTS:
      return state.merge({
        pairedPatients: state.pairedPatients.map((pairedPatient) =>
          pairedPatient.key === payload.key ? payload : pairedPatient
        ),
      });

    case t.REMOVED_PAIRED_PATIENTS:
      return state.merge({
        pairedPatients: state.pairedPatients.filter(
          (pairedPatient) => pairedPatient.key !== payload.key
        ),
      });

    case t.LOAD_APPOINTMENTS:
      return state.merge(
        fromJS({
          selectedPatient: {
            ...state.toJS().selectedPatient,
            appointments: payload,
          },
        })
      );

    case t.LOAD_ENCOUNTERS:
      return state.merge(
        fromJS({
          selectedPatient: {
            ...state.toJS().selectedPatient,
            encounters: payload,
          },
        })
      );

    default:
      return state;
  }
}
