import { fromJS, Record } from "immutable";

import * as t from "./action-types";

export const PractitionersState = new Record({
  practitionersList: {},
});

export function practitionersReducer(
  state = new PractitionersState(),
  { payload, type }
) {
  let practitionersObject;

  switch (type) {
    case t.CREATE_PRACTITIONER_SUCCESS:
      return state.merge(
        fromJS({
          practitionersList: state.practitionersList.set(payload.key, payload),
        })
      );

    case t.REMOVE_PRACTITIONER_SUCCESS:
      return state.merge(
        fromJS({
          practitionersList: state.practitionersList.delete(payload.key),
        })
      );

    case t.UPDATE_PRACTITIONER_SUCCESS:
      return state.merge(
        fromJS({
          practitionersList: state.practitionersList.set(payload.key, payload),
        })
      );

    case t.LOAD_PRACTITIONERS_SUCCESS:
      // Transform from array to
      practitionersObject = {};
      payload.forEach((practitioner) => {
        practitionersObject[practitioner.key] = practitioner;
      });
      return state.merge(fromJS({ practitionersList: practitionersObject }));

    case t.UNLOAD_PRACTITIONERS_SUCCESS:
      return state.merge(fromJS({ practitionersList: {} }));

    default:
      return state;
  }
}
