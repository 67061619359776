import React, { useState } from "react";
// eslint-disable-next-line import/no-unresolved
import { FormGroup, InputGroup, Button, Intent, H3 } from "@blueprintjs/core";

const emailFilter =
  /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const Reset = (props) => {
  const { onSubmitReset, setLogin } = props;

  const [email, setEmail] = useState("");
  const isEmailValid = email.length > 0 && emailFilter.test(email);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmitReset(email);
  };

  return (
    <div>
      <H3
        style={{
          marginBottom: 24,
        }}
      >
        Reset password
      </H3>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <InputGroup
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            large
            fill
            leftIcon="envelope"
            type="email"
            placeholder="Email Address"
          />
        </FormGroup>
        <Button
          onClick={() => onSubmitReset({ email })}
          text="Send Request"
          disabled={!isEmailValid}
          large
          fill
          intent={Intent.NONE}
          type="submit"
          rightIcon={isEmailValid ? "arrow-right" : undefined}
        />
      </form>
      <Button
        onClick={() => setLogin()}
        text="Return to login"
        minimal
        fill
        intent={Intent.NONE}
        style={{ marginTop: 16 }}
      />
    </div>
  );
};

const Login = (props) => {
  const { onSubmitLogin, setReset } = props;

  const [email, setEmail] = useState("");
  const isEmailValid = email.length > 0 && emailFilter.test(email);
  const [password, setPassword] = useState("");
  const isPasswordValid = password.length > 5;

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmitLogin(email, password);
  };

  return (
    <div>
      <H3
        style={{
          marginBottom: 24,
        }}
      >
        Login to your account
      </H3>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <InputGroup
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            large
            fill
            leftIcon="envelope"
            type="email"
            placeholder="Email Address"
          />
        </FormGroup>

        <FormGroup>
          <InputGroup
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            large
            fill
            leftIcon="lock"
            type="password"
            placeholder="Password"
          />
        </FormGroup>

        <Button
          onClick={() => onSubmitLogin({ email, password })}
          text="Login"
          disabled={!isEmailValid || !isPasswordValid}
          large
          fill
          intent={Intent.NONE}
          type="submit"
          rightIcon={
            isEmailValid && isPasswordValid ? "arrow-right" : undefined
          }
        />
      </form>
      <Button
        onClick={() => setReset()}
        text="Forgot password?"
        minimal
        fill
        intent={Intent.NONE}
        style={{ marginTop: 16 }}
      />
    </div>
  );
};

const LoginForm = (props) => {
  const { onSubmitLogin, onSubmitReset } = props;
  const [mode, setMode] = useState("login");

  if (mode === "login") {
    return (
      <Login
        onSubmitLogin={onSubmitLogin}
        setSignup={() => setMode("signup")}
        setReset={() => setMode("reset")}
      />
    );
  }

  return (
    <Reset onSubmitReset={onSubmitReset} setLogin={() => setMode("login")} />
  );
};

export default LoginForm;
