import { fromJS, Record } from "immutable";
import {
  UPDATE_ROLES_AND_LINKED_HEALTH_CARE_SERVICES,
  INIT_AUTH,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  LOAD_PRACTITIONER_ROLE,
} from "./action-types";

const ACCESSARRAYS = {
  admin: [
    "patients",
    "dashboard",
    "users",
    "providers",
    "invites",
    "sessions",
    "session",
    "inbox",
    "call",
    "availability",
    "settings",
    "dispenseRequests",
    "newPatients",
  ],
  pharmacist: ["dispenseRequests"],
  serviceProvider: ["patients", "inbox", "call", "availability", "settings"],
  auditor: ["dashboard"],
  customerService: [],
  onboardingSpecialist: ["newPatients"],
};

const getAccessArray = (roles) => {
  if (roles) {
    const accessArrays = Object.keys(roles).map((item) =>
      roles[item] ? ACCESSARRAYS[item] : []
    );
    return [...new Set(accessArrays.flat())];
  }
  return [];
};

export const AuthState = new Record({
  authenticated: false,
  id: null,
  username: null,
  roles: null,
  practitionerRole: null,
  activeHealthcareService: null,
  accessArray: [],
});

export function authReducer(state = new AuthState(), { payload, type }) {
  switch (type) {
    case INIT_AUTH:
      return state.merge(
        fromJS({
          authenticated: !!payload,
          id: payload ? payload.uid : null,
          username: payload ? payload.email : null,
        })
      );
    case SIGN_IN_SUCCESS:
      return state.merge(
        fromJS({
          authenticated: !!payload,
          id: payload ? payload.uid : null,
          username: payload ? payload.email : null,
        })
      );

    case LOAD_PRACTITIONER_ROLE:
      return state.merge({
        practitionerRole: fromJS(payload),
      });

    case UPDATE_ROLES_AND_LINKED_HEALTH_CARE_SERVICES:
      return state.merge(
        fromJS({
          roles: payload,
          activeHealthcareService: payload.healthcareService,
          accessArray: getAccessArray(payload.roles),
        })
      );

    case SIGN_OUT_SUCCESS:
      return new AuthState();

    default:
      return state;
  }
}
