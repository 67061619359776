import React from "react";
import "react-image-lightbox/style.css";
// import VideoCallButton from "./VideoCallButton";

const VideoCallCard = ({ practitionerSpeciality }) => {
  return (
    <div>
      {practitionerSpeciality === "primary-care"
        ? "قام الطبيب بطلب موعد مكالمة فيديو"
        : "قام الأخصائي بطلب موعد مكالمة فيديو"}
    </div>
  );
};

export default VideoCallCard;
