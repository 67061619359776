/* eslint-disable global-require */
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import history from "./history";
import reducers from "./reducers";

export default (initialState = {}) => {
  let middleware;
  const middlewares = [thunk, routerMiddleware(history)];

  if (process.env.NODE_ENV !== "production") {
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);

    const { devToolsExtension } = window;
    if (typeof devToolsExtension === "function") {
      middleware = compose(
        applyMiddleware(...middlewares),
        devToolsExtension()
      );
    } else {
      middleware = applyMiddleware(...middlewares);
    }
  } else {
    middleware = applyMiddleware(...middlewares);
  }

  const store = createStore(reducers, initialState, middleware);

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(require("./reducers").default);
    });
  }

  return store;
};
