import React from "react";
import {
  Intent,
  Card,
  Elevation,
  Icon,
  HTMLTable,
  H5,
} from "@blueprintjs/core";

const StyleGuide = ({ visible, onClick }) => {
  if (visible) {
    return (
      <Card interactive onClick={onClick} elevation={Elevation.ZERO}>
        <HTMLTable className="full-width">
          <thead>
            <tr>
              <th>
                <p className="bp3-rtl">
                  <Icon intent={Intent.DANGER} icon="cross" />
                </p>
              </th>
              <th>
                <p className="bp3-rtl">
                  <Icon intent={Intent.SUCCESS} icon="tick" />
                </p>
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className="bp3-rtl">وين مكان الألم؟</p>
              </td>
              <td>
                <p className="bp3-rtl">أين يقع الألم؟</p>
              </td>
              <td>
                <H5 className="bp3-rtl">استخدم اللغة العربية الفصحى</H5>
              </td>
            </tr>
            <tr>
              <td>
                <p className="bp3-rtl">متى بدأ معك الألم و أين يقع؟</p>
              </td>
              <td>
                <p className="bp3-rtl">متى بدأ معك الألم؟</p>
              </td>
              <td>
                <H5 className="bp3-rtl">اسأل سؤال واحد فقط</H5>
              </td>
            </tr>
            <tr>
              <td>
                <p className="bp3-rtl">سلامتك، متى بدأ معك الألم؟</p>
              </td>
              <td>
                <p className="bp3-rtl">متى بدأ معك الألم؟</p>
              </td>
              <td>
                <H5 className="bp3-rtl">لا تستخدم العبارات التلطيفية</H5>
              </td>
            </tr>
            <tr>
              <td>
                <p className="bp3-rtl">هل تؤلمك قدمك اليمنى؟</p>
              </td>
              <td>
                <p className="bp3-rtl">هل تؤلم القدم اليمنى؟</p>
              </td>
              <td>
                <H5 className="bp3-rtl">استخدم الفعل المبني للمجهول</H5>
              </td>
            </tr>
            <tr>
              <td>
                <p className="bp3-rtl">في أي مكان يوجد الألم؟</p>
              </td>
              <td>
                <p className="bp3-rtl">أين يقع الألم؟</p>
              </td>
              <td>
                <H5 className="bp3-rtl">اختزل</H5>
              </td>
            </tr>
          </tbody>
        </HTMLTable>
      </Card>
    );
  }
  return null;
};

export default StyleGuide;
