import React from "react";
import { Card, Tag } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";

const moment = require("moment");

const TimeCard = ({ activeSlot, slot, index, toggleSlotAvailability }) => {
  const bufferTime = 120;
  const colors = {
    ACTIVE: "#556571",
    ENABLE: "#0A6640",
    DISABLE: "#A82A2A",
    DISABLED: "#a7b6c299",
    DISABLED_CARD: "#202B33",
  };

  const getBackgroundColor = (toggle, available) => {
    if (!available && !toggle) return colors.DISABLED_CARD;
    if (toggle === undefined) return null;
    return toggle ? colors.ENABLE : colors.DISABLE;
  };

  const SlotTag = ({ intent, label }) => {
    if (label === "Booked")
      return (
        <Tooltip2
          content={<span>You can join the call from the Patients screen</span>}
          placement="top"
        >
          <Tag style={{ marginTop: 5 }} intent={intent} icon="lock">
            <p>{label}</p>
          </Tag>
        </Tooltip2>
      );
    if (label === "Available")
      return (
        <Tag style={{ marginTop: 8 }} intent={intent}>
          {label}
        </Tag>
      );
    return (
      <Tag style={{ marginTop: 8 }} intent={intent} minimal>
        {label}
      </Tag>
    );
  };

  return (
    <Card
      style={{
        color: !slot.isAvailable ? colors.DISABLED : null,
        margin: 5,
        padding: 15,
        backgroundColor:
          index === activeSlot
            ? colors.ACTIVE
            : getBackgroundColor(slot.toggled, slot.isAvailable),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      onClick={() => {
        if (
          !slot.appointmentID &&
          moment().add(bufferTime, "minutes").isBefore(slot.startMoment)
        ) {
          toggleSlotAvailability(slot.id, slot.isAvailable);
        }
      }}
      key={slot.id}
    >
      {slot.startMoment.local().format("hh:mm")} -{" "}
      {slot.startMoment
        .clone()
        .add(slot.duration, "minutes")
        .local()
        .format("hh:mm A")}
      {!slot.isAvailable && <SlotTag intent="none" label="Unavailable" />}
      {slot.isAvailable && !slot.appointmentID && (
        <SlotTag intent="success" label="Available" />
      )}
      {slot.appointmentID && <SlotTag intent="warning" label="Booked" />}
    </Card>
  );
};

const memoizeTimeCard = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.slot) === JSON.stringify(nextProps.slot) &&
    prevProps.index === nextProps.index &&
    prevProps.activeSlot === nextProps.activeSlot &&
    prevProps.toggleSlotAvailability === nextProps.toggleSlotAvailability
  );
};

export default React.memo(TimeCard, memoizeTimeCard);
