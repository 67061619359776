import React from "react";

function ReminderTypeSelect({
  type,
  setReminderType,
  practitionerSpeciality,
  activeHealthcareService,
}) {
  const optionPrefix = () => {
    switch (type) {
      case "patientReminders":
        return "Remind ";
      default:
        return "";
    }
  };

  const optionText = (optionType) => {
    switch (optionType) {
      case "me":
        return "Me";
      case "speciality":
        return `Speciality (${practitionerSpeciality})`;
      case "healthcareService":
        return `Healthcare Service (${activeHealthcareService})`;
      default:
        return ``;
    }
  };

  return (
    <div className="bp3-select bp3-fill .modifier">
      <select
        defaultChecked="me"
        onChange={(e) => {
          setReminderType(e.target.value);
        }}
      >
        <option value="me">{optionPrefix() + optionText("me")}</option>
        {practitionerSpeciality && (
          <option value="speciality">
            {optionPrefix() + optionText("speciality")}
          </option>
        )}
        {activeHealthcareService && (
          <option value="healthcareService">
            {optionPrefix() + optionText("healthcareService")}
          </option>
        )}
      </select>
    </div>
  );
}

export default ReminderTypeSelect;
