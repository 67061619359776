import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { NonIdealState, Spinner } from "@blueprintjs/core";

import { TimeSlotInput, BookingsCalendar } from "../../components/Bookings";

import {
  bookingsActions,
  getBookingsLoaded,
  getBookingSlots,
  getIsCreatingSlots,
  getPractitionerRole,
  getIsUpdatingAvailability,
  getIsChangingDuration,
  getToggledSlots,
  getShifts,
  getIsUpdatingShifts,
} from "../../../bookings";
import Shifts from "../../components/Bookings/Shifts";
import AvailabilityTabs from "../../components/Bookings/AvailabilityTabs";

const BookingsPage = ({
  bookingsLoaded,
  bookingSlots,
  isCreating,
  loadData,
  unloadData,
  practitionerRole,
  createTimeSlots,
  updateAvailability,
  isUpdatingAvailability,
  changeSlotDuration,
  isChangingDuration,
  toggledSlots,
  toggleSlotAvailability,
  updateShifts,
  shifts,
  isUpdatingShifts,
}) => {
  useEffect(() => {
    loadData();
    return () => {
      unloadData();
    };
  }, [loadData, unloadData, practitionerRole]);

  const [selectedTab, setSelectedTab] = useState("shifts");

  const hasBookings = bookingSlots.size > 0;
  if (!(bookingsLoaded && shifts)) {
    return (
      <div style={{ display: "flex", flex: 1 }} className="scrollable">
        <NonIdealState>
          <Spinner size={Spinner.SIZE_STANDARD} intent="primary" />
        </NonIdealState>
      </div>
    );
  }

  return (
    <div className="respond-page-container scrollable">
      {selectedTab === "shifts" && (
        <Shifts
          updateShifts={updateShifts}
          shifts={shifts}
          isUpdatingShifts={isUpdatingShifts}
        />
      )}
      <div
        style={{ padding: 15, flex: 1, alignItems: "center" }}
        className="scrollable"
      >
        {selectedTab === "bookings" && !hasBookings && (
          <TimeSlotInput
            createTimeSlots={createTimeSlots}
            isCreating={isCreating}
            shifts={shifts}
          />
        )}
        {selectedTab === "bookings" && hasBookings && (
          <BookingsCalendar
            bookingSlots={bookingSlots}
            updateAvailability={updateAvailability}
            isUpdatingAvailability={isUpdatingAvailability}
            changeSlotDuration={changeSlotDuration}
            isChangingDuration={isChangingDuration}
            toggledSlots={toggledSlots}
            toggleSlotAvailability={toggleSlotAvailability}
            updateShifts={updateShifts}
            shifts={shifts}
            isUpdatingShifts={isUpdatingShifts}
          />
        )}
      </div>
      {bookingsLoaded && (
        <AvailabilityTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}
    </div>
  );
};

const mapStateToProps = createSelector(
  getBookingsLoaded,
  getBookingSlots,
  getIsCreatingSlots,
  getPractitionerRole,
  getIsUpdatingAvailability,
  getIsChangingDuration,
  getToggledSlots,
  getShifts,
  getIsUpdatingShifts,
  (
    bookingsLoaded,
    bookingSlots,
    isCreating,
    practitionerRole,
    isUpdatingAvailability,
    isChangingDuration,
    toggledSlots,
    shifts,
    isUpdatingShifts
  ) => ({
    bookingsLoaded,
    bookingSlots,
    isCreating,
    practitionerRole,
    isUpdatingAvailability,
    isChangingDuration,
    toggledSlots,
    shifts,
    isUpdatingShifts,
  })
);

const mapDispatchToProps = {
  ...bookingsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsPage);
