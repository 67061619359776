import React, { useState } from "react";
import { Dialog, Button, FormGroup, Intent } from "@blueprintjs/core";
import ObservationInput from "./ObservationInput";

function getObservationName(observationType) {
  return observationType.displayName.en
    ? observationType.displayName.en
    : observationType.displayName.ar;
}

const ObservationValue = ({
  addObservation,
  observationName,
  observationTypes,
  patientKey,
  renderObservationValue,
  value,
  hideObservationUpdate,
}) => {
  const [updateValue, setUpdateValue] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [addingObservation, setAddingObservation] = useState(false);
  const observationType = observationTypes.find(
    (observation) => observation.key === observationName
  );
  const { isUpdatableByProvider, preferredUnit, type } =
    observationType.updateRules;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p style={{ marginRight: 10 }}>{renderObservationValue(value)}</p>
      {isUpdatableByProvider && !hideObservationUpdate && (
        <>
          <Button
            text="Update"
            onClick={() => setUpdateValue((prev) => !prev)}
          />
          <Dialog
            style={{ width: "100%", maxWidth: 500, paddingBottom: 20 }}
            isOpen={updateValue}
            onClose={() => {
              setNewValue("");
              setUpdateValue(false);
            }}
            className="bp3-dark"
            title={`Update ${getObservationName(observationType)} value`}
            icon="clipboard"
          >
            <div
              style={{
                marginTop: 20,
                marginRight: 20,
                marginLeft: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormGroup
                label={`New ${getObservationName(observationType)} value`}
                labelFor={observationName}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                inline
              >
                <ObservationInput
                  type={type}
                  newValue={newValue}
                  setNewValue={setNewValue}
                  preferredUnit={preferredUnit}
                />
              </FormGroup>
              <Button
                intent={Intent.SUCCESS}
                style={{ width: 80 }}
                large={false}
                loading={addingObservation}
                onClick={async () => {
                  setAddingObservation(true);
                  await addObservation({
                    observationName,
                    value: type === "number" ? Number(newValue) : newValue,
                    patientKey,
                    unit: preferredUnit,
                  });
                  setNewValue("");
                  setAddingObservation(false);
                  setUpdateValue(false);
                }}
                text="Add"
                disabled={newValue === ""}
              />
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ObservationValue;
