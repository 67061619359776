import React from "react";
import PropTypes from "prop-types";
import {
  Classes,
  Button,
  Intent,
  Dialog,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";

const InviteOverlay = ({
  invite = {},
  isNewInvite = false,
  onClose = () => null,
  onSave = () => null,
  showOverlay = false,
}) => (
  <Dialog
    className="bp3-dark"
    isOpen={showOverlay}
    onClose={onClose}
    icon="envelope"
    title="Invite Code"
  >
    <div className={Classes.DIALOG_BODY}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSave(event.target.key.value, {
            startTime: Number(event.target.startTime.value),
            endTime: Number(event.target.endTime.value),
            usageLimit: Number(event.target.usageLimit.value),
            discount: Number(event.target.discount.value),
            isArchived: false,
            isActive: false,
          });
        }}
      >
        <FormGroup label="Key" labelFor="key-input">
          <InputGroup
            name="key"
            disabled={!isNewInvite}
            id="key-input"
            placeholder="Enter invite code to be entered for claiming"
            value={invite.key}
          />
        </FormGroup>
        <FormGroup label="Start Time (unix)" labelFor="start-input">
          <InputGroup
            name="startTime"
            defaultValue={invite.startTime}
            type="number"
            step="1"
            min="0"
            id="start-input"
            placeholder="Pick invite code start time"
          />
        </FormGroup>
        <FormGroup label="End Time (unix)" labelFor="end-input">
          <InputGroup
            name="endTime"
            defaultValue={invite.endTime}
            type="number"
            step="1"
            min="0"
            id="end-input"
            placeholder="Pick invite code end time"
          />
        </FormGroup>
        <FormGroup label="Usage Limit" labelFor="limit-input">
          <InputGroup
            name="usageLimit"
            defaultValue={invite.usageLimit}
            type="number"
            step="1"
            min={invite.usedBy ? Object.keys(invite.usedBy).length : 0}
            max="9999999"
            id="limit-input"
            placeholder="Enter the code usage limit count"
          />
        </FormGroup>
        <FormGroup label="Discount Percentage" labelFor="discount-input">
          <InputGroup
            name="discount"
            defaultValue={invite.discount}
            type="number"
            step="1"
            min="1"
            max="99"
            id="discount-input"
            placeholder="Percentage to be deducted"
          />
        </FormGroup>

        <Button
          icon={isNewInvite ? "star" : "floppy-disk"}
          text={isNewInvite ? "Create" : "Save"}
          intent={Intent.SUCCESS}
          type="submit"
        />
      </form>
    </div>
  </Dialog>
);

InviteOverlay.propTypes = {
  invite: PropTypes.shape({
    invite: PropTypes.string,
    subscription: PropTypes.bool,
  }),
};

export default InviteOverlay;
