import { createSelector } from "reselect";

export function getInvites(state) {
  return state.invites;
}

export function getInviteList(state) {
  return getInvites(state).list;
}

export function getInviteFilter(state) {
  return getInvites(state).filter;
}

export function getDeletedInvite(state) {
  return getInvites(state).deleted;
}

export function getShowOverlay(state) {
  return getInvites(state).showOverlay;
}

export function getDraftInvite(state) {
  return getInvites(state).draftInvite;
}

export function getNewInviteFlag(state) {
  return getInvites(state).isNewInvite;
}

//= ====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getVisibleInvites = createSelector(
  getInviteList,
  getInviteFilter,
  (invites, filter) => {
    switch (filter) {
      // case 'active':
      //  return invites.filter(invite => !invite.completed);
      default:
        return invites;
    }
  }
);
