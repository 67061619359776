import { createSelector } from "reselect";
import { List } from "immutable";

export function getInbox(state) {
  return state.inbox;
}

export function getInboxes(state) {
  return getInbox(state).inboxes;
}

export function getActiveInbox(state) {
  const inboxes = getInboxes(state);
  const activeInboxKey = getInbox(state).activeInbox;
  if (inboxes && inboxes.get(activeInboxKey)) {
    return inboxes.get(activeInboxKey).toJS();
  }
  return undefined;
}

export function getPractitioners(state) {
  return state.practitioners.practitionersList;
}

export function getLoaded(state) {
  return getInbox(state).loaded;
}

export function getPractitionerWeeklyAverageResponseTime(state) {
  return getInbox(state).practitionerWeeklyAverageResponseTime;
}

export function getInput(state) {
  return getInbox(state).input;
}

export function getEditFlag(state) {
  return getInbox(state).edit;
}

export function getAccountList(state) {
  return getInbox(state).list;
}

export function getAccountFilter(state) {
  return getInbox(state).filter;
}

export function getSelectedAccount(state) {
  if (getInbox(state).selectedAccount) {
    return getInbox(state).selectedAccount.toJS();
  }
  return undefined;
}

export function getSelectedSession(state) {
  return getInbox(state).selectedSession;
}

export function getSelectedProfile(state) {
  return getInbox(state).selectedProfile;
}

export function getSelectedSpeciality(state) {
  return getInbox(state).selectedSpeciality;
}

export function getDeletedAccount(state) {
  return getInbox(state).deleted;
}

export function getShowEndSessionOverlay(state) {
  return getInbox(state).showEndSessionOverlay;
}

export function getActiveHealthcareService(state) {
  return state.auth.activeHealthcareService;
}

export function getLabTests(state) {
  return getInbox(state)
    .labTests.toArray()
    .map((item) => item.toJS());
}

export function getObservationTypes(state) {
  return state.observationTypes.list.toJS();
}

//= ====================================
//  MEMOIZED SELECTORS
//-------------------------------------

const getSessionTitle = (session) => {
  if (session && session.questions) {
    const questions = Object.values(session.questions).sort(
      (questionA, questionB) => questionA.sent - questionB.sent
    );

    return typeof questions[Object.keys(questions)[0]].question !== "object"
      ? questions[Object.keys(questions)[0]].question
      : "Symptom";
  }
  return undefined;
};

export const getSelectedSessionObject = createSelector(
  getSelectedSession,
  getSelectedAccount,
  getSelectedProfile,
  (selectedSession, selectedAccount, selectedProfile) => {
    if (
      selectedSession &&
      selectedAccount &&
      selectedAccount.sessions &&
      selectedProfile
    ) {
      return {
        ...selectedAccount.sessions[selectedSession],
        sessionTitle: getSessionTitle(
          selectedAccount.sessions[selectedSession]
        ),
        key: selectedSession,
        observations: selectedProfile.observations,
      };
    }
    return undefined;
  }
);

export const getAllSelectedSessionPreviousSessions = createSelector(
  getSelectedAccount,
  getSelectedSession,
  (selectedAccount, selectedSession) => {
    let sessions = new List();
    if (
      selectedSession &&
      selectedAccount &&
      selectedAccount.sessions &&
      Object.keys(selectedAccount.sessions).length > 0
    ) {
      const selectedSessionPatientID =
        selectedAccount.sessions[selectedSession] &&
        selectedAccount.sessions[selectedSession].patientID;
      const userID = selectedAccount.key;
      Object.keys(selectedAccount.sessions).forEach((sessionKey) => {
        const currentSessionPatientID =
          selectedAccount.sessions[sessionKey].patientID;
        const currentSessionHasNoPatientID =
          !selectedAccount.sessions[sessionKey].patientID;
        const selectedSessionHasNoPatientID = !selectedSessionPatientID;
        if (
          (selectedSessionPatientID === currentSessionPatientID ||
            (selectedSessionHasNoPatientID &&
              currentSessionPatientID === userID) ||
            (currentSessionHasNoPatientID &&
              selectedSessionPatientID === userID)) &&
          sessionKey !== selectedSession.sessionKey
        ) {
          const sessionObject = {
            userID: selectedAccount.key,
            key: sessionKey,
            ...selectedAccount.sessions[sessionKey],
          };
          if (selectedSession !== sessionKey)
            sessions = sessions.push(sessionObject);
        }
      });
      return sessions;
    }
    return undefined;
  }
);
