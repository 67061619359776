import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import { Intent, Spinner, Icon } from "@blueprintjs/core";

import "react-image-lightbox/style.css";

const bytesToSize = (bytes) => {
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)}${sizes[i]}`;
};

class Attachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      url: "",
      filename: "",
      filetype: "",
      filesize: "",
      loadCounter: 2,
      enlarged: false,
    };
  }

  async loadAttachmentInformation(storagePath, storagePathResolver) {
    const { loadCounter } = this.state;
    const { metadataPromise, downloadUrlPromise } =
      storagePathResolver(storagePath);
    const metadata = await metadataPromise;
    const downloadUrl = await downloadUrlPromise;
    this.setState({
      filename: metadata.name.split(".")[0],
      filetype: metadata.name.split(".")[1],
      filesize: bytesToSize(metadata.size),
      loadCounter: loadCounter - 1,
      url: downloadUrl,
    });
  }

  render() {
    const { type, storagePath, storagePathResolver } = this.props;
    const {
      enlarged,
      url,
      loadCounter,
      hovered,
      filename,
      filesize,
      filetype,
    } = this.state;
    const isImage = type === "image";

    if (loadCounter !== 0) {
      this.loadAttachmentInformation(storagePath, storagePathResolver);
    }

    if (enlarged) {
      return (
        <Lightbox
          mainSrc={url}
          onCloseRequest={() => this.setState({ enlarged: false })}
        />
      );
    }

    const isLoading = loadCounter > 0;
    return (
      <div
        onClick={() => {
          if (!isLoading) {
            if (isImage) {
              this.setState({ enlarged: true });
            } else {
              const downloadAnchor = document.createElement("a");
              downloadAnchor.href = url;
              downloadAnchor.target = "_blank";
              const downloadEvent = document.createEvent("MouseEvents");
              downloadEvent.initEvent("click", true, true);
              downloadAnchor.dispatchEvent(downloadEvent);
            }
          }
          this.setState({ hovered: false });
        }}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        className={hovered ? "bp3-elevation-1" : ""}
        style={{
          marginTop: "12px",
          borderRadius: "10px",
          overflow: "hidden",
          backgroundImage: isImage ? `url("${url}")` : null,
          backgroundColor: "rgba(0,0,0,0.15)",
          height: "70px",
          width: "114px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          alignSelf: "flex-end",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading && <Spinner intent={Intent.PRIMARY} size={35} />}
        {!isImage && !isLoading && (
          <div
            style={{
              textAlign: "left",
              alignItems: "start",
              display: "flex",
              flexDirection: "column",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <strong>{filename}</strong>
            <div className="bp3-text-muted">
              <Icon icon="document" />
              {` ${filesize.toUpperCase()} ${filetype.toUpperCase()}`}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Attachment;
