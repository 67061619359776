import React, { useState } from "react";
import { Spinner } from "@blueprintjs/core";
import InputBox from "../../InputBox";

const PatientActions = ({
  sendMessageToPatient,
  activeHealthcareService,
  practitionerSpeciality,
  conditions,
  medications,
  specialties,
  labTests,
  patientID,
}) => {
  const timeNow = new Date();
  timeNow.setSeconds(0, 0);

  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isSessionAction, setIsSessionAction] = useState(false);

  if (!isSendingMessage) {
    return (
      <InputBox
        patientID={patientID}
        activeHealthcareService={activeHealthcareService}
        practitionerSpeciality={practitionerSpeciality}
        selectedSpeciality={practitionerSpeciality}
        sendAction={async (inputsRecommendations, inputsArray, action) => {
          setIsSendingMessage(true);
          await sendMessageToPatient(
            inputsRecommendations,
            inputsArray,
            action
          );
          setIsSendingMessage(false);
          setIsSessionAction(false);
        }}
        changeSpeciality={() => {}}
        medications={medications}
        conditions={conditions}
        labTests={labTests}
        specialties={specialties}
        isPatientAction
        isSessionAction={isSessionAction}
        source="patientProfile"
        setIsSessionAction={setIsSessionAction}
      />
    );
  }
  return (
    <div>
      <Spinner />
    </div>
  );
};

export default PatientActions;
