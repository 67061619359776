import { Record } from "immutable";

const Specialty = new Record({
  key: null,
  brief: null,
  name: {
    arabic: null,
    english: null,
  },
  referralNote: null,
});

export default Specialty;
