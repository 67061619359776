/* eslint-disable import/no-cycle */
import { Map } from "immutable";
import * as t from "./action-types";

import {
  firebaseDb,
  storage,
  servervalue,
  firestore,
  firestoreTimestamp,
  functions,
} from "../firebase";
import { conditionsActions } from "../conditions";
import { specialtiesActions } from "../specialties";
import { practitionerActions } from "../practitioners";
import { observationTypesActions } from "../observationTypes";
import { accountsActions } from "../inbox";
import Notification from "../views/components/Notification";
import { communicationRequestsActions } from "../communicationRequests";
import { observationAlertsActions } from "../observationAlerts";
import { warnOnSlotUnavailability } from "../inbox/actions";
import { medicationsActions } from "../medications";
import api from "./api";

export function loadProfile(patientKey) {
  return async (dispatch, getState) => {
    const { activeHealthcareService } = getState().auth;
    if (activeHealthcareService) {
      const profile = await api.getProfile({
        healthcareServiceKey: activeHealthcareService,
        patientKey,
      });

      if (profile)
        dispatch({
          type: t.LOAD_PROFILE,
          payload: {
            [patientKey]: profile,
          },
        });
    }
    return null;
  };
}

let unsubscribePairedPatients;
export function loadPairedPatients() {
  return async (dispatch, getState) => {
    const practitionerID = getState().auth.id;
    let isFirstRun = true;
    unsubscribePairedPatients = firestore
      .collection("patientPractitionerRelations")
      .where("practitionerID", "==", practitionerID)
      .where("active", "==", true)
      .onSnapshot((querySnapshot) => {
        if (isFirstRun) {
          isFirstRun = false;
          const pairedPatients = [];
          querySnapshot.forEach((doc) => {
            pairedPatients.push({
              key: doc.id,
              ...doc.data(),
            });
            loadProfile(doc.data().patientID)(dispatch, getState);
          });
          dispatch({
            type: t.LOAD_PAIRED_PATIENTS,
            payload: pairedPatients,
          });
        } else {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              dispatch({
                type: t.CREATE_PAIRED_PATIENTS,
                payload: { ...change.doc.data(), key: change.doc.id },
              });
              loadProfile(change.doc.data().pairedPatients)(dispatch, getState);
            }
            if (change.type === "modified") {
              dispatch({
                type: t.UPDATE_PAIRED_PATIENTS,
                payload: { ...change.doc.data(), key: change.doc.id },
              });
            }
            if (change.type === "removed") {
              dispatch({
                type: t.REMOVED_PAIRED_PATIENTS,
                payload: { ...change.doc.data(), key: change.doc.id },
              });
            }
          });
        }
      });
  };
}

let unsubscribePatientServiceRequests;
let unsubscribePatientDiagnosticReports;
let unsubscribePatientAppointments;
let unsubscribePatientEncounters;

function clearListeners() {
  return (dispatch, getState) => {
    const { selectedPatient } = getState().patients;
    const { activeHealthcareService } = getState().auth;
    if (Map.isMap(selectedPatient)) {
      const previousSelectedPatientKey =
        Boolean(selectedPatient.get("profile")) &&
        Boolean(selectedPatient.get("profile").get("key"))
          ? selectedPatient.get("profile").get("key")
          : undefined;
      if (previousSelectedPatientKey !== undefined) {
        firebaseDb.ref(`/asknala/${previousSelectedPatientKey}`).off();
        firebaseDb
          .ref(
            `healthcareServices/${activeHealthcareService}/profiles/${previousSelectedPatientKey}`
          )
          .off();
      }
    }
    if (unsubscribePatientServiceRequests) unsubscribePatientServiceRequests();
    if (unsubscribePatientDiagnosticReports)
      unsubscribePatientDiagnosticReports();
    if (unsubscribePatientAppointments) unsubscribePatientAppointments();
    if (unsubscribePatientServiceRequests) unsubscribePatientEncounters();
  };
}

export function selectPatient(profile) {
  return (dispatch, getState) => {
    clearListeners()(dispatch, getState);
    dispatch({
      type: t.SELECT_PATIENT,
      payload: profile,
    });
    const { activeHealthcareService } = getState().auth;
    firebaseDb
      .ref(
        `healthcareServices/${activeHealthcareService}/profiles/${profile.key}`
      )
      .on("value", (snapshot) => {
        dispatch({
          type: t.SELECT_PATIENT,
          payload: {
            ...snapshot.val(),
            key: snapshot.key,
          },
        });
      });
    firebaseDb.ref(`/asknala/${profile.key}`).on("value", (snapshot) => {
      dispatch({
        type: t.LOAD_ASKNALA_ACCOUNT,
        payload: snapshot.val(),
      });
    });
    unsubscribePatientServiceRequests = firestore
      .collection("serviceRequests")
      .where("recipient.userId", "==", profile.key)
      .onSnapshot((querySnapshot) => {
        const serviceRequests = [];
        querySnapshot.forEach((doc) => {
          serviceRequests.push({ ...doc.data(), key: doc.id });
        });
        dispatch({
          type: t.LOAD_SERVICE_REQUESTS,
          payload: serviceRequests,
        });
      });
    unsubscribePatientDiagnosticReports = firestore
      .collection("diagnosticReports")
      .where("userId", "==", profile.key)
      .onSnapshot((querySnapshot) => {
        const diagnosticReports = [];
        querySnapshot.forEach((doc) => {
          diagnosticReports.push({ ...doc.data(), key: doc.id });
        });
        dispatch({
          type: t.LOAD_DIAGNOSTIC_REPORTS,
          payload: diagnosticReports,
        });
      });
    const appointmentStatuses = [
      "booked",
      "arrived",
      "fulfilled",
      "cancelled",
      "noshow",
      "provider-noshow",
    ];
    unsubscribePatientAppointments = firestore
      .collection("appointments")
      .where("patientID", "==", profile.key)
      .where("status", "in", appointmentStatuses)
      .onSnapshot((querySnapshot) => {
        const appointments = [];
        querySnapshot.forEach((doc) => {
          appointments.push({ ...doc.data(), key: doc.id });
        });
        dispatch({
          type: t.LOAD_APPOINTMENTS,
          payload: appointments,
        });
      });
    unsubscribePatientEncounters = firestore
      .collection("encounters")
      .where("subject", "==", profile.key)
      .onSnapshot((querySnapshot) => {
        const encounters = [];
        querySnapshot.forEach((doc) => {
          encounters.push({ ...doc.data(), key: doc.id });
        });
        dispatch({
          type: t.LOAD_ENCOUNTERS,
          payload: encounters,
        });
      });
  };
}

export function sendMessageToPatient(
  inputsRecommendations,
  inputsArray,
  action
) {
  return async (dispatch, getState) => {
    const { profile } = getState().patients.toJS().selectedPatient;
    const { activeHealthcareService, practitionerRole, id } = getState().auth;
    const practitionerSpeciality =
      practitionerRole && practitionerRole.get("speciality")
        ? practitionerRole.get("speciality")
        : "primary-care";
    const isPrescription = action && action.prescription;

    if (action && action.videoCall) {
      const pendingAppointmentsSnapshot = await firestore
        .collection("appointments")
        .where("status", "==", "pending")
        .where("speciality", "==", practitionerSpeciality)
        .where("patientID", "==", profile.key)
        .where(
          "serviceProvider.healthcareServiceID",
          "==",
          activeHealthcareService
        )
        .where(
          "serviceProvider.practitionerRoleID",
          "==",
          practitionerRole.get("key")
        )
        .get();

      let requestExists;
      pendingAppointmentsSnapshot.forEach((doc) => {
        if (doc.exists) requestExists = true;
      });
      if (requestExists) {
        Notification.Error(`Appointment Request has already been sent before`);
        return;
      }
      warnOnSlotUnavailability(
        activeHealthcareService,
        practitionerRole.get("key"),
        practitionerSpeciality
      );
      const profileSnapshot = await firebaseDb
        .ref()
        .child("practitioners")
        .child(id)
        .once("value");
      const practitionerProfile = profileSnapshot.val();

      const appointment = {
        created: firestoreTimestamp.fromDate(new Date()),
        description: action.videoCall.videoCallNote,
        isVideoCall: true,
        patientID: profile.key,
        speciality: practitionerSpeciality,
        status: "pending",
        serviceProvider: {
          healthcareServiceID: activeHealthcareService,
          practitionerID: id,
          practitionerRoleID: practitionerRole.get("key"),
          profile: practitionerProfile,
        },
      };
      await firestore.collection("appointments").add(appointment);
      Notification.Success(`Appointment Request sent successfully`);
      return;
    }

    const getAge = () => {
      return (
        new Date(Date.now() - new Date(profile.dateOfBirth)).getUTCFullYear() -
        1970
      );
    };

    // adding fake session
    let session;

    if (!isPrescription) {
      const sessionKey = await firebaseDb
        .ref("asknala")
        .child(profile.key)
        .child("sessions")
        .push({
          started: servervalue.TIMESTAMP,
          patientID: profile.key,
          patient: {
            gender: profile.gender,
            age: getAge(),
            observations: profile.observations,
            conditions: profile.conditions,
            medications: profile.medications,
            procedures: profile.procedures,
            allergyIntolerances: profile.allergyIntolerances,
            familyHistories: profile.familyHistories,
          },
          isAutomated: false,
          healthcareService: activeHealthcareService,
          speciality: practitionerSpeciality,
          unread: true,
          isInitiatedByPractitioner: true,
        }).key;

      // creating a fake question
      const fakeQuestion =
        practitionerSpeciality === "primary-care"
          ? "-تم إنشاء المحادثة من قبل الطبيب-"
          : "-تم إنشاء المحادثة من قبل الأخصائي-";

      await firebaseDb
        .ref("asknala")
        .child(profile.key)
        .child("sessions")
        .child(sessionKey)
        .child("questions")
        .push({
          question: fakeQuestion,
          sent: servervalue.TIMESTAMP,
          responded: servervalue.TIMESTAMP,
        }).key;

      session = (
        await firebaseDb
          .ref(`asknala/${profile.key}/sessions/${sessionKey}`)
          .once("value")
      ).val();

      session.key = sessionKey;
    }

    await accountsActions.sendAction(
      inputsRecommendations,
      inputsArray,
      action,
      session,
      profile,
      "patientProfile"
    )(dispatch, getState);
  };
}

export function loadData() {
  return (dispatch, getState) => {
    accountsActions.loadLabTests()(dispatch, getState);
    conditionsActions.loadConditions()(dispatch, getState);
    specialtiesActions.loadSpecialties()(dispatch, getState);
    practitionerActions.loadPractitioners()(dispatch, getState);
    observationTypesActions.loadObservationTypes()(dispatch, getState);
    medicationsActions.loadMedications()(dispatch, getState);
    loadPairedPatients()(dispatch, getState);
  };
}

export function loadProfiles() {
  return async (dispatch, getState) => {
    const { activeHealthcareService } = getState().auth;
    const communicationRequests =
      getState().communicationRequests.communicationRequestsList;
    const profiles = await Promise.all(
      communicationRequests
        .filter((communicationRequest) => {
          return !(
            getState().patients.profiles &&
            getState().patients.profiles[communicationRequest.subject]
          );
        })
        .map((communicationRequest) => {
          return api.getProfile({
            healthcareServiceKey: activeHealthcareService,
            patientKey: communicationRequest.subject,
          });
        })
    );
    const normalizedProfiles = profiles
      .filter((profile) => profile)
      .reduce(
        (result, profile) => ({
          ...result,
          [profile.key]: profile,
        }),
        {}
      );
    dispatch({
      type: t.LOAD_PROFILES,
      payload: normalizedProfiles,
    });
  };
}

export function unloadData() {
  return () => {
    if (unsubscribePairedPatients) unsubscribePairedPatients();
  };
}

/**
 * Returns download url promise
 * @param {String} fullPath To be resolved into a download url
 */
export function storagePathResolver(fullPath) {
  return () => {
    const metadataPromise = storage.ref(fullPath).getMetadata();
    const downloadUrlPromise = storage.ref(fullPath).getDownloadURL();
    return { metadataPromise, downloadUrlPromise };
  };
}

export function sendAnswerToHistorySession(
  inputsRecommendations,
  inputsArray,
  action,
  selectedSession
) {
  return async (dispatch, getState) => {
    const selectedProfile = getState().patients.toJS().selectedPatient.profile;
    return accountsActions.sendAction(
      inputsRecommendations,
      inputsArray,
      action,
      selectedSession,
      selectedProfile,
      "session"
    )(dispatch, getState);
  };
}

function getSelectedPatient() {
  return (dispatch, getState) => {
    const { selectedPatient } = getState().patients;
    const patientID = selectedPatient.get("profile").get("key");
    return patientID;
  };
}

export function createPatientReminder({
  reminderType,
  reminderTime,
  note,
  payload = {},
}) {
  return async (dispatch, getState) => {
    await communicationRequestsActions.createPatientReminder({
      reminderTime,
      reminderType,
      note,
      patientID: getSelectedPatient()(dispatch, getState),
      payload,
    })(dispatch, getState);
  };
}

export function communicationRequestAction({ communicationRequest, action }) {
  return (dispatch, getState) => {
    return communicationRequestsActions.communicationRequestAction({
      communicationRequest,
      action,
    })(dispatch, getState);
  };
}

export function processDiagnosticReport({
  observations,
  conclusion,
  diagnosticReport,
}) {
  return async (dispatch, getState) => {
    const { selectedPatient } = getState().patients;
    const patientID = selectedPatient.get("profile").get("key");
    const diagnosticReportKey = diagnosticReport.key;
    const practitionerID = getState().auth.id;
    const generatedKey = await firebaseDb.ref(`profiles`).push().key;

    await firestore
      .collection("diagnosticReports")
      .doc(diagnosticReportKey)
      .set(
        {
          status: "complete",
          conclusion,
          result: { observations },
        },
        { merge: true }
      );

    await firebaseDb
      .ref("profiles")
      .child(patientID)
      .transaction((patientProfile) => {
        if (patientProfile) {
          const resultPatientProfile = { ...patientProfile };

          // Add observations to user profile
          observations.forEach((observation) => {
            const { observationKey } = observation;

            if (resultPatientProfile.observations === undefined) {
              resultPatientProfile.observations = {};
            }

            const newValue = {
              dateTime: new Date().getTime(),
              performer: practitionerID,
              unit:
                !observation.newUnit || observation.newUnit === ""
                  ? null
                  : observation.newUnit,
              value: observation.newValue,
              derivedFrom: {
                diagnosticReportKey,
              },
            };

            const observationHistory =
              Boolean(resultPatientProfile.observations[observationKey]) &&
              resultPatientProfile.observations[observationKey].history
                ? resultPatientProfile.observations[observationKey].history
                : {};

            resultPatientProfile.observations[observationKey] = {
              current: newValue,
              history: {
                ...observationHistory,
                [generatedKey]: newValue,
              },
            };
          });

          return resultPatientProfile;
        }

        return patientProfile;
      });
  };
}

export function createObservationAlert({
  note,
  reminderType,
  observation,
  formula,
  dateTime,
  alertEmergencyContact,
}) {
  return async (dispatch, getState) => {
    const patientID = getSelectedPatient()(dispatch, getState);
    await observationAlertsActions.createObservationAlert({
      note,
      reminderType,
      observation,
      formula,
      dateTime,
      patientID,
      alertEmergencyContact,
    })(dispatch, getState);
  };
}

export function removeObservationAlert({
  observationAlertKey,
  patientKey,
  observationKey,
}) {
  return async (dispatch, getState) => {
    await observationAlertsActions.removeObservationAlert({
      observationAlertKey,
      patientKey,
      observationKey,
    })(dispatch, getState);
  };
}

export function sendNotificationToUser({
  notificationTitle = "",
  notificationBody,
  data = {},
  patientID,
}) {
  return async () => {
    await api.sendNotificationToUser({
      notificationTitle,
      notificationBody,
      data,
      patientID,
    });
  };
}

export function addObservation({ observationName, value, patientKey, unit }) {
  return async (dispatch, getState) => {
    const addObservationFunction = functions.httpsCallable("addObservation");
    const performer = getState().auth.id;
    const user = { uid: patientKey };
    try {
      await addObservationFunction({
        observationName,
        value,
        user,
        unit,
        performer,
      });
    } catch (err) {
      // console.log({ err });
    }
  };
}

export function setNewPrescription({ userID }) {
  return async () => {
    await api.setNewPrescription({ userID });
  };
}
