/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

const AudioAttachment = ({ storagePath, storagePathResolver }) => {
  const { downloadUrlPromise } = storagePathResolver(storagePath);
  const [url, setUrl] = useState("");

  const loadData = async () => {
    const downloadUrl = await downloadUrlPromise;

    setUrl(downloadUrl);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div
      style={{
        marginTop: 10,
        marginBottom: 5,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {url ? (
        <audio controls>
          <source src={url} />
          Your browser does not support the audio element.
        </audio>
      ) : (
        <div
          style={{
            width: 30,
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <Spinner size={30} />
        </div>
      )}
    </div>
  );
};

export default AudioAttachment;
