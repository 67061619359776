/* eslint-disable react/jsx-props-no-spreading */
import { Classes } from "@blueprintjs/core";
import React, { forwardRef } from "react";

const PhoneNumberInput = (props, ref) => {
  const { onChange, value } = props;

  return (
    <input
      className="test"
      // eslint-disable-next-line react/no-unknown-property
      class={Classes.INPUT}
      {...props}
      value={value}
      onChange={onChange}
      ref={ref}
      fullWidth
      name="phone"
      style={{ width: "100%" }}
    />
  );
};

export default forwardRef(PhoneNumberInput);
