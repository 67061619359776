// import conditionList from "./condition-list";
import { FirebaseList } from "../firebase";
import Condition from "./condition";

import {
  CREATE_CONDITION_SUCCESS,
  REMOVE_CONDITION_SUCCESS,
  UPDATE_CONDITION_SUCCESS,
  LOAD_CONDITIONS_SUCCESS,
  LOAD_CONDITIONS_ERROR,
  UNLOAD_CONDITIONS,
} from "./action-types";

export function createConditionSuccess(condition) {
  return {
    type: CREATE_CONDITION_SUCCESS,
    payload: condition,
  };
}

export function removeConditionSuccess(condition) {
  return {
    type: REMOVE_CONDITION_SUCCESS,
    payload: condition,
  };
}

export function updateConditionSuccess(condition) {
  return {
    type: UPDATE_CONDITION_SUCCESS,
    payload: condition,
  };
}

export function loadConditionsSuccess(conditions) {
  return {
    type: LOAD_CONDITIONS_SUCCESS,
    payload: conditions,
  };
}

export function loadConditionsError(conditions) {
  return {
    type: LOAD_CONDITIONS_ERROR,
    payload: conditions,
  };
}

/** condition-lit */

const conditionList = new FirebaseList(
  {
    onAdd: createConditionSuccess,
    onChange: updateConditionSuccess,
    onRemove: removeConditionSuccess,
    onLoad: loadConditionsSuccess,
  },
  Condition
);

/** */

export function loadConditions() {
  return (dispatch) => {
    conditionList.path = `knowledge/conditions`;
    conditionList.subscribe(dispatch);
  };
}

export function unloadConditions() {
  conditionList.unsubscribe();
  return {
    type: UNLOAD_CONDITIONS,
  };
}
