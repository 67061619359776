import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Intent } from "@blueprintjs/core";

const moment = require("moment");

const InviteRow = ({
  invite = {},
  onEdit = () => null,
  toggleActive = () => null,
}) => {
  let iconType;
  if (invite.isActive) {
    if (
      !invite.usedBy ||
      invite.usageLimit - Object.keys(invite.usedBy).length > 0
    ) {
      iconType = <Icon icon="full-circle" intent={Intent.SUCCESS} />;
    } else {
      iconType = <Icon icon="full-circle" intent={Intent.WARNING} />;
    }
  } else {
    iconType = <Icon icon="full-circle" intent={Intent.DANGER} />;
  }

  return (
    <tr>
      <td
        style={{
          verticalAlign: "middle",
        }}
      >
        {iconType}
      </td>
      <td
        style={{
          verticalAlign: "middle",
        }}
      >
        {invite.key}
      </td>
      <td
        style={{
          verticalAlign: "middle",
        }}
      >
        {moment(invite.startTime).format("MM DD YYYY, HH:mm")}
      </td>
      <td
        style={{
          verticalAlign: "middle",
        }}
      >
        {moment(invite.endTime).format("MM DD YYYY, HH:mm")}
      </td>
      <td
        style={{
          verticalAlign: "middle",
        }}
      >
        {invite.usageLimit -
          (invite.usedBy ? Object.keys(invite.usedBy).length : 0)}
        &nbsp;of {invite.usageLimit}
      </td>
      <td
        style={{
          verticalAlign: "middle",
        }}
      >
        {invite.discount}%
      </td>
      <td
        style={{
          verticalAlign: "middle",
        }}
      >
        <Button
          disabled
          style={{
            marginRight: "5px",
          }}
          icon="history"
          onClick={() => null}
        />

        {invite.isActive ? (
          <Button
            style={{
              marginRight: "5px",
            }}
            disabled={false}
            icon="pause"
            intent={Intent.WARNING}
            onClick={() => toggleActive(false)}
          />
        ) : (
          <Button
            style={{
              marginRight: "5px",
            }}
            disabled={false}
            icon="play"
            intent={Intent.SUCCESS}
            onClick={() => toggleActive(true)}
          />
        )}
        <Button
          style={{
            marginRight: "5px",
          }}
          disabled={false}
          icon="edit"
          intent={Intent.PRIMARY}
          onClick={onEdit}
        />
      </td>
    </tr>
  );
};

InviteRow.propTypes = {
  invite: PropTypes.shape({
    invite: PropTypes.string,
    subscription: PropTypes.bool,
  }),
};

export default InviteRow;
