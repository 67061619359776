import { firebaseAuth, firebaseDb, functions } from "../firebase";

import Notification from "../views/components/Notification";
import {
  UPDATE_ROLES_AND_LINKED_HEALTH_CARE_SERVICES,
  INIT_AUTH,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  LOAD_PRACTITIONER_ROLE,
} from "./action-types";
import { loadCommunicationRequests } from "../communicationRequests/actions";

function updateUserClaims({ userKey }) {
  return async () => {
    const updateUserCustomClaims = functions.httpsCallable(
      "updateUserCustomClaims"
    );
    try {
      await updateUserCustomClaims({ userKey });
    } catch (err) {
      // console.log({ err });
    }
  };
}

export function updateRolesAndLinkedHealthCareServices(user) {
  return async (dispatch) => {
    const userRef = firebaseDb.ref().child("users").child(user.uid);
    const userObject = (await userRef.once("value")).val();

    if (userObject && userObject.access) {
      dispatch({
        type: UPDATE_ROLES_AND_LINKED_HEALTH_CARE_SERVICES,
        payload: userObject.access,
      });
    } else {
      dispatch({
        type: UPDATE_ROLES_AND_LINKED_HEALTH_CARE_SERVICES,
        payload: {},
      });
    }
    await updateUserClaims({ userKey: user.uid })(dispatch);
  };
}

export function loadPractitionerRoleData(user) {
  return async (dispatch, getState) => {
    const { activeHealthcareService } = getState().auth;

    if (
      activeHealthcareService !== null &&
      activeHealthcareService !== undefined
    ) {
      const practitionerRoles = (
        await firebaseDb
          .ref(
            `healthcareServices/${activeHealthcareService}/practitionerRoles`
          )
          .once("value")
      ).val();

      const practitionerRoleKey = Object.keys(practitionerRoles).find(
        (roleKey) => practitionerRoles[roleKey].practitionerKey === user.uid
      );

      if (practitionerRoleKey) {
        const practitionerRole = {
          key: practitionerRoleKey,
          ...practitionerRoles[practitionerRoleKey],
        };
        dispatch({
          type: LOAD_PRACTITIONER_ROLE,
          payload: practitionerRole,
        });
      } else {
        Notification.Error(`Could not find practitionerRole`);
      }
    }
  };
}

export function collectUserData({ user }) {
  return async (dispatch, getState) => {
    // Do not change order
    await updateRolesAndLinkedHealthCareServices(user)(dispatch);
    await loadPractitionerRoleData(user)(dispatch, getState);
    await loadCommunicationRequests()(dispatch, getState);
  };
}

export function signInSuccess(result) {
  return async (dispatch, getState) => {
    // console.log("SIGNINSUCCESS!!!", result.user);
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: result.user,
    });
    await collectUserData({ user: result.user })(dispatch, getState);
  };
}

export function signInError(error) {
  Notification.Error(error.message);
  // console.log(error);

  return {
    type: SIGN_IN_ERROR,
    payload: error,
  };
}

export function login({ email, password }) {
  return (dispatch, getState) => {
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        signInSuccess(result)(dispatch, getState);
      })
      .catch((error) => dispatch(signInError(error)));
  };
}

export function reset({ email }) {
  return (dispatch) => {
    firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        Notification.Success("Reset email has been sent");
      })
      .catch((error) => dispatch(signInError(error)));
  };
}

export function signup({ email, password }) {
  return (dispatch, getState) => {
    firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        signInSuccess(result)(dispatch, getState);
        // TODO: Create user object in database: or create a cloud function for it
      })
      .catch((error) => dispatch(signInError(error)));
  };
}

export function initAuth(user) {
  return async (dispatch, getState) => {
    dispatch({
      type: INIT_AUTH,
      payload: user,
    });
    if (user) {
      await collectUserData({ user })(dispatch, getState);
    }
  };
}

export function signOutSuccess() {
  return {
    type: SIGN_OUT_SUCCESS,
  };
}

export function signOut() {
  return (dispatch) => {
    firebaseAuth.signOut().then(() => dispatch(signOutSuccess()));
  };
}
