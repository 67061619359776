import { Button, Intent, MenuItem } from "@blueprintjs/core";
import { Omnibar } from "@blueprintjs/select";
import React from "react";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";

const MedicationsSelect = ({
  isMedicationOmniBar,
  setIsMedicationOmniBar,
  medications,
  setSelectedMedication,
  selectedMedication,
}) => {
  if (!medications) {
    return <></>;
  }

  return (
    <Omnibar
      isOpen={isMedicationOmniBar}
      inputProps={{
        name: "medication",
        autoComplete: "off",
        placeholder: "Search medications",
        rightElement: (
          <Button
            icon="cross"
            intent={Intent.DANGER}
            minimal
            onClick={() => {
              setIsMedicationOmniBar(false);
            }}
          />
        ),
      }}
      name="medication"
      id="medication"
      initialContent={
        <MenuItem
          className="medications-item"
          disabled
          text={`${medications.length} medications loaded.`}
        />
      }
      resetOnSelect
      items={medications}
      onItemSelect={(medication) => {
        setSelectedMedication(medication);
        setIsMedicationOmniBar(false);
      }}
      itemRenderer={(medication, { handleClick }) => (
        <MenuItem
          style={{
            fontSize: 10,
          }}
          className="medications-item"
          key={medication.key}
          label={`${medication.name.scientificName.en}`}
          text={`(${medication.name.tradeName.en} : ${medication.strength.value} ${medication.strength.unit}) ${medication.form} [${medication.administrationRoute}]`.toUpperCase()}
          onClick={handleClick}
          shouldDismissPopover
        />
      )}
      itemPredicate={(query, medication) => {
        if (query.length <= 1) {
          return false;
        }
        return `${medication.predicate}`.indexOf(query.toLowerCase()) >= 0;
      }}
      noResults={
        <MenuItem className="medications-item" disabled text="No results." />
      }
      overlayProps={{
        autoFocus: true,
      }}
    >
      <Button
        style={{
          marginBottom: "0.5em",
        }}
        fill
        large={false}
        alignText="left"
        text={
          selectedMedication
            ? selectedMedication.name.scientificName.en
            : "Select a medication template"
        }
        icon="search"
      />
    </Omnibar>
  );
};

export default MedicationsSelect;
