function getCommunicationRequestsReducer(state) {
  return state.communicationRequests;
}

export function getCommunicationRequests(state) {
  return getCommunicationRequestsReducer(state).communicationRequestsList;
}

export function getIsCommunicationRequestsLoaded(state) {
  return getCommunicationRequestsReducer(state).loaded;
}
