import { List, Record } from "immutable";

import {
  LOAD_CONDITIONS_SUCCESS,
  CREATE_CONDITION_SUCCESS,
} from "./action-types";

export const ConditionsState = new Record({
  list: new List(),
});
export function conditionsReducer(
  state = new ConditionsState(),
  { payload, type }
) {
  switch (type) {
    case LOAD_CONDITIONS_SUCCESS:
      return state.set("list", new List(payload));

    case CREATE_CONDITION_SUCCESS:
      return state.set("list", state.list.unshift(payload));

    default:
      return state;
  }
}
