import React from "react";
import PropTypes from "prop-types";
import { Menu } from "@blueprintjs/core";

import SidebarHeader from "../sidebarHeader";

const Sidebar = ({ login, reset, signup }) => (
  <div className="bp3-menu main-nav bp3-elevation-2">
    <Menu large>
      <SidebarHeader login={login} reset={reset} signup={signup} />
    </Menu>
  </div>
);

Sidebar.propTypes = {
  login: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
};

export default Sidebar;
