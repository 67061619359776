import React, { useState } from "react";
import { Callout, Intent, Button, Tag, Spinner } from "@blueprintjs/core";
import moment from "moment";
import { getPractitionerNameFromPractitionersList } from "../../utils";
import Notification from "../Notification";
import { VideoCallButton } from "../VideoCallCard";

const reminderFor = (communicationRequest) => {
  const { recipient } = communicationRequest;
  if (recipient && recipient.practitionerRole) {
    return "you";
  }

  if (recipient && recipient.speciality) {
    return `${recipient.speciality}`;
  }

  return "everyone";
};

const PatientCommunicationRequests = ({
  patientCommunicationRequests,
  practitioners,
  communicationRequestAction,
  openDiagnosticReportOverlay,
  diagnosticReports,
}) => {
  const renderPatientCommunicationRequests = () => {
    // puts alerts at the top, and the rest below
    return [
      ...patientCommunicationRequests
        .filter(
          (communicationRequest) =>
            communicationRequest.payload &&
            communicationRequest.payload.isObservationAlert === true
        ) // First Group: if communicationRequest is an observation alert
        .sort((communicationRequestA, communicationRequestB) => {
          return (
            communicationRequestA.occurrenceDateTime.seconds -
            communicationRequestB.occurrenceDateTime.seconds
          );
        }),
      ...patientCommunicationRequests
        .filter(
          (communicationRequest) =>
            !(
              communicationRequest.payload &&
              communicationRequest.payload.isObservationAlert === true
            )
        ) // Second Group: if communicationRequest is not an observation alert
        .sort((communicationRequestA, communicationRequestB) => {
          return (
            communicationRequestA.occurrenceDateTime.seconds -
            communicationRequestB.occurrenceDateTime.seconds
          );
        }),
    ].map((communicationRequest) => {
      switch (communicationRequest.category) {
        case "reminder":
        case "alert":
          return (
            <PatientReminder
              key={communicationRequest.key}
              communicationRequest={communicationRequest}
              practitionerName={getPractitionerNameFromPractitionersList(
                practitioners,
                communicationRequest.requester
              )}
              communicationRequestAction={communicationRequestAction}
              openDiagnosticReportOverlay={openDiagnosticReportOverlay}
              diagnosticReports={diagnosticReports}
            />
          );
        default:
          return <></>;
      }
    });
  };

  if (patientCommunicationRequests.size === 0) {
    return <></>;
  }

  return (
    <div className="no-scrollbar">{renderPatientCommunicationRequests()}</div>
  );
};

export default PatientCommunicationRequests;

const CommunicationRequestCallout = ({ intent, icon, isLoading, children }) => {
  return (
    <Callout
      style={{ margin: "10px 0px" }}
      intent={intent}
      icon={!isLoading ? icon : null}
    >
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>{children}</>
      )}
    </Callout>
  );
};

const getOccurrenceDateText = (communicationRequest) => {
  if (
    communicationRequest.payload &&
    communicationRequest.payload.isObservationAlert === true
  ) {
    return "";
  }
  return ` at ${moment(communicationRequest.occurrenceDateTime.toDate()).format(
    "D MMM. YYYY hh:mm A"
  )}`;
};

const PatientReminder = ({
  communicationRequest,
  practitionerName,
  communicationRequestAction,
  openDiagnosticReportOverlay,
  diagnosticReports,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isVideoCallReminder =
    communicationRequest.payload &&
    communicationRequest.payload.isVideoCallReminder;
  const isObservationAlert =
    communicationRequest.payload &&
    communicationRequest.payload.isObservationAlert === true;
  const isDiagnosticReportReminder =
    communicationRequest.payload &&
    communicationRequest.payload.isDiagnosticReportReminder === true;

  const isDiagnosticReportComplete = (diagnosticReportID) => {
    const getDiagnosticReport = () => {
      if (
        diagnosticReports &&
        Array.isArray(diagnosticReports) &&
        diagnosticReports.length > 0
      ) {
        return diagnosticReports.find(
          (diagnosticReport) => diagnosticReport.key === diagnosticReportID
        );
      }
      return undefined;
    };
    if (getDiagnosticReport() === undefined) {
      return false;
    }
    return getDiagnosticReport().status === "complete";
  };

  const submitAction = async ({ action, actionName }) => {
    setIsLoading(true);
    try {
      await communicationRequestAction({
        communicationRequest,
        action,
      });
      Notification.Success(`${actionName} action performed successfully`);
    } catch (e) {
      setIsLoading(false);
      Notification.Error(e.message);
    }
  };

  const getCustomizedConfig = () => {
    if (isVideoCallReminder) {
      return {
        title: `There is an upcoming video call with this patient for `,
        icon: `mobile-video`,
        color: Intent.SUCCESS,
        showRequesterName: true,
      };
    }

    if (isObservationAlert) {
      return {
        title: `This patient requires immediate attention from `,
        icon: `warning-sign`,
        color: Intent.DANGER,
        showRequesterName: true,
      };
    }

    if (isDiagnosticReportReminder) {
      return {
        title: `This patient has pending diagnostic report that needs processing from `,
        icon: `document`,
        color: Intent.SUCCESS,
        showRequesterName: false,
      };
    }

    return {
      title: `There is a reminder about this patient for `,
      icon: `time`,
      color: Intent.PRIMARY,
      showRequesterName: true,
    };
  };

  return (
    <CommunicationRequestCallout
      intent={getCustomizedConfig().color}
      icon={getCustomizedConfig().icon}
      isLoading={isLoading}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div className="bp3-dark bp3-intent-primary bp3-heading">
            {getCustomizedConfig().title}
            {reminderFor(communicationRequest)}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {communicationRequest.note && (
              <div style={{ marginRight: 10 }}>{communicationRequest.note}</div>
            )}
            <Tag minimal round>
              {getCustomizedConfig().showRequesterName ? practitionerName : ""}
              {getOccurrenceDateText(communicationRequest)}
            </Tag>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {isVideoCallReminder &&
            communicationRequest.payload &&
            communicationRequest.payload.channelName && (
              <div style={{ marginRight: 10 }}>
                <VideoCallButton
                  communicationRequestID={communicationRequest.key}
                  intent={Intent.PRIMARY}
                  status={communicationRequest.payload.status}
                />
              </div>
            )}
          {isDiagnosticReportReminder &&
            communicationRequest.payload &&
            communicationRequest.payload.diagnosticReportID && (
              <Button
                disabled={isDiagnosticReportComplete(
                  communicationRequest.payload.diagnosticReportID
                )}
                style={{ marginRight: 10 }}
                icon={
                  !isDiagnosticReportComplete(
                    communicationRequest.payload.diagnosticReportID
                  )
                    ? getCustomizedConfig().icon
                    : "tick"
                }
                target="_blank"
                text="Process Diagnostic Report"
                intent={Intent.PRIMARY}
                onClick={() => {
                  openDiagnosticReportOverlay({
                    diagnosticReportKey:
                      communicationRequest.payload.diagnosticReportID,
                  });
                }}
              />
            )}
          <Button
            style={{ marginRight: 10 }}
            icon="small-tick"
            intent={Intent.SUCCESS}
            onClick={async () => {
              await submitAction({
                action: "markAsDone",
                actionName: "Mark as done",
              });
            }}
            disabled={
              isVideoCallReminder && !communicationRequest.payload?.encounterID
            }
          >
            Mark as done
          </Button>
          <Button
            minimal
            intent={Intent.DANGER}
            onClick={async () => {
              await submitAction({ action: "revoke", actionName: "Revoke" });
            }}
          >
            Revoke
          </Button>
        </div>
      </div>
    </CommunicationRequestCallout>
  );
};
