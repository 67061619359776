import React from "react";
import PropTypes from "prop-types";
import { HTMLTable } from "@blueprintjs/core";

import InviteRow from "./InviteRow";

const InvitesTable = ({
  invites = [],
  onEdit = () => null,
  toggleActive = () => null,
}) => (
  <HTMLTable className="table-content" striped>
    <thead>
      <tr>
        <th> </th>
        <th>Code</th>
        <th>Start</th>
        <th>End</th>
        <th>Remaining Uses</th>
        <th>Discount</th>
      </tr>
    </thead>
    <tbody>
      {invites.map((invite) => (
        <InviteRow
          key={invite.key}
          invite={invite}
          onEdit={() => onEdit(invite)}
          toggleActive={(value) => toggleActive(invite.key, value)}
        />
      ))}
    </tbody>
  </HTMLTable>
);

InvitesTable.propTypes = {
  invites: PropTypes.arrayOf(
    PropTypes.shape({
      invite: PropTypes.string,
      subscription: PropTypes.bool,
    })
  ).isRequired,
};

export default InvitesTable;
