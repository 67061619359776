/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
import { Button, H3, Intent } from "@blueprintjs/core";
import moment from "moment";
import React from "react";
import DispenseRequestActions from "./DispenseRequestActions";

const MedicationsList = ({
  medicationsList,
  removeMedicationFromMedicationsList = null,
  showOnly = false,
  showIssuedTime = false,
  showSubstitutionAllowed = true,
  isDispenseRequest = false,
  showReadyAmount = false,
  showDispensedAmount = false,
  dispenseRequestActions = {
    markDispenseRequestAsCancelled: () => {},
    markDispenseRequestAsOnHold: () => {},
    markDispenseRequestAsCompleted: () => {},
  },
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {!showOnly && (
        <H3
          style={
            showOnly
              ? {
                  marginBottom: 10,
                  marginLeft: 10,
                  fontSize: 23,
                  marginTop: 10,
                }
              : {
                  marginBottom: 10,
                }
          }
        >
          Prescriptions List
        </H3>
      )}
      <table
        className="bp3-html-table .modifier .bp3-interactive"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th>Trade Name</th>
            <th>Strength</th>
            <th>Dosage Type</th>
            <th>Adminstration Route</th>
            <th>Dosage Instructions</th>
            <th>Note</th>
            {showSubstitutionAllowed && <th>Substitution Allowed?</th>}
            {showIssuedTime && <th>Issued at</th>}
            <th>Prescribed Quantity</th>
            <th>Duration</th>
            {isDispenseRequest && showDispensedAmount && (
              <th>Dispensed Quantity</th>
            )}
            {isDispenseRequest && showReadyAmount && <th>Ready Quantity</th>}
            {!showOnly && <th> </th>}
            {isDispenseRequest && <th> </th>}
          </tr>
        </thead>
        <tbody>
          {medicationsList.map((medication) => {
            if (medication.requester === "wasfaty") {
              return <></>;
            }
            return (
              <MedicationRow
                key={`${medication.key}${medication.associatedDispenseRequest?.dispenseRequest?.key}${medication.associatedDispenseRequest?.dispenseRequest?.quantity}`}
                medication={medication}
                removeMedicationFromMedicationsList={
                  removeMedicationFromMedicationsList
                }
                showOnly={showOnly}
                showIssuedTime={showIssuedTime}
                showSubstitutionAllowed={showSubstitutionAllowed}
                isDispenseRequest={isDispenseRequest}
                associatedDispenseRequest={
                  medication.associatedDispenseRequest
                    ? medication.associatedDispenseRequest.dispenseRequest
                    : undefined
                }
                dispenseRequestActions={dispenseRequestActions}
                showReadyAmount={showReadyAmount}
                showDispensedAmount={showDispensedAmount}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const getDispensedQuantity = ({ dispenseRequests }) => {
  let dispensedQuantity = 0;
  for (const dispenseRequestKey in dispenseRequests) {
    const dispenseRequest = dispenseRequests[dispenseRequestKey];
    if (
      dispenseRequest.status === "on-hold" ||
      dispenseRequest.status === "completed"
    ) {
      dispensedQuantity += parseInt(dispenseRequest.quantity, 10);
    }
  }
  return dispensedQuantity;
};

const MedicationRow = ({
  medication,
  removeMedicationFromMedicationsList,
  showOnly,
  showIssuedTime,
  showSubstitutionAllowed,
  isDispenseRequest,
  associatedDispenseRequest,
  dispenseRequestActions,
  showDispensedAmount,
  showReadyAmount,
}) => {
  if (medication?.dispenseRequest?.initialFill?.duration) {
    medication.duration = medication.dispenseRequest.initialFill.duration;
  }

  if (medication?.dispenseRequest?.initialFill?.quantity?.value) {
    medication.quantity =
      medication?.dispenseRequest?.initialFill?.quantity?.value;
  }

  if (medication.note) {
    medication.doctorNote = medication.note;
  }

  if (isDispenseRequest) {
    medication.dispensedQuantity = getDispensedQuantity({
      dispenseRequests: medication.dispenseRequests,
    });
  }

  return (
    <tr>
      <td>{medication?.medication?.name?.tradeName?.en}</td>
      <td>{`${medication?.medication?.strength?.value} ${medication.medication?.strength.unit}`}</td>
      <td>{medication.medication?.form}</td>
      <td>{medication.medication?.administrationRoute}</td>
      <td>{medication.dosageInstructions}</td>
      <td>{medication.doctorNote}</td>
      {showSubstitutionAllowed && (
        <td>{medication.isSubstitutionAllowed === true ? "Yes" : "No"}</td>
      )}
      {showIssuedTime && <td>{moment(medication.authoredOn).fromNow()}</td>}
      <td>
        {medication.quantity} {medication.medication?.package?.type}
      </td>
      <td>{`${medication.duration} ${
        medication.duration <= 1 ? "day" : "days"
      }`}</td>
      {isDispenseRequest && showDispensedAmount && (
        <td>
          {medication.dispensedQuantity}/{medication.quantity}{" "}
          {medication.medication?.package?.type}
        </td>
      )}
      {isDispenseRequest && showReadyAmount && (
        <td>
          {medication.associatedDispenseRequest.dispenseRequest.quantity}{" "}
          {medication.associatedDispenseRequest.dispenseRequest.quantity
            ? medication.medication?.package?.type
            : ""}
        </td>
      )}
      {!showOnly && (
        <td>
          {" "}
          <Button
            minimal
            icon="trash"
            intent={Intent.DANGER}
            onClick={() => {
              removeMedicationFromMedicationsList({
                medicationKey: medication.medication.key,
              });
            }}
          />
        </td>
      )}
      {isDispenseRequest && (
        <td style={{ width: 300 }}>
          <DispenseRequestActions
            dispenseItem={medication}
            associatedDispenseRequest={associatedDispenseRequest}
            dispenseRequestActions={dispenseRequestActions}
          />
        </td>
      )}
    </tr>
  );
};

export default MedicationsList;
