import {
  Button,
  ButtonGroup,
  Callout,
  Card,
  Intent,
  Tag,
} from "@blueprintjs/core";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { dispenseRequestsActions } from "../../../dispenseRequests";
import MedicationsList from "../InputBox/PrescriptionInput/MedicationsList";
import Notification from "../Notification/Notification";
import PharmacyItemList from "./PharmacyItemList";
import WasfatyPrescription from "./WasfatyPrescription";

const UserDispenseRequestsCard = ({
  userWithDispenseRequests,
  markDispenseRequestAsCancelled,
  markDispenseRequestAsOnHold,
  markDispenseRequestAsCompleted,
  tab,
  isPharmacyItem,
}) => {
  const {
    userInfo,
    type,
    destination,
    whenCreated,
    whenPrepared,
    whenHandedOver,
    whenCancelled,
    orderID,
  } = userWithDispenseRequests[0].dispenseRequest;
  const { medicationRequest, dispenseRequest } = userWithDispenseRequests[0];
  const isWasfatyPrescription =
    medicationRequest &&
    medicationRequest.requester === "wasfaty" &&
    medicationRequest.requesterType === "organization";

  const typeConfig = {
    delivery: {
      string: "Delivery",
      color: "#365c77",
      intent: Intent.PRIMARY,
    },
    storePickup: {
      string: "Store Pickup",
      color: "#675e53",
      intent: Intent.WARNING,
    },
    undefined: {
      string: "",
      color: "white",
      intent: Intent.NONE,
    },
  };

  const getMedicationsList = () => {
    const medicationsList = [];

    for (let index = 0; index < userWithDispenseRequests.length; index += 1) {
      const d = userWithDispenseRequests[index];
      if (d.medicationRequest) {
        medicationsList.push({
          ...d.medicationRequest,
          associatedDispenseRequest: d,
        });
      }
    }
    return medicationsList;
  };

  const getPharmacyItemsList = () => {
    const pharmacyItemsList = [];
    for (let index = 0; index < userWithDispenseRequests.length; index += 1) {
      const d = userWithDispenseRequests[index];
      if (d.pharmacyItem) {
        pharmacyItemsList.push({
          ...d.pharmacyItem,
          associatedDispenseRequest: d,
        });
      }
    }
    return pharmacyItemsList;
  };

  return (
    <Card
      style={{
        marginBottom: 20,
        width: "100%",
        borderLeft: `4px solid ${typeConfig[type].color}`,
      }}
    >
      <h2 style={{ marginBottom: 10 }}>Patient Information</h2>
      <Callout>
        <div>
          <p>
            Name{" "}
            <strong style={{ color: "white", marginLeft: 10 }}>
              {userInfo.name}
            </strong>
          </p>
          <p>
            Phone{" "}
            <strong style={{ color: "white", marginLeft: 6 }}>
              {userInfo.phoneNumber}
            </strong>
          </p>
          {orderID && (
            <p>
              Order ID{" "}
              <strong style={{ color: "white", marginLeft: 6 }}>
                {orderID}
              </strong>
            </p>
          )}
          {whenCreated && (
            <p>
              Received at{" "}
              <strong style={{ color: "white", marginLeft: 6 }}>
                {moment(whenCreated).format("YYYY/MM/DD hh:mm")} (
                {moment(whenCreated).fromNow()})
              </strong>
            </p>
          )}
          {whenPrepared && (
            <p>
              Prepared at{" "}
              <strong style={{ color: "white", marginLeft: 6 }}>
                {moment(whenPrepared).format("YYYY/MM/DD hh:mm")} (
                {moment(whenPrepared).fromNow()})
              </strong>
            </p>
          )}
          {whenHandedOver && (
            <p>
              Handed over at{" "}
              <strong style={{ color: "white", marginLeft: 6 }}>
                {moment(whenHandedOver).format("YYYY/MM/DD hh:mm")} (
                {moment(whenHandedOver).fromNow()})
              </strong>
            </p>
          )}
          {whenCancelled && (
            <p>
              Cancelled at{" "}
              <strong style={{ color: "white", marginLeft: 6 }}>
                {moment(whenCancelled).format("YYYY/MM/DD hh:mm")} (
                {moment(whenCancelled).fromNow()})
              </strong>
            </p>
          )}
          {type && destination && (
            <>
              <p>
                Dispense Type{" "}
                <strong style={{ color: "white", marginLeft: 6 }}>
                  <Tag
                    style={{ marginLeft: 5 }}
                    intent={typeConfig[type].intent}
                    minimal
                    round
                  >
                    {typeConfig[type].string}
                  </Tag>
                </strong>
              </p>
              {type === "storePickup" ? (
                <p>Branch </p>
              ) : (
                <p>Delivery Location </p>
              )}
              <p>
                <p style={{ color: "white", marginLeft: 20 }}>
                  {destination.city}
                </p>
              </p>
              {destination.branchName && (
                <p>
                  <p style={{ color: "white", marginLeft: 20 }}>
                    {destination.branchName}{" "}
                    {destination.branchCode && <>({destination.branchCode})</>}
                  </p>
                </p>
              )}
              {destination.address && (
                <p>
                  <p style={{ color: "white", marginLeft: 20 }}>
                    {destination.address}
                  </p>
                </p>
              )}

              {destination.note && (
                <p>
                  <p style={{ color: "white", marginLeft: 20 }}>
                    {destination.note}
                  </p>
                </p>
              )}
              <p style={{ color: "white", marginLeft: 20, marginTop: 5 }}>
                <ButtonGroup small>
                  <Button
                    small
                    onClick={() => {
                      window.open(destination.url);
                    }}
                    intent={Intent.NONE}
                  >
                    <p style={{ fontSize: 13 }}>See Location on Google Maps</p>
                  </Button>
                  <Button
                    small
                    onClick={() => {
                      navigator.clipboard.writeText(destination.url);
                      Notification.Success("URL copied to clipboard");
                    }}
                    intent={Intent.NONE}
                  >
                    <p style={{ fontSize: 13 }}>Copy Location Link</p>
                  </Button>
                </ButtonGroup>
              </p>
            </>
          )}
        </div>
      </Callout>

      {isWasfatyPrescription ? (
        <>
          <h2 style={{ marginBottom: 10, marginTop: 15 }}>
            Wasfaty Prescription
          </h2>
          <Callout>
            <WasfatyPrescription
              medicationRequest={medicationRequest}
              dispenseRequest={dispenseRequest}
              dispenseRequestActions={{
                markDispenseRequestAsCancelled,
                markDispenseRequestAsOnHold,
                markDispenseRequestAsCompleted,
              }}
            />
          </Callout>
        </>
      ) : (
        <>
          <h2 style={{ marginBottom: 10, marginTop: 15 }}>
            {isPharmacyItem ? "Pharmacy Item" : "Prescription"}
          </h2>
          <Callout>
            {getPharmacyItemsList().length > 0 && (
              <PharmacyItemList
                pharmacyItems={getPharmacyItemsList()}
                showDispensedAmount={["pending", "completed"].includes(tab)}
                showReadyAmount={["readyForPickup"].includes(tab)}
                dispenseRequestActions={{
                  markDispenseRequestAsCancelled,
                  markDispenseRequestAsOnHold,
                  markDispenseRequestAsCompleted,
                }}
              />
            )}
            {getMedicationsList().length > 0 && (
              <MedicationsList
                medicationsList={getMedicationsList()}
                showOnly
                showIssuedTime
                showSubstitutionAllowed
                isDispenseRequest
                showDispensedAmount={["pending", "completed"].includes(tab)}
                showReadyAmount={["readyForPickup"].includes(tab)}
                dispenseRequestActions={{
                  markDispenseRequestAsCancelled,
                  markDispenseRequestAsOnHold,
                  markDispenseRequestAsCompleted,
                }}
              />
            )}
          </Callout>
        </>
      )}
    </Card>
  );
};

const mapDispatchToProps = {
  ...dispenseRequestsActions,
};

export default connect(() => {}, mapDispatchToProps)(UserDispenseRequestsCard);
