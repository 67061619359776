import { Button, H4, InputGroup, Intent, Menu } from "@blueprintjs/core";
import React, { useState } from "react";
import ObservationTypesOptions from "./ObservationTypesOptions";
import ObservationValueInput from "./ObservationValueInput";
import SelectedObservationsTable from "./SelectedObservationsTable";

const ObservationTypesTable = ({
  selectedObservations,
  setSelectedObservations,
  observationTypes,
}) => {
  const [isObservationTypesOptionsVisible, setObservationTypesOptionsVisible] =
    useState(false);

  const [selectedObservation, setSelectedObservation] = useState(undefined);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [searchObservationTerm, setSearchObservationTerm] = useState("");

  const onSelectObservationTypeOption = (observationType) => {
    setSelectedObservation(observationType);
    setSearchObservationTerm(observationType.displayName.en);
    if (
      observationType.updateRules &&
      observationType.updateRules.preferredUnit
    ) {
      setSelectedUnit(observationType.updateRules.preferredUnit);
    }
  };

  const clearObservationInputs = () => {
    setSelectedValue("");
    setSelectedUnit("");
    setSelectedObservation(undefined);
    setSearchObservationTerm("");
  };

  const addToSelectedObservations = () => {
    const getValue = () => {
      if (selectedValue === "false") {
        return false;
      }
      if (selectedValue === "true") {
        return true;
      }

      const isNumeric = (str) => {
        if (typeof str !== "string") return false;
        // eslint-disable-next-line no-restricted-globals
        return !isNaN(str) && !isNaN(parseFloat(str));
      };

      if (isNumeric(selectedValue)) {
        return parseFloat(selectedValue);
      }

      return selectedValue;
    };

    setSelectedObservations([
      ...selectedObservations,
      {
        observationKey: selectedObservation.key,
        observationType: selectedObservation,
        newValue: getValue(),
        newUnit: selectedUnit,
      },
    ]);
    clearObservationInputs();
  };

  return (
    <div style={{ marginTop: 15 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, marginRight: 10 }}>
          <H4>Observation Name</H4>
        </div>
        <div style={{ flex: 1, marginRight: 10 }}>
          <H4>Value</H4>
        </div>
        <div style={{ flex: 1, marginRight: 5 }}>
          <H4>Unit (Optional)</H4>
        </div>
        <div style={{ visibility: "hidden", marginLeft: 5 }}>
          <Button icon="plus" intent={Intent.SUCCESS} type="submit" />
        </div>
      </div>

      <SelectedObservationsTable selectedObservations={selectedObservations} />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, marginRight: 10 }}>
          {selectedObservation === undefined ? (
            <InputGroup
              title="Observation"
              placeholder=""
              value={searchObservationTerm}
              onFocus={() => {
                setObservationTypesOptionsVisible(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setSearchObservationTerm("");
                  setObservationTypesOptionsVisible(false);
                }, 200);
              }}
              onChange={(e) => {
                setSearchObservationTerm(e.target.value);
              }}
            />
          ) : (
            <InputGroup
              title="Observation"
              placeholder=""
              value={
                selectedObservation &&
                selectedObservation.displayName &&
                selectedObservation.displayName.en
                  ? selectedObservation.displayName.en
                  : ""
              }
              rightElement={
                <Button
                  small
                  text="Clear"
                  minimal
                  intent={Intent.DANGER}
                  onClick={() => {
                    clearObservationInputs();
                  }}
                />
              }
            />
          )}
          {isObservationTypesOptionsVisible &&
            selectedObservation === undefined && (
              <div
                style={{
                  position: "absolute",
                  marginTop: 7,
                  overflow: "auto",
                  maxHeight: 200,
                }}
              >
                <Menu>
                  <ObservationTypesOptions
                    observationTypes={observationTypes}
                    searchObservationTerm={searchObservationTerm}
                    selectedObservations={selectedObservations}
                    onSelectObservationTypeOption={
                      onSelectObservationTypeOption
                    }
                  />
                </Menu>
              </div>
            )}
        </div>
        <div style={{ flex: 1, marginRight: 10 }}>
          <ObservationValueInput
            selectedObservation={selectedObservation}
            selectedValue={selectedValue}
            setSelectedValue={(value) => {
              setSelectedValue(value);
            }}
          />
        </div>
        <div style={{ flex: 1, marginRight: 5 }}>
          <InputGroup
            disabled={selectedObservation === undefined}
            title="Unit"
            placeholder=""
            value={selectedUnit}
            onChange={(e) => {
              setSelectedUnit(e.target.value);
            }}
          />
        </div>
        <div style={{ marginLeft: 5 }}>
          <Button
            icon="plus"
            disabled={selectedObservation === undefined || selectedValue === ""}
            intent={Intent.SUCCESS}
            type="submit"
            onClick={() => {
              addToSelectedObservations();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ObservationTypesTable;
