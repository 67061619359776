import { firestore, functions, firebaseDb } from "../firebase";

const moment = require("moment-timezone");

let unsubscribe;

async function subscribeBookingSlots({
  loadBookings,
  practitionerRoleID,
  healthcareServiceID,
  speciality,
}) {
  const today = moment().tz("Asia/Riyadh").startOf("day").toDate();

  const bookingSlotsQuery = firestore
    .collection("bookingSlots")
    .where("practitionerRoleID", "==", practitionerRoleID)
    .where("healthcareServiceID", "==", healthcareServiceID)
    .where("speciality", "==", speciality)
    .where("startDate", ">=", today);
  unsubscribe = bookingSlotsQuery.onSnapshot(
    (snapshot) => {
      const bookingSlotsData = [];
      snapshot.forEach((doc) => {
        bookingSlotsData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      loadBookings(bookingSlotsData);
    },
    () => {
      // console.error(`Error subscribing to bookings collection: ${error}`)
    }
  );
}

async function unsubscribeBookingSlots() {
  if (unsubscribe) unsubscribe();
}

async function createBookingSlots({ bookingSlots }) {
  unsubscribeBookingSlots();
  const createBookingSlotsFunction =
    functions.httpsCallable("createBookingSlots");
  try {
    const result = await createBookingSlotsFunction({ bookingSlots });
    return result.data;
  } catch (err) {
    return { created: false };
  }
}

async function updateSlotAvailability({ toggledSlots }) {
  const firestoreUpdatePromises = [];
  Object.keys(toggledSlots).forEach((id) => {
    firestoreUpdatePromises.push(
      firestore
        .collection("bookingSlots")
        .doc(id)
        .update({ isAvailable: toggledSlots[id] })
    );
  });
  return firestoreUpdatePromises;
}

async function updateSlotDuration({
  practitionerRoleID,
  healthcareServiceID,
  speciality,
  dayToUpdateFrom,
  bookingSlots,
}) {
  unsubscribeBookingSlots();
  const querySlotsToDelete = firestore
    .collection("bookingSlots")
    .where("practitionerRoleID", "==", practitionerRoleID)
    .where("healthcareServiceID", "==", healthcareServiceID)
    .where("speciality", "==", speciality)
    .where("startDate", ">=", dayToUpdateFrom);

  const slotsToDelete = [];
  const snapshot = await querySlotsToDelete.get();
  snapshot.forEach((doc) => {
    slotsToDelete.push(doc.id);
  });

  const createBookingSlotsFunction =
    functions.httpsCallable("createBookingSlots");
  try {
    const result = await createBookingSlotsFunction({
      bookingSlots,
      slotsToDelete,
    });
    return result.data;
  } catch (err) {
    return { created: false };
  }
}

function updateShifts({ healthcareService, practitionerRole, shifts }) {
  return firebaseDb
    .ref("healthcareServices")
    .child(healthcareService)
    .child("practitionerRoles")
    .child(practitionerRole)
    .update({ shifts });
}

function updateDurationPerPatient({
  healthcareServiceID,
  practitionerRoleID,
  duration,
}) {
  return firebaseDb
    .ref("healthcareServices")
    .child(healthcareServiceID)
    .child("practitionerRoles")
    .child(practitionerRoleID)
    .update({ durationPerPatient: duration });
}

export default {
  createBookingSlots,
  subscribeBookingSlots,
  unsubscribeBookingSlots,
  updateSlotAvailability,
  updateSlotDuration,
  updateShifts,
  updateDurationPerPatient,
};
