export const SET_LOADED = "inbox/SET_LOADED";

// System

export const SELECT_ACCOUNT = "inbox/SELECT_ACCOUNT";
export const SELECT_PROFILE = "inbox/SELECT_PROFILE";

export const SEND_ANSWER = "inbox/SEND_ANSWER";

export const TOGGLE_EDIT = "inbox/TOGGLE_EDIT";

export const END_SESSION = "inbox/END_SESSION";

export const LOAD_LAB_TESTS = "inbox/LOAD_LAB_TESTS";

export const SET_WEEK_AVG_RESPONSE_TIME = "inbox/SET_WEEK_AVG_RESPONSE_TIME";

// Inboxes

export const UPDATE_INBOX = "inbox/UPDATE_INBOX";
export const SELECT_INBOX = "inbox/SELECT_INBOX";
export const CLEAR_ACTIVE_ACCOUNT_AND_SESSION =
  "inbox/CLEAR_ACTIVE_ACCOUNT_AND_SESSION";
