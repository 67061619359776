import {
  Button,
  Callout,
  Checkbox,
  Dialog,
  FormGroup,
  H4,
  InputGroup,
  Intent,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

const AddMedicationOverlay = ({
  isAddMedicationOverlay,
  setIsAddMedicationOverlay,
  selectedMedication,
  setIsMedicationOmniBar,
  addMedicationToMedicationsList,
}) => {
  const [quantity, setQuantity] = useState(null);
  const [duration, setDuration] = useState(null);
  const [dosageInstructions, setDosageInstructions] = useState(null);
  const [doctorNote, setDoctorNote] = useState(null);
  const [isSubstitutionAllowed, setIsSubstitutionAllowed] = useState(true);

  useEffect(() => {
    setDoctorNote(null);
    setDosageInstructions(null);
    setDuration(null);
    setQuantity(null);
  }, [selectedMedication]);

  const canSubmit = () => {
    if (!!quantity && !!duration && !!dosageInstructions) {
      return true;
    }
    return false;
  };

  if (!selectedMedication) {
    return <></>;
  }

  const renderQuantityOptions = () => {
    const packageSize = parseInt(selectedMedication.package.size, 10);
    const packageOptions = [];

    for (let i = 0; i < 10; i += 1) {
      packageOptions.push(packageSize * (i + 1));
    }

    return packageOptions.map((packageOption, index) => {
      return (
        <option
          selected={quantity === `${packageOption}`}
          value={`${packageOption}`}
        >
          {packageOption} ({packageSize}x{index + 1})
        </option>
      );
    });
  };

  return (
    <Dialog
      style={{ width: "100%", maxWidth: 800 }}
      className="bp3-dark"
      isOpen={isAddMedicationOverlay}
      onClose={() => {
        setIsAddMedicationOverlay(false);
      }}
      title="Add Medication"
    >
      {selectedMedication && (
        <div style={{ padding: 10, marginBottom: -20 }}>
          <Callout icon="new-prescription">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <H4 style={{ margin: 0 }}>
                {selectedMedication.name.tradeName.en}
              </H4>
              <Button
                small
                onClick={() => {
                  setIsMedicationOmniBar(true);
                }}
                style={{ marginLeft: 10 }}
              >
                Change Medication
              </Button>
            </div>
            <DrugInformation
              label="Trade Name (English)"
              value={selectedMedication.name.tradeName.en}
            />
            {selectedMedication.name.tradeName.ar && (
              <DrugInformation
                label="Trade Name (Arabic)"
                value={selectedMedication.name.tradeName.ar}
              />
            )}
            <DrugInformation
              label="Scientific Name (English)"
              value={selectedMedication.name.scientificName.en}
            />
            {selectedMedication.name.scientificName.ar && (
              <DrugInformation
                label="Scientific Name (Arabic)"
                value={selectedMedication.name.scientificName.ar}
              />
            )}
            <DrugInformation
              label="Strength"
              value={`${selectedMedication.strength.value} ${selectedMedication.strength.unit}`}
            />
            <DrugInformation label="Form" value={selectedMedication.form} />
            <DrugInformation
              label="Administration Route"
              value={selectedMedication.administrationRoute}
            />
            {selectedMedication.size.amount && (
              <DrugInformation
                label="Size"
                value={`${selectedMedication.size.amount} ${selectedMedication.size.unit}`}
              />
            )}
            <DrugInformation
              label="Package Size"
              value={selectedMedication.package.size}
            />
            <DrugInformation
              label="Package Type"
              value={selectedMedication.package.type}
            />
          </Callout>
          <div style={{ padding: 10, marginTop: 10 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormGroup
                style={{ width: "50%", paddingRight: 10 }}
                label="Quantity"
                labelFor="text-input"
                labelInfo=" (required)"
              >
                <div className="bp3-html-select .modifier bp3-fill">
                  <select
                    className="bp3-fill"
                    onChange={(e) => {
                      setQuantity(parseInt(e.target.value, 10));
                    }}
                  >
                    {quantity === null && (
                      <option selected>Select quantity</option>
                    )}
                    {renderQuantityOptions()}
                  </select>
                  <span className="bp3-icon bp3-icon-double-caret-vertical" />
                </div>
              </FormGroup>

              <FormGroup
                style={{ width: "50%" }}
                label="Duration"
                labelFor="text-input"
                labelInfo=" (required)"
              >
                <InputGroup
                  rightElement={<Tag minimal>days</Tag>}
                  id="text-input"
                  type="number"
                  value={duration}
                  onChange={(e) => {
                    setDuration(parseInt(e.target.value, 10));
                  }}
                />
              </FormGroup>
            </div>
            <FormGroup
              label="Dosage Instructions"
              labelFor="doctorName"
              labelInfo=" (required)"
            >
              <TextArea
                id="doctorName"
                required
                growVertically
                fill
                small
                style={{ direction: "rtl", height: "5em", resize: "none" }}
                intent={Intent.PRIMARY}
                value={dosageInstructions}
                onChange={(event) => {
                  setDosageInstructions(event.target.value);
                }}
              />
            </FormGroup>
            <FormGroup label="Doctor Note" labelFor="doctorName" labelInfo="">
              <TextArea
                id="doctorName"
                required
                growVertically
                fill
                small
                style={{ direction: "rtl", height: "5em", resize: "none" }}
                intent={Intent.PRIMARY}
                value={doctorNote}
                onChange={(event) => {
                  setDoctorNote(event.target.value);
                }}
              />
            </FormGroup>
            <Checkbox
              checked={isSubstitutionAllowed}
              defaultChecked={isSubstitutionAllowed}
              label="Is substitution allowed?"
              onChange={(e) => {
                setIsSubstitutionAllowed(e.target.checked);
              }}
            />

            <Button
              fill
              disabled={!canSubmit()}
              intent={Intent.PRIMARY}
              onClick={() => {
                addMedicationToMedicationsList({
                  medication: {
                    medication: selectedMedication,
                    quantity,
                    dosageInstructions,
                    doctorNote,
                    duration,
                    isSubstitutionAllowed,
                  },
                });
                setIsAddMedicationOverlay(false);
              }}
            >
              Add Medication
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

const DrugInformation = ({ label, value }) => {
  return (
    <div style={{ display: "flex" }}>
      <p style={{ fontWeight: "800" }}>{label}: </p>
      <p style={{ marginLeft: 5 }}>{value}</p>
    </div>
  );
};

export default AddMedicationOverlay;
