import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Card, Elevation, H5, Button, Icon } from "@blueprintjs/core";
import InputBox from "../InputBox";
import Conversation from "../Conversation";
import { getPractitionerNameFromPractitionersList } from "../../utils";

const SessionsHistoryCard = ({
  session = {},
  storagePathResolver,
  medications,
  conditions,
  specialties,
  sendAnswerToHistorySession,
  practitioners,
  activeHealthcareService,
  practitionerSpeciality,
  labTests,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const shouldHaveRespondBox = () => {
    if (Boolean(session) && Boolean(session.questions)) {
      const lastQuestion = Object.values(session.questions)
        .sort((questionA, questionB) => {
          return questionA.sent - questionB.sent;
        })
        .slice(-1)[0];
      if (Boolean(lastQuestion) && lastQuestion.responded === undefined) {
        return true;
      }
    }
    return false;
  };

  const getDay = () => {
    return new Date(session.started).getDate();
  };

  const getMonthAndYear = () => {
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(session.started);
    return `${MONTHS[date.getMonth()]}. ${`${date.getFullYear()}`.slice(
      2,
      4
    )}'`;
  };

  const renderSessionPractitioner = () => {
    let result = "";
    if (session.practitioners && session.practitioners.length > 0) {
      result += "with";
      session.practitioners.forEach((practitionerName, index) => {
        if (index > 0) {
          result += `, ${practitionerName}`;
        } else {
          result += ` ${practitionerName}`;
        }
      });
    }
    return result;
  };

  const getSessionSpeciality = () => {
    if (session.speciality === "mental-health") {
      return "Mental Health";
    }
    return "Primary Care";
  };

  const getLastQuestion = () => {
    try {
      if (session.questions) {
        return Object.values(session.questions).sort((questionA, questionB) => {
          return questionA.sent - questionB.sent;
        })[Object.values(session.questions).length - 1];
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  };

  const renderLastEncountered = () => {
    const lastQuestion = getLastQuestion();
    if (
      Boolean(lastQuestion) &&
      Boolean(lastQuestion.responded) &&
      Boolean(lastQuestion.respondedBy)
    ) {
      const practitionerName = getPractitionerNameFromPractitionersList(
        practitioners,
        lastQuestion.respondedBy
      );
      return `${
        practitionerName && practitionerName !== ""
          ? `by ${practitionerName}`
          : ""
      }`;
    }
    if (Boolean(lastQuestion) && !lastQuestion.respondedBy) {
      return `by Patient`;
    }
    return "";
  };

  const renderLastEncounteredTime = () => {
    const lastQuestion = getLastQuestion();
    if (lastQuestion) {
      if (lastQuestion.responded) {
        return moment(lastQuestion.responded).fromNow();
      }
      return moment(lastQuestion.sent).fromNow();
    }
    return "";
  };

  return (
    <Card
      style={{ marginBottom: "10px" }}
      interactive={!isExpanded}
      elevation={Elevation.TWO}
      disabled={isExpanded}
      onClick={() => {
        if (!isExpanded) {
          setIsExpanded(true);
        }
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          <H5
            style={{
              marginBottom: 2,
            }}
          >
            {getDay()}
          </H5>
          <div
            className="bp3-text-muted"
            style={{ textTransform: "uppercase" }}
          >
            {getMonthAndYear()}
          </div>
        </div>
        <div
          style={{
            marginLeft: "16px",
          }}
        >
          <div
            style={{
              marginBottom: 3,
            }}
          >
            {getSessionSpeciality()} Chat {renderSessionPractitioner()}
          </div>
          <div className="bp3-text-muted bp3-text-small">
            Last encounter {renderLastEncounteredTime()}{" "}
            {renderLastEncountered()}
          </div>
        </div>
        {!isExpanded && (
          <div style={{ marginLeft: "auto" }}>
            <Icon icon="chevron-right" iconSize={20} />
          </div>
        )}
      </div>
      {isExpanded && (
        <div style={{ marginTop: "10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 2, maxWidth: "100%" }}>
              <Conversation
                practitioners={practitioners}
                questions={session.questions}
                storagePathResolver={storagePathResolver}
                practitionerSpeciality={practitionerSpeciality}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          {shouldHaveRespondBox() && sendAnswerToHistorySession && (
            <InputBox
              patientID={session.patientID}
              source="session"
              medications={medications}
              activeHealthcareService={activeHealthcareService}
              practitionerSpeciality={practitionerSpeciality}
              selectedSpeciality={session.speciality}
              sendAction={sendAnswerToHistorySession}
              conditions={conditions}
              specialties={specialties}
              labTests={labTests}
              session={session}
            />
          )}
          <Button
            style={{ marginTop: "10px" }}
            fill
            icon="chevron-up"
            minimal
            disabled={!isExpanded}
            onClick={() => {
              if (isExpanded) {
                setIsExpanded(false);
              }
            }}
          />
        </div>
      )}
    </Card>
  );
};

SessionsHistoryCard.propTypes = {
  session: PropTypes.shape({}),
};

export default SessionsHistoryCard;
