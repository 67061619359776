import React, { useState, useEffect } from "react";
import { Tabs, Tab, Tag, Intent } from "@blueprintjs/core";

const QuestionSound = new Audio("definite.mp3");

const InboxTabs = ({ inboxes, selectInbox = () => {}, activeInbox = null }) => {
  const getInboxSessionsCount = (inboxObject) => {
    if (inboxObject && inboxObject.activeSessions) {
      return Object.keys(inboxObject.activeSessions).length;
    }
    return 0;
  };

  const [previousInboxes, setPreviousInboxes] = useState(inboxes);

  useEffect(() => {
    Object.keys(inboxes.toJS()).forEach((inboxKey) => {
      const currentInboxObject = inboxes.toJS()[inboxKey];
      const previousInboxObject = previousInboxes.toJS()[inboxKey];
      if (
        currentInboxObject &&
        currentInboxObject.inboxObject &&
        currentInboxObject.inboxObject.activeSessions &&
        previousInboxObject &&
        previousInboxObject.inboxObject &&
        previousInboxObject.inboxObject.activeSessions
      ) {
        if (
          Object.values(currentInboxObject.inboxObject.activeSessions).length >
          Object.values(previousInboxObject.inboxObject.activeSessions).length
        ) {
          QuestionSound.play();
        }
      }

      if (currentInboxObject && previousInboxObject) {
        if (
          currentInboxObject.inboxObject.activeSessions &&
          !previousInboxObject.inboxObject.activeSessions
        ) {
          QuestionSound.play();
        }
      }
    });
    setPreviousInboxes(inboxes);
  }, [previousInboxes, inboxes]);

  const renderTabs = () => {
    return Object.values(inboxes.toJS()).map((inbox) => {
      return (
        <Tab key={inbox.inboxKey} id={inbox.inboxKey}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {inbox.inboxName}
            {getInboxSessionsCount(inbox.inboxObject) > 0 && (
              <Tag
                style={{
                  fontSize: 10,
                  marginLeft: 10,
                  minWidth: 5,
                  minHeight: 5,
                  padding: "0px 8px",
                }}
                round
                minimal
                intent={Intent.NONE}
              >
                {getInboxSessionsCount(inbox.inboxObject)}
              </Tag>
            )}
          </div>
        </Tab>
      );
    });
  };

  return (
    <div
      style={{
        overflowX: "auto",
        padding: "6px 20px",
        paddingBottom: 10,
        backgroundColor: "#2A3742",
      }}
    >
      {activeInbox && (
        <Tabs
          large
          id="TabsExample"
          onChange={(inboxKey) => {
            selectInbox(inboxKey);
          }}
          selectedTabId={activeInbox.inboxKey}
        >
          {renderTabs()}
        </Tabs>
      )}
    </div>
  );
};

export default InboxTabs;
