import { Record } from "immutable";

const Condition = new Record({
  key: null,
  brief: null,
  whenToSeeDoctor: null,
  howToReduceSymptoms: null,
  name: {
    arabic: null,
    english: null,
  },
});

export default Condition;
