// Accounts
export const CREATE_ACCOUNT_ERROR = "sessions/CREATE_ACCOUNT_ERROR";
export const CREATE_ACCOUNT_SUCCESS = "sessions/CREATE_ACCOUNT_SUCCESS";

export const REMOVE_ACCOUNT_ERROR = "sessions/REMOVE_ACCOUNT_ERROR";
export const REMOVE_ACCOUNT_SUCCESS = "sessions/REMOVE_ACCOUNT_SUCCESS";

export const UNDELETE_ACCOUNT_ERROR = "sessions/UNDELETE_ACCOUNT_ERROR";

export const UPDATE_ACCOUNT_ERROR = "sessions/UPDATE_ACCOUNT_ERROR";
export const UPDATE_ACCOUNT_SUCCESS = "sessions/UPDATE_ACCOUNT_SUCCESS";

export const LOAD_ACCOUNTS_SUCCESS = "sessions/LOAD_ACCOUNTS_SUCCESS";
export const LOAD_ACCOUNTS_ERROR = "sessions/LOAD_ACCOUNTS_ERROR";

export const UNLOAD_ACCOUNTS_SUCCESS = "sessions/UNLOAD_ACCOUNTS_SUCCESS";
