import React from "react";
import {
  Button,
  Icon,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import Notification from "../Notification";

const ObservationAlertInput = ({
  valueType,
  selectedExpression,
  selectedValues,
  setSelectedValues,
}) => {
  const getInputType = () => {
    switch (valueType) {
      case "number":
        return "number";
      case "string":
        return "string";
      default:
        return "text";
    }
  };

  if (valueType === "boolean") {
    return (
      <RadioGroup
        inline
        onChange={(event) => {
          if (event.currentTarget.value === "false") {
            setSelectedValues([false]);
          } else if (event.currentTarget.value === "true") {
            setSelectedValues([true]);
          }
        }}
        selectedValue={selectedValues[0].toString()}
      >
        <Radio
          labelElement={
            <Icon icon="small-tick" iconSize={16} intent="success" />
          }
          value="true"
        />
        <Radio
          labelElement={<Icon icon="cross" iconSize={16} intent="danger" />}
          value="false"
        />
      </RadioGroup>
    );
  }

  return (
    <>
      <InputGroup
        type={getInputType()}
        autoComplete="off"
        value={selectedValues[0]}
        onChange={(event) => {
          if (getInputType() === "number") {
            if (event.target.value === "") {
              setSelectedValues(["", ...selectedValues.slice(1)]);
            } else {
              setSelectedValues([
                parseInt(event.target.value, 10),
                ...selectedValues.slice(1),
              ]);
            }
          } else {
            setSelectedValues([event.target.value, ...selectedValues.slice(1)]);
          }
        }}
        id="selectedValue"
        rightElement={
          selectedExpression === "in" && (
            <Button
              disabled={selectedValues[0] === ""}
              onClick={() => {
                if (selectedValues.slice(1).includes(selectedValues[0])) {
                  Notification.Error(`${selectedValues[0]} is already added`);
                } else {
                  setSelectedValues(["", ...selectedValues]);
                }
              }}
              icon="plus"
              intent={Intent.NONE}
              minimal
            />
          )
        }
      />
      {selectedValues.slice(1).map((value) => {
        return (
          <div key={value} style={{ marginTop: 10 }}>
            <InputGroup
              type={getInputType()}
              key={value}
              autoComplete="off"
              value={value}
              disabled
              id="value"
              rightElement={
                <Button
                  onClick={() => {
                    setSelectedValues(
                      selectedValues.filter(
                        (removedValue) => removedValue !== value
                      )
                    );
                  }}
                  icon="remove"
                  intent={Intent.DANGER}
                  minimal
                />
              }
            />
          </div>
        );
      })}
    </>
  );
};

export default ObservationAlertInput;
