import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Intent,
  Card,
  Elevation,
  Button,
  MenuItem,
  Position,
  ButtonGroup,
  Alignment,
  FormGroup,
  TextArea,
  Callout,
  InputGroup,
} from "@blueprintjs/core";
import { Select, MultiSelect } from "@blueprintjs/select";
import { ArabicNormalize, isSecondaryProfile } from "../../../utils";

import Input from "./Input";
import EndButtons from "./EndButtons";

import StyleGuide from "./StyleGuide";

import Recommendations from "../Recommendations";
import PrescriptionInput from "./PrescriptionInput/PrescriptionInput";
// import VideoCallCallout from "../VideoCallCard/VideoCallCallout";

const AnswerSound = new Audio("direct.mp3");

const InputBox = ({
  selectedSpeciality,
  changeSpeciality,
  sendAction,
  conditions = [],
  specialties = [],
  medications = [],
  session = null,
  labTests,
  isSessionAction = true,
  setIsSessionAction,
  isPatientAction = false,
  source = "session",
  patientID,
}) => {
  const [input, setInput] = useState("");
  const [isDoctorNote, setIsDoctorNote] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [confirmingText, setConfirmingText] = useState(null);
  const [confirmingIntent, setConfirmingIntent] = useState(Intent.NONE);
  const [showConditionSelector, setShowConditionSelector] = useState(false);
  const [showSpecialtyReferralSelector, setShowSpecialtyReferralSelector] =
    useState(false);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [selectedSpecialtyReferral, setSelectedSpecialtyReferral] =
    useState(null);
  const [specialtyReferral, setSpecialtyReferral] = useState({
    brief: "",
    name: {
      english: "",
      arabic: "",
    },
    referralNote: "",
  });
  const [isSpecialtyReferral, setIsSpecialtyReferral] = useState(null);
  const [isEmergency, setIsEmergency] = useState(null);
  const [isChangeSpeciality, setIsChangeSpeciality] = useState(null);
  const [showStyleGuide, setShowStyleGuide] = useState(false);
  const [inputs, setInputs] = useState([""]);
  const [inputsRecommendations, setInputsRecommendations] = useState([null]);
  const [activeInputCharacterIndex, setActiveInputCharacterIndex] = useState(0);
  const [treatmentPlan, setTreatmentPlan] = useState({
    brief: "",
    howToReduceSymptoms: "",
    whenToSeeDoctor: "",
    edited: false,
    name: {
      english: "",
      arabic: "",
    },
  });

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  // Video Call Values
  const [isStartVideoCall, setIsStartVideoCall] = useState(null);
  // const [showStartVideoCallSettings, setShowVideoCallSettings] = useState(
  //   false
  // );
  const timeNow = new Date();
  timeNow.setSeconds(0, 0);
  const [videoCallNote, setVideoCallNote] = useState("");
  // End Video Call Values

  // Prescription Values
  const [isPrescription, setIsPrescription] = useState(false);
  const [medicationsList, setMedicationsList] = useState([]);
  // End Prescription Values

  // Lab Requests Values
  const [isLabTestRequest, setIsLabTestRequest] = useState(null);
  const [showLabTestRequestForm, setShowLabTestRequestForm] = useState(false);
  const [labTestRequest, setLabTestRequest] = useState({
    supportingInfo: "",
    labTests: [],
  });

  const isAllowedConfirm = () => {
    if (showConditionSelector && treatmentPlan.brief === "") {
      return false;
    }
    if (showSpecialtyReferralSelector && specialtyReferral.brief === "") {
      return false;
    }
    if (isPrescription && medicationsList.length === 0) {
      return false;
    }
    if (isLabTestRequest && labTestRequest.labTests.length === 0) {
      return false;
    }
    return true;
  };

  const updateLabTestRequest = (inputFromArg, property) => {
    setLabTestRequest({
      ...labTestRequest,
      [property]: inputFromArg,
    });
  };

  const removeAllLabTests = () => {
    setLabTestRequest({
      ...labTestRequest,
      labTests: [],
    });
  };

  const removeLabTest = (labTest) => {
    const newLabTestsArray = labTestRequest.labTests.filter(
      (item) => item.labTest !== labTest
    );
    setLabTestRequest({
      ...labTestRequest,
      labTests: newLabTestsArray,
    });
  };

  const addLabTest = (labTest) => {
    const isAlreadyAdded = labTestRequest.labTests.findIndex(
      (item) => item === labTest
    );
    if (isAlreadyAdded !== -1) {
      // item is already added
      return;
    }

    setLabTestRequest({
      ...labTestRequest,
      labTests: [...labTestRequest.labTests, labTest],
    });
  };

  const updateTreatmentPlan = (inputFromArg, property) => {
    setTreatmentPlan({
      ...treatmentPlan,
      edited: true,
      [property]: inputFromArg,
    });
  };

  const updateSelectedCondition = (condition) => {
    setTreatmentPlan({
      ...condition.toObject(),
      edited: false,
    });
    setSelectedCondition(condition);
  };

  const updateSpecialtyReferral = (inputFromArg, property) => {
    setSpecialtyReferral({
      ...specialtyReferral,
      [property]: inputFromArg,
    });
  };

  const updateSelectedSpecialtyReferral = (specialty) => {
    const {
      referralNote: deletedDefaultReferralNote,
      ...specialityWithoutNote
    } = specialty.toObject();

    setSpecialtyReferral({
      ...specialtyReferral,
      ...specialityWithoutNote,
    });
    setSelectedSpecialtyReferral(specialty);
  };

  const updateInput = (inputFromArg, index, recommendedAnswer = null) => {
    setInputs([
      ...inputs.slice(0, index),
      inputFromArg,
      ...inputs.slice(index + 1),
    ]);
    setInputsRecommendations([
      ...inputsRecommendations.slice(0, index),
      recommendedAnswer,
      ...inputsRecommendations.slice(index + 1),
    ]);
  };

  const removeInput = (index) => {
    setInputs([...inputs.slice(0, index), ...inputs.slice(index + 1)]);
    setInputsRecommendations([
      ...inputsRecommendations.slice(0, index),
      ...inputsRecommendations.slice(index + 1),
    ]);
  };

  const isTextEndingWithQuestionMark = (text) => {
    return text.trim().substr(-1) === "?" || text.trim().substr(-1) === "؟";
  };

  const areInputsEndingWithQuestionMark = () => {
    let allAreEndingWithQuestionMark = true;
    inputs.forEach((inputFromArg) => {
      if (!isTextEndingWithQuestionMark(inputFromArg)) {
        allAreEndingWithQuestionMark = false;
      }
    });
    return allAreEndingWithQuestionMark;
  };

  const AddAnotherInput = () => {
    setInputs([...inputs, ""]);
    setInputsRecommendations([...inputsRecommendations, null]);
  };

  const onConfirm = async (options) => {
    if (!!options && options.canceled) {
      setIsEmergency(false);
      setIsChangeSpeciality(false);
      setIsStartVideoCall(false);
      // setShowVideoCallSettings(false);
      setShowSpecialtyReferralSelector(false);
      setIsSpecialtyReferral(false);
      setIsPrescription(false);
      setSpecialtyReferral({
        brief: "",
        name: {
          english: "",
          arabic: "",
        },
        referralNote: "",
      });
      setSelectedCondition(null);
      setIsLabTestRequest(false);
      setTreatmentPlan({
        brief: "",
        howToReduceSymptoms: "",
        whenToSeeDoctor: "",
        edit: false,
        name: {
          english: "",
          arabic: "",
        },
      });
      setLabTestRequest({
        supportingInfo: "",
        labTests: [],
      });
      setVideoCallNote("");
      return;
    }

    if (isPrescription) {
      setIsConfirmLoading(true);
      sendAction(
        null,
        [``],
        {
          prescription: {
            medicationsList,
          },
        },
        session,
        null,
        source
      );
    } else if (isStartVideoCall) {
      setIsConfirmLoading(true);
      sendAction(
        null,
        [``],
        {
          videoCall: { videoCallNote },
        },
        session,
        null,
        source
      );
    } else if (isChangeSpeciality) {
      const getOtherSpecialtyType = () => {
        // if specific session is selected
        if (selectedSpeciality === "mental-health") {
          return "primary-care";
        }
        // else (selectedSpeciality === "primary-care")
        return "mental-health";
      };
      setIsConfirming(false);
      changeSpeciality({
        newSpeciality: getOtherSpecialtyType(),
        sessionKey:
          session !== undefined && session !== null ? session.key : undefined,
      });
    } else if (isLabTestRequest) {
      setIsConfirmLoading(true);
      sendAction(
        null,
        [``],
        {
          labTestRequest: {
            authoredOn: new Date().getTime(),
            labTests: labTestRequest.labTests,
            supportingInfo: labTestRequest.supportingInfo,
            priority: "routine",
            category: "laboratory",
            status: "active",
          },
        },
        session,
        null,
        source
      );
    } else {
      const getSpecialityTitle = () => {
        let serviceProvider;
        if (selectedSpeciality === "mental-health") {
          serviceProvider = "الأخصائي";
        } else {
          serviceProvider = "الطبيب";
        }

        if (isSpecialtyReferral) {
          return `بناء على ما ذكرت، يعتقد ${serviceProvider} بأن حالتك تستدعي الإحالة لتخصص آخر`;
        }
        return `بناء على ما ذكرت، هذا ما يعتقده ${serviceProvider} لحالتك`;
      };

      const actionData = {};
      if (isSpecialtyReferral) {
        actionData.specialtyReferral = specialtyReferral;
      } else {
        actionData.emergency = isEmergency;
        actionData.treatmentPlan = isDoctorNote
          ? { brief: input }
          : treatmentPlan;
      }

      setIsConfirmLoading(true);
      await sendAction(
        null,
        [getSpecialityTitle()],
        {
          ...actionData,
        },
        session,
        null,
        source
      );
      setTimeout(() => {
        setIsConfirmLoading(false);
      }, 5000);
    }
  };

  const onSubmit = async () => {
    AnswerSound.play();
    setIsConfirmLoading(true);
    await sendAction(
      inputsRecommendations,
      inputs,
      null,
      session,
      null,
      source
    );
    setTimeout(() => {
      setIsConfirmLoading(false);
    }, 5000);
  };

  // Maximum amount of characters allowed in a reply from the doctor
  const characterLimit = 140;
  const areAllInputsCharacterCountUnderLimit = () => {
    let allAreUnderCharacterCountLimit = true;
    inputs.forEach((inputFromArg) => {
      if (inputFromArg.length > characterLimit) {
        allAreUnderCharacterCountLimit = false;
      }
    });
    return allAreUnderCharacterCountLimit;
  };

  return (
    <div className="input-box-container">
      {isSessionAction && (
        <StyleGuide
          visible={showStyleGuide}
          onClick={() => setShowStyleGuide(false)}
        />
      )}
      {isSessionAction &&
        !showStyleGuide &&
        !(inputs.length > 1) &&
        !isConfirming && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {!isPatientAction && (
              <Recommendations
                updateInput={updateInput}
                inputRecommendation={inputsRecommendations[0]}
              />
            )}
            <ButtonGroup
              style={{ marginBottom: "0.5em", alignSelf: "flex-end" }}
              fill={false}
              minimal
              large={false}
              alignText={Alignment.LEFT}
            >
              <Button
                icon="manual"
                onClick={() => setShowStyleGuide(!showStyleGuide)}
              >
                Styleguide
              </Button>
            </ButtonGroup>
          </div>
        )}
      {isSessionAction &&
        (!isConfirming || input.length > 0) &&
        inputs.map((question, index) => {
          return (
            <Input
              // behavior change when solved with -> const key = `${index} ${question}`;
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              input={inputs[index]}
              inputChange={(inputFromArg) => {
                setInput(inputFromArg);
                updateInput(inputFromArg, index, null);
              }}
              sendAction={onSubmit}
              disabled={isConfirming}
              placeHolder={
                isPatientAction ? "Type Message..." : "Type Response..."
              }
              onFocus={() => {
                setShowStyleGuide(false);
                setActiveInputCharacterIndex(index);
              }}
              secondary={inputs.length > 1 && index < inputs.length - 1} // there is more than one input && this is not the last input
              final={inputs.length > 1 && index === inputs.length - 1} // there is more than one input && this is the last input
              removeInput={() => {
                removeInput(index);
                setActiveInputCharacterIndex(0);
              }}
              isEndingWithQuestionMark={isTextEndingWithQuestionMark(
                inputs[index]
              )}
              areInputsEndingWithQuestionMark={areInputsEndingWithQuestionMark()}
              characterLimit={characterLimit}
              activeInputCharacterCounter={
                inputs[activeInputCharacterIndex].length
              }
              isConfirmLoading={isConfirmLoading}
            />
          );
        })}
      {/* {showStartVideoCallSettings && (
        <VideoCallCallout
          videoCallNote={videoCallNote}
          setVideoCallNote={setVideoCallNote}
          // activeHealthcareService={activeHealthcareService}
          // practitionerSpeciality={practitionerSpeciality}
        />
      )} */}
      {showConditionSelector && (
        <Card
          className="chatbox-conditions-picker"
          interactive={false}
          elevation={Elevation.TWO}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Select
            inputProps={{
              name: "condition",
              autoComplete: "off",
            }}
            name="condition"
            id="condition"
            initialContent={
              <MenuItem
                className="conditions-item"
                disabled
                text={`${conditions.length} conditions loaded.`}
              />
            }
            items={conditions}
            inputValueRenderer={(condition) =>
              `${condition.name.arabic} (${condition.name.english})`
            }
            onItemSelect={(condition) => updateSelectedCondition(condition)}
            itemRenderer={(condition, { handleClick }) => (
              <MenuItem
                className="conditions-item"
                key={condition.key}
                label={`${condition.name.english}`}
                text={`${condition.name.arabic}`}
                onClick={handleClick}
                shouldDismissPopover
              />
            )}
            itemPredicate={(query, condition) =>
              `${ArabicNormalize(
                condition.name.arabic
              )} ${condition.name.english.toLowerCase()}`.indexOf(
                ArabicNormalize(query).toLowerCase()
              ) >= 0
            }
            noResults={
              <MenuItem
                className="conditions-item"
                disabled
                text="No results."
              />
            }
            popoverProps={{
              minimal: true,
              inner: true,
              boundary: "window",
              position: Position.TOP,
              hasBackdrop: true,
              popoverClassName: "conditions-popover",
              className: "conditions",
              targetClassName: "conditions-target",
            }}
            openOnKeyDown
            selectedItem="this.state.condition"
          >
            <Button
              style={{
                marginBottom: "0.5em",
              }}
              fill
              large={false}
              alignText="left"
              text={
                selectedCondition
                  ? selectedCondition.name.arabic
                  : "Select a condition template"
              }
              icon="search"
            />
          </Select>
          <Callout>
            <FormGroup
              label="Condtion Name"
              labelFor="name"
              helperText="Provider the condition names (English and Arabic)"
            >
              <InputGroup
                fill
                intent={Intent.PRIMARY}
                placeholder="Condition english name"
                style={{}}
                small
                value={treatmentPlan.name.english}
                onChange={(event) =>
                  updateTreatmentPlan(
                    {
                      english: event.target.value,
                      arabic: treatmentPlan.name.arabic,
                    },
                    "name"
                  )
                }
              />
              <InputGroup
                fill
                intent={Intent.PRIMARY}
                style={{
                  direction: "rtl",
                }}
                placeholder="اسم الحالة باللغة العربية"
                small
                value={treatmentPlan.name.arabic}
                onChange={(event) =>
                  updateTreatmentPlan(
                    {
                      arabic: event.target.value,
                      english: treatmentPlan.name.english,
                    },
                    "name"
                  )
                }
              />
            </FormGroup>
            <FormGroup
              label="Brief"
              labelFor="brief"
              helperText="Provide the patient with a brief about the condition"
              labelInfo="(required)"
            >
              <TextArea
                required
                growVertically
                fill
                small
                style={{ direction: "rtl", height: "5em", resize: "none" }}
                intent={Intent.PRIMARY}
                value={treatmentPlan.brief}
                onChange={(event) =>
                  updateTreatmentPlan(event.target.value, "brief")
                }
              />
            </FormGroup>

            <FormGroup
              label="How to reduce symptoms?"
              helperText="Write information that helps the patient in reducing symptoms"
              labelFor="reduce-symptoms"
            >
              <TextArea
                growVertically
                fill
                small
                style={{ direction: "rtl", height: "10em", resize: "none" }}
                intent={Intent.PRIMARY}
                value={treatmentPlan.howToReduceSymptoms}
                onChange={(event) =>
                  updateTreatmentPlan(event.target.value, "howToReduceSymptoms")
                }
              />
            </FormGroup>

            <FormGroup
              label="When to see a doctor?"
              labelFor="when-to-see-doctor"
              helperText="Let the patient when he/she should seek a medical assistant"
            >
              <TextArea
                growVertically
                fill
                small
                style={{ direction: "rtl", height: "7em", resize: "none" }}
                intent={Intent.PRIMARY}
                value={treatmentPlan.whenToSeeDoctor}
                onChange={(event) =>
                  updateTreatmentPlan(event.target.value, "whenToSeeDoctor")
                }
              />
            </FormGroup>
          </Callout>
        </Card>
      )}
      {showLabTestRequestForm && (
        <Card
          className="chatbox-conditions-picker"
          interactive={false}
          elevation={Elevation.TWO}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Callout>
            <FormGroup
              label="Lab Tests Checklist"
              labelFor="checklist"
              helperText="Choose lab test for the patient to complete the request"
              labelInfo="(required)"
            >
              <MultiSelect
                inputProps={{
                  name: "condition",
                  autoComplete: "off",
                }}
                name="condition"
                id="condition"
                initialContent={
                  <MenuItem
                    className="conditions-item"
                    disabled
                    text={`${conditions.length} conditions loaded.`}
                  />
                }
                items={labTests}
                inputValueRenderer={(item) =>
                  `${item.labTest} (${item.categoryName})`
                }
                onItemSelect={(item) => addLabTest(item)}
                resetOnSelect
                tagRenderer={(item) => item.labTest}
                selectedItems={labTestRequest.labTests}
                tagInputProps={{
                  onRemove: (labTest) => removeLabTest(labTest),
                  rightElement: (
                    <Button
                      icon="cross"
                      minimal
                      onClick={() => removeAllLabTests()}
                    />
                  ),
                  tagProps: {
                    minimal: false,
                    intent: Intent.PRIMARY,
                  },
                }}
                itemRenderer={(item, { handleClick }) => (
                  <MenuItem
                    className="conditions-item"
                    key={item.labTest}
                    label={`${item.categoryName}`}
                    text={`${item.labTest}`}
                    onClick={handleClick}
                    shouldDismissPopover
                  />
                )}
                itemPredicate={(query, item) =>
                  `${item.labTest.toLowerCase()} ${item.categoryName.toLowerCase()}`.indexOf(
                    ArabicNormalize(query).toLowerCase()
                  ) >= 0
                }
                noResults={
                  <MenuItem
                    className="conditions-item"
                    disabled
                    text="No results."
                  />
                }
                popoverProps={{
                  minimal: true,
                  inner: true,
                  boundary: "window",
                  position: Position.TOP,
                  hasBackdrop: true,
                  popoverClassName: "conditions-popover",
                  className: "conditions",
                  targetClassName: "conditions-target",
                }}
                openOnKeyDown
              >
                <Button
                  style={{
                    marginBottom: "0.5em",
                  }}
                  fill
                  large={false}
                  alignText="left"
                  text={
                    selectedCondition
                      ? selectedCondition.name.arabic
                      : "Select a condition template"
                  }
                  icon="search"
                />
              </MultiSelect>
            </FormGroup>
            {/* {LabTests} */}
            <FormGroup
              label="Supporting Info (Will be visible to patient)"
              labelFor="supportingInfo"
              helperText="Provide the patient with any needed supporting info to prepare for the lab test"
              labelInfo=""
            >
              <TextArea
                required
                growVertically
                fill
                small
                style={{ direction: "rtl", height: "5em", resize: "none" }}
                intent={Intent.PRIMARY}
                value={labTestRequest.supportingInfo}
                onChange={(event) =>
                  updateLabTestRequest(event.target.value, "supportingInfo")
                }
              />
            </FormGroup>
          </Callout>
        </Card>
      )}
      {showSpecialtyReferralSelector && (
        <Card
          className="chatbox-conditions-picker"
          interactive={false}
          elevation={Elevation.TWO}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Select
            inputProps={{
              name: "specialty",
              autoComplete: "off",
            }}
            name="specialty"
            id="specialty"
            initialContent={
              <MenuItem
                className="conditions-item"
                disabled
                text={`${specialties.length} specialties loaded.`}
              />
            }
            items={specialties}
            inputValueRenderer={(specialty) =>
              `${specialty.name.arabic} (${specialty.name.english})`
            }
            onItemSelect={(specialty) =>
              updateSelectedSpecialtyReferral(specialty)
            }
            itemRenderer={(specialty, { handleClick }) => (
              <MenuItem
                className="conditions-item"
                key={specialty.key}
                label={`${specialty.name.english}`}
                text={`${specialty.name.arabic}`}
                onClick={handleClick}
                shouldDismissPopover
              />
            )}
            itemPredicate={(query, specialty) =>
              `${ArabicNormalize(
                specialty.name.arabic
              )} ${specialty.name.english.toLowerCase()}`.indexOf(
                ArabicNormalize(query).toLowerCase()
              ) >= 0
            }
            noResults={
              <MenuItem
                className="conditions-item"
                disabled
                text="No results."
              />
            }
            popoverProps={{
              minimal: true,
              inner: true,
              boundary: "window",
              position: Position.TOP,
              hasBackdrop: true,
              popoverClassName: "conditions-popover",
              className: "conditions",
              targetClassName: "conditions-target",
            }}
            openOnKeyDown
            selectedItem="this.state.specialty"
          >
            <Button
              style={{
                marginBottom: "0.5em",
              }}
              fill
              large={false}
              alignText="left"
              text={
                selectedSpecialtyReferral
                  ? selectedSpecialtyReferral.name.arabic
                  : "Select a specialty"
              }
              icon="search"
            />
          </Select>
          <Callout>
            <FormGroup
              label="Referral Note"
              labelFor="referralNote"
              helperText="Provide the patient with a referral note"
              labelInfo=""
            >
              <TextArea
                required
                growVertically
                fill
                small
                style={{ direction: "rtl", height: "5em", resize: "none" }}
                intent={Intent.PRIMARY}
                value={specialtyReferral.referralNote}
                onChange={(event) =>
                  updateSpecialtyReferral(event.target.value, "referralNote")
                }
              />
            </FormGroup>
          </Callout>
        </Card>
      )}
      {isPrescription && (
        <PrescriptionInput
          medications={medications}
          medicationsList={medicationsList}
          setMedicationsList={setMedicationsList}
        />
      )}
      <EndButtons
        selectedSpeciality={selectedSpeciality}
        showEndNote={input.length > characterLimit}
        hideButtons={input.length > 0}
        isDoctorNote={isDoctorNote}
        isConfirming={isConfirming}
        isAllowedConfirm={isAllowedConfirm()}
        toggleConfirming={setIsConfirming}
        confirmingText={confirmingText}
        setConfirmingText={setConfirmingText}
        confirmingIntent={confirmingIntent}
        setIsDoctorNote={setIsDoctorNote}
        setConfirmingIntent={setConfirmingIntent}
        setShowConditionSelector={setShowConditionSelector}
        setShowLabTestRequestForm={setShowLabTestRequestForm}
        setIsLabTestRequest={setIsLabTestRequest}
        setShowSpecialtyReferralSelector={setShowSpecialtyReferralSelector}
        setIsSpecialtyReferral={setIsSpecialtyReferral}
        isEmergency={isEmergency}
        setIsEmergency={setIsEmergency}
        changeSpeciality={changeSpeciality}
        setIsChangeSpeciality={setIsChangeSpeciality}
        setIsStartVideoCall={setIsStartVideoCall}
        // setShowVideoCallSettings={setShowVideoCallSettings}
        onConfirm={onConfirm}
        isMultipleQuestions={inputs.length > 1}
        AddAnotherInput={AddAnotherInput}
        areInputsEndingWithQuestionMark={areInputsEndingWithQuestionMark()}
        areAllInputsCharacterCountUnderLimit={areAllInputsCharacterCountUnderLimit()}
        isConfirmLoading={isConfirmLoading}
        setIsPrescription={setIsPrescription}
        isPatientAction={isPatientAction}
        isSessionAction={isSessionAction}
        setIsSessionAction={setIsSessionAction}
        isSecondaryProfile={isSecondaryProfile(patientID)}
      />
    </div>
  );
};

InputBox.propTypes = {
  sendAction: PropTypes.func,
  changeSpeciality: PropTypes.func,
};

export default InputBox;
