import React, { Component } from "react";
import { List } from "immutable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import {
  getInviteFilter,
  getVisibleInvites,
  invitesActions,
  getShowOverlay,
  getDraftInvite,
  getNewInviteFlag,
} from "../../../invites";
import InvitesTable from "../../components/InvitesTable";
import InviteOverlay from "../../components/InvitesTable/InviteOverlay";

import TableHeader from "../../components/TableHeader";

export class InvitesPage extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { loadInvites } = this.props;
    loadInvites();
  }

  componentWillUnmount() {
    const { unloadInvites } = this.props;
    unloadInvites();
  }

  render() {
    const {
      startNew,
      invites,
      startEdit,
      toggleActive,
      showOverlay,
      clearDraftInvite,
      draftInvite,
      isNewInvite,
      updateInvite,
    } = this.props;
    return (
      <div className="container scrollable">
        <TableHeader title="Invite Codes" newClick={startNew} />
        <InvitesTable
          invites={invites}
          onEdit={startEdit}
          toggleActive={toggleActive}
        />
        <InviteOverlay
          showOverlay={showOverlay}
          onClose={clearDraftInvite}
          invite={draftInvite}
          isNewInvite={isNewInvite}
          onSave={updateInvite}
        />
      </div>
    );
  }
}

InvitesPage.propTypes = {
  loadInvites: PropTypes.func.isRequired,
  clearDraftInvite: PropTypes.func.isRequired,
  startNew: PropTypes.func.isRequired,
  startEdit: PropTypes.func.isRequired,
  toggleActive: PropTypes.func.isRequired,
  updateInvite: PropTypes.func.isRequired,
  invites: PropTypes.instanceOf(List).isRequired,
  unloadInvites: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool,
  draftInvite: PropTypes.bool,
  isNewInvite: PropTypes.bool,
};

//= ====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = createSelector(
  getInviteFilter,
  getVisibleInvites,
  getShowOverlay,
  getDraftInvite,
  getNewInviteFlag,
  (filterType, invites, showOverlay, draftInvite, isNewInvite) => ({
    filterType,
    invites,
    showOverlay,
    draftInvite,
    isNewInvite,
  })
);

const mapDispatchToProps = {
  ...invitesActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitesPage);
