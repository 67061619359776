import { firestore } from "../firebase";
import * as api from "./api";
import * as t from "./action-types";
import Notification from "../views/components/Notification";

const NotificationSound = new Audio("definite.mp3");

export function loadDispenseRequests() {
  return (dispatch, getState) => {
    const { activeHealthcareService } = getState().auth;

    const isValidCommunicationRequest = ({ dispenseRequest }) => {
      const { performer, dispenseRequests } = dispenseRequest;
      if (performer !== activeHealthcareService) {
        return false;
      }

      if (dispenseRequests === null || dispenseRequests === undefined) {
        return false;
      }

      for (let i = 0; i < Object.values(dispenseRequests).length; i += 1) {
        if (
          !Object.values(dispenseRequests)[i].status ||
          !Object.values(dispenseRequests)[i].userInfo
        ) {
          return false;
        }
      }

      return true;
    };

    let isFirstRun = true;
    firestore
      .collection("medicationRequests")
      .where("performer", "==", activeHealthcareService)
      .onSnapshot((querySnapshot) => {
        if (isFirstRun) {
          isFirstRun = false;
          const dispenseRequests = [];
          querySnapshot.forEach((doc) => {
            if (isValidCommunicationRequest({ dispenseRequest: doc.data() })) {
              dispenseRequests.push({ ...doc.data(), key: doc.id });
            }
          });
          dispatch({
            type: t.LOAD_DISPENSE_REQUESTS_SUCCESS,
            payload: dispenseRequests,
          });
        } else {
          querySnapshot.docChanges().forEach((change) => {
            if (
              !isValidCommunicationRequest({
                dispenseRequest: change.doc.data(),
              })
            ) {
              return;
            }

            if (change.type === "added") {
              NotificationSound.play();
              dispatch({
                type: t.CREATE_DISPENSE_REQUESTS_SUCCESS,
                payload: { ...change.doc.data(), key: change.doc.id },
              });
            }
            if (change.type === "modified") {
              dispatch({
                type: t.UPDATE_DISPENSE_REQUESTS_SUCCESS,
                payload: { ...change.doc.data(), key: change.doc.id },
              });
            }
            if (change.type === "removed") {
              dispatch({
                type: t.REMOVE_DISPENSE_REQUESTS_SUCCESS,
                payload: { ...change.doc.data(), key: change.doc.id },
              });
            }
          });
        }
      });
  };
}

export function loadPharmacyOrderInvoices() {
  return async (dispatch) => {
    let isFirstRun = true;
    firestore
      .collection("invoices")
      .where("type", "==", "PHARMACY_ORDER")
      .onSnapshot((querySnapshot) => {
        if (isFirstRun) {
          isFirstRun = false;
          const invoices = [];
          querySnapshot.forEach((doc) => {
            invoices.push({ ...doc.data(), key: doc.id });
          });
          dispatch({
            type: t.LOAD_PHARMACY_ORDER_INVOICES_SUCCESS,
            payload: invoices,
          });
        } else {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              NotificationSound.play();
              dispatch({
                type: t.CREATE_PHARMACY_ORDER_INVOICES_SUCCESS,
                payload: { ...change.doc.data(), key: change.doc.id },
              });
            }
            if (change.type === "modified") {
              dispatch({
                type: t.UPDATE_PHARMACY_ORDER_INVOICES_SUCCESS,
                payload: { ...change.doc.data(), key: change.doc.id },
              });
            }
            if (change.type === "removed") {
              dispatch({
                type: t.REMOVE_PHARMACY_ORDER_INVOICES_SUCCESS,
                payload: { ...change.doc.data(), key: change.doc.id },
              });
            }
          });
        }
      });
  };
}

function getPharmacistInformation() {
  return async (dispatch, getState) => {
    if (getState().auth.practitionerRole === undefined) {
      Notification.Error("Missing practitioner role, contact developers");
      return undefined;
    }
    const userPractitionerRole = getState().auth.practitionerRole.toJS();
    return {
      practitionerRole: userPractitionerRole.key,
      practitionerKey: userPractitionerRole.practitionerKey,
    };
  };
}

export function markDispenseRequestAsCancelled({
  dispenseItem,
  medicationRequestKey,
  dispenseRequestKey,
  statusReason,
}) {
  return async (dispatch, getState) => {
    const pharmacistInformation = await getPharmacistInformation()(
      dispatch,
      getState
    );
    if (pharmacistInformation === undefined) {
      return;
    }

    await api.markDispenseRequestAsCancelled({
      dispenseItem,
      medicationRequestKey,
      dispenseRequestKey,
      statusReason,
      pharmacistInformation,
    });

    api.dispenseRequestStatusChanged({
      destinationType: dispenseItem.dispenseRequests[dispenseRequestKey].type,
      isPharmacyOrder: Boolean(dispenseItem.sku),
      newStatus: "cancelled",
      performer: dispenseItem.performer,
      subject: dispenseItem.subject,
    });
  };
}

export function markDispenseRequestAsOnHold({
  dispenseItem,
  quantity,
  allowedQuantity,
  type,
  destination,
  dispenseRequestKey,
  isWasfatyPrescription = false,
  isWasfatyPartiallyDispensed = false,
}) {
  return async (dispatch, getState) => {
    const pharmacistInformation = await getPharmacistInformation()(
      dispatch,
      getState
    );
    if (pharmacistInformation === undefined) {
      return;
    }
    const { userInfo, orderID } = Object.values(
      dispenseItem.dispenseRequests
    )[0];
    if (isWasfatyPrescription) {
      await api.markDispenseRequestAsOnHold({
        dispenseItem,
        quantity: null,
        dispenseRequestKey,
        pharmacistInformation,
        isWasfatyPartiallyDispensed,
      });
    } else {
      if (allowedQuantity - quantity > 0) {
        await api.createDispenseRequest({
          dispenseItem,
          medicationRequestKey: dispenseItem.key,
          orderID,
          userInfo,
          type,
          destination,
        });
      }
      api.markDispenseRequestAsOnHold({
        dispenseItem,
        quantity,
        dispenseRequestKey,
        pharmacistInformation,
      });
    }

    api.dispenseRequestStatusChanged({
      destinationType: dispenseItem.dispenseRequests[dispenseRequestKey].type,
      isPharmacyOrder: Boolean(dispenseItem.sku),
      newStatus: "on-hold",
      performer: dispenseItem.performer,
      subject: dispenseItem.subject,
    });
  };
}

export function markDispenseRequestAsCompleted({
  dispenseItem,
  dispenseRequestKey,
  medicationRequestKey,
}) {
  return async (dispatch, getState) => {
    const pharmacistInformation = await getPharmacistInformation()(
      dispatch,
      getState
    );
    if (pharmacistInformation === undefined) {
      return;
    }

    api.markDispenseRequestAsCompleted({
      dispenseItem,
      dispenseRequestKey,
      medicationRequestKey,
      pharmacistInformation,
    });

    api.dispenseRequestStatusChanged({
      destinationType: dispenseItem.dispenseRequests[dispenseRequestKey].type,
      isPharmacyOrder: Boolean(dispenseItem.sku),
      newStatus: "completed",
      performer: dispenseItem.performer,
      subject: dispenseItem.subject,
    });
  };
}

export function loadCancelledDispenseRequests() {}
