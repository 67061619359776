import { functions, firebaseDb } from "../firebase";

async function sendNotificationToUser({
  notificationTitle,
  notificationBody,
  data,
  patientID,
}) {
  const sendNotificationToUserFunction = functions.httpsCallable(
    "sendNotificationToUser"
  );
  try {
    await sendNotificationToUserFunction({
      notificationTitle,
      notificationBody,
      data,
      patientID,
    });
  } catch (err) {
    // console.log({ err });
  }
}

async function setNewPrescription({ userID }) {
  await firebaseDb.ref(`profiles/${userID}/hasNewPrescriptions`).set(true);
}

async function getProfile({ healthcareServiceKey, patientKey }) {
  const path = `healthcareServices/${healthcareServiceKey}/profiles/${patientKey}`;
  const snapshot = await firebaseDb.ref(path).once("value");
  if (snapshot.exists()) {
    return {
      key: snapshot.key,
      ...snapshot.val(),
    };
  }
  return null;
}

export default {
  getProfile,
  sendNotificationToUser,
  setNewPrescription,
};
