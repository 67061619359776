const ArabicNormalize = (s: string) => {
  function filter(c: string) {
    switch (c) {
      // ALEF Chars
      case "\u0625":
      case "\u0623":
      case "\u0622":
      case "\u0675":
      case "\u0673":
      case "\u0672":
      case "\u0671":
      case "\u0670":
      case "\u0674":
        return "\u0627";
      // TAAA MARBOTA Chars
      case "\u06c3":
      case "\u06c2":
      case "\u06c0":
      case "\u06c1":
      case "\u0647":
        return "\u0629";
      // YAAA Chars
      case "\u0649":
      case "\u0678":
      case "\u064a":
        return "\u0620";

      case "\u0626":
        return "\u0620\u0621";

      default:
        return c;
    }
  }

  let normalized = "";
  let i = 0;
  let l = 0;

  for (i = 0, l = s.length; i < l; i += 1) {
    normalized += filter(s.charAt(i));
  }
  return normalized;
};

export default ArabicNormalize;
