export const LOAD_DISPENSE_REQUESTS_SUCCESS =
  "dispenseRequests/LOAD_DISPENSE_REQUESTS_SUCCESS";
export const UNLOAD_DISPENSE_REQUESTS_SUCCESS =
  "dispenseRequests/UNLOAD_DISPENSE_REQUESTS_SUCCESS";
export const CREATE_DISPENSE_REQUESTS_SUCCESS =
  "dispenseRequests/CREATE_DISPENSE_REQUESTS_SUCCESS";
export const REMOVE_DISPENSE_REQUESTS_SUCCESS =
  "dispenseRequests/REMOVE_DISPENSE_REQUESTS_SUCCESS";
export const UPDATE_DISPENSE_REQUESTS_SUCCESS =
  "dispenseRequests/UPDATE_DISPENSE_REQUESTS_SUCCESS";

export const LOAD_PHARMACY_ORDER_INVOICES_SUCCESS =
  "dispenseRequests/LOAD_PHARMACY_ORDER_INVOICES_SUCCESS";
export const UNLOAD_PHARMACY_ORDER_INVOICES_SUCCESS =
  "dispenseRequests/UNLOAD_PHARMACY_ORDER_INVOICES_SUCCESS";
export const CREATE_PHARMACY_ORDER_INVOICES_SUCCESS =
  "dispenseRequests/CREATE_PHARMACY_ORDER_INVOICES_SUCCESS";
export const REMOVE_PHARMACY_ORDER_INVOICES_SUCCESS =
  "dispenseRequests/REMOVE_PHARMACY_ORDER_INVOICES_SUCCESS";
export const UPDATE_PHARMACY_ORDER_INVOICES_SUCCESS =
  "dispenseRequests/UPDATE_PHARMACY_ORDER_INVOICES_SUCCESS";
