import React from "react";
import { Card, Elevation, H5, Tag } from "@blueprintjs/core";
import { getAge } from "../../utils";

const PatientCard = ({
  profile,
  selectPatient,
  isSelected,
  isCheckedIn,
  displayName,
}) => {
  const name = displayName
    ? profile.fullName || profile.name || "Unknown"
    : "Unknown";
  return (
    <Card
      style={{
        marginTop: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        border: isSelected ? "1px #0E5A8A solid" : null,
        padding: isSelected ? "19px" : "20px",
      }}
      onClick={() => {
        if (!isSelected) {
          selectPatient(profile);
        }
      }}
      interactive
      elevation={Elevation.ONE}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <H5 style={{ marginBottom: 0 }}>{name}</H5>
        <div
          style={{
            marginBottom: 0,
            marginLeft: "12px",
            textTransform: "capitalize",
          }}
        >
          {`${getAge(profile.dateOfBirth)} Years - ${profile.gender}`}
        </div>
      </div>
      {isCheckedIn && (
        <Tag style={{ marginTop: 8 }} intent="primary">
          <p>Checked In</p>
        </Tag>
      )}
    </Card>
  );
};

export default PatientCard;
