import React, { useState } from "react";
import {
  Dialog,
  FormGroup,
  Button,
  Intent,
  Menu,
  MenuItem,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

import ObservationInput from "./ObservationInput";

const AddNewObservation = ({
  addObservation,
  observationTypes,
  addNewObservation,
  patientKey,
  setAddNewObservation,
}) => {
  const [newValue, setNewValue] = useState("");
  const [addingObservation, setAddingObservation] = useState(false);
  const [selectedObservation, setSelectedObservation] = useState(null);

  const renderObservation = (observation, { handleClick }) => {
    return (
      <MenuItem
        key={observation.key}
        onClick={handleClick}
        text={observation.displayName.en || observation.displayName.ar}
      />
    );
  };

  const renderObservationsList = ({ items, itemsParentRef, renderItem }) => {
    const renderedItems = items.map(renderItem).filter((item) => item != null);
    return (
      <Menu ulRef={itemsParentRef} style={{ minWidth: 0 }}>
        {renderedItems}
      </Menu>
    );
  };

  const getObservationName = () =>
    selectedObservation &&
    selectedObservation.displayName &&
    (selectedObservation.displayName.en || selectedObservation.displayName.ar);

  return (
    <Dialog
      style={{ width: "100%", maxWidth: 500, paddingBottom: 20 }}
      isOpen={addNewObservation}
      onClose={() => {
        setAddNewObservation(false);
        setSelectedObservation(null);
        setNewValue("");
        setAddingObservation(false);
      }}
      className="bp3-dark"
      title="Add New Observation"
      icon="plus"
    >
      <div
        style={{
          marginTop: 20,
          marginRight: 20,
          marginLeft: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "flex-start",
            marginBottom: 20,
          }}
        >
          {/* <p style={{ marginRight: 10 }}>Select observation to add</p>
          <p style={{ marginRight: 10 }}> - </p> */}
          <Select
            filterable={false}
            name="observation"
            id="observation"
            items={observationTypes}
            onItemSelect={(observation) => {
              setSelectedObservation(observation);
            }}
            itemRenderer={renderObservation}
            itemListRenderer={renderObservationsList}
            popoverProps={{
              minimal: true,
              inner: true,
              boundary: "window",
              hasBackdrop: true,
              popoverClassName: "conditions-popover",
            }}
          >
            <Button
              fill
              style={{ minWidth: 80 }}
              large={false}
              text={
                selectedObservation
                  ? selectedObservation.displayName.en ||
                    selectedObservation.displayName.ar
                  : "Select Observation"
              }
            />
          </Select>
        </div>
        {selectedObservation && (
          <>
            <FormGroup
              label={`New ${getObservationName()} value`}
              labelFor={selectedObservation.key}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
              inline
            >
              <ObservationInput
                type={selectedObservation.updateRules.type}
                newValue={newValue}
                setNewValue={setNewValue}
                preferredUnit={selectedObservation.updateRules.preferredUnit}
              />
            </FormGroup>
            <Button
              intent={Intent.SUCCESS}
              style={{ width: 80 }}
              large={false}
              loading={addingObservation}
              onClick={async () => {
                setAddingObservation(true);
                await addObservation({
                  observationName: selectedObservation.key,
                  value:
                    selectedObservation.updateRules.type === "number"
                      ? Number(newValue)
                      : newValue,
                  patientKey,
                  unit: selectedObservation.updateRules.preferredUnit,
                });
                setNewValue("");
                setAddingObservation(false);
                setAddNewObservation(false);
                setSelectedObservation(null);
              }}
              text="Add"
              disabled={newValue === ""}
            />
          </>
        )}
      </div>
    </Dialog>
  );
};

export default AddNewObservation;
