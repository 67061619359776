import React from "react";

const NoDispenseRequests = ({ message }) => {
  return (
    <div
      style={{
        height: 400,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {message}
    </div>
  );
};

export default NoDispenseRequests;
