import React from "react";
import {
  ControlGroup,
  InputGroup,
  Button,
  Intent,
  TextArea,
} from "@blueprintjs/core";

import PropTypes from "prop-types";

const hasArabicCharacters = (text) => {
  const arabicRegex = /[\u0600-\u06FF]/;
  return arabicRegex.test(text);
};

const Input = ({
  input,
  inputChange,
  sendAction,
  disabled = false,
  placeHolder = "Type Response...",
  onFocus,
  secondary,
  final,
  removeInput,
  isEndingWithQuestionMark,
  areInputsEndingWithQuestionMark,
  characterLimit,
  activeInputCharacterCounter,
  isConfirmLoading,
}) => {
  let sendButtonText;
  if (secondary) {
    sendButtonText = "";
  } else if (final) {
    sendButtonText = "Send All";
  } else {
    sendButtonText = "Send";
  }

  const sendElement =
    (!disabled &&
      ((!secondary && input.length > 0) ||
        (secondary && input.length === 0))) ||
    final ? (
      <Button
        minimal={secondary}
        disabled={
          (final && !areInputsEndingWithQuestionMark) ||
          input.length > characterLimit ||
          isConfirmLoading
        }
        onClick={secondary ? removeInput : sendAction}
        icon={secondary ? "cross" : "comment"}
        intent={secondary ? Intent.DANGER : Intent.PRIMARY}
        loading={isConfirmLoading}
      >
        {sendButtonText}
      </Button>
    ) : null;

  let characterCounterColor;

  if (activeInputCharacterCounter < characterLimit * 0.7) {
    characterCounterColor = "white";
  } else if (activeInputCharacterCounter < characterLimit) {
    characterCounterColor = "#FFC940";
  } else {
    characterCounterColor = "#FF5A5F";
  }
  const characterCounter = activeInputCharacterCounter > 0 && (
    <span style={{ color: characterCounterColor, marginRight: 10 }}>
      {" "}
      {`${activeInputCharacterCounter}/${characterLimit}`}{" "}
    </span>
  );

  const inputIntent =
    (secondary || final) && !isEndingWithQuestionMark
      ? Intent.DANGER
      : Intent.NONE;

  const singleLineInput = (
    <InputGroup
      style={{
        direction: hasArabicCharacters(input) ? "rtl" : "ltr",
      }}
      large
      placeholder={placeHolder}
      onFocus={onFocus}
      value={input}
      disabled={disabled}
      onChange={(event) => inputChange(event.target.value)}
      rightElement={sendElement}
      intent={inputIntent}
      autoFocus={final || (!final && !secondary)}
    />
  );

  const multiLineInput = (
    <div>
      <TextArea
        style={{
          resize: "none",
          marginTop: "5px",
          direction: hasArabicCharacters(input) ? "rtl" : "ltr",
        }}
        large
        placeholder={placeHolder}
        onFocus={onFocus}
        value={input}
        disabled={disabled}
        onChange={(event) => inputChange(event.target.value)}
        intent={inputIntent}
        autoFocus={final || (!final && !secondary)}
        growVertically
        fill
      />

      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          {characterCounter}
          {sendElement}
        </div>
      </div>
    </div>
  );

  return (
    <div className="chatbox-input" style={{ marginLeft: 1, marginRight: 1 }}>
      <form onSubmit={sendAction}>
        <ControlGroup fill>
          {secondary ? singleLineInput : multiLineInput}
        </ControlGroup>
      </form>
    </div>
  );
};

Input.propTypes = {
  input: PropTypes.string,
  inputChange: PropTypes.func,
  sendAction: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Input;
