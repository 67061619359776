import React from "react";
import { Intent, Tag } from "@blueprintjs/core";
import Attachment from "../Attachment";
import { formatTime } from "../../utils";
import AudioAttachment from "../Attachment/AudioAttachment";

const UserConversationText = ({
  text,
  attachment,
  storagePathResolver,
  time,
  appendedQuestions,
}) => {
  const hasAttachment = Boolean(attachment);
  const hasImage = hasAttachment && attachment.type === "image";
  const hasAudio = hasAttachment && attachment.type === "audio";

  const renderAppendedQuestions = () => {
    if (appendedQuestions) {
      return Object.values(appendedQuestions)
        .sort(
          (appendedQuestionA, appendedQuestionB) =>
            appendedQuestionA.sent - appendedQuestionB.sent
        )
        .map((appendedQuestion) => {
          return (
            <div
              key={JSON.stringify(appendedQuestion)}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Tag
                className="chatbox-item item-user"
                large
                interactive={false}
                round={false}
                multiline
                intent={Intent.PRIMARY}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "4px",
                    marginBottom: 4,
                  }}
                >
                  {appendedQuestion.text}
                </div>
              </Tag>
              <div
                className="bp3-text-small bp3-text-muted"
                style={{
                  marginTop: "-5px",
                  alignSelf: "flex-end",
                  marginRight: "10px",
                }}
              >
                {`${formatTime(appendedQuestion.sent)}`}
              </div>
            </div>
          );
        });
    }
    return null;
  };

  return (
    <>
      <Tag
        className="chatbox-item item-user"
        large
        interactive={false}
        round={false}
        multiline
        intent={Intent.PRIMARY}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "4px",
            marginBottom: hasImage ? 10 : 4,
          }}
        >
          {text}
          {hasImage && (
            <Attachment
              type={attachment.type}
              storagePath={attachment.storagePath}
              storagePathResolver={storagePathResolver}
            />
          )}
          {hasAudio && (
            <AudioAttachment
              storagePath={attachment.storagePath}
              storagePathResolver={storagePathResolver}
            />
          )}
        </div>
      </Tag>
      <div
        className="bp3-text-small bp3-text-muted"
        style={{
          marginTop: "-5px",
          alignSelf: "flex-end",
          marginRight: "10px",
        }}
      >
        {`${time}`}
      </div>
      {renderAppendedQuestions()}
    </>
  );
};

export default UserConversationText;
