import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { usersReducer } from "./users";
import { invitesReducer } from "./invites";
import { sessionsReducer } from "./sessions";
import { conditionsReducer } from "./conditions";
import { patientReducer } from "./patients";
import { practitionersReducer } from "./practitioners";
import { inboxReducer } from "./inbox";
import { specialtiesReducer } from "./specialties";
import { communicationRequestsReducer } from "./communicationRequests";
import { observationTypesReducer } from "./observationTypes";
import { bookingsReducer } from "./bookings";
import { settingsReducer } from "./settings";
import { medicationsReducer } from "./medications";
import { dispenseRequestsReducer } from "./dispenseRequests";
import { newPatientsReducer } from "./newPatients";

export default combineReducers({
  auth: authReducer,
  routing: routerReducer,
  users: usersReducer,
  sessions: sessionsReducer,
  invites: invitesReducer,
  // accounts: accountsReducer,
  conditions: conditionsReducer,
  patients: patientReducer,
  practitioners: practitionersReducer,
  inbox: inboxReducer,
  specialties: specialtiesReducer,
  communicationRequests: communicationRequestsReducer,
  observationTypes: observationTypesReducer,
  bookings: bookingsReducer,
  settings: settingsReducer,
  medications: medicationsReducer,
  dispenseRequests: dispenseRequestsReducer,
  newPatients: newPatientsReducer,
});
