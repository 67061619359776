/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import { HTMLTable } from "@blueprintjs/core";
import InviteRow from "./InviteRow";

const InvitesTable = ({
  invites,
  resendInvite,
  updateWelcomeCallStatus,
  removeInvite,
}) => {
  const renderInviteRows = () => {
    return invites
      .toArray()
      .sort((inviteA, inviteB) => {
        return inviteB[1].get("sent") - inviteA[1].get("sent");
      })
      .map((invite) => (
        <InviteRow
          invite={invite}
          resendInvite={resendInvite}
          updateWelcomeCallStatus={updateWelcomeCallStatus}
          removeInvite={removeInvite}
        />
      ));
  };

  const renderSkeleton = () => {
    return Array.from(Array(5).keys()).map((key) => {
      return (
        <tr>
          <td>
            <div key={key} className="bp3-skeleton" style={{ height: 20 }} />
          </td>
          <td>
            <div key={key} className="bp3-skeleton" style={{ height: 20 }} />
          </td>
          <td>
            <div key={key} className="bp3-skeleton" style={{ height: 20 }} />
          </td>
          <td>
            <div key={key} className="bp3-skeleton" style={{ height: 20 }} />
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="bp3-elevation-4">
      {invites && Boolean(invites.size) && invites.size > 0 && (
        <div>
          <HTMLTable style={{ width: "100%", tableLayout: "fixed" }} striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Progress</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderInviteRows()}</tbody>
          </HTMLTable>
        </div>
      )}
      {invites === undefined && (
        <div>
          <HTMLTable style={{ width: "100%", tableLayout: "fixed" }} striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Progress</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderSkeleton()}</tbody>
          </HTMLTable>
        </div>
      )}
    </div>
  );
};

export default InvitesTable;
