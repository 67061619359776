import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Elevation,
  Tag,
  H5,
  H6,
  Intent,
  Button,
  Icon,
  Callout,
} from "@blueprintjs/core";

const moment = require("moment");

const QuestionPart = ({ question = {} }) => {
  const hasResponse = question && question.responded;
  const sentAt = moment(question.sent)
    .utcOffset(180)
    .format("MMMM Do YYYY, h:mm:ss a");
  const respondedAt = hasResponse
    ? moment(question.responded)
        .utcOffset(180)
        .format("MMMM Do YYYY, h:mm:ss a")
    : null;
  // const durationString = `${moment.duration(duration).hours()} hrs ${moment.duration(duration).minutes()} min ${moment.duration(duration).seconds()} sec`
  const { isEndingSession } = question;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "2px",
      }}
    >
      <Tag
        className="chatbox-item item-user"
        large
        interactive={false}
        round={false}
        multiline
        intent={Intent.PRIMARY}
      >
        {question.question}
      </Tag>
      <div
        className="bp3-text-small bp3-text-muted"
        style={{
          marginTop: "-5px",
          alignSelf: "flex-end",
        }}
      >
        {`${sentAt}`}
      </div>
      <Tag
        className="chatbox-item item-doctor"
        large
        interactive={false}
        round={false}
        multiline
        intent={isEndingSession ? Intent.SUCCESS : Intent.NONE}
      >
        {isEndingSession ? question.endingSessionText : question.answer}
      </Tag>
      {hasResponse && (
        <div
          className="bp3-text-small bp3-text-muted"
          style={{
            marginLeft: "10px",
            marginTop: "-5px",
          }}
        >
          {`${respondedAt}`}
        </div>
      )}
    </div>
  );
};

const SessionCard = ({ session = {}, addTypeLabel, isDetailsPage = false }) => {
  // const SessionCard = ({  }) => {
  // const session = { // Dummy session
  //     id: "-L_-1qxalFqJyTAe7fB6",
  //     condition: "نعم بالطبع",
  //     ended: 1557262356426,
  //     endedBy: "pEHZis6ud6W3B1wQPhGjZGlCKdk1",
  //     patient: {
  //         age: 28,
  //         gender: "male",
  //     },
  //     questions: {
  //         "-LjVTk6uBMu0sB3VW7S5" : {
  //             "answer" : "test",
  //             "answerReady" : 1562839343087,
  //             "question" : "Test no response please",
  //             "responded" : 1562839343087,
  //             "respondedBy" : "m8OAhW6t6hNm0QVzs1I3cYm8z1z2",
  //             "sent" : 1562839288612
  //         },
  //         "-LjVUJER0A-zn_QE_wmQ" : {
  //             "answer" : "متى بدأت الحالة؟",
  //             "answerReady" : 1562839485654,
  //             "flags" : {
  //                 "isSinceWhen" : true
  //             },
  //             "question" : "-تم إرفاق ملف-",
  //                 "responded" : 1562839485654,
  //                     "respondedBy" : "m8OAhW6t6hNm0QVzs1I3cYm8z1z2",
  //                         "sent" : 1562839434406
  //         },
  //         "-LjVUYR_-JliF38Gm1NX" : {
  //             "addedToQue" : 1562839485654,
  //             "answer" : "test?",
  //             "answerReady" : 1562839496845,
  //             "question" : "يومين",
  //             "responded" : 1562839496845,
  //             "respondedBy" : "m8OAhW6t6hNm0QVzs1I3cYm8z1z2",
  //             "sent" : 1562839496696
  //         },
  //         "-LjVUZfXaaoFchmLr5HC" : {
  //             "addedToQue" : 1562839485654,
  //             "answer" : "testy?",
  //             "answerReady" : 1562839501851,
  //             "question" : "تست",
  //             "responded" : 1562839501851,
  //             "respondedBy" : "m8OAhW6t6hNm0QVzs1I3cYm8z1z2",
  //             "sent" : 1562839501737
  //         },
  //         "-LjVU_TxnqLzaYYhTzB0" : {
  //             "answer" : "test",
  //             "answerReady" : 1562839634369,
  //             "question" : "تست",
  //             "responded" : 1562839634369,
  //             "respondedBy" : "m8OAhW6t6hNm0QVzs1I3cYm8z1z2",
  //             "sent" : 1562839505049
  //         },
  //         "-LjVVE0iGtTjW_oyHQH-" : {
  //             "answer" : "متى بدأت الحالة؟",
  //             "answerReady" : 1562839684593,
  //             "flags" : {
  //                 "isSinceWhen" : true
  //             },
  //             "question" : "تست",
  //             "responded" : 1562839684593,
  //             "respondedBy" : "m8OAhW6t6hNm0QVzs1I3cYm8z1z2",
  //             "sent" : 1562839675200
  //         }
  //     },
  //     started: 1557262216507,
  //     // isEmergency: true
  // };
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [isExpanded, setIsExpanded] = useState(!!isDetailsPage);
  const [isNeedsConfirmation, setIsNeedsConfirmation] = useState(false);

  const endedWithCondition = !!session.condition;
  const endedWithNoReply = session.condition === ""; // was checking (== "") which might give unpredicted behavior
  const endedWithEmergency = session.isEmergency && session.condition === ""; // was checking (== "") which might give unpredicted behavior
  let endingSessionText = "";
  if (endedWithCondition) {
    endingSessionText = `Provisional Diagnosis: ${session.condition}`;
  }
  if (endedWithNoReply) {
    endingSessionText = `Ended with no response`;
  }
  if (endedWithEmergency) {
    endingSessionText = `Sent to emergency`;
  }

  const confirm = () => {
    addTypeLabel(selectedLabel);
    setSelectedLabel(null);
    setIsNeedsConfirmation(false);
  };

  const setLabel = (label) => {
    setSelectedLabel(label);
    setIsNeedsConfirmation(true);
  };

  const isLabeled = Boolean(session.typeLabel);
  return (
    <Card
      style={{ marginBottom: "10px" }}
      interactive={!isExpanded}
      elevation={Elevation.TWO}
      disabled={isExpanded}
      onClick={() => {
        if (!isExpanded) {
          setIsExpanded(true);
        }
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <H5
            style={{
              marginBottom: 0,
            }}
          >
            {session.key}
          </H5>
          <div
            className="bp3-text-small bp3-text-muted"
            style={{
              marginLeft: "10px",
            }}
          >
            {!session.ended && (
              <Icon
                style={{
                  marginRight: "5px",
                }}
                icon="full-circle"
                intent={Intent.SUCCESS}
              />
            )}
            {moment(session.started).fromNow()}
          </div>
        </div>
        {isLabeled ? (
          <Tag intent={Intent.PRIMARY} round large={false}>
            {session.typeLabel}
          </Tag>
        ) : (
          <Tag intent={Intent.WARNING} round large={false}>
            unlabeled
          </Tag>
        )}
      </div>
      {isExpanded && (
        <div style={{ marginTop: "10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 2 }}>
              {session.questions &&
                Object.keys(session.questions).map((questionKey) => (
                  <QuestionPart
                    key={questionKey}
                    question={{
                      endingSessionText,
                      key: questionKey,
                      ...session.questions[questionKey],
                    }}
                  />
                ))}
            </div>
            <div
              style={{
                flex: 1,
                marginLeft: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {session.ended && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <H6 className="bp3-text-muted">
                    <Icon style={{ marginRight: "5px" }} icon="time" />
                    Session Duration
                  </H6>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <H6 style={{ marginLeft: "10px" }}>
                      {session.ended
                        ? moment
                            .duration(session.ended - session.started)
                            .humanize()
                        : "Active"}
                    </H6>
                  </div>
                </div>
              )}
              {session.ended && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <H6 className="bp3-text-muted">
                    <Icon style={{ marginRight: "5px" }} icon="pulse" />
                    Ended By
                  </H6>
                  <H6 style={{ marginLeft: "10px" }}>
                    {session.ended ? session.endedBy : "Active"}
                  </H6>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <H6 className="bp3-text-muted">
                  <Icon style={{ marginRight: "5px" }} icon="tag" />
                  User Key
                </H6>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <H6>{session.patient && session.userID}</H6>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <H6 className="bp3-text-muted">
                  <Icon style={{ marginRight: "5px" }} icon="user" />
                  Profile
                </H6>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <H6>{session.patient && session.patient.gender}</H6>
                  <H6 style={{ marginLeft: "10px" }}>
                    {`${session.patient && session.patient.age} years`}
                  </H6>
                </div>
              </div>
              {session.ended && (session.condition || session.isEmergency) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <H6 className="bp3-text-muted">Conclusion</H6>
                  {session.isEmergency ? (
                    <Tag
                      style={{}}
                      intent={Intent.DANGER}
                      round={false}
                      interactive={false}
                    >
                      isEmergency
                    </Tag>
                  ) : (
                    <Tag
                      style={{}}
                      intent={Intent.SUCCESS}
                      round={false}
                      interactive={false}
                      multiline
                    >
                      {session.condition}
                    </Tag>
                  )}
                </div>
              )}
            </div>
          </div>
          {!isLabeled && !isDetailsPage && (
            <Callout style={{ marginTop: "20px" }}>
              <H6>Add Label</H6>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Tag
                  style={{ marginRight: "5px" }}
                  intent={
                    selectedLabel === "medical-question"
                      ? Intent.PRIMARY
                      : Intent.NONE
                  }
                  round
                  interactive
                  onClick={() => setLabel("medical-question")}
                >
                  Medical Question
                </Tag>
                <Tag
                  style={{ marginRight: "5px" }}
                  intent={
                    selectedLabel === "needs-diagnosis"
                      ? Intent.PRIMARY
                      : Intent.NONE
                  }
                  round
                  interactive
                  onClick={() => setLabel("needs-diagnosis")}
                >
                  Needs Diagnosis
                </Tag>
                <Tag
                  style={{ marginRight: "5px" }}
                  intent={
                    selectedLabel === "needs-appointment"
                      ? Intent.PRIMARY
                      : Intent.NONE
                  }
                  round
                  interactive
                  onClick={() => setLabel("needs-appointment")}
                >
                  Needs Appointment
                </Tag>
                <Tag
                  style={{ marginRight: "5px" }}
                  intent={
                    selectedLabel === "other" ? Intent.PRIMARY : Intent.NONE
                  }
                  round
                  interactive
                  onClick={() => setLabel("other")}
                >
                  Other
                </Tag>
              </div>
            </Callout>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
          {isNeedsConfirmation ? (
            <Button
              style={{ marginTop: "10px" }}
              fill
              icon="tick"
              text="Confirm"
              intent={Intent.SUCCESS}
              minimal={false}
              disabled={!isExpanded}
              onClick={() => {
                if (isExpanded) {
                  confirm();
                }
              }}
            />
          ) : (
            <Button
              style={{ marginTop: "10px" }}
              fill
              icon="chevron-up"
              minimal
              disabled={!isExpanded}
              onClick={() => {
                if (isExpanded) {
                  setIsExpanded(false);
                }
              }}
            />
          )}
        </div>
      )}
    </Card>
  );
};

SessionCard.propTypes = {
  session: PropTypes.shape({}),
};

export default SessionCard;
