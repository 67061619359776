import { firestore } from "../firebase";
import * as t from "./action-types";

export function loadMedications() {
  return (dispatch) => {
    let isFirstRun = true;

    firestore.collection("medications").onSnapshot((querySnapshot) => {
      if (isFirstRun) {
        isFirstRun = false;
        const medications = [];
        querySnapshot.forEach((doc) => {
          const medication = doc.data();

          if (
            medication.status.authorizationStatus === "Valid" &&
            medication.status.marketingStatus === "Marketed"
          ) {
            medications.push({
              ...doc.data(),
              key: doc.id,
              predicate: `${medication.name.tradeName.en.toLowerCase()}${medication.name.scientificName.en.toLowerCase()}`,
            });
          }
        });
        dispatch({
          type: t.LOAD_MEDICATIONS,
          payload: medications,
        });
      }
    });
  };
}

export function unloadMedications() {}
