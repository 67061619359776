import { ReducerState } from "../types/redux";

export function getNewPatientsState(state: ReducerState) {
  return state.newPatients;
}

export function getInvites(state: ReducerState) {
  return getNewPatientsState(state).invites;
}

export function getLoadedState(state: ReducerState) {
  return getNewPatientsState(state).loaded;
}

export function getPairingPractitioners(state: ReducerState) {
  return getNewPatientsState(state).pairingPractitioners;
}

export function getAuthId(state: ReducerState) {
  return state.auth.id;
}
