import React from "react";
import { Card } from "@blueprintjs/core";

import Slots from "./Slots";

const moment = require("moment");

const Date = ({
  slots,
  dayOffset,
  toggleSlotAvailability,
  showOnlyActiveSlots,
}) => {
  const today = moment();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Card
        interactive
        style={{
          margin: 5,
          paddingTop: 5,
          paddingBottom: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: today.isSame(slots[0].startMoment, "days")
            ? "#556571"
            : null,
        }}
      >
        <p>{slots[0].startMoment.format("MMM DD").toUpperCase()}</p>
        <p style={{ fontSize: 20 }}>{slots[0].startMoment.format("ddd")}</p>
      </Card>
      <Slots
        slots={slots}
        dayOffset={dayOffset}
        toggleSlotAvailability={toggleSlotAvailability}
        showOnlyActiveSlots={showOnlyActiveSlots}
      />
    </div>
  );
};

export default Date;
