import React from "react";
import PropTypes from "prop-types";
import LoginForm from "../LoginForm";
import Logo from "../logo";

const SidebarHeader = ({ login, reset, signup }) => (
  <li className="bp3-menu-header sidebar-header">
    <div className="main-nav-header-logo">
      <a href="/">
        <Logo width={80} />
      </a>
    </div>
    <div style={{ marginTop: 24 }} className="main-nav-header-form">
      <LoginForm
        onSubmitLogin={login}
        onSubmitReset={reset}
        onSubmitSignup={signup}
      />
    </div>
  </li>
);

SidebarHeader.propTypes = {
  login: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
};

export default SidebarHeader;
