export const LOAD_PROFILES = "patients/LOAD_PROFILES";
export const LOAD_PROFILE = "patients/LOAD_PROFILE";

export const LOAD_SERVICE_REQUESTS = "patients/LOAD_SERVICE_REQUESTS";
export const LOAD_DIAGNOSTIC_REPORTS = "patients/LOAD_DIAGNOSTIC_REPORTS";
export const LOAD_APPOINTMENTS = "patients/LOAD_APPOINTMENTS";
export const LOAD_ENCOUNTERS = "patients/LOAD_ENCOUNTERS";

export const LOAD_PAIRED_PATIENTS = "patients/LOAD_PAIRED_PATIENTS";
export const CREATE_PAIRED_PATIENTS = "patients/CREATE_PAIRED_PATIENTS";
export const UPDATE_PAIRED_PATIENTS = "patients/UPDATE_PAIRED_PATIENTS";
export const REMOVED_PAIRED_PATIENTS = "patients/REMOVED_PAIRED_PATIENTS";

export const SELECT_PATIENT = "profiles/SELECT_PATIENT";
export const LOAD_ASKNALA_ACCOUNT = "profile/LOAD_ASKNALA_ACCOUNT";
export const UPDATE_ASKNALA_ACCOUNT = "profiles/UPDATE_ASKNALA_ACCOUNT";
