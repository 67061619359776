import { List, Record } from "immutable";
import * as t from "./action-types";

export const MedicationsState = new Record({
  // Accounts
  medications: new List(),
  loaded: false,
});

export function medicationsReducer(
  state = new MedicationsState(),
  { payload, type }
) {
  switch (type) {
    case t.LOAD_MEDICATIONS:
      return state.merge({
        medications: new List(payload),
        loaded: true,
      });

    default:
      return state;
  }
}
