import api from "./api";

export function createTask({
  startedAt = new Date().getTime(),
  completedAt = undefined,
  type,
  actionText,
  userKey = undefined,
  payload = undefined,
}) {
  return async () => {
    if (!type) {
      throw new Error("Missing required task property");
    }

    if (!actionText) {
      throw new Error("Missing actionText");
    }

    const task = {
      startedAt,
      type,
      userKey,
      actionText,
    };

    if (completedAt) {
      task.completedAt = completedAt;
    }

    if (payload) {
      task.payload = payload;
    }

    await api.createTask({ task });
  };
}

export function markTaskAsDone() {}
