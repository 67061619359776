import { fromJS, List, Record } from "immutable";
import { SIGN_OUT_SUCCESS } from "../auth/action-types";
import {
  CREATE_INVITE_SUCCESS,
  REMOVE_INVITE_SUCCESS,
  UPDATE_INVITE_SUCCESS,
  LOAD_INVITES_SUCCESS,
  CLEAR_DRAFT_INVITE,
  START_EDIT,
  START_NEW,
} from "./action-types";

export const InvitesState = new Record({
  list: new List(),
  showOverlay: false,
  draftInvite: {},
  isNewInvite: true,
});

export function invitesReducer(state = new InvitesState(), { payload, type }) {
  switch (type) {
    case CLEAR_DRAFT_INVITE:
      return state.merge(
        fromJS({
          showOverlay: false,
          draftInvite: {},
          isNewInvite: true,
        })
      );

    case START_EDIT:
      return state.merge(
        fromJS({
          showOverlay: true,
          draftInvite: payload.invite,
          isNewInvite: false,
        })
      );

    case START_NEW:
      return state.merge(
        fromJS({
          showOverlay: true,
          draftInvite: {
            key: null,
            startTime: null,
            endTime: null,
            usageLimit: null,
            duration: null,
            isActive: false,
            isArchived: false,
          },
          isNewInvite: true,
        })
      );

    case CREATE_INVITE_SUCCESS:
      return state.merge({
        list: state.list.unshift(payload),
      });

    case REMOVE_INVITE_SUCCESS:
      return state.merge({
        list: state.list.filter((invite) => invite.key !== payload.key),
      });

    case UPDATE_INVITE_SUCCESS:
      return state.merge({
        list: state.list.map((invite) => {
          return invite.key === payload.key ? payload : invite;
        }),
      });

    case LOAD_INVITES_SUCCESS:
      return state.set("list", new List(payload.reverse()));

    case SIGN_OUT_SUCCESS:
      return new InvitesState();

    default:
      return state;
  }
}
