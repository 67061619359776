/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import moment from "moment";

import {
  Icon,
  Callout,
  HTMLTable,
  Tooltip,
  Button,
  Position,
  Intent,
} from "@blueprintjs/core";
import {
  capitalize,
  getPractitionerNameFromPractitionersList,
} from "../../utils";
import expressionTypes from "../../../config/expressionTypes";
import ObservationHistory from "./ObservationHistory";
import MedicationRequestsHistory from "./MedicationRequestsHistory";
import ObservationValue from "./ObservationValue";
import AddNewObservation from "./AddNewObservation";

const PatientHealthRecord = ({
  patient,
  practitioners,
  removeObservationAlert,
  observationTypes,
  addObservation,
  hideObservationUpdate,
}) => {
  const {
    observationAlerts,
    observations,
    conditions,
    medications,
    procedures,
    allergyIntolerances,
    familyHistories,
  } = patient;

  // Observations that are not added yet which are updatable by provider
  const getNewObservationTypes = () => {
    if (observations) {
      return observationTypes
        .filter(
          (observationType) => observationType.updateRules.isUpdatableByProvider
        )
        .filter(
          (observationType) =>
            !Object.keys(observations).some(
              (observationKey) => observationKey === observationType.key
            )
        );
    }
    return observationTypes.filter(
      (observationType) => observationType.updateRules.isUpdatableByProvider
    );
  };

  const [addNewObservation, setAddNewObservation] = useState(false);
  const onObservationAlertRemove = ({
    observationAlertKey,
    observationKey,
  }) => {
    removeObservationAlert({
      observationAlertKey,
      patientKey: patient.key,
      observationKey,
    });
  };

  const renderObservationAlerts = (observationKey) => {
    const observationAlertsForSpecifiedObservation = () => {
      if (observationAlerts && Boolean(observationAlerts[observationKey])) {
        return observationAlerts[observationKey];
      }
      return undefined;
    };

    if (!observationAlertsForSpecifiedObservation()) {
      return "no alerts";
    }
    return (
      <ObservationAlertsCell
        observationKey={observationKey}
        observationAlerts={observationAlertsForSpecifiedObservation()}
        practitioners={practitioners}
        onObservationAlertRemove={onObservationAlertRemove}
      />
    );
  };

  const renderObservations = () => {
    return Object.keys(observations).map((observationName) => {
      const observation = observations[observationName];
      const renderObservationValue = (value) => {
        // if value of observation is boolean
        if (typeof value === "boolean") {
          if (value) {
            return <Icon icon="small-tick" iconSize={16} intent="success" />;
          }
          return <Icon icon="cross" iconSize={16} intent="danger" />;
        }
        return capitalize(value.toString());
      };

      if (observationName !== "gailScore" && Boolean(observation.current)) {
        return (
          <tr key={observationName}>
            <td>{capitalize(observationName)}</td>
            <td>
              <ObservationValue
                observationName={observationName}
                value={observation.current.value}
                renderObservationValue={renderObservationValue}
                observationTypes={observationTypes}
                addObservation={addObservation}
                patientKey={patient.key}
                hideObservationUpdate={hideObservationUpdate}
              />
            </td>
            <td>
              <ObservationHistory
                observation={observation}
                observationName={observationName}
                renderObservationValue={renderObservationValue}
                observationTypes={observationTypes}
              />
            </td>
            {window.location.pathname !== "/inbox" && (
              <td>{renderObservationAlerts(observationName)}</td>
            )}
          </tr>
        );
      }

      return <div />;
    });
  };
  const renderConditions = () => {
    return Object.keys(conditions).map((key) => {
      const condition = conditions[key];
      const reportedDate = moment(condition.current.dateTime).fromNow();

      if (Boolean(condition.current) && Boolean(condition.current.value)) {
        return (
          <tr key={key}>
            <td>{capitalize(condition.current.value.code)}</td>
            <td>{capitalize(condition.current.value.onsetString)}</td>
            <td>{reportedDate}</td>
          </tr>
        );
      }
      return <div />;
    });
  };
  const renderMedications = () => {
    return Object.keys(medications).map((key) => {
      const medication = medications[key];
      const reportedDate = moment(medication.current.dateTime).fromNow();

      if (Boolean(medication.current) && Boolean(medication.current.value)) {
        return (
          <tr key={key}>
            <td>{capitalize(medication.current.value.code)}</td>
            <td>{capitalize(medication.current.value.status)}</td>
            <td>{reportedDate}</td>
          </tr>
        );
      }
      return <div />;
    });
  };
  const renderProcedures = () => {
    return Object.keys(procedures).map((key) => {
      const procedure = procedures[key];
      const reportedDate = moment(procedure.current.dateTime).fromNow();

      if (Boolean(procedure.current) && Boolean(procedure.current.value)) {
        return (
          <tr key={key}>
            <td>{capitalize(procedure.current.value.note)}</td>
            <td>
              {moment(procedure.current.value.performedDateTime).fromNow()}
            </td>
            <td>{reportedDate}</td>
          </tr>
        );
      }
      return <div />;
    });
  };
  const renderAllergyIntolerances = () => {
    return Object.keys(allergyIntolerances).map((key) => {
      const allergyIntolerance = allergyIntolerances[key];
      const reportedDate = moment(
        allergyIntolerance.current.dateTime
      ).fromNow();

      if (
        Boolean(allergyIntolerance.current) &&
        Boolean(allergyIntolerance.current.value)
      ) {
        return (
          <tr key={key}>
            <td>{capitalize(allergyIntolerance.current.value.note)}</td>
            <td>{capitalize(allergyIntolerance.current.value.onsetString)}</td>
            <td>{reportedDate}</td>
          </tr>
        );
      }
      return <div />;
    });
  };
  const renderFamilyHistories = () => {
    return Object.keys(familyHistories).map((key) => {
      const familyHistory = familyHistories[key];
      const reportedDate = moment(familyHistory.current.dateTime).fromNow();

      if (
        Boolean(familyHistory.current) &&
        Boolean(familyHistory.current.value)
      ) {
        return (
          <tr key={key}>
            <td>{capitalize(familyHistory.current.value.relationship)}</td>
            <td>{capitalize(familyHistory.current.value.condition)}</td>
            <td>{capitalize(familyHistory.current.value.onsetString)}</td>
            <td>{reportedDate}</td>
          </tr>
        );
      }

      return <div />;
    });
  };

  return (
    <div>
      {Boolean(observations) && (
        <div style={{ marginTop: "24px" }}>
          <Callout
            style={{
              overflowX: "scroll",
              display: "flex",
              flexDirection: "column",
            }}
            icon="clipboard"
            title="Observations"
          >
            <HTMLTable style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Reported Date</th>
                  {window.location.pathname !== "/inbox" && (
                    <th>Observation alerts</th>
                  )}
                </tr>
              </thead>
              <tbody>{renderObservations()}</tbody>
            </HTMLTable>
            {getNewObservationTypes().length > 0 && !hideObservationUpdate && (
              <Button
                style={{ alignSelf: "center", marginTop: 10, width: 170 }}
                onClick={() => setAddNewObservation(true)}
                text="Add New Observation"
              />
            )}
            <AddNewObservation
              addObservation={addObservation}
              addNewObservation={addNewObservation}
              setAddNewObservation={setAddNewObservation}
              observationTypes={getNewObservationTypes()}
              patientKey={patient.key}
            />
          </Callout>
        </div>
      )}
      {Boolean(!observations) && (
        <div style={{ marginTop: "24px" }}>
          <Callout
            style={{
              overflowX: "scroll",
              display: "flex",
              flexDirection: "column",
            }}
            icon="clipboard"
            title="Observations"
          >
            <p style={{ textAlign: "center", marginTop: 15, marginBottom: 15 }}>
              There are no observations
            </p>
            {getNewObservationTypes().length > 0 && !hideObservationUpdate && (
              <Button
                style={{ alignSelf: "center", marginTop: 10, width: 170 }}
                onClick={() => setAddNewObservation(true)}
                text="Add New Observation"
              />
            )}
            <AddNewObservation
              addObservation={addObservation}
              addNewObservation={addNewObservation}
              setAddNewObservation={setAddNewObservation}
              observationTypes={getNewObservationTypes()}
              patientKey={patient.key}
            />
          </Callout>
        </div>
      )}
      {
        <div style={{ marginTop: "24px" }}>
          <MedicationRequestsHistory patientID={patient.key} />
        </div>
      }
      {Boolean(conditions) && (
        <div style={{ marginTop: "24px" }}>
          <Callout
            style={{
              overflowX: "scroll",
            }}
            icon="asterisk"
            title="Conditions"
          >
            <HTMLTable style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Onset</th>
                  <th>Reported Date</th>
                </tr>
              </thead>
              <tbody>{renderConditions()}</tbody>
            </HTMLTable>
          </Callout>
        </div>
      )}
      {Boolean(medications) && (
        <div style={{ marginTop: "24px" }}>
          <Callout
            style={{
              overflowX: "scroll",
            }}
            icon="prescription"
            title="Medications"
          >
            <HTMLTable style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Status</th>
                  <th>Reported Date</th>
                </tr>
              </thead>
              <tbody>{renderMedications()}</tbody>
            </HTMLTable>
          </Callout>
        </div>
      )}
      {Boolean(procedures) && (
        <div style={{ marginTop: "24px" }}>
          <Callout
            style={{
              overflowX: "scroll",
            }}
            icon="lifesaver"
            title="Procedures"
          >
            <HTMLTable style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Note</th>
                  <th>Performed Date</th>
                  <th>Reported Date</th>
                </tr>
              </thead>
              <tbody>{renderProcedures()}</tbody>
            </HTMLTable>
          </Callout>
        </div>
      )}
      {Boolean(allergyIntolerances) && (
        <div style={{ marginTop: "24px" }}>
          <Callout
            style={{
              overflowX: "scroll",
            }}
            icon="flame"
            title="Allergy Intolerances"
          >
            <HTMLTable style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Note</th>
                  <th>Onset</th>
                  <th>Reported Date</th>
                </tr>
              </thead>
              <tbody>{renderAllergyIntolerances()}</tbody>
            </HTMLTable>
          </Callout>
        </div>
      )}
      {Boolean(familyHistories) && (
        <div style={{ marginTop: "24px" }}>
          <Callout
            style={{
              overflowX: "scroll",
            }}
            icon="people"
            title="Family Histories"
          >
            <HTMLTable style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Relationship</th>
                  <th>Condition</th>
                  <th>Onset</th>
                  <th>Reported Date</th>
                </tr>
              </thead>
              <tbody>{renderFamilyHistories()}</tbody>
            </HTMLTable>
          </Callout>
        </div>
      )}
    </div>
  );
};

const getNotifiedStringFromRecipient = ({ recipient, requesterName }) => {
  if (
    recipient.healthcareService !== undefined &&
    recipient.speciality === undefined &&
    recipient.practitionerRole === undefined
  ) {
    return `Healthcare Service ${recipient.healthcareService}`;
  }

  if (
    recipient.healthcareService !== undefined &&
    recipient.speciality !== undefined
  ) {
    return `Speciality ${recipient.speciality}`;
  }

  if (
    recipient.healthcareService !== undefined &&
    recipient.practitionerRole !== undefined
  ) {
    return requesterName;
  }

  return "";
};

export default PatientHealthRecord;
const ObservationAlertsCell = ({
  observationKey,
  observationAlerts,
  practitioners,
  onObservationAlertRemove,
}) => {
  const renderObservationAlerts = Object.keys(observationAlerts).map(
    (observationAlertKey) => {
      const observationAlert = observationAlerts[observationAlertKey];
      const requesterName = getPractitionerNameFromPractitionersList(
        practitioners,
        observationAlert.requester
      );

      if (
        observationAlert.formula.value !== undefined &&
        observationAlert.formula.expression !== undefined
      ) {
        return (
          <div key={observationAlertKey}>
            <Tooltip
              content={
                <div>
                  Created by {requesterName} (
                  {moment(observationAlert.dateTime).fromNow()}) (Notify{" "}
                  {getNotifiedStringFromRecipient({
                    recipient: observationAlert.recipient,
                    requesterName,
                  })}
                  )
                </div>
              }
              position={Position.TOP}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    textDecoration: "underline",
                    textUnderlinePosition: "under",
                    paddingTop: 2,
                    marginRight: 5,
                  }}
                >
                  If {expressionTypes[observationAlert.formula.expression].text}{" "}
                  {observationAlert.formula.value.toString()}
                </p>
                <Button
                  onClick={() => {
                    // eslint-disable-next-line no-alert
                    const isRemoveConfirmed = confirm(
                      "Are you sure you want to remove observation alert ?"
                    );
                    if (isRemoveConfirmed) {
                      onObservationAlertRemove({
                        observationAlertKey,
                        observationKey,
                      });
                    }
                  }}
                  icon="remove"
                  intent={Intent.DANGER}
                  minimal
                />
              </div>
            </Tooltip>
          </div>
        );
      }
      return <></>;
    }
  );
  return <div>{renderObservationAlerts}</div>;
};
