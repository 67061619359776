import { createSelector } from "reselect";

export function getUsers(state) {
  return state.users;
}

export function getUserList(state) {
  return getUsers(state).list;
}

export function getServiceProviderList(state) {
  return getUsers(state).list.filter((user) => {
    return (
      Boolean(user.healthcareServiceRoles) &&
      Boolean(user.healthcareServiceRoles.nala) &&
      Boolean(user.healthcareServiceRoles.serviceProvider)
    );
  });
}

export function getUserFilter(state) {
  return getUsers(state).filter;
}

export function getDeletedUser(state) {
  return getUsers(state).deleted;
}

//= ====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getVisibleUsers = createSelector(
  getUserList,
  getUserFilter,
  (users, filter) => {
    switch (filter) {
      // case 'active':
      //  return users.filter(user => !user.completed);
      default:
        return users;
    }
  }
);

export const getVisibleServiceProviders = createSelector(
  getServiceProviderList,
  getUserFilter,
  (serviceProviders, filter) => {
    switch (filter) {
      // case 'active':
      //  return users.filter(user => !user.completed);
      default:
        return serviceProviders;
    }
  }
);
