import React, { useState } from "react";
import { Button, Intent, Checkbox, Tag } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { getProgramLabel } from "../../../utils";

const ProgramsPanel = ({
  isUpdatingRole,
  updateParticipatingPrograms,
  participatingPrograms,
  assignedPatients,
}) => {
  const [toggled, setToggled] = useState({});

  const onSubmit = async (event) => {
    event.preventDefault();
    const newProgramValues = {};
    Object.keys(participatingPrograms).forEach((program) => {
      newProgramValues[program] = event.target[program].checked;
    });
    await updateParticipatingPrograms(newProgramValues);
    setToggled({});
  };

  const onChange = (event) => {
    const { name } = event.target;

    if (Object.keys(toggled).includes(name))
      setToggled((prev) => {
        const { [name]: remove, ...updatedValues } = prev;
        return updatedValues;
      });
    else
      setToggled((prev) => ({
        ...prev,
        [name]: true,
      }));
  };

  const canToggleRole = (program) => {
    return assignedPatients.get(program);
  };

  return (
    <div>
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={onSubmit}
        onChange={onChange}
      >
        {Object.entries(participatingPrograms).map((program) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            key={program[0]}
          >
            <Checkbox
              large
              disabled={canToggleRole(program[0])}
              defaultChecked={program[1]}
              label={getProgramLabel(program[0])}
              name={program[0]}
            />
            {assignedPatients.get(program[0]) && (
              <Tooltip2
                content={<span>Number of your assigned patients</span>}
                placement="right"
              >
                <Tag
                  minimal
                  round
                  rightIcon="people"
                  style={{ marginBottom: 10, marginLeft: 10 }}
                >
                  {assignedPatients.get(program[0])}
                </Tag>
              </Tooltip2>
            )}
          </div>
        ))}

        <Button
          text="Save"
          intent={Intent.SUCCESS}
          type="submit"
          loading={isUpdatingRole}
          disabled={Object.keys(toggled).length === 0}
        />
      </form>
    </div>
  );
};

export default ProgramsPanel;
