export const INIT_AUTH = "auth/INIT_AUTH";

export const SIGN_IN_ERROR = "auth/SIGN_IN_ERROR";
export const SIGN_IN_SUCCESS = "auth/SIGN_IN_SUCCESS";

export const SIGN_OUT_SUCCESS = "auth/SIGN_OUT_SUCCESS";

export const UPDATE_ROLES_AND_LINKED_HEALTH_CARE_SERVICES =
  "auth/UPDATE_ROLES_AND_LINKED_HEALTH_CARE_SERVICES";

export const LOAD_PRACTITIONER_ROLE = "auth/LOAD_PRACTITIONER_ROLE";
