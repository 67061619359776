export const LOAD_COMMUNICATION_REQUESTS_SUCCESS =
  "communicationRequests/LOAD_COMMUNICATION_REQUESTS_SUCCESS";
export const UNLOAD_COMMUNICATION_REQUESTS_SUCCESS =
  "communicationRequests/UNLOAD_COMMUNICATION_REQUESTS_SUCCESS";
export const CREATE__COMMUNICATION_REQUESTS_SUCCESS =
  "communicationRequests/CREATE_COMMUNICATION_REQUESTS_SUCCESS";
export const REMOVE_COMMUNICATION_REQUESTS_SUCCESS =
  "communicationRequests/REMOVE_COMMUNICATION_REQUESTS_SUCCESS";
export const UPDATE__COMMUNICATION_REQUESTS_SUCCESS =
  "communicationRequests/UPDATE_COMMUNICATION_REQUESTS_SUCCESS";
export const LOAD_APPOINTMENT_COMMUNICATION_REQUESTS_SUCCESS =
  "communicationRequests/LOAD_APPOINTMENT_COMMUNICATION_REQUESTS_SUCCESS";
export const UNLOAD_APPOINTMENT_COMMUNICATION_REQUESTS_SUCCESS =
  "communicationRequests/UNLOAD_APPOINTMENT_COMMUNICATION_REQUESTS_SUCCESS";
