import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Navbar,
  Button,
  Alignment,
  Menu,
  Intent,
  Tag,
} from "@blueprintjs/core";
import { useWindowWidth } from "@react-hook/window-size";

import Logo from "../logo";

function getOnlineStatus() {
  return typeof navigator !== "undefined" &&
    typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;
}

function useOnlineStatus() {
  const [onlineStatus, setOnlineStatus] = useState(getOnlineStatus());

  const goOnline = () => setOnlineStatus(true);

  const goOffline = () => setOnlineStatus(false);

  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  return onlineStatus;
}

const CommunicationRequestsTag = ({ communicationRequests }) => {
  const todayTimestamp = new Date().setHours(0, 0, 0, 0);
  const todayCommunicationRequests = communicationRequests.filter(
    (communicationRequest) => {
      const communicationRequestDayTimestamp =
        communicationRequest.occurrenceDateTime.toDate().setHours(0, 0, 0, 0);
      return todayTimestamp === communicationRequestDayTimestamp;
    }
  );

  return (
    <>
      {todayCommunicationRequests.size > 0 && (
        <Tag style={{ marginLeft: 5 }} intent={Intent.PRIMARY} round>
          {todayCommunicationRequests.size}
        </Tag>
      )}
    </>
  );
};

const MobileMenu = ({
  authenticated,
  accessArray = [],
  activeHealthcareService = "",
  username = "Username",
  signOut,
  browserHistory,
  setOpen,
}) => {
  const modifiedBrowserPush = (pathname) => {
    setOpen(false);
    browserHistory.push(pathname);
  };
  return (
    <Menu large>
      {authenticated && (
        <div>
          {/* <Menu.Item
          text="Schedule"
          active={browserHistory.location.pathname === "/schedule"}
          onClick={() => modifiedBrowserPush("/schedule")}
          icon="calendar"
        />
        <Menu.Item
          text="Settings"
          active={browserHistory.location.pathname === "/settings"}
          onClick={() => modifiedBrowserPush("/settings")}
          icon="cog"
        />
        <Menu.Item
          text="Profile"
          active={browserHistory.location.pathname === "/profile"}
          onClick={() => modifiedBrowserPush("/profile")}
          icon="user"
        /> */}
          {accessArray.includes("patients") && (
            <Menu.Item
              text="Patients"
              active={browserHistory.location.pathname === "/patients"}
              onClick={() => browserHistory.push("/patients")}
              icon="person"
            />
          )}

          {accessArray.includes("dispenseRequests") && (
            <Menu.Item
              text="Dispense Requests"
              active={browserHistory.location.pathname === "/dispenseRequests"}
              onClick={() => modifiedBrowserPush("/dispenseRequests")}
              icon="prescription"
            />
          )}

          {(accessArray.includes("medicationRequests") ||
            accessArray.includes("inbox")) && <Menu.Divider />}

          {accessArray.includes("invites") && (
            <Menu.Item
              text="Promos"
              active={browserHistory.location.pathname === "/invites"}
              onClick={() => modifiedBrowserPush("/invites")}
              icon="barcode"
            />
          )}

          {accessArray.includes("inbox") && (
            <Menu.Item
              text="Inbox"
              active={browserHistory.location.pathname === "/inbox"}
              onClick={() => modifiedBrowserPush("/inbox")}
              icon="inbox"
            />
          )}

          {accessArray.includes("availability") && (
            <Menu.Item
              text="Availability"
              active={browserHistory.location.pathname === "/availability"}
              onClick={() => modifiedBrowserPush("/availability")}
              icon="calendar"
            />
          )}

          {accessArray.includes("newPatients") && (
            <Menu.Item
              text="New Patients"
              active={browserHistory.location.pathname === "/newPatients"}
              onClick={() => modifiedBrowserPush("/newPatients")}
              icon="new-person"
            />
          )}

          {accessArray.includes("settings") && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Menu.Item
                text={username}
                active={browserHistory.location.pathname === "/settings"}
                onClick={() => modifiedBrowserPush("/settings")}
                icon="cog"
              />
              <Tag style={{ margin: "10px 10px" }} intent={Intent.PRIMARY}>
                {activeHealthcareService}
              </Tag>
            </div>
          )}

          <Menu.Divider />
          {authenticated && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Menu.Item
                text="Logout"
                onClick={() => {
                  signOut();
                  setOpen(false);
                }}
                icon="log-out"
              />
            </div>
          )}
          {!authenticated && <div className="main-nav-header-form" />}
        </div>
      )}
    </Menu>
  );
};

const DesktopMenu = ({
  authenticated,
  accessArray = [],
  activeHealthcareService = "",
  username = "Username",
  signOut,
  browserHistory,
  isOnline,
  communicationRequests,
}) => {
  return (
    <div>
      <Navbar.Group>
        {/* <Navbar.Divider />
        <Button
          className="bp3-minimal"
          text="Schedule"
          active={browserHistory.location.pathname === "/schedule"}
          onClick={() => browserHistory.push("/schedule")}
          icon="calendar"
        />
        <Button
          className="bp3-minimal"
          text="Settings"
          active={browserHistory.location.pathname === "/settings"}
          onClick={() => browserHistory.push("/settings")}
          icon="cog"
        />
        <Button
          className="bp3-minimal"
          text="Profile"
          active={browserHistory.location.pathname === "/profile"}
          onClick={() => browserHistory.push("/profile")}
          icon="user"
        /> */}

        {accessArray.includes("patients") && (
          <Button
            className="bp3-minimal"
            active={browserHistory.location.pathname === "/patients"}
            onClick={() => browserHistory.push("/patients")}
            icon="person"
          >
            Patients{" "}
            {browserHistory.location.pathname !== "/patients" && (
              <CommunicationRequestsTag
                communicationRequests={communicationRequests}
              />
            )}
          </Button>
        )}

        {accessArray.includes("dispenseRequests") && (
          <Button
            className="bp3-minimal"
            text="Dispense Requests"
            active={browserHistory.location.pathname === "/dispenseRequests"}
            onClick={() => browserHistory.push("/dispenseRequests")}
            icon="prescription"
          />
        )}

        {(accessArray.includes("dispenseRequests") ||
          accessArray.includes("inbox")) && <Navbar.Divider />}

        {accessArray.includes("invites") && (
          <Button
            className="bp3-minimal"
            text="Promos"
            active={browserHistory.location.pathname === "/invites"}
            onClick={() => browserHistory.push("/invites")}
            icon="barcode"
          />
        )}

        {accessArray.includes("inbox") && (
          <Button
            className="bp3-minimal"
            text="Inbox"
            active={browserHistory.location.pathname === "/inbox"}
            onClick={() => browserHistory.push("/inbox")}
            icon="inbox"
          />
        )}

        {accessArray.includes("availability") && (
          <Button
            className="bp3-minimal"
            text="Availability"
            active={browserHistory.location.pathname === "/availability"}
            onClick={() => browserHistory.push("/availability")}
            icon="calendar"
          />
        )}

        {accessArray.includes("newPatients") && (
          <Button
            className="bp3-minimal"
            text="New Patients"
            active={browserHistory.location.pathname === "/newPatients"}
            onClick={() => browserHistory.push("/newPatients")}
            icon="new-person"
          />
        )}
      </Navbar.Group>
      {authenticated && (
        <Navbar.Group align={Alignment.RIGHT}>
          {isOnline ? (
            <Button
              icon="symbol-circle"
              intent={Intent.SUCCESS}
              onClick={undefined}
              disabled
              minimal
            />
          ) : (
            <Button
              icon="offline"
              intent={Intent.DANGER}
              onClick={undefined}
              disabled
              minimal
            />
          )}
          <Navbar.Heading
            className="bp3-text-large bp3-text-muted"
            style={{
              fontWeight:
                browserHistory.location.pathname === "/settings" ? 600 : 400,
              cursor: "pointer",
            }}
            onClick={() => {
              if (accessArray.includes("settings"))
                browserHistory.push("/settings");
            }}
          >
            {username}
            {activeHealthcareService && (
              <Tag style={{ margin: "10px 10px" }} intent={Intent.PRIMARY}>
                {activeHealthcareService}
              </Tag>
            )}
          </Navbar.Heading>
          <Button icon="log-out" onClick={signOut} minimal />
        </Navbar.Group>
      )}
    </div>
  );
};

const TopBar = ({
  authenticated,
  accessArray = [],
  activeHealthcareService = "",
  username = "Username",
  signOut,
  browserHistory,
  communicationRequests,
}) => {
  const [open, setOpen] = useState(false);

  const windowWidth = useWindowWidth();
  const isOnline = useOnlineStatus();

  return (
    <div>
      <Navbar className="top-bar bp3-elevation-2" fixedToTop={false}>
        <Navbar.Group>
          <Navbar.Heading>
            <Logo width={50} />
          </Navbar.Heading>
        </Navbar.Group>
        {windowWidth < 1350 ? (
          <Navbar.Group align={Alignment.RIGHT}>
            {isOnline ? (
              <Button
                icon="symbol-circle"
                intent={Intent.SUCCESS}
                onClick={undefined}
                disabled
                minimal
              />
            ) : (
              <Button
                icon="offline"
                intent={Intent.DANGER}
                onClick={undefined}
                disabled
                minimal
              />
            )}
            <Button icon="menu" onClick={() => setOpen(!open)} minimal />
          </Navbar.Group>
        ) : (
          <DesktopMenu
            authenticated={authenticated}
            accessArray={accessArray}
            activeHealthcareService={activeHealthcareService}
            username={username}
            signOut={signOut}
            browserHistory={browserHistory}
            setOpen={setOpen}
            isOnline={isOnline}
            communicationRequests={communicationRequests}
          />
        )}
      </Navbar>
      {open && (
        <MobileMenu
          authenticated={authenticated}
          accessArray={accessArray}
          activeHealthcareService={activeHealthcareService}
          username={username}
          signOut={signOut}
          browserHistory={browserHistory}
          setOpen={setOpen}
          communicationRequests={communicationRequests}
        />
      )}
    </div>
  );
};

TopBar.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  activeHealthcareService: PropTypes.string,
  username: PropTypes.string,
  signOut: PropTypes.func.isRequired,
};

export default TopBar;
