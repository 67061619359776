import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LogRocket from "logrocket";
import { createSelector } from "reselect";

import { authActions, getAuth } from "../../auth";
import { getCommunicationRequests } from "../../communicationRequests";

import RequireAuthRoute from "../components/require-auth-route";
import RequireUnauthRoute from "../components/require-unauth-route";
import Sidebar from "../components/sidebar";
import TopBar from "../components/TopBar";

import SignInPage from "../pages/sign-in";
import Main from "../pages/main";
import InvitesPage from "../pages/invites";
// import ProfilePage from '../pages/profile';
import SessionPage from "../pages/session";
import PatientsPage from "../pages/patients";
import CallPage from "../pages/call/pages/App";
import InboxPage from "../pages/inbox";
import BookingsPage from "../pages/bookings";
import SettingsPage from "../pages/settings";
import dispenseRequests from "../pages/dispenseRequests";
import NewPatientsPage from "../pages/newPatients";

LogRocket.init("dguwv8/nala-blue");

const App = ({
  authenticated,
  accessArray = [],
  username,
  login,
  signOut,
  // pathname,
  history,
  reset,
  signup,
  activeHealthcareService,
  communicationRequests,
}) => {
  const routesComponents = {
    invites: InvitesPage,
    session: SessionPage,
    patients: PatientsPage,
    call: CallPage,
    inbox: InboxPage,
    availability: BookingsPage,
    settings: SettingsPage,
    dispenseRequests,
    newPatients: NewPatientsPage,
  };
  const accessRoutes = accessArray.map((item) => {
    if (item === "session") {
      return (
        <RequireAuthRoute
          key={item}
          authenticated={authenticated}
          exact
          path="/sessions/:userID/:sessionID"
          component={routesComponents[item]}
        />
      );
    }
    return routesComponents[item] ? (
      <RequireAuthRoute
        key={item}
        authenticated={authenticated}
        exact
        path={`/${item}`}
        component={routesComponents[item]}
      />
    ) : null;
  });

  if (authenticated) {
    LogRocket.identify(username);
  }
  return (
    <div className="bp3-dark app-container">
      {authenticated ? (
        <div className="app-wrapper">
          <TopBar
            authenticated={authenticated}
            accessArray={accessArray}
            activeHealthcareService={activeHealthcareService}
            username={username}
            signOut={signOut}
            browserHistory={history}
            communicationRequests={communicationRequests}
          />
          <div className="app">
            <main className="main-body">
              <RequireAuthRoute
                authenticated={authenticated}
                exact
                path="/"
                component={Main}
              />
              {accessRoutes}
              <RequireUnauthRoute
                authenticated={authenticated}
                path="/login"
                component={SignInPage}
              />
            </main>
          </div>
        </div>
      ) : (
        <div className="app">
          <Sidebar login={login} reset={reset} signup={signup} />
        </div>
      )}
    </div>
  );
};

App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  accessArray: PropTypes.arrayOf(PropTypes.string),
  login: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

//= ====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = createSelector(
  getAuth,
  getCommunicationRequests,
  (auth, communicationRequests) => ({ ...auth, communicationRequests })
);

const mapDispatchToProps = {
  login: authActions.login,
  reset: authActions.reset,
  signup: authActions.signup,
  signOut: authActions.signOut,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
