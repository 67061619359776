import { functions } from "../firebase";

const createTask = async ({ task }) => {
  const createTaskCloudFunction = functions.httpsCallable("createTask");
  try {
    const result = await createTaskCloudFunction({ task });
    return result.data;
  } catch (err) {
    return { created: false };
  }
};

const markTaskAsDone = () => {};

export default {
  createTask,
  markTaskAsDone,
};
