import React from "react";
import { Button, Checkbox, InputGroup, Intent } from "@blueprintjs/core";

const DispenseRequestsFilter = ({
  isSearchable,
  setSearchTerm,
  searchTerm,
  setTypeFilter,
  listOfBranches,
  setBranchFilter,
  showOnlyWasfatyPrescriptions,
  setShowOnlyWasfatyPrescriptions,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {isSearchable && (
          <InputGroup
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            value={searchTerm}
            intent={Intent.PRIMARY}
            leftIcon="filter"
            placeholder="Filter by patient name or phone"
            rightElement={
              <Button
                onClick={() => {
                  setSearchTerm("");
                }}
              >
                Clear
              </Button>
            }
            style={{
              width: 400,
              marginBottom: 30,
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: 15,
        }}
      >
        <p style={{ marginTop: 3 }}>Type</p>
        <div style={{ marginLeft: 8 }} className="bp3-html-select .modifier">
          <select
            onChange={(e) => {
              setTypeFilter(e.target.value);
            }}
          >
            <option value="all" selected>
              All
            </option>
            <option value="storePickup">Store Pickup</option>
            <option value="delivery">Delivery</option>
          </select>
          <span className="bp3-icon bp3-icon-double-caret-vertical" />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: 15,
        }}
      >
        <p style={{ marginTop: 3 }}>Branch</p>
        <div style={{ marginLeft: 8 }} className="bp3-html-select .modifier">
          <select
            onChange={(e) => {
              setBranchFilter(e.target.value);
            }}
          >
            <option value="all" selected>
              All
            </option>
            {listOfBranches.map((branchName) => {
              return <option value={branchName}>{branchName}</option>;
            })}
          </select>
          <span className="bp3-icon bp3-icon-double-caret-vertical" />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: 15,
        }}
      >
        <p style={{ marginTop: 3 }}>Only Wasfaty</p>
        <div
          style={{ marginLeft: 8, marginTop: 4 }}
          className="bp3-html-select .modifier"
        >
          <Checkbox
            defaultChecked={showOnlyWasfatyPrescriptions}
            onChange={(e) => {
              setShowOnlyWasfatyPrescriptions(e.target.checked);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DispenseRequestsFilter;
