export const CREATE_INVITE_ERROR = "CREATE_INVITE_ERROR";
export const CREATE_INVITE_SUCCESS = "CREATE_INVITE_SUCCESS";

export const REMOVE_INVITE_ERROR = "REMOVE_INVITE_ERROR";
export const REMOVE_INVITE_SUCCESS = "REMOVE_INVITE_SUCCESS";

export const UNDELETE_INVITE_ERROR = "UNDELETE_INVITE_ERROR";

export const UPDATE_INVITE_ERROR = "UPDATE_INVITE_ERROR";
export const UPDATE_INVITE_SUCCESS = "UPDATE_INVITE_SUCCESS";

export const LOAD_INVITES_SUCCESS = "LOAD_INVITES_SUCCESS";
export const LOAD_INVITES_ERROR = "LOAD_INVITES_ERROR";

export const UNLOAD_INVITES_SUCCESS = "UNLOAD_INVITES_SUCCESS";

export const CLEAR_DRAFT_INVITE = "CLEAR_DRAFT_INVITE";
export const START_EDIT = "START_EDIT";
export const START_NEW = "START_NEW";
export const TOGGLE_ACTIVE = "TOGGLE_ACTIVE";
