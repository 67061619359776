/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-await-in-loop */
import { Map } from "immutable";
import { conditionsActions } from "../conditions";
import { specialtiesActions } from "../specialties";
import { observationTypesActions } from "../observationTypes";

import {
  SELECT_ACCOUNT,
  SELECT_PROFILE,
  TOGGLE_EDIT,
  END_SESSION,
  UPDATE_INBOX,
  SELECT_INBOX,
  SET_LOADED,
  CLEAR_ACTIVE_ACCOUNT_AND_SESSION,
  LOAD_LAB_TESTS,
  SET_WEEK_AVG_RESPONSE_TIME,
} from "./action-types";

import {
  firebaseDb,
  storage,
  servervalue,
  firestore,
  firestoreTimestamp,
} from "../firebase";
import Notification from "../views/components/Notification";
import { practitionerActions } from "../practitioners";
import { medicationsActions } from "../medications";
import { tasksActions } from "../tasks";
import { patientActions } from "../patients";
import api from "./api";
import { communicationRequestsActions } from "../communicationRequests";
import { observationAlertsActions } from "../observationAlerts";
import {
  getLastQuestionKey,
  getPractitionerTitle,
  getDoctorInfo,
  buildPrescription,
} from "./sendActionHelpers";

const moment = require("moment");

export { goOnline, goOffline } from "./serviceProviderActions";

function clearAccountListener() {
  return (dispatch, getState) => {
    const { selectedAccount } = getState().inbox;
    const { activeHealthcareService } = getState().auth;

    if (Map.isMap(selectedAccount)) {
      const previousSelectedAccountKey = selectedAccount.get("key")
        ? selectedAccount.get("key")
        : undefined;

      if (previousSelectedAccountKey !== undefined) {
        firebaseDb.ref(`/asknala/${previousSelectedAccountKey}`).off();
        firebaseDb
          .ref(
            `healthcareServices/${activeHealthcareService}/profiles/${previousSelectedAccountKey}`
          )
          .off();
      }
    }
  };
}

export function clearActiveAccountAndSession() {
  return (dispatch, getState) => {
    clearAccountListener()(dispatch, getState);
    dispatch({
      type: CLEAR_ACTIVE_ACCOUNT_AND_SESSION,
    });
  };
}

export function removeSessionFromInbox({ accountID, inboxID, sessionID }) {
  return async (dispatch, getState) => {
    return firebaseDb
      .ref(`${inboxID}/activeSessions/${accountID}+${sessionID}`)
      .remove()
      .then(() => {
        clearActiveAccountAndSession()(dispatch, getState);
        firebaseDb.ref(`/asknala/${accountID}`).off();
      });
  };
}

export function hideSession() {
  return async (dispatch, getState) => {
    const { selectedSession, activeInbox, selectedAccount } = getState().inbox;
    removeSessionFromInbox({
      accountID: selectedAccount.get("key"),
      inboxID: activeInbox,
      sessionID: selectedSession,
    })(dispatch, getState);
  };
}

export function selectSession({ accountKey, sessionKey }) {
  return async (dispatch, getState) => {
    const { activeInbox } = getState().inbox;
    const { activeHealthcareService } = getState().auth;

    clearAccountListener()(dispatch, getState);
    firebaseDb.ref(`/asknala/${accountKey}`).on("value", async (snapshot) => {
      const asknalaObject = snapshot.val();
      if (
        asknalaObject.deletedSessions &&
        asknalaObject.deletedSessions[sessionKey]
      ) {
        Notification.Success("Conversation is deleted by the user");
        await removeSessionFromInbox({
          inboxID: activeInbox,
          sessionID: sessionKey,
          accountID: accountKey,
        })(dispatch, getState);
        return;
      }
      dispatch({
        type: SELECT_ACCOUNT,
        payload: {
          account: { ...asknalaObject, key: snapshot.key },
          sessionKey,
        },
      });
    });

    firebaseDb
      .ref(
        `healthcareServices/${activeHealthcareService}/profiles/${accountKey}`
      )
      .on("value", (snapshot) => {
        dispatch({
          type: SELECT_PROFILE,
          payload: {
            ...snapshot.val(),
            key: snapshot.key,
          },
        });
      });
  };
}

export function createPatientReminder({
  reminderType,
  reminderTime,
  note,
  payload = {},
}) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState().inbox;
    const patientID = selectedAccount.get("key");
    await communicationRequestsActions.createPatientReminder({
      reminderTime,
      reminderType,
      note,
      patientID,
      payload,
    })(dispatch, getState);
  };
}

export function warnOnSlotUnavailability(
  activeHealthcareService,
  practitionerRole,
  speciality
) {
  const daysToGetSlots = 7;
  const dateStart = moment().toDate();
  const dateEnd = moment().add(daysToGetSlots, "days").toDate();

  firestore
    .collection("bookingSlots")
    .where("healthcareServiceID", "==", activeHealthcareService)
    .where("practitionerRoleID", "==", practitionerRole)
    .where("speciality", "==", speciality)
    .where("isAvailable", "==", true)
    .where("startDate", ">", dateStart)
    .where("startDate", "<", dateEnd)
    .get()
    .then((snapshot) => {
      let slotAvailable = false;
      snapshot.forEach((doc) => {
        if (!doc.data().appointmentID) {
          slotAvailable = true;
        }
      });
      if (!slotAvailable) {
        Notification.Error(
          "No appointment slots available this week for booking"
        );
      }
    })
    .catch(() => {
      // console.log("Error checking slot availability");
    });
}

export function sendAction(
  inputsRecommendations,
  inputsArray,
  action,
  selectedSession = null,
  selectedProfile = null,
  source = "session"
) {
  return async (dispatch, getState) => {
    // const answer = getState().accounts.input;
    const isPrescription = Boolean(action) && Boolean(action.prescription);
    const sendActionWithoutSession =
      source === "patientProfile" && isPrescription;
    const account = selectedProfile || getState().inbox.selectedAccount.toJS();
    const userID = account.key;
    const { activeHealthcareService, practitionerRole } = getState().auth;

    if (sendActionWithoutSession) {
      if (isPrescription) {
        await buildPrescription({
          session: null,
          sessionKey: null,
          userID,
          action,
          practitionerRole,
        })(dispatch, getState);

        await patientActions.setNewPrescription({ userID })(dispatch, getState);

        await patientActions.sendNotificationToUser({
          notificationTitle: "",
          notificationBody: "قام الطبيب بإصدار وصفة طبية لك",
          patientID: userID,
          data: {
            action: "prescriptions",
          },
        })();
      }
      return Promise.resolve();
    }

    let answer = inputsArray ? inputsArray[0] : "";

    const session =
      selectedSession ||
      getState().inbox?.selectedAccount?.toJS().sessions[
        getState().inbox?.selectedSession
      ];
    session.sessionKey = getState().inbox?.selectedSession;
    const actionData = action;

    const { questions } = session;

    const sessionKey = selectedSession ? session.key : session.sessionKey;
    const respondedBy = getState().auth.id;

    const { flags, userInput } =
      Boolean(inputsRecommendations) && Boolean(inputsRecommendations[0])
        ? inputsRecommendations[0].userRecommendations
        : {};

    const lastQuestionKey = await getLastQuestionKey({
      questions,
    });
    if (isPrescription) {
      const prescriptionRef = await buildPrescription({
        session,
        sessionKey,
        userID,
        action,
        practitionerRole,
      })(dispatch, getState);
      answer = `بناء على حالتك، قام الطبيب بإصدار وصفة طبية لك`;

      actionData.prescription = {
        prescriptionRef,
      };
    }

    if (Boolean(action) && Boolean(action.videoCall)) {
      const doctor = await getDoctorInfo()(dispatch, getState);

      warnOnSlotUnavailability(
        activeHealthcareService,
        practitionerRole.get("key"),
        session.speciality
      );

      const appointment = {
        created: firestoreTimestamp.fromDate(new Date()),
        description: action.videoCall.videoCallNote,
        isVideoCall: true,
        patientID: session.patientID,
        payload: { isProposed: true },
        speciality: session.speciality,
        status: "proposed",
        serviceProvider: {
          healthcareServiceID: activeHealthcareService,
          practitionerID: respondedBy,
          practitionerRoleID: practitionerRole.get("key"),
          profile: doctor,
        },
      };
      const result = await firestore
        .collection("appointments")
        .add(appointment);

      actionData.videoCall = {
        profile: doctor,
        practitionerID: respondedBy,
        practitionerRoleID: practitionerRole.get("key"),
        speciality: session.speciality,
        healthcareServiceID: activeHealthcareService,
        appointmentID: result.id,
      };

      // answer is no longer used in Native
      answer = "";
    }

    if (Boolean(action) && Boolean(action.labTestRequest)) {
      // add the service request to user's profile
      // get the service request ID and add it to the session's action
      const doctor = await getDoctorInfo()(dispatch, getState);

      const serviceRequestKey = await api.addServiceRequestToProfile(userID, {
        ...action.labTestRequest,
        requester: respondedBy,
        recipient: {
          healthcareService: activeHealthcareService,
          practitionerRole: practitionerRole.toJSON().key,
          userId: userID,
          practitionerId: respondedBy,
          patientId: session.patientID, // might be secondary profile id
        },
        serviceProvider: doctor,
      });

      let labTestsNamesString = "";
      action.labTestRequest.labTests.forEach((labTest, index) => {
        const labTestName = labTest.labTest;
        labTestsNamesString +=
          index === 0 ? `${labTestName}` : ` - ${labTestName}`;
      });

      await tasksActions.createTask({
        type: "labTestRequest",
        userKey: userID,
        payload: {
          serviceRequestKey,
        },
        actionText: {
          ar: `ارفع نتائج الفحص الخاص بـ (${labTestsNamesString})`,
          en: `Upload lab test results for (${labTestsNamesString})`,
        },
      })(dispatch, getState);

      actionData.labTestRequest = {
        serviceRequestRef: serviceRequestKey,
      };

      answer = `لقد قام ال${getPractitionerTitle({
        session,
      })} بطلب رفع نتائج فحص مختبر`;
    }

    const updates = {};
    const sessionPath = `/asknala/${userID}/sessions/${sessionKey}`;
    const questionPath = `${sessionPath}/questions/${lastQuestionKey}`;
    // transactional db call
    updates[`${sessionPath}/unread`] = true;
    updates[`${questionPath}/answerReady`] = servervalue.TIMESTAMP;
    updates[`${questionPath}/answer`] = answer;
    updates[`${questionPath}/respondedBy`] = respondedBy;
    updates[`${questionPath}/action`] = actionData || null;
    if (flags) updates[`${questionPath}/flags`] = flags;
    if (userInput) updates[`${questionPath}/userInput`] = userInput;

    // update questionQue
    if (inputsArray) {
      inputsArray.slice(1).forEach((answerElement) => {
        const { key } = firebaseDb
          .ref("asknala")
          .child(userID)
          .child("sessions")
          .child(sessionKey)
          .child("questionsQueue")
          .push();
        // FIXME: use `push().ref.toString() and use it in the update so you DRY
        // @see https://stackoverflow.com/questions/25649204/firebase-get-full-path-of-reference-url-in-datasnapshot-javascript-api
        updates[
          `/asknala/${userID}/sessions/${sessionKey}/questionsQueue/${key}`
        ] = {
          answer: answerElement,
          respondedBy,
          addedToQue: servervalue.TIMESTAMP,
        };
      });
    }

    return Promise.resolve(
      await firebaseDb
        .ref()
        .update(updates)
        .then(() => {
          clearActiveAccountAndSession()(dispatch, getState);
        })
        .catch((e) => {
          Notification.Error(e.message);
        })
    );
  };
}

/**
 * Returns download url promise
 * @param {String} fullPath To be resolved into a download url
 */
export function storagePathResolver(fullPath) {
  return () => {
    const metadataPromise = storage.ref(fullPath).getMetadata();
    const downloadUrlPromise = storage.ref(fullPath).getDownloadURL();
    return { metadataPromise, downloadUrlPromise };
  };
}

export function toggleEdit(message) {
  return (dispatch) => {
    const action = {
      type: TOGGLE_EDIT,
      payload: message,
    };
    dispatch(action);
  };
}

// TODO: remove end session and wire the hole thing to sendAnswer
export function endSession(values) {
  return (dispatch, getState) => {
    const { selectedAccount } = getState().inbox;
    const action = {
      type: END_SESSION,
    };

    const userID = selectedAccount.key;
    const sessionKey = selectedAccount.activeSession;
    const questionKey = selectedAccount.activeQuestion;
    const respondedBy = getState().auth.id;

    firebaseDb
      .ref("asknala")
      .child(userID)
      .child("sessions")
      .child(sessionKey)
      .child("questions")
      .child(questionKey)
      .update({
        answerReady: servervalue.TIMESTAMP,
        respondedBy,
        isEndingSession: true,
      });

    firebaseDb
      .ref("asknala")
      .child(userID)
      .child("sessions")
      .child(sessionKey)
      .update({
        ended: servervalue.TIMESTAMP,
        endedBy: respondedBy,
        ...values,
      })
      .catch(() => {
        // alert("unable to end session", er);
      })
      .then(() => {
        firebaseDb.ref("asknala").child(userID).update({
          activeQuestion: null,
          activeSession: null,
        });
      })
      .then(() => {
        dispatch(action);
      })
      .catch(() => {
        // alert("unable to end session", er);
      });
  };
}

export function selectInbox(inboxKey) {
  return async (dispatch) => {
    dispatch({
      type: SELECT_INBOX,
      payload: {
        inboxKey,
      },
    });
  };
}

function generateInboxes() {
  return async (dispatch, getState) => {
    const availableInboxes = [];
    const userSpecialties = [];
    const userPractitionerRoles = [];

    const { id, activeHealthcareService } = getState().auth;

    if (!activeHealthcareService) {
      return availableInboxes;
    }

    const healthcareServicePractitionerRoles = (
      await firebaseDb
        .ref(`healthcareServices/${activeHealthcareService}/practitionerRoles`)
        .once("value")
    ).val();

    // collecting user practitioner roles and speciality
    if (healthcareServicePractitionerRoles) {
      await Object.values(healthcareServicePractitionerRoles).forEach(
        (practitionerRoleObject, index) => {
          const practitionerRoleKey = Object.keys(
            healthcareServicePractitionerRoles
          )[index];

          if (id === practitionerRoleObject.practitionerKey) {
            userPractitionerRoles.push(practitionerRoleKey);

            if (practitionerRoleObject.speciality) {
              userSpecialties.push(practitionerRoleObject.speciality);
            }
          }
        }
      );
    }

    // get user speciality inboxes
    await userSpecialties.forEach((speciality) => {
      availableInboxes.push({
        inboxKey: `inboxes/${activeHealthcareService}+speciality:${speciality}`,
        inboxName: speciality,
      });
    });

    // get user inbox
    await userPractitionerRoles.forEach((practitionerRoleKey) => {
      availableInboxes.push({
        inboxKey: `inboxes/${activeHealthcareService}+practitionerRole:${practitionerRoleKey}`,
        inboxName: `personal`,
      });
    });

    availableInboxes.push({
      inboxKey: `inboxes/${activeHealthcareService}`,
      inboxName: activeHealthcareService,
    });

    return availableInboxes;
  };
}

function clearInboxListeners({ inboxes }) {
  return () => {
    Object.keys(inboxes).forEach((inboxKey) => {
      firebaseDb.ref(inboxKey).off();
    });
  };
}

function registerInboxListeners({ inboxes }) {
  return async (dispatch, getState) => {
    let defaultInboxKey;

    for (let i = 0; i < inboxes.length; i += 1) {
      if (i === 0) {
        defaultInboxKey = inboxes[i].inboxKey;
      }
      await firebaseDb.ref(inboxes[i].inboxKey).on("value", (snapshot) => {
        const inboxObject = snapshot.val();
        if (inboxObject) {
          const getInboxName = () => {
            switch (inboxObject.inboxType) {
              case "PractitionerRole":
                return "Practitioner";
              case "speciality":
                return inboxObject.speciality
                  .replace("-", " ")
                  .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
              case "HealthcareService":
                return (
                  inboxObject.healthcareService[0].toUpperCase() +
                  inboxObject.healthcareService.substring(1)
                );
              default:
                return "Unknown";
            }
          };

          dispatch({
            type: UPDATE_INBOX,
            payload: {
              inboxKey: `inboxes/${snapshot.key}`,
              inboxName: getInboxName(),
              inboxObject,
            },
          });
        }
      });
    }
    selectInbox(defaultInboxKey)(dispatch, getState);
    dispatch({ type: SET_LOADED });
  };
}

export function loadLabTests() {
  return async (dispatch) => {
    const labTestsData = await api.getLabTests();
    return dispatch({
      type: LOAD_LAB_TESTS,
      payload: {
        labTests: labTestsData,
      },
    });
  };
}

export function loadAverageResponseTime() {
  return async (dispatch, getState) => {
    const { id } = getState().auth;
    const lastSundayTimestamp = moment()
      .startOf("isoWeek")
      .subtract(1, "day")
      .valueOf();
    const answersThisWeek = [];

    const snapshot = await firestore
      .collection("completedQuestions")
      .where("respondedBy", "==", id)
      .where("answerTime", ">", lastSundayTimestamp)
      .get();

    snapshot.forEach((doc) => {
      const completedQuestion = doc.data();
      answersThisWeek.push(completedQuestion);
    });

    if (answersThisWeek.length === 0) {
      return;
    }

    const oneWeekSumResponseTime = answersThisWeek.reduce(
      (sum, completedQuestion) => sum + completedQuestion.responseDuration,
      0
    );
    const oneWeekAverageResponseTime =
      oneWeekSumResponseTime / answersThisWeek.length;

    dispatch({
      type: SET_WEEK_AVG_RESPONSE_TIME,
      payload: {
        practitionerWeeklyAverageResponseTime: oneWeekAverageResponseTime,
      },
    });
  };
}

export function loadData() {
  return async (dispatch, getState) => {
    observationTypesActions.loadObservationTypes()(dispatch, getState);
    conditionsActions.loadConditions()(dispatch);
    specialtiesActions.loadSpecialties()(dispatch);
    practitionerActions.loadPractitioners()(dispatch, getState);
    medicationsActions.loadMedications()(dispatch);
    loadLabTests()(dispatch, getState);
    const inboxes = await generateInboxes()(dispatch, getState);
    loadAverageResponseTime()(dispatch, getState);
    registerInboxListeners({ inboxes })(dispatch, getState);
  };
}

export function unloadData() {
  return async (dispatch, getState) => {
    conditionsActions.unloadConditions();
    specialtiesActions.unloadSpecialties();
    const { inboxes } = getState().inbox;
    clearActiveAccountAndSession()(dispatch, getState);
    if (Map.isMap(inboxes)) {
      clearInboxListeners({ inboxes: inboxes.toJS() })(dispatch, getState);
    }
  };
}

export function createObservationAlert({
  note,
  reminderType,
  observation,
  formula,
  dateTime,
  alertEmergencyContact,
}) {
  return (dispatch, getState) => {
    const patientID = getState().inbox.selectedAccount.get("key");
    return observationAlertsActions.createObservationAlert({
      note,
      reminderType,
      observation,
      formula,
      dateTime,
      patientID,
      alertEmergencyContact,
    })(dispatch, getState);
  };
}

export function removeObservationAlert({
  observationAlertKey,
  patientKey,
  observationKey,
}) {
  return async (dispatch, getState) => {
    await observationAlertsActions.removeObservationAlert({
      observationAlertKey,
      patientKey,
      observationKey,
    })(dispatch, getState);
  };
}

export function getPrescription({ prescriptionRef }) {
  return async () => {
    const prescription = (
      await api.getPrescription({ prescriptionRef })
    ).data();

    const medicationRequests = await api.getMedicationRequestsFromPrescription({
      prescriptionID: prescriptionRef,
    });

    return { prescription, medicationRequests };
  };
}

export function getMedicationRequestForPatient({ userID }) {
  return async () => {
    let medicationRequests = await api.getMedicationRequestForPatient({
      userID,
    });

    medicationRequests = medicationRequests.sort(
      (medicationRequest1, medicationRequest2) => {
        return medicationRequest2.authoredOn - medicationRequest1.authoredOn;
      }
    );

    return medicationRequests;
  };
}
