import React from "react";
import { Tag, Intent } from "@blueprintjs/core";

import RecommendedAnswers from "./RecommendedAnswers";

const Recommendations = ({
  recommendations = RecommendedAnswers,
  updateInput,
  inputRecommendation,
}) => (
  <div
    // className="recommendations-container"
    style={{
      flex: 3,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    }}
  >
    {recommendations.map((recommendation) => (
      <Tag
        // className="recommendation-item"
        style={{
          marginRight: "2px",
          marginBottom: "2px",
          flexShrink: 0,
          fontSize: 12,
        }}
        key={JSON.stringify({ recommendation })}
        large
        round
        interactive
        minimal
        intent={
          Boolean(inputRecommendation) &&
          inputRecommendation.name === recommendation.name
            ? Intent.PRIMARY
            : Intent.NONE
        }
        onClick={() => {
          updateInput(recommendation.title, 0, recommendation);
        }}
      >
        {recommendation.title}
      </Tag>
    ))}
  </div>
);

export default Recommendations;
