import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import {
  getEditFlag,
  getLoaded,
  accountsActions,
  getPractitioners,
  getInboxes,
  getActiveInbox,
  getLabTests,
  getPractitionerWeeklyAverageResponseTime,
  getObservationTypes,
  // getSelectedSession
} from "../../../inbox";
import InboxRespondBox from "../../components/InboxRespondBox";
import { patientActions } from "../../../patients";

import {
  getSelectedSessionObject,
  getAllSelectedSessionPreviousSessions,
} from "../../../inbox/selectors";
import {
  getActiveHealthcareService,
  getPractitionerSpeciality,
} from "../../../auth";
import { getConditionsList } from "../../../conditions";
import { getSpecialtiesList } from "../../../specialties";
import { getMedications, getIsMedicationsLoaded } from "../../../medications";
import InboxTabs from "../../components/InboxTabs";
import SessionCards from "../../components/InboxRespondBox/SessionCards";

export class InboxPage extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { loadData, activeHealthcareService } = this.props;
    if (activeHealthcareService !== undefined) {
      loadData();
    }
  }

  componentWillUnmount() {
    const { unloadData } = this.props;
    unloadData();
  }

  render() {
    // console.log(typeof (this.props.conditions))
    // console.log(this.props.conditions)
    const {
      loaded,
      selectedSessionPreviousSession,
      selectedSession,
      selectSession,
      edit,
      storagePathResolver,
      conditions,
      specialties,
      endSession,
      toggleEdit,
      sendAction,
      practitioners,
      inboxes,
      selectInbox,
      activeInbox,
      clearActiveAccountAndSession,
      activeHealthcareService,
      practitionerSpeciality,
      hideSession,
      labTests,
      createPatientReminder,
      createObservationAlert,
      practitionerWeeklyAverageResponseTime,
      loadAverageResponseTime,
      observationTypes,
      medications,
      addObservation,
    } = this.props;

    const activeSessions = () => {
      if (activeInbox && activeInbox.inboxObject) {
        if (activeInbox.inboxObject.activeSessions) {
          return activeInbox.inboxObject.activeSessions;
        }
        return null; // no active sessions inside inbox
      }
      return undefined; // inbox is loading
    };

    if (!activeHealthcareService) {
      return <div />;
    }

    return (
      <div className="respond-page-container scrollable">
        <>
          {selectedSession === undefined && (
            <>
              <div className="respond-box scrollable">
                <SessionCards
                  activeSessions={activeSessions()}
                  loaded={loaded}
                  selectSession={selectSession}
                  loadAverageResponseTime={loadAverageResponseTime}
                  practitionerWeeklyAverageResponseTime={
                    practitionerWeeklyAverageResponseTime
                  }
                  inboxType={
                    activeInbox && activeInbox.inboxObject
                      ? activeInbox.inboxObject.inboxType
                      : undefined
                  }
                />
              </div>
              {loaded && (
                <InboxTabs
                  inboxes={inboxes}
                  selectInbox={selectInbox}
                  activeInbox={activeInbox}
                />
              )}
            </>
          )}
          {selectedSession && (
            <InboxRespondBox
              medications={medications}
              storagePathResolver={storagePathResolver}
              selectedSession={selectedSession}
              sendAction={sendAction}
              toggleEdit={toggleEdit}
              conditions={conditions}
              specialties={specialties}
              endSession={endSession}
              selectedSessionPreviousSession={selectedSessionPreviousSession}
              edit={edit}
              practitioners={practitioners}
              activeInbox={activeInbox}
              clearActiveAccountAndSession={clearActiveAccountAndSession}
              hideSession={hideSession}
              labTests={labTests}
              createPatientReminder={createPatientReminder}
              activeHealthcareService={activeHealthcareService}
              practitionerSpeciality={practitionerSpeciality}
              createObservationAlert={createObservationAlert}
              observationTypes={observationTypes}
              addObservation={addObservation}
            />
          )}
        </>
      </div>
    );
  }
}

//= ====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = createSelector(
  getLoaded,
  getEditFlag,
  getConditionsList,
  getSpecialtiesList,
  getSelectedSessionObject,
  getAllSelectedSessionPreviousSessions,
  getPractitioners,
  getInboxes,
  getActiveInbox,
  getActiveHealthcareService,
  getLabTests,
  getPractitionerSpeciality,
  getPractitionerWeeklyAverageResponseTime,
  getObservationTypes,
  getMedications,
  getIsMedicationsLoaded,
  (
    loaded,
    edit,
    conditions,
    specialties,
    selectedSession,
    selectedSessionPreviousSession,
    practitioners,
    inboxes,
    activeInbox,
    activeHealthcareService,
    labTests,
    practitionerSpeciality,
    practitionerWeeklyAverageResponseTime,
    observationTypes,
    medications
  ) => ({
    loaded,
    edit,
    conditions,
    specialties,
    selectedSession,
    selectedSessionPreviousSession,
    practitioners,
    inboxes,
    activeInbox,
    activeHealthcareService,
    labTests,
    practitionerSpeciality,
    practitionerWeeklyAverageResponseTime,
    observationTypes,
    medications,
  })
);

const mapDispatchToProps = {
  ...accountsActions,
  addObservation: patientActions.addObservation,
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxPage);
