import { AnchorButton, Spinner } from "@blueprintjs/core";
import React from "react";

function VideoCallButton({
  communicationRequestID,
  intent,
  isSmall = false,
  status,
}) {
  return (
    <AnchorButton
      style={{ marginLeft: "8px" }}
      icon="mobile-video"
      href={`/call#/${communicationRequestID}`}
      target="_blank"
      text={
        status === "booked" ? (
          <span style={{ display: "flex", flexDirection: "row" }}>
            <Spinner size={12} />
            <p style={{ marginLeft: 5 }}>Waiting for Patient</p>
          </span>
        ) : (
          "Join"
        )
      }
      disabled={status === "booked"}
      small={isSmall}
      intent={intent}
    />
  );
}

export default VideoCallButton;
