import React, { useState } from "react";

import { Card, Elevation, H4, Icon } from "@blueprintjs/core";
import PatientHealthRecord from "./PatientHealthRecord";
import { capitalize } from "../../utils";
import PatientActionButtons from "./PatientActionButtons";
import { isSecondaryProfile } from "../../../utils";

const PatientInformationCard = ({
  practitioners,
  patient,
  createPatientReminder,
  createObservationAlert,
  removeObservationAlert,
  activeHealthcareService,
  practitionerSpeciality,
  observationTypes,
  addObservation,
  hideObservationUpdate,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card
      style={{
        marginBottom: "10px",
        padding: 0,
      }}
      elevation={Elevation.TWO}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            padding: 20,
          }}
          onClick={() => {
            if (!isSecondaryProfile(patient.key)) setIsExpanded(!isExpanded);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <H4
              style={{
                marginBottom: 0,
                display: "inline",
                fontWeight: "600",
                marginLeft: "15px",
              }}
            >
              {capitalize(patient.gender)} - {patient.age} Years
            </H4>
          </div>
          {!isSecondaryProfile(patient.key) && (
            <Icon
              icon={isExpanded ? "chevron-down" : "chevron-right"}
              iconSize={20}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <div style={{ margin: 20 }}>
          <PatientHealthRecord
            patient={patient}
            practitioners={practitioners}
            removeObservationAlert={removeObservationAlert}
            observationTypes={observationTypes}
            addObservation={addObservation}
            hideObservationUpdate={hideObservationUpdate}
          />
          <PatientActionButtons
            patient={patient}
            createPatientReminder={createPatientReminder}
            createObservationAlert={createObservationAlert}
            activeHealthcareService={activeHealthcareService}
            practitionerSpeciality={practitionerSpeciality}
          />
        </div>
      )}
    </Card>
  );
};

export default PatientInformationCard;
