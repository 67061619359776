import "jquery/src/jquery";
import React, { useEffect } from "react";
import * as Plottable from "plottable";
import "plottable/plottable.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import moment from "moment";

const { $ } = window;
export default function ObservationPlot({ data, observationType }) {
  const padAxisDomain = () => {
    const values = data.map((observation) => observation.value);
    const max = Math.max(...values);
    const min = Math.min(...values);

    const padding = 20;

    const upperBound = max + padding;
    const lowerBound = min - padding;
    const domain = [lowerBound < 0 ? 0 : lowerBound, upperBound];
    return domain;
  };

  const symbolSize = 8;

  const plotDivRef = React.createRef();

  const xScale = new Plottable.Scales.Time();
  const xAxis = new Plottable.Axes.Time(xScale, "bottom");

  let yScale;
  let yAxis;
  switch (observationType) {
    case "number":
      yScale = new Plottable.Scales.Linear().domain(padAxisDomain(data));
      yAxis = new Plottable.Axes.Numeric(yScale, "left");
      break;

    case "boolean":
      yScale = new Plottable.Scales.Category().domain(["Yes", "No"]);
      yAxis = new Plottable.Axes.Category(yScale, "left");
      break;

    default:
      yScale = new Plottable.Scales.Linear().domain(padAxisDomain(data));
      yAxis = new Plottable.Axes.Numeric(yScale, "left");
      break;
  }

  const mapBooleanToString = (value) => {
    return value ? "Yes" : "No";
  };

  const linePlot = new Plottable.Plots.Line()
    .addDataset(new Plottable.Dataset(data))
    .x((d) => d.dateTime, xScale)
    .y((d) => {
      if (observationType === "boolean") {
        return mapBooleanToString(d.value);
      }
      return d.value;
    }, yScale)
    .attr("stroke-width", 2)
    .attr("stroke", "#2965CC")
    .curve("monotone");

  const scatterPlot = new Plottable.Plots.Scatter()
    .addDataset(new Plottable.Dataset(data))
    .x((d) => d.dateTime, xScale)
    .y((d) => {
      if (observationType === "boolean") {
        return mapBooleanToString(d.value);
      }
      return d.value;
    }, yScale)
    .attr("opacity", 1)
    .attr("stroke-width", 2)
    .attr("stroke", "black")
    .attr("fill", "white")
    .size(symbolSize)
    .addDataset(new Plottable.Dataset(data));

  // Add line plot only for numeric obvservations
  let plots;
  if (observationType === "boolean") {
    plots = new Plottable.Components.Group([scatterPlot]);
  } else {
    plots = new Plottable.Components.Group([linePlot, scatterPlot]);
  }

  // eslint-disable-next-line
  const panZoom = new Plottable.Interactions.PanZoom(xScale, null);
  panZoom.attachTo(plots);

  const table = new Plottable.Components.Table([
    [yAxis, plots],
    [null, xAxis],
  ]).removeClass("table");

  useEffect(() => {
    if (plotDivRef.current) {
      table.renderTo(plotDivRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Renders plot and then initialize tooltip */
  /* Adapted from  http://plottablejs.org/tutorials/tooltips/ */
  /* TODO replace use of jquery and bootstrap with @blueprintjs/popover2 */
  useEffect(() => {
    if (plotDivRef.current && scatterPlot.foreground()) {
      // Initializing tooltip anchor
      const tooltipAnchorSelection = scatterPlot.foreground().append("circle");

      const tooltipAnchor = $(tooltipAnchorSelection.node());
      tooltipAnchor.attr({
        r: 3,
        opacity: 0,
      });

      tooltipAnchor.tooltip({
        animation: false,
        container: "body",
        placement: "auto",
        title: "text",
        trigger: "manual",
        html: true,
      });
      let disableTooltip = false;
      // Setup Interaction.Pointer
      const pointer = new Plottable.Interactions.Pointer();
      const plotWidth = scatterPlot.width();
      pointer.onPointerMove((p) => {
        const closest = scatterPlot.entityNearest(p);
        if (closest && !disableTooltip) {
          const { value, dateTime } = closest.datum;
          const { x, y } = closest.position;
          // Fix problem with tooltip appearing outside plot after pan/zoom ends
          if (x >= 0 && x <= plotWidth) {
            const formattedDate = moment(dateTime).format("MMM DD");
            tooltipAnchor.attr({
              cx: x,
              cy: y,
              "data-original-title": `Reported: ${formattedDate}<br />Value: ${value}`,
            });
            tooltipAnchor.tooltip("show");
          }
        }
      });

      pointer.onPointerExit(() => {
        tooltipAnchor.tooltip("hide");
      });

      pointer.attachTo(scatterPlot);

      // Add listeners to hide tooltip on pan/zoom
      panZoom.onPanZoomUpdate(() => {
        tooltipAnchor.tooltip("hide");
        disableTooltip = true;
      });
      panZoom.onPanEnd(() => {
        disableTooltip = false;
      });
      panZoom.onZoomEnd(() => {
        disableTooltip = false;
      });
    }
  }, [plots, plotDivRef, table, scatterPlot, panZoom]);

  return (
    <div style={{ marginTop: "20px", marginRight: "20px", overflow: "hidden" }}>
      <div ref={plotDivRef} style={{ height: "280px", width: "100%" }} />
    </div>
  );
}
