import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { getTimes } from "./helper";

const Times = getTimes();

const ShiftSlot = ({
  addShift,
  deleteShift,
  editShift,
  day,
  startAt,
  endAt,
  type,
  setAddingShift,
  checkShiftValidity,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [startTime, setStartTime] = useState(startAt);
  const [endTime, setEndTime] = useState(endAt);

  const [shiftValid, setShiftValid] = useState(
    checkShiftValidity({
      type,
      startTime: startAt,
      endTime: endAt,
      day,
      prevStartTime: startAt,
    })
  );

  const renderTimesList = ({ items, itemsParentRef, renderItem }) => {
    const renderedItems = items.map(renderItem).filter((item) => item != null);
    return (
      <Menu ulRef={itemsParentRef} style={{ minWidth: 0 }}>
        {renderedItems}
      </Menu>
    );
  };

  const renderTime = (time, { handleClick }) => {
    return <MenuItem key={time} onClick={handleClick} text={time} />;
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Select
        filterable={false}
        name="shift-start"
        id="shift-start"
        items={Times}
        onItemSelect={(time) => {
          setShiftValid(
            checkShiftValidity({
              type,
              startTime: time,
              endTime,
              day,
              prevStartTime: startAt,
            })
          );
          setStartTime(time);
        }}
        itemRenderer={renderTime}
        itemListRenderer={renderTimesList}
        popoverProps={{
          minimal: true,
          inner: true,
          boundary: "window",
          hasBackdrop: true,
          popoverClassName: "conditions-popover",
        }}
        disabled={!isEditing && !(type === "add")}
      >
        <Button
          fill
          style={{ minWidth: 80 }}
          large={false}
          alignText="left"
          text={`${startTime}`}
          disabled={!isEditing && !(type === "add")}
        />
      </Select>
      <span style={{ fontSize: 16, marginLeft: 10, marginRight: 10 }}>-</span>
      <Select
        filterable={false}
        name="shift-end"
        id="shift-end"
        items={Times}
        onItemSelect={(time) => {
          setShiftValid(
            checkShiftValidity({
              type,
              startTime,
              endTime: time,
              day,
              prevStartTime: startAt,
            })
          );
          setEndTime(time);
        }}
        itemRenderer={renderTime}
        itemListRenderer={renderTimesList}
        popoverProps={{
          minimal: true,
          inner: true,
          boundary: "window",
          hasBackdrop: true,
          popoverClassName: "conditions-popover",
        }}
        disabled={!isEditing && !(type === "add")}
      >
        <Button
          fill
          style={{ minWidth: 80 }}
          large={false}
          alignText="left"
          text={`${endTime}`}
          disabled={!isEditing && !(type === "add")}
        />
      </Select>
      {type === "add" && (
        <div>
          <Button
            icon="add"
            style={{ marginLeft: 15 }}
            disabled={!shiftValid}
            onClick={() => {
              addShift({
                day,
                startTime,
                endTime,
              });
              setAddingShift(false);
            }}
            minimal
          />
          <Button
            icon="cross"
            style={{ marginLeft: 15 }}
            onClick={() => setAddingShift(false)}
            minimal
            intent="danger"
          />
        </div>
      )}
      {type !== "add" && !isEditing && (
        <div>
          <Button
            icon="edit"
            style={{ marginLeft: 15 }}
            onClick={() => {
              setIsEditing(true);
            }}
            minimal
            intent="primary"
          />
        </div>
      )}
      {type !== "add" && isEditing && (
        <div>
          <Button
            icon="tick"
            intent="success"
            minimal
            style={{ marginLeft: 15 }}
            disabled={!shiftValid}
            onClick={() => {
              editShift({
                day,
                startTime,
                endTime,
                prevStartTime: startAt,
              });
              setIsEditing(false);
            }}
          />
          <Button
            icon="remove"
            style={{ marginLeft: 15 }}
            onClick={() => {
              deleteShift({
                day,
                startTime,
              });
            }}
            minimal
            intent="danger"
          />
        </div>
      )}
    </div>
  );
};

export default ShiftSlot;
