import {
  Button,
  Callout,
  Card,
  Elevation,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import AddMedicationOverlay from "./AddMedicationOverlay";
import MedicationsSelect from "./MedicationsSelect";
import MedicationsList from "./MedicationsList";

const PrescriptionInput = ({
  medications,
  medicationsList,
  setMedicationsList,
}) => {
  const [isAddMedicationOverlay, setIsAddMedicationOverlay] = useState(false);
  const [isMedicationOmniBar, setIsMedicationOmniBar] = useState(false);
  const [selectedMedication, setSelectedMedication] = useState(null);

  const addMedicationToMedicationsList = ({ medication }) => {
    setMedicationsList([...medicationsList, medication]);
  };

  const removeMedicationFromMedicationsList = ({ medicationKey }) => {
    setMedicationsList([
      ...medicationsList.filter((medication) => {
        return medication.medication.key !== medicationKey;
      }),
    ]);
    setSelectedMedication(null);
  };

  useEffect(() => {
    if (selectedMedication !== null) {
      setIsAddMedicationOverlay(true);
    }
  }, [selectedMedication]);

  const isLoading = medications === null || medications.length === 0;

  return (
    <>
      <AddMedicationOverlay
        isAddMedicationOverlay={isAddMedicationOverlay}
        setIsAddMedicationOverlay={setIsAddMedicationOverlay}
        selectedMedication={selectedMedication}
        setSelectedMedication={setSelectedMedication}
        setIsMedicationOmniBar={setIsMedicationOmniBar}
        addMedicationToMedicationsList={addMedicationToMedicationsList}
      />
      <Card
        className="chatbox-conditions-picker"
        interactive={false}
        elevation={Elevation.TWO}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        {isLoading && (
          <div
            style={{
              margin: "40px 0px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner size={60} />
            <p style={{ marginTop: 15 }}>Loading Medications</p>
          </div>
        )}
        {!isLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px",
            }}
          >
            <MedicationsSelect
              setIsMedicationOmniBar={setIsMedicationOmniBar}
              isMedicationOmniBar={isMedicationOmniBar}
              medications={medications}
              setSelectedMedication={setSelectedMedication}
              selectedMedication={selectedMedication}
            />
            {medicationsList.length > 0 && (
              <Callout style={{ marginBottom: 10 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <MedicationsList
                    medicationsList={medicationsList}
                    removeMedicationFromMedicationsList={
                      removeMedicationFromMedicationsList
                    }
                  />
                </div>
              </Callout>
            )}
          </div>
        )}
        <Button
          style={{ padding: 10 }}
          fill
          icon="plus"
          minimal
          intent={Intent.PRIMARY}
          onClick={() => {
            setIsMedicationOmniBar(true);
          }}
        >
          Add Medication
        </Button>
      </Card>
    </>
  );
};

export default PrescriptionInput;
