/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { List } from "immutable";

import {
  getProfilesList,
  getPractitioners,
  getSelectedPatient,
  getIsProfilesLoaded,
  patientActions,
  getObservationTypes,
  getPairedPatients,
} from "../../../patients";
import {
  getCommunicationRequests,
  getIsCommunicationRequestsLoaded,
} from "../../../communicationRequests";
import {
  getActiveHealthcareService,
  getPractitionerSpeciality,
} from "../../../auth";
import {
  getLabTests,
  // getSelectedSession
} from "../../../inbox";
import { PatientProfile } from "../../components/Patients";

import { getConditionsList } from "../../../conditions";
import { getSpecialtiesList } from "../../../specialties";
import { getMedications } from "../../../medications";

import PatientsSection from "../../components/Patients/PatientsSection";

export const PatientsPage = ({
  isProfilesLoaded,
  profiles,
  practitioners,
  selectedPatient,
  selectPatient,
  sendMessageToPatient,
  storagePathResolver,
  conditions,
  specialties,
  sendAnswerToHistorySession,
  communicationRequests,
  isCommunicationRequestsLoaded,
  createPatientReminder,
  createObservationAlert,
  removeObservationAlert,
  activeHealthcareService,
  practitionerSpeciality,
  communicationRequestAction,
  observationTypes,
  processDiagnosticReport,
  labTests,
  medications,
  pairedPatients,
  addObservation,
  loadData,
  unloadData,
  loadProfiles,
}) => {
  useEffect(() => {
    loadData();
    return () => {
      unloadData();
    };
  }, [loadData, unloadData]);

  useEffect(() => {
    loadProfiles();
  }, [communicationRequests, loadProfiles]);

  const extractSelectedPatientCommunicationRequests = () => {
    if (!selectedPatient || !selectedPatient.profile) {
      return new List();
    }
    return communicationRequests.filter((communicationRequest) => {
      return communicationRequest.subject === selectedPatient.profile.key;
    });
  };

  return (
    <div className="main-container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
        }}
      >
        <PatientsSection
          profiles={profiles}
          isProfilesLoaded={isProfilesLoaded}
          communicationRequests={communicationRequests}
          isCommunicationRequestsLoaded={isCommunicationRequestsLoaded}
          selectPatient={selectPatient}
          selectedPatient={selectedPatient}
          pairedPatients={pairedPatients}
        />
        <div
          style={{
            flex: 3,
            marginLeft: "12px",
          }}
        >
          {Boolean(selectedPatient) && Boolean(selectedPatient.profile) && (
            <PatientProfile
              selectedPatient={selectedPatient}
              sendMessageToPatient={sendMessageToPatient}
              storagePathResolver={storagePathResolver}
              medications={medications}
              conditions={conditions}
              specialties={specialties}
              sendAnswerToHistorySession={sendAnswerToHistorySession}
              practitioners={practitioners}
              createObservationAlert={createObservationAlert}
              removeObservationAlert={removeObservationAlert}
              createPatientReminder={createPatientReminder}
              activeHealthcareService={activeHealthcareService}
              practitionerSpeciality={practitionerSpeciality}
              patientCommunicationRequests={extractSelectedPatientCommunicationRequests()}
              communicationRequestAction={communicationRequestAction}
              observationTypes={observationTypes}
              processDiagnosticReport={processDiagnosticReport}
              labTests={labTests}
              pairedPatients={pairedPatients}
              addObservation={addObservation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

//= ====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = createSelector(
  getProfilesList,
  getPractitioners,
  getSelectedPatient,
  getIsProfilesLoaded,
  getConditionsList,
  getSpecialtiesList,
  getCommunicationRequests,
  getIsCommunicationRequestsLoaded,
  getActiveHealthcareService,
  getPractitionerSpeciality,
  getObservationTypes,
  getLabTests,
  getMedications,
  getPairedPatients,
  (
    profiles,
    practitioners,
    selectedPatient,
    isProfilesLoaded,
    conditions,
    specialties,
    communicationRequests,
    isCommunicationRequestsLoaded,
    activeHealthcareService,
    practitionerSpeciality,
    observationTypes,
    labTests,
    medications,
    pairedPatients
  ) => ({
    profiles,
    practitioners,
    selectedPatient,
    isProfilesLoaded,
    conditions,
    specialties,
    communicationRequests,
    isCommunicationRequestsLoaded,
    activeHealthcareService,
    practitionerSpeciality,
    observationTypes,
    labTests,
    medications,
    pairedPatients,
  })
);

const mapDispatchToProps = { ...patientActions };

export default connect(mapStateToProps, mapDispatchToProps)(PatientsPage);
