export const CREATE_OBSERVATION_TYPE_ERROR = "CREATE_OBSERVATION_TYPE_ERROR";
export const CREATE_OBSERVATION_TYPE_SUCCESS =
  "CREATE_OBSERVATION_TYPE_SUCCESS";

export const REMOVE_OBSERVATION_TYPE_ERROR = "REMOVE_OBSERVATION_TYPE_ERROR";
export const REMOVE_OBSERVATION_TYPE_SUCCESS =
  "REMOVE_OBSERVATION_TYPE_SUCCESS";

export const UNDELETE_OBSERVATION_TYPE_ERROR =
  "UNDELETE_OBSERVATION_TYPE_ERROR";

export const UPDATE_OBSERVATION_TYPE_ERROR = "UPDATE_OBSERVATION_TYPE_ERROR";
export const UPDATE_OBSERVATION_TYPE_SUCCESS =
  "UPDATE_OBSERVATION_TYPE_SUCCESS";

export const LOAD_OBSERVATION_TYPES_SUCCESS = "LOAD_OBSERVATION_TYPES_SUCCESS";
export const LOAD_OBSERVATION_TYPES_ERROR = "LOAD_OBSERVATION_TYPES_ERROR";

export const UNLOAD_OBSERVATION_TYPES = "UNLOAD_OBSERVATION_TYPES";
