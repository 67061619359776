/* eslint-disable no-param-reassign */
import {
  Button,
  ButtonGroup,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import React, { useState } from "react";

function getCancellationReason(associatedDispenseRequest) {
  switch (associatedDispenseRequest.statusReason) {
    case "outOfStock":
      return "Cancelled - Out Of Stock";
    case "prescriptionDispensed":
      return "Cancelled - Prescription Dispensed";
    default:
      if (associatedDispenseRequest.statusReason) {
        return `Cancelled - ${associatedDispenseRequest.statusReason}`;
      }
      return "Cancelled";
  }
}
const DispenseRequestActions = ({
  dispenseItem,
  associatedDispenseRequest,
  dispenseRequestActions,
  isWasfatyPrescription = false,
}) => {
  let originalQuantity;
  let allowedQuantity;
  let dispensedQuantity;
  let isPartialDispensing;

  if (!isWasfatyPrescription) {
    originalQuantity = dispenseItem.dispenseRequest.initialFill.quantity.value;
    dispensedQuantity = dispenseItem.dispensedQuantity;
    allowedQuantity = originalQuantity - dispensedQuantity;
    isPartialDispensing = dispenseItem.dispensedQuantity !== 0;
  }

  const dispenseRequestStatus = associatedDispenseRequest.status;
  const [action, setAction] = useState(null);
  const [amount, setAmount] = useState(allowedQuantity);
  const [cancelReason, setCancelReason] = useState("");

  if (action === null) {
    if (dispenseRequestStatus === "preparation") {
      if (isPartialDispensing) {
        return (
          <>
            <ButtonGroup fill>
              <Button
                onClick={() => {
                  setAction("ready");
                }}
                intent={Intent.WARNING}
              >
                Add More
              </Button>
              <Button
                onClick={() => {
                  setAction("cancelOptions");
                }}
                intent={Intent.DANGER}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </>
        );
      }

      return (
        <>
          <ButtonGroup fill>
            <Button
              onClick={() => {
                if (isWasfatyPrescription) {
                  setAction("confirmReady");
                } else {
                  setAction("ready");
                }
              }}
              intent={Intent.SUCCESS}
            >
              Ready
            </Button>
            <Button
              onClick={() => {
                setAction("cancelOptions");
              }}
              intent={Intent.DANGER}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </>
      );
    }
    if (dispenseRequestStatus === "cancelled") {
      return (
        <Button fill disabled intent={Intent.DANGER}>
          {getCancellationReason(associatedDispenseRequest)}
        </Button>
      );
    }

    if (dispenseRequestStatus === "on-hold") {
      return (
        <>
          <ButtonGroup fill>
            <Button
              onClick={() => {
                setAction("confirmCompleted");
              }}
              intent={Intent.SUCCESS}
            >
              Completed
            </Button>
            <Button
              onClick={() => {
                setAction("cancelOptions");
              }}
              intent={Intent.DANGER}
            >
              Cancelled
            </Button>
          </ButtonGroup>
        </>
      );
    }
  }

  if (action === "cancelOptions") {
    return (
      <>
        <ButtonGroup fill>
          <Button
            onClick={() => {
              setCancelReason("outOfStock");
              setAction("confirmCancel");
            }}
            intent={Intent.DANGER}
          >
            Out of stock
          </Button>
          <Button
            onClick={() => {
              setCancelReason("prescriptionDispensed");
              setAction("confirmCancel");
            }}
            intent={Intent.DANGER}
          >
            Order already dispensed
          </Button>
          <Button
            onClick={() => {
              setAction("cancelTextOption");
            }}
            intent={Intent.DANGER}
          >
            Other
          </Button>
        </ButtonGroup>
      </>
    );
  }

  if (action === "cancelTextOption") {
    return (
      <>
        <div className="bp3-input-group .modifier">
          <FormGroup intent={Intent.DANGER}>
            <InputGroup
              value={cancelReason}
              onChange={(e) => {
                setCancelReason(e.target.value);
              }}
              type="text"
              placeholder="Enter cancellation reason"
              rightElement={
                <Button
                  disabled={cancelReason === "" || !cancelReason}
                  icon="tick"
                  onClick={() => {
                    setAction("confirmCancel");
                  }}
                  intent={Intent.DANGER}
                >
                  Confirm
                </Button>
              }
            />
          </FormGroup>
        </div>
      </>
    );
  }

  if (action === "confirmReady") {
    return (
      <>
        <ButtonGroup fill>
          <Button
            onClick={() => {
              setAction(null);
            }}
            intent={Intent.NONE}
          >
            Cancel
          </Button>
          {isWasfatyPrescription && (
            <Button
              onClick={() => {
                if (isWasfatyPrescription) {
                  dispenseRequestActions.markDispenseRequestAsOnHold({
                    dispenseItem,
                    dispenseRequestKey: associatedDispenseRequest.key,
                    isWasfatyPrescription: true,
                    isWasfatyPartiallyDispensed: true,
                  });
                }
              }}
              intent={Intent.WARNING}
            >
              Confirm order is partially ready
            </Button>
          )}
          <Button
            onClick={() => {
              if (isWasfatyPrescription) {
                dispenseRequestActions.markDispenseRequestAsOnHold({
                  dispenseItem,
                  dispenseRequestKey: associatedDispenseRequest.key,
                  isWasfatyPrescription: true,
                });
              }
            }}
            intent={Intent.SUCCESS}
          >
            Confirm order is fully ready
          </Button>
        </ButtonGroup>
      </>
    );
  }

  if (action === "ready") {
    const getReadyHelperMessage = () => {
      if (!amount || amount <= 0) {
        return "Enter a valid amount";
      }
      if (amount > allowedQuantity) {
        return `Dispensed amount can't be larger than ${allowedQuantity}`;
      }
      return undefined;
    };

    return (
      <>
        <div className="bp3-input-group .modifier">
          <FormGroup
            helperText={getReadyHelperMessage()}
            intent={Intent.DANGER}
          >
            <InputGroup
              value={amount}
              onChange={(e) => {
                setAmount(parseInt(e.target.value, 10));
              }}
              max={allowedQuantity}
              type="number"
              placeholder="Enter dispensed amount"
              rightElement={
                <Button
                  disabled={!amount || amount > allowedQuantity || amount < 0}
                  icon="tick"
                  onClick={() => {
                    dispenseRequestActions.markDispenseRequestAsOnHold({
                      quantity: amount,
                      allowedQuantity,
                      type: associatedDispenseRequest.type,
                      destination: associatedDispenseRequest.destination,
                      dispenseRequestKey: associatedDispenseRequest.key,
                      dispenseItem,
                    });
                  }}
                  intent={Intent.SUCCESS}
                >
                  Confirm
                </Button>
              }
            />
          </FormGroup>
        </div>
      </>
    );
  }

  if (action === "confirmCompleted") {
    return (
      <>
        <ButtonGroup fill>
          <Button
            onClick={() => {
              setAction(null);
            }}
            intent={Intent.NONE}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispenseRequestActions.markDispenseRequestAsCompleted({
                dispenseItem,
                medicationRequestKey: dispenseItem.key,
                dispenseRequestKey: associatedDispenseRequest.key,
              });
            }}
            intent={Intent.SUCCESS}
          >
            Confirm item completed
          </Button>
        </ButtonGroup>
      </>
    );
  }

  if (action === "confirmCancel") {
    return (
      <>
        <ButtonGroup fill>
          <Button
            onClick={() => {
              setAction(null);
            }}
            intent={Intent.NONE}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispenseRequestActions.markDispenseRequestAsCancelled({
                dispenseItem,
                medicationRequestKey: dispenseItem.key,
                dispenseRequestKey: associatedDispenseRequest.key,
                statusReason: cancelReason,
              });
            }}
            intent={Intent.DANGER}
          >
            Confirm item cancellation
          </Button>
        </ButtonGroup>
      </>
    );
  }
  return <></>;
};

export default DispenseRequestActions;
