import React, { useEffect, useRef } from "react";
import ConversationUnit from "./ConversationUnit";

const Conversation = ({
  questions,
  storagePathResolver,
  practitioners,
  shouldScrollToBottomAfterStateChanges = false,
  practitionerSpeciality,
}) => {
  const stringifiedQuestions = JSON.stringify({ questions });
  const conversationRef = useRef(null);
  useEffect(() => {
    if (
      conversationRef.current !== null &&
      shouldScrollToBottomAfterStateChanges
    ) {
      conversationRef.current.scrollIntoView(false);
    }
  }, [stringifiedQuestions, shouldScrollToBottomAfterStateChanges]);

  return (
    <div ref={conversationRef}>
      {questions &&
        Object.keys(questions)
          .sort((questionKeyA, questionKeyB) => {
            return questions[questionKeyA].sent - questions[questionKeyB].sent;
          })
          .map((questionKey) => {
            return (
              <ConversationUnit
                key={questionKey}
                question={{
                  key: questionKey,
                  ...questions[questionKey],
                }}
                storagePathResolver={storagePathResolver}
                practitioners={practitioners}
                practitionerSpeciality={practitionerSpeciality}
              />
            );
          })}
    </div>
  );
};

export default Conversation;
