/* eslint-disable no-underscore-dangle */
import { firebaseDb } from "./firebase";

export default class FirebaseList {
  /**
   *Creates an instance of FirebaseList.
   * @param {*} actions
   * @param {*} modelClass
   * @param {*} [path=null]
   * @param { { path: string, operation: string, value: any } } filter @see https://firebase.google.com/docs/database/web/lists-of-data#sorting_and_filtering_data
   * @memberof FirebaseList
   *
   * @exampe filter
   * ```js
   *  new FirebaseList(actions, model,
   * {
   *  path:'activeSession',
   *  operation: 'endAt',
   *  value: false
   * });
   * ```
   */
  constructor(actions, modelClass, path = null, filter) {
    this._actions = actions;
    this._modelClass = modelClass;
    this._path = path;
    this._filter = filter;
  }

  get path() {
    return this._path;
  }

  set path(value) {
    this._path = value;
  }

  push(value) {
    return new Promise((resolve, reject) => {
      firebaseDb
        .ref(this._path)
        .push(value, (error) => (error ? reject(error) : resolve()));
    });
  }

  remove(key) {
    return new Promise((resolve, reject) => {
      firebaseDb
        .ref(`${this._path}/${key}`)
        .remove((error) => (error ? reject(error) : resolve()));
    });
  }

  set(key, value) {
    return new Promise((resolve, reject) => {
      firebaseDb
        .ref(`${this._path}/${key}`)
        .set(value, (error) => (error ? reject(error) : resolve()));
    });
  }

  update(key, value) {
    return new Promise((resolve, reject) => {
      firebaseDb
        .ref(`${this._path}/${key}`)
        .update(value, (error) => (error ? reject(error) : resolve()));
    });
  }

  subscribe(emit) {
    const ref = this._filter
      ? firebaseDb
          .ref(this._path)
          .orderByChild(this._filter.path)
          [this._filter.operation](this._filter.value)
      : // [this._filter.operation](this._filter.value) :
        firebaseDb.ref(this._path);

    let initialized = false;
    const list = [];

    ref.once("value", () => {
      initialized = true;
      emit(this._actions.onLoad(list));
    });

    ref.on("child_added", (snapshot) => {
      if (initialized) {
        emit(this._actions.onAdd(this.unwrapSnapshot(snapshot)));
      } else {
        list.push(this.unwrapSnapshot(snapshot));
      }
    });

    ref.on("child_changed", (snapshot) => {
      emit(this._actions.onChange(this.unwrapSnapshot(snapshot)));
    });

    ref.on("child_removed", (snapshot) => {
      emit(this._actions.onRemove(this.unwrapSnapshot(snapshot)));
    });

    this._unsubscribe = () => ref.off();
  }

  unsubscribe() {
    this._unsubscribe();
  }

  // eslint-disable-next-line consistent-return
  unwrapSnapshot(snapshot) {
    const attrs = snapshot.val();
    if (typeof attrs === "object") {
      attrs.key = snapshot.key;
      return new this._modelClass(attrs);
    }
  }
}
