import { Record, fromJS, Collection } from "immutable";
import { AnyAction } from "redux";

import * as t from "./action-types";

export interface SettingsReducerState {
  isUpdatingProfile: boolean;
  isUpdatingRole: boolean;
  loaded: boolean;
  profile: Collection<unknown, unknown>;
  assignedPatients: Collection<unknown, unknown>;
  programConfigs: Collection<any, any>;
}

export const SettingsState = Record({
  isUpdatingProfile: false,
  isUpdatingRole: false,
  loaded: false,
  profile: Collection(),
  assignedPatients: Collection(),
  programConfigs: Collection(),
});

export function settingsReducer(
  state = new SettingsState(),
  action: AnyAction
) {
  const { payload, type } = action;
  switch (type) {
    case t.LOAD_PROFILE:
      return state.merge({
        profile: fromJS(payload.profile),
        loaded: true,
      });

    case t.UPDATING_PROFILE:
      return state.merge({
        isUpdatingProfile: true,
      });

    case t.UPDATED_PROFILE:
      return state.merge({
        profile: fromJS(payload.profile),
        isUpdatingProfile: false,
      });

    case t.UPDATING_PARTICIPATING_PROGRAM:
      return state.merge({
        isUpdatingRole: payload.isUpdatingRole,
      });

    case t.LOAD_ASSIGNED_PATIENTS:
      return state.merge({
        assignedPatients: fromJS(payload.assignedPatients),
      });

    case t.LOAD_PROGRAM_CONFIGS:
      return state.merge({
        programConfigs: fromJS(payload.programConfigs),
      });

    default:
      return state;
  }
}
