import React, { useState, useEffect } from "react";
import { H1, Tab, Tabs } from "@blueprintjs/core";
import { daysOfWeek, localToUtcDay, utcToLocalDay } from "nala-shared";
import ShiftSlots from "./ShiftSlots";

const isEqual = require("lodash/isEqual");
const omitBy = require("lodash/omitBy");
const moment = require("moment-timezone");

const Shifts = ({ shifts, updateShifts, isUpdatingShifts }) => {
  const [selectedDayTab, setSelectedDayTab] = useState(0);
  const [newShifts, setNewShifts] = useState(shifts);
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  useEffect(() => {
    const daysWithoutShifts = (dailyShifts) =>
      dailyShifts && dailyShifts.length === 0;
    const newShiftsWithoutEmptyDays = omitBy(newShifts, daysWithoutShifts);

    if (isEqual(shifts, newShiftsWithoutEmptyDays)) {
      setDisableSaveButton(true);
    } else {
      setDisableSaveButton(false);
    }
  }, [shifts, newShifts]);

  const localizeShifts = (utcShifts) => {
    const localizedShifts = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [day, dailyShifts] of Object.entries(utcShifts)) {
      if (dailyShifts) {
        dailyShifts.forEach((shift) => {
          const localizedDay = utcToLocalDay({
            utcDay: Number(daysOfWeek.indexOf(day)),
            utcTime: moment.utc(shift.startAt, "HH:mm"),
          });
          const localizedShift = {
            startAt: moment
              .utc(shift.startAt, "HH:mm")
              .local()
              .format("HH:mma"),
            endAt: moment.utc(shift.endAt, "HH:mm").local().format("HH:mma"),
          };
          if (!localizedShifts[daysOfWeek[localizedDay]]) {
            localizedShifts[daysOfWeek[localizedDay]] = [localizedShift];
          } else {
            localizedShifts[daysOfWeek[localizedDay]] = [
              ...localizedShifts[daysOfWeek[localizedDay]],
              localizedShift,
            ];
          }
        });
      }
    }
    return localizedShifts;
  };

  const addShift = ({ day: localDay, startTime, endTime }) => {
    const localStartTime = moment(startTime, "hh:mma");
    const localEndTime = moment(endTime, "hh:mma");
    const utcDay = localToUtcDay({ localDay, localTime: localStartTime });

    const shift = {
      startAt: localStartTime.utc().format("HH:mm"),
      endAt: localEndTime.utc().format("HH:mm"),
    };
    const dayName = daysOfWeek[utcDay];
    if (!newShifts[dayName]) {
      setNewShifts((prev) => ({
        ...prev,
        [dayName]: [shift],
      }));
    } else {
      setNewShifts((prev) => ({
        ...prev,
        [dayName]: [...prev[dayName], shift],
      }));
    }
  };

  const deleteShift = ({ day, startTime }) => {
    const localStartTime = moment(startTime, "hh:mma");
    const utcDay = localToUtcDay({ localDay: day, localTime: localStartTime });
    const startTimeInUtc = localStartTime.utc().format("HH:mm");
    const shiftsInDay = newShifts[daysOfWeek[utcDay]];
    const updatedShiftsInDay = shiftsInDay.filter(
      (shift) => !(shift.startAt === startTimeInUtc)
    );
    setNewShifts({
      ...newShifts,
      [daysOfWeek[utcDay]]: updatedShiftsInDay,
    });
  };

  const editShift = ({ day, prevStartTime, startTime, endTime }) => {
    deleteShift({ day, startTime: prevStartTime });
    addShift({ day, startTime, endTime });
  };

  const checkShiftValidity = ({
    day,
    type,
    startTime: startAt,
    endTime: endAt,
    prevStartTime,
  }) => {
    const startTime = moment(startAt, "HH:mma");
    // Account for endtime being midnight of next day
    const endTime =
      endAt === "12:00am" ? moment("24:00", "HH:mm") : moment(endAt, "HH:mma");

    if (endTime.isBefore(startTime) || endTime.isSame(startTime)) return false;
    const localizedShifts = localizeShifts(newShifts);

    const shiftsInDay =
      type === "add"
        ? localizedShifts[daysOfWeek[day]]
        : localizedShifts[daysOfWeek[day]].filter(
            (shift) =>
              !moment(shift.startAt, "hh:mma").isSame(
                moment(prevStartTime, "hh:mma")
              )
          );
    // A shift is invalid if it overlaps with any other shift
    const invalidShift = (shift) => {
      const shiftStart = moment(shift.startAt, "HH:mma");
      // Account for endtime being midnight of next day
      const shiftEnd =
        shift.endAt === "00:00am"
          ? moment("24:00", "HH:mm")
          : moment(shift.endAt, "hh:mma");
      if (startTime.isSame(shiftStart)) {
        return true;
      }
      if (startTime.isAfter(shiftStart)) {
        if (startTime.isAfter(shiftEnd) || startTime.isSame(shiftEnd)) {
          return false;
        }
      } else if (startTime.isBefore(shiftStart)) {
        if (endTime.isBefore(shiftStart) || endTime.isSame(shiftStart)) {
          return false;
        }
      }
      return true;
    };

    if (!shiftsInDay) return true;
    return !shiftsInDay.some(invalidShift);
  };

  const handleTabChange = (tabId) => {
    setSelectedDayTab(tabId);
  };

  return (
    <div style={{ margin: 15 }}>
      <div style={{ marginBottom: 10 }}>
        <H1>Shifts</H1>
        Set your weekly working hours
      </div>
      <Tabs
        id="shifts"
        onChange={handleTabChange}
        selectedTabId={selectedDayTab}
        vertical
        animate
      >
        {daysOfWeek.map((dayName, day) => (
          <Tab
            id={day}
            title={daysOfWeek[day]}
            key={dayName}
            panel={
              <ShiftSlots
                day={day}
                dayName={dayName}
                addShift={addShift}
                utcShifts={newShifts}
                shifts={localizeShifts(newShifts)}
                updateShifts={updateShifts}
                disableSaveButton={disableSaveButton}
                isUpdatingShifts={isUpdatingShifts}
                editShift={editShift}
                deleteShift={deleteShift}
                checkShiftValidity={checkShiftValidity}
              />
            }
          />
        ))}
      </Tabs>
    </div>
  );
};

export default Shifts;
