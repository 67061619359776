const expressionTypes = {
  equalTo: {
    key: "equalTo",
    text: "equal to",
  },
  largerThan: {
    key: "largerThan",
    text: "larger than",
  },
  lessThan: {
    key: "lessThan",
    text: "less than",
  },
  in: {
    key: "in",
    text: "in",
  },
};

export default expressionTypes;
