import React, { useEffect, useState } from "react";

import {
  Dialog,
  Classes,
  H4,
  Intent,
  Spinner,
  Button,
  Checkbox,
} from "@blueprintjs/core";
import Notification from "../Notification";
import ReminderTypeSelect from "../ReminderTypeSelect";
import ObservationTypeSelect from "../ObservationTypeSelect";
import ObservationAlertInput from "./ObservationAlertInput";
import ExpressionRadioButtons from "./ExpressionRadioButtons";
import expressionTypes from "../../../config/expressionTypes";

const timeNow = new Date();
timeNow.setSeconds(0, 0);

const ObservationAlertOverlay = ({
  createObservationAlert,
  observations,
  isOpen,
  onClose,
  activeHealthcareService,
  practitionerSpeciality,
}) => {
  const [reminderType, setReminderType] = useState("me");
  const [selectedObservation, setSelectedObservation] = useState();
  const [selectedExpression, setSelectedExpression] = useState("equalTo");
  const [selectedValues, setSelectedValues] = useState([""]);
  const [alertEmergencyContact, setAlertEmergencyContact] = useState(false);
  const isConfirmButtonEnabled = () => {
    if (selectedValues.length === 1 && selectedValues[0] === "") {
      return false;
    }
    if (selectedExpression === "in" && selectedValues.length <= 1) {
      return false;
    }
    return true;
  };

  const formatValuesToString = () => {
    if (selectedExpression === "in") {
      return selectedValues
        .slice(1)
        .reduce((finalValue, currentValue, index) => {
          if (index === 0) {
            return `${finalValue} ${currentValue}`;
          }
          return `${finalValue}, ${currentValue}`;
        }, "");
    }
    return selectedValues[0];
  };

  const formatReminderTypeToString = () => {
    if (reminderType === "healthcareService") {
      return activeHealthcareService;
    }
    if (reminderType === "speciality") {
      return practitionerSpeciality;
    }
    return reminderType;
  };

  const formatConfirmButtonText = () => {
    return `Alert ${formatReminderTypeToString()} when ${selectedObservation} is ${
      expressionTypes[selectedExpression].text
    } ${formatValuesToString()}`;
  };

  const formatObservationAlertNote = () => {
    return `(${selectedObservation}) value is ${
      expressionTypes[selectedExpression].text
    } ${formatValuesToString()}`;
  };

  const valueType = () => {
    if (
      observations &&
      Boolean(observations[selectedObservation]) &&
      Boolean(observations[selectedObservation].current)
    ) {
      return typeof observations[selectedObservation].current.value;
    }
    return undefined;
  };

  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setReminderType("me");
    setSelectedObservation(undefined);
    onClose();
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const formula = {
        expression: selectedExpression,
        value:
          selectedValues.length > 1
            ? selectedValues.slice(1)
            : selectedValues[0],
      };
      await createObservationAlert({
        note: formatObservationAlertNote(),
        reminderType,
        observation: selectedObservation,
        formula,
        dateTime: new Date().getTime(),
        alertEmergencyContact,
      });
      setIsLoading(false);
      closeModal();
      Notification.Success("Alert created successfully");
    } catch (error) {
      setIsLoading(false);
      // console.log({ error });
      Notification.Error(error.message);
    }
  };

  // When value is changed
  useEffect(() => {
    setSelectedExpression("equalTo");
    setSelectedValues([""]);
  }, [selectedObservation]);

  // When expression is changed
  useEffect(() => {
    setSelectedValues([""]);
  }, [selectedExpression]);

  return (
    <Dialog
      className="bp3-dark"
      isOpen={isOpen}
      onClose={() => {
        closeModal();
      }}
      icon="warning-sign"
      title="Set observation alert"
      style={{ width: 800 }}
    >
      <style type="text/css">
        .hidden {"{ display:none; }"}
        .centered{" "}
        {"{ display:flex; flex-direction:column; align-items:center; }"}
      </style>

      <div style={{ marginBottom: 0 }} className={Classes.DIALOG_BODY}>
        {isLoading ? (
          <div
            style={{ height: 200, display: "flex", justifyContent: "center" }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ flex: 1, margin: "0px 10px" }}>
                <H4>Alert</H4>
                <ReminderTypeSelect
                  setReminderType={setReminderType}
                  practitionerSpeciality={practitionerSpeciality}
                  activeHealthcareService={activeHealthcareService}
                />
                <Checkbox
                  style={{ marginTop: 10 }}
                  label="Alert patient's contact"
                  defaultChecked={alertEmergencyContact}
                  checked={alertEmergencyContact}
                  onChange={(event) => {
                    setAlertEmergencyContact(event.target.checked);
                  }}
                />
              </div>
              <div style={{ flex: 1, margin: "0px 10px" }}>
                <H4>when</H4>
                <ObservationTypeSelect
                  observations={observations}
                  setObservation={setSelectedObservation}
                />
              </div>
              <div style={{ flex: 1, margin: "0px 10px" }}>
                <H4>is</H4>
                <ExpressionRadioButtons
                  expressionTypes={expressionTypes}
                  valueType={valueType()}
                  setSelectedExpression={setSelectedExpression}
                  selectedExpression={selectedExpression}
                />
              </div>
              <div style={{ flex: 2, margin: "0px 10px" }}>
                <H4 style={{ opacity: 0 }}>Value</H4>
                <ObservationAlertInput
                  valueType={valueType()}
                  selectedExpression={selectedExpression}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                />
              </div>
            </div>
            {isConfirmButtonEnabled() && (
              <Button
                onClick={onSubmit}
                style={{ width: "100%", marginTop: 20 }}
                text={formatConfirmButtonText()}
                intent={Intent.SUCCESS}
                type="submit"
              />
            )}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default ObservationAlertOverlay;
