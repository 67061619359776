import React, { useState } from "react";
import { Dialog, Button, HTMLTable, Callout } from "@blueprintjs/core";
import moment from "moment";

import ObservationPlot from "./ObservationPlots";
import { capitalize } from "../../utils";

const compareDateTime = (observationA, observationB) =>
  observationB[1].dateTime - observationA[1].dateTime;

const renderObservationTable = ({
  renderObservationValue,
  observation,
  showMore,
  numberOfItemsShown,
}) => {
  let dataset = Object.entries(observation.history).sort(compareDateTime);
  if (!showMore) dataset = dataset.slice(0, numberOfItemsShown);
  return (
    <HTMLTable style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>Value</th>
          <th>Reported Date</th>
        </tr>
      </thead>
      <tbody>
        {dataset.map(([key, observationInstant]) => (
          <tr key={key}>
            <td>{renderObservationValue(observationInstant.value)}</td>
            <td>{moment(observationInstant.dateTime).fromNow()}</td>
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  );
};

const renderObservationPlot = ({ observation, observationType }) => {
  const dataset = Object.entries(observation.history)
    .sort(compareDateTime)
    .reverse()
    .map(([, observationInstant]) => observationInstant);
  return <ObservationPlot data={dataset} observationType={observationType} />;
};

const getObservationType = (observationName, observationTypes) => {
  const { type } = observationTypes.find(
    (observation) => observation.key === observationName
  ).updateRules;
  return type;
};

const ObservationHistory = ({
  observation,
  observationName,
  renderObservationValue,
  observationTypes,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const lastReportedDate = moment(observation.current.dateTime).fromNow();
  const isHistoryAvailable =
    observationName !== "gailScore" && Boolean(observation.history);

  const observationType = getObservationType(observationName, observationTypes);
  const numberOfObservations =
    isHistoryAvailable && Object.keys(observation.history).length;
  const numberOfItemsShown = 3;
  const showMoreAvailable = numberOfObservations > numberOfItemsShown;
  const isPlottable = () => {
    // Line plot requires at minimum two data points
    return (
      ["boolean", "number"].includes(observationType) &&
      isHistoryAvailable &&
      numberOfObservations > 1
    );
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p style={{ marginRight: 10 }}>{lastReportedDate}</p>
      {isHistoryAvailable && (
        <>
          {numberOfObservations > 1 && (
            <Button text="History" onClick={() => setIsOpen((prev) => !prev)} />
          )}
          <Dialog
            style={{ width: "100%", maxWidth: 500, paddingBottom: 20 }}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            className="bp3-dark"
            title={capitalize(observationName)}
            icon="timeline-line-chart"
          >
            {isPlottable() &&
              renderObservationPlot({ observation, observationType })}
            <div style={{ marginTop: 20, marginRight: 20, marginLeft: 20 }}>
              <Callout>
                {renderObservationTable({
                  renderObservationValue,
                  observation,
                  showMore,
                  numberOfItemsShown,
                })}
                {showMoreAvailable && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 5,
                    }}
                  >
                    <Button
                      text={showMore ? "View Less" : "View More"}
                      minimal
                      onClick={() => setShowMore((prev) => !prev)}
                    />
                  </div>
                )}
              </Callout>
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ObservationHistory;
