import { Button, Intent } from "@blueprintjs/core";
import React from "react";
import DispenseRequestActions from "../InputBox/PrescriptionInput/DispenseRequestActions";
import Notification from "../Notification";

const WasfatyPrescription = ({
  medicationRequest,
  dispenseRequest,
  dispenseRequestActions,
}) => {
  return (
    <div>
      <table
        className="bp3-html-table .modifier .bp3-interactive"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th>Patient Name</th>
            <th>National ID</th>
            <th>Prescription ID</th>
            {medicationRequest.wasfatyPrescription &&
              medicationRequest.wasfatyPrescription.dispenseStatus && (
                <th>Dispense Status</th>
              )}
            {medicationRequest.wasfatyPrescription &&
              medicationRequest.wasfatyPrescription.prescriptionStatus && (
                <th>Prescription Status</th>
              )}
            <th> </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{medicationRequest.wasfatyPrescription.wasfatyUser.name}</td>
            <td>
              {medicationRequest.wasfatyPrescription.wasfatyUser.nationalID}
              <Button
                style={{ marginLeft: 8 }}
                small
                icon="duplicate"
                onClick={() => {
                  navigator.clipboard.writeText(
                    medicationRequest.wasfatyPrescription.wasfatyUser.nationalID
                  );
                  Notification.Success("National ID copied to clipboard");
                }}
                intent={Intent.NONE}
              >
                <p style={{ fontSize: 11 }}>Copy</p>
              </Button>
            </td>
            <td>
              {medicationRequest.wasfatyPrescription.patientPrescriptionID}
              <Button
                style={{ marginLeft: 8 }}
                small
                icon="duplicate"
                onClick={() => {
                  navigator.clipboard.writeText(
                    medicationRequest.wasfatyPrescription.patientPrescriptionID
                  );
                  Notification.Success("Prescription ID copied to clipboard");
                }}
                intent={Intent.NONE}
              >
                <p style={{ fontSize: 11 }}>Copy</p>
              </Button>
            </td>
            {medicationRequest.wasfatyPrescription &&
              medicationRequest.wasfatyPrescription.dispenseStatus && (
                <th>{medicationRequest.wasfatyPrescription.dispenseStatus}</th>
              )}
            {medicationRequest.wasfatyPrescription &&
              medicationRequest.wasfatyPrescription.prescriptionStatus && (
                <th>
                  {medicationRequest.wasfatyPrescription.prescriptionStatus}
                </th>
              )}
            <td>
              <DispenseRequestActions
                dispenseItem={medicationRequest}
                associatedDispenseRequest={dispenseRequest}
                dispenseRequestActions={dispenseRequestActions}
                isWasfatyPrescription
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WasfatyPrescription;
