import {
  Button,
  Dialog,
  H4,
  Intent,
  Spinner,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import Attachment from "../../Attachment";
import Notification from "../../Notification";
import ObservationTypesTable from "./ObservationTypesTable";

const ProcessDiagnosticReportOverlay = ({
  isOpen,
  onClose,
  diagnosticReportID,
  observationTypes,
  processDiagnosticReport,
  serviceRequests,
  diagnosticReports,
  storagePathResolver,
}) => {
  const getDiagnosticReport = () => {
    if (
      diagnosticReports &&
      Array.isArray(diagnosticReports) &&
      diagnosticReports.length > 0
    ) {
      return diagnosticReports.find(
        (diagnosticReport) => diagnosticReport.key === diagnosticReportID
      );
    }
    return undefined;
  };

  const getServiceRequest = () => {
    if (
      serviceRequests &&
      Array.isArray(serviceRequests) &&
      serviceRequests.length > 0 &&
      getDiagnosticReport() !== undefined
    ) {
      return serviceRequests.find(
        (serviceRequest) => serviceRequest.key === getDiagnosticReport().basedOn
      );
    }
    return undefined;
  };

  const [selectedObservations, setSelectedObservations] = useState([]);
  const [conclusion, setConclusion] = useState("");
  const [isUploadingResults, setIsUploadingResults] = useState(false);

  const freeState = () => {
    setConclusion("");
    setSelectedObservations([]);
    setIsUploadingResults(false);
  };

  useEffect(() => {
    freeState();
  }, [isOpen]);

  if (
    getDiagnosticReport() === undefined ||
    getServiceRequest() === undefined
  ) {
    return (
      <Dialog
        style={{ width: "100%", maxWidth: 800 }}
        className="bp3-dark"
        isOpen={isOpen}
        onClose={onClose}
        title="Process Diagnostic Report"
      >
        <Spinner />
      </Dialog>
    );
  }

  const renderLabTests = () => {
    const labTestsByCategories = {};
    Object.values(getServiceRequest().labTests).forEach((labTest) => {
      labTestsByCategories[labTest.categoryName] = labTestsByCategories[
        labTest.categoryName
      ]
        ? [...labTestsByCategories[labTest.categoryName], labTest.labTest]
        : [labTest.labTest];
    });

    const renderLabTestsForCategory = (category) => {
      return labTestsByCategories[category].map((labTest) => {
        return <p style={{ fontSize: 12 }}>{labTest}</p>;
      });
    };

    return Object.keys(labTestsByCategories).map((category) => {
      return (
        <div>
          <Tag intent={Intent.PRIMARY}>{category}</Tag>
          <div style={{ marginLeft: 10 }}>
            {renderLabTestsForCategory(category)}
          </div>
        </div>
      );
    });
  };

  const renderUploadedFiles = () => {
    return Object.values(getDiagnosticReport().presentedForm).map(
      (uploaded) => {
        return (
          <div style={{ marginLeft: 5 }}>
            <Attachment
              type={uploaded.type}
              storagePath={uploaded.storagePath}
              storagePathResolver={storagePathResolver}
            />
          </div>
        );
      }
    );
  };

  return (
    <Dialog
      style={{ width: "100%", maxWidth: 800 }}
      className="bp3-dark"
      isOpen={
        isOpen &&
        getDiagnosticReport() !== undefined &&
        getServiceRequest() !== undefined
      }
      onClose={onClose}
      title="Process Diagnostic Report"
    >
      <div style={{ padding: "20px 20px 0px 20px" }}>
        <div className="bp3-callout .modifier">
          {getServiceRequest().labTests && (
            <div style={{ marginBottom: 10 }}>
              <h4 className="bp3-heading">Requested Scans</h4>
              <div style={{ marginLeft: 5 }}>{renderLabTests()}</div>
            </div>
          )}
          {getServiceRequest().supportingInfo && (
            <div style={{ marginBottom: 10 }}>
              <h4 className="bp3-heading">Supporting Information</h4>
              <div style={{ marginLeft: 5 }}>
                <p>{getServiceRequest().supportingInfo}</p>
              </div>
            </div>
          )}
          {getDiagnosticReport().presentedForm && (
            <div>
              <h4 className="bp3-heading">Uploaded Files</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {renderUploadedFiles()}
              </div>
            </div>
          )}
        </div>

        <div style={{ marginTop: 30 }}>
          <ObservationTypesTable
            observationTypes={observationTypes}
            selectedObservations={selectedObservations}
            setSelectedObservations={setSelectedObservations}
          />
        </div>

        <div style={{ marginTop: 20 }}>
          <H4>Conclusion (Optional - Will be visible to patient)</H4>
          <TextArea
            style={{ minWidth: "100%" }}
            intent={Intent.PRIMARY}
            value={conclusion}
            onChange={(e) => {
              setConclusion(e.target.value);
            }}
          />
        </div>

        <div style={{ marginTop: 20 }}>
          <Button
            disabled={selectedObservations.length === 0}
            text="Process Diagnostic Report"
            intent={Intent.SUCCESS}
            fill
            loading={isUploadingResults}
            onClick={async () => {
              try {
                setIsUploadingResults(true);
                await processDiagnosticReport({
                  conclusion,
                  observations: selectedObservations,
                  diagnosticReport: getDiagnosticReport(),
                });
                Notification.Success(
                  "Observations added to patient profile successfully"
                );
                onClose();
              } catch (e) {
                setIsUploadingResults(false);
                Notification.Error(
                  "Error happened when processing diagnostic report"
                );
              }
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ProcessDiagnosticReportOverlay;
