import React, { useEffect, useState } from "react";
import { Callout } from "@blueprintjs/core";
import { accountsActions } from "../../../inbox";
import "react-image-lightbox/style.css";
import MedicationsList from "../InputBox/PrescriptionInput/MedicationsList";

const PrescriptionCard = ({ answer, prescriptionRef }) => {
  const [prescription, setPrescription] = useState(undefined);
  const [medicationRequests, setMedicationRequests] = useState(undefined);

  useEffect(() => {
    (async () => {
      if (prescriptionRef) {
        accountsActions
          .getPrescription({ prescriptionRef })()
          .then((result) => {
            setPrescription(result.prescription);
            setMedicationRequests(result.medicationRequests);
          })
          .catch(() => {
            // console.log("error");`∆
          });
      } else {
        setPrescription(null);
      }
    })();
  }, [prescriptionRef]);

  if (!prescription) {
    return <></>;
  }

  return (
    <div>
      <div style={{ margin: "10px 0px" }}>
        <div style={{ marginBottom: 10 }}>{answer}</div>
        <div>
          {medicationRequests && medicationRequests.length > 0 && (
            <Callout>
              <MedicationsList medicationsList={medicationRequests} showOnly />
            </Callout>
          )}

          {prescription.prescriptionBody && (
            <>
              <h5 className="bp3-text-large" style={{ fontWeight: 600 }}>
                Prescription Body
              </h5>
              <p
                style={{
                  marginLeft: 5,
                  width: "100%",
                  whiteSpace: "pre-wrap",
                }}
              >
                {prescription.prescriptionBody}
              </p>
            </>
          )}
          {Boolean(prescription.doctorNote) && (
            <>
              <h5
                className="bp3-text-large"
                style={{ fontWeight: 600, marginTop: 5 }}
              >
                Doctor Note
              </h5>
              <div
                style={{
                  marginLeft: 5,
                  width: "100%",
                  whiteSpace: "pre-wrap",
                }}
              >
                {prescription.doctorNote}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionCard;
