export const CREATE_CONDITION_ERROR = "CREATE_CONDITION_ERROR";
export const CREATE_CONDITION_SUCCESS = "CREATE_CONDITION_SUCCESS";

export const REMOVE_CONDITION_ERROR = "REMOVE_CONDITION_ERROR";
export const REMOVE_CONDITION_SUCCESS = "REMOVE_CONDITION_SUCCESS";

export const UNDELETE_CONDITION_ERROR = "UNDELETE_CONDITION_ERROR";

export const UPDATE_CONDITION_ERROR = "UPDATE_CONDITION_ERROR";
export const UPDATE_CONDITION_SUCCESS = "UPDATE_CONDITION_SUCCESS";

export const LOAD_CONDITIONS_SUCCESS = "LOAD_CONDITIONS_SUCCESS";
export const LOAD_CONDITIONS_ERROR = "LOAD_CONDITIONS_ERROR";

export const UNLOAD_CONDITIONS = "UNLOAD_CONDITIONS";
