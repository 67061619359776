import firebase from "firebase/app";

import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";

import firebaseConfig from "./config";

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();
export const firebaseDb = firebase.database();
export const storage = firebase.storage();
export const servervalue = firebase.database.ServerValue;
export const functions = firebase.functions();
export const firestore = firebase.firestore();

const useEmulators = false;

if (useEmulators) {
  firebaseAuth.useEmulator("http://localhost:9099/");
  firebaseDb.useEmulator("localhost", 9000);
  firestore.useEmulator("localhost", 8080);
  functions.useEmulator("localhost", 5001);
  storage.useEmulator("localhost", 9199);
}

export const firestoreTimestamp = firebase.firestore.Timestamp;
