import React, { useCallback, useEffect, useState } from "react";
import * as Cookies from "js-cookie";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import { Map } from "immutable";

import InputChannel from "./InputChannel";
import "../../assets/fonts/css/icons.css";
// import { RESOLUTION_ARR } from '../../utils/Settings'
import "./index.css";

/* eslint no-unused-vars: 0 */
/* eslint global-require: 0 */
/* eslint-disable jsx-a11y/label-has-associated-control */
const Index = ({
  communicationRequest,
  loadData,
  unloadData,
  match,
  updateProviderJoinedStatus,
}) => {
  const { communicationRequestID } = match.params;
  const [joinBtn, setJoinBtn] = useState(true);
  const [channel, setChannel] = useState(null);
  const [baseMode, setBaseMode] = useState("avc");
  const [transcode, setTranscode] = useState("interop");
  const [attendeeMode, setAttendeeMode] = useState("video");
  const [videoProfile, setVideoProfile] = useState("480p_4");
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const handleJoin = useCallback(async () => {
    if (!joinBtn || !channel) {
      return;
    }
    setIsUpdatingStatus(true);
    if (communicationRequest.getIn(["payload", "status"]) === "arrived")
      await updateProviderJoinedStatus(communicationRequest);
    Cookies.set("channel", channel);
    Cookies.set("baseMode", baseMode);
    Cookies.set("transcode", transcode);
    Cookies.set("attendeeMode", attendeeMode);
    Cookies.set("videoProfile", videoProfile);
    Cookies.set("communicationRequestID", communicationRequestID);
    window.location.hash = `${channel}/meeting`;
    setIsUpdatingStatus(false);
  }, [
    attendeeMode,
    baseMode,
    channel,
    communicationRequest,
    communicationRequestID,
    joinBtn,
    transcode,
    updateProviderJoinedStatus,
    videoProfile,
  ]);

  useEffect(() => {
    loadData(communicationRequestID);
    return () => {
      unloadData();
    };
  }, [communicationRequestID, loadData, unloadData]);

  useEffect(() => {
    if (
      Map.isMap(communicationRequest) &&
      communicationRequest.hasIn(["payload", "channelName"])
    )
      setChannel(communicationRequest.getIn(["payload", "channelName"]));
    else setChannel(null);
  }, [communicationRequest]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 13) handleJoin();
    };

    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleJoin]);

  /**
   * @param {String} val 0-9 a-z A-Z _ only
   * @param {Boolean} state
   */
  // const handleChannel = (val, state) => {
  //   setChannel(val);
  //   setJoinBtn(state);
  // };

  if (!channel)
    return (
      <NonIdealState>
        <Spinner size={Spinner.SIZE_STANDARD} intent="primary" />
      </NonIdealState>
    );

  return (
    <div className="wrapper index">
      <div className="ag-header" />
      <div className="ag-main">
        <section className="login-wrapper">
          <div className="login-header">
            <img src={require("../../assets/images/ag-logo.png")} alt="" />
            <p className="login-title">Nala Call</p>
            <p className="login-subtitle">Powering Real-Time Communications</p>
          </div>
          <div className="login-body">
            <div className="columns">
              <div className="column is-12">
                <InputChannel
                  channel={channel}
                  // onChange={this.handleChannel}
                  placeholder="Input a room name here"
                />
              </div>
            </div>
            {/* <div className="columns">
              <div className="column is-7">
                <BaseOptions
                  onChange={val => this.setState({ baseMode: val })}>
                </BaseOptions>
              </div>
              <div className="column is-5">
                <AdvancedOptions
                  onRadioChange={val => this.setState({ transcode: val })}
                  onSelectChange={val => this.setState({ videoProfile: val })}>
                </AdvancedOptions>
              </div>
            </div> */}
            <div className="columns">
              <div className="column">
                <div id="attendeeMode" className="control">
                  <label className="radio">
                    <input
                      onChange={(e) => setAttendeeMode(e.target.value)}
                      value="video"
                      type="radio"
                      name="attendee"
                      defaultChecked
                    />
                    <span className="radio-btn" />
                    <span className="radio-img video" />
                    <span className="radio-msg">
                      Video Call : join with video call
                    </span>
                  </label>
                  <br />
                  <label className="radio">
                    <input
                      onChange={(e) => setAttendeeMode(e.target.value)}
                      value="audio-only"
                      type="radio"
                      name="attendee"
                    />
                    <span className="radio-btn" />
                    <span className="radio-img audio" />
                    <span className="radio-msg">
                      Audio-only : join with audio call
                    </span>
                  </label>
                  <br />
                  {/* <label className="radio">
                    <input onChange={e => this.setState({ attendeeMode: e.target.value })}
                      value="audience" type="radio"
                      name="attendee" />
                    <span className="radio-btn">
                    </span>
                    <span className="radio-img audience">
                    </span>
                    <span className="radio-msg">Audience : join as an audience</span>
                  </label> */}
                </div>
              </div>
            </div>
          </div>
          <div className="login-footer">
            <button
              id="joinBtn"
              type="button"
              onClick={handleJoin}
              disabled={!channel}
              className="ag-rounded button is-info"
            >
              {isUpdatingStatus ? (
                <Spinner size={Spinner.SIZE_SMALL} intent="primary" />
              ) : (
                "Join"
              )}
            </button>
          </div>
        </section>
      </div>
      <div className="ag-footer">
        <a className="ag-href" href="https://www.agora.io">
          <span>Powered By Agora</span>
        </a>
      </div>
    </div>
  );
};

// class BaseOptions extends React.Component {
//   constructor(props) {
//     super(props)
//     this._options = [
//       {
//         label: 'Agora Video Call',
//         value: 'avc',
//         content: 'One to one and group calls'
//       },
//       {
//         label: 'Agora Live',
//         value: 'al',
//         content: 'Enabling real-time interactions between the host and the audience'
//       }
//     ]
//     this.state = {
//       active: false,
//       message: 'Agora Video Call',

//     }
//   }

//   handleSelect = (item) => {
//     let msg = item.label
//     let val = item.value
//     this.setState({
//       'message': msg,
//       'active': false
//     })
//     this.props.onChange(val)
//   }

//   render() {
//     const options = this._options.map((item, index) => {
//       return (
//         <div className="dropdown-item"
//           key={index}
//           onClick={(e) => this.handleSelect(item, e)}>
//           <p>{item.label}</p>
//           <hr />
//           <p>{item.content}</p>
//         </div>
//       )
//     })

//     return (
//       <div className={this.state.active ? 'dropdown is-active' : 'dropdown'}>
//         <div className="dropdown-trigger"
//           onClick={() => this.setState({ 'active': !this.state.active })}>
//           <a id="baseMode" className="ag-rounded button" aria-haspopup="true" aria-controls="baseModeOptions">
//             <span id="baseOptionLabel">{this.state.message}</span>
//             <span className="icon is-small">
//               <i className="ag-icon ag-icon-arrow-down" aria-hidden="true"></i>
//             </span>
//           </a>
//         </div>
//         <div className="dropdown-menu" id="baseModeOptions" role="menu">
//           <div className="dropdown-content">
//             {options}
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// class AdvancedOptions extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       active: false,
//     }
//   }

//   handleRadio = (e) => {
//     this.props.onRadioChange(e.target.value)
//   }

//   handleSelect = (e) => {
//     this.props.onSelectChange(e.target.value)
//   }

//   render() {
//     const options = Object.entries(RESOLUTION_ARR).map((item, index) => {
//       return (
//         <option key={index} value={item[0].split(",")[0]}>{item[1][0]}x {item[1][1]}, {item[1][2]}fps, {item[1][3]}kbps</option>
//       )
//     })
//     return (
//       <div className={this.state.active ? 'dropdown is-active' : 'dropdown'}>
//         <div className="dropdown-trigger"
//           onClick={() => this.setState({ 'active': !this.state.active })}>
//           <a id="advancedProfile" className="ag-rounded button" aria-haspopup="true" aria-controls="advancedOptions">
//             <span>Advanced</span>
//           </a>
//         </div>
//         <div className="dropdown-menu" id="advancedOptions" role="menu">
//           <div className="dropdown-content">
//             <div className="dropdown-item">
//               <div className="control">
//                 <label className="radio">
//                   <input value="" type="radio" name="transcode" onChange={this.handleRadio} />
//                   <span>VP8-only</span>
//                 </label>
//                 <label className="radio">
//                   <input value="interop" type="radio" defaultChecked onChange={this.handleRadio} name="transcode" />
//                   <span>VP8 &amp; H264</span>
//                 </label>
//                 <label className="radio">
//                   <input value="h264_interop" type="radio" onChange={this.handleRadio} name="transcode" />
//                   <span>H264-only</span>
//                 </label>
//               </div>
//             </div>
//             <div className="dropdown-item">
//               <div className="select is-rounded">
//                 <select onChange={this.handleSelect} defaultValue="480p_4" id="videoProfile" className="ag-rounded is-clipped">
//                   {options}
//                 </select>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

export default Index;
