import { createSelector } from "reselect";
import { List } from "immutable";

export function getAccounts(state) {
  return state.sessions;
}

export function getLoaded(state) {
  return getAccounts(state).loaded;
}

export function getAccountList(state) {
  return getAccounts(state).list;
}

export function getAccountFilter(state) {
  return getAccounts(state).filter;
}

//= ====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getAllSessions = createSelector(getAccountList, (accounts) => {
  let sessions = new List();
  if (accounts.get(0)) {
    accounts.forEach((account) => {
      if (account.sessions && Object.keys(account.sessions).length > 0) {
        Object.keys(account.sessions).forEach((sessionKey) => {
          const sessionObject = {
            userID: account.key,
            key: sessionKey,
            ...account.sessions[sessionKey],
          };
          sessions = sessions.push(sessionObject);
        });
      }
    });
  }
  return sessions;
});

// not really sure how to solve it.
// eslint-disable-next-line consistent-return
export const getAllQuestions = createSelector(getAccountList, (accounts) => {
  if (accounts.get(0)) {
    accounts.forEach((account) => {
      if (account.sessions) {
        if (account.sessions && Object.keys(account.sessions).length > 0) {
          Object.keys(account.sessions).forEach((sessionKey) => {
            const sessionObject = account.sessions[sessionKey];
            if (
              sessionObject.questions &&
              Object.keys(sessionObject.questions)
            ) {
              Object.keys(sessionObject.questions).map((questionKey) => {
                const questionObject = sessionObject.questions[questionKey];
                return questionObject;
                // return {
                //   sessionKey: sessionKey,
                //   sessionStarted: sessionObject.started,
                //   sessionEnded: sessionObject.ended,
                //   sessionCondition: sessionObject.condition,
                //   sessionIsEmergency: sessionObject.isEmergency,
                //   patientAge: sessionObject.patient ? sessionObject.patient.age : '',
                //   patientGender: sessionObject.patient ? sessionObject.patient.gender : '',
                //   questionKey: questionKey,
                //   questionUser: questionObject.question,
                //   questionAnswer: questionObject.answer,
                //   questionSent: questionObject.sent,
                //   questionResponded: questionObject.responded,
                //   questionRespondedBy: questionObject.respondedBy,
                //   questionIsEndingSession: questionObject.isEndingSession,
                // };
              });
            }
          });
        }
      }
    });
  } else {
    return new List();
  }
});
