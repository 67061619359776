import { firebaseDb, FirebaseList } from "../firebase";
// import userList from "./user-list";
import User from "./user";

import {
  CREATE_USER_SUCCESS,
  REMOVE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  LOAD_USERS_SUCCESS,
  UNLOAD_USERS_SUCCESS,
} from "./action-types";

export function createUserSuccess(user) {
  return {
    type: CREATE_USER_SUCCESS,
    payload: user,
  };
}

export function removeUserSuccess(user) {
  return {
    type: REMOVE_USER_SUCCESS,
    payload: user,
  };
}

export function updateUserSuccess(user) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user,
  };
}

export function loadUsersSuccess(users) {
  return {
    type: LOAD_USERS_SUCCESS,
    payload: users,
  };
}

/** user-list */

const userList = new FirebaseList(
  {
    onAdd: createUserSuccess,
    onChange: updateUserSuccess,
    onRemove: removeUserSuccess,
    onLoad: loadUsersSuccess,
  },
  User
);

/** */

export function loadUsers() {
  return (dispatch) => {
    userList.path = `users/`;
    userList.subscribe(dispatch);
  };
}

export function unloadUsers() {
  userList.unsubscribe();
  return {
    type: UNLOAD_USERS_SUCCESS,
  };
}

export function toggleOnline(userID) {
  return () => {
    firebaseDb.ref("users").child(userID).child("online/respondblue").set({
      isOnline: true,
    });
  };
}

export function toggleOffline(userID) {
  return () => {
    firebaseDb.ref("users").child(userID).child("online/respondblue").set({
      isOnline: false,
    });
  };
}
