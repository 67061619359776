import { List, Record } from "immutable";
import { SIGN_OUT_SUCCESS } from "../auth/action-types";
import {
  CREATE_USER_SUCCESS,
  REMOVE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  LOAD_USERS_SUCCESS,
} from "./action-types";

export const UsersState = new Record({
  list: new List(),
});

export function usersReducer(state = new UsersState(), { payload, type }) {
  switch (type) {
    case CREATE_USER_SUCCESS:
      return state.merge({
        list: state.list.unshift(payload),
      });

    case REMOVE_USER_SUCCESS:
      return state.merge({
        list: state.list.filter((user) => user.key !== payload.key),
      });

    case UPDATE_USER_SUCCESS:
      return state.merge({
        list: state.list.map((user) => {
          return user.key === payload.key ? payload : user;
        }),
      });

    case LOAD_USERS_SUCCESS:
      return state.set("list", new List(payload.reverse()));

    case SIGN_OUT_SUCCESS:
      return new UsersState();

    default:
      return state;
  }
}
