/* eslint-disable no-console */
/* eslint-disable global-require */
import "./views/styles/index.css";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";

import { register } from "./registerServiceWorker";
import CacheBuster from "./CacheBuster";

import { initAuth } from "./auth";
import history from "./history";
import configureStore from "./store";
import App from "./views/app";

import { version } from "../package.json";

const store = configureStore();
const rootElement = document.getElementById("root");

function render(Component) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              // You can decide how and when you want to force reload
              refreshCacheAndReload();
            }

            return (
              <div className="route-container">
                <Component
                  pathname={history.location.pathname}
                  history={history}
                />
              </div>
            );
          }}
        </CacheBuster>
      </ConnectedRouter>
    </Provider>,
    rootElement
  );
}

function printVersion() {
  console.log(`
  ███╗   ██╗ █████╗ ██╗      █████╗     ██████╗ ██╗     ██╗   ██╗███████╗
  ████╗  ██║██╔══██╗██║     ██╔══██╗    ██╔══██╗██║     ██║   ██║██╔════╝
  ██╔██╗ ██║███████║██║     ███████║    ██████╔╝██║     ██║   ██║█████╗  
  ██║╚██╗██║██╔══██║██║     ██╔══██║    ██╔══██╗██║     ██║   ██║██╔══╝  
  ██║ ╚████║██║  ██║███████╗██║  ██║    ██████╔╝███████╗╚██████╔╝███████╗
  ╚═╝  ╚═══╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝    ╚═════╝ ╚══════╝ ╚═════╝ ╚══════╝
  V.${version}
  `);
}

printVersion();

if (module.hot) {
  module.hot.accept("./views/app", () => {
    render(require("./views/app").default);
  });
}

// unregister();

register();

initAuth(store.dispatch, store.getState)
  .then(() => render(App))
  .catch((error) => console.error(error));
