// import specialtyList from "./specialty-list";
import { FirebaseList } from "../firebase";
import Specialty from "./specialty";

import {
  CREATE_SPECIALTY_SUCCESS,
  REMOVE_SPECIALTY_SUCCESS,
  UPDATE_SPECIALTY_SUCCESS,
  LOAD_SPECIALTIES_SUCCESS,
  LOAD_SPECIALTIES_ERROR,
  UNLOAD_SPECIALTIES,
} from "./action-types";

export function createSpecialtySuccess(specialty) {
  return {
    type: CREATE_SPECIALTY_SUCCESS,
    payload: specialty,
  };
}

export function removeSpecialtySuccess(specialty) {
  return {
    type: REMOVE_SPECIALTY_SUCCESS,
    payload: specialty,
  };
}

export function updateSpecialtySuccess(specialty) {
  return {
    type: UPDATE_SPECIALTY_SUCCESS,
    payload: specialty,
  };
}

export function loadSpecialtiesSuccess(specialties) {
  return {
    type: LOAD_SPECIALTIES_SUCCESS,
    payload: specialties,
  };
}

export function loadSpecialtiesError(specialties) {
  return {
    type: LOAD_SPECIALTIES_ERROR,
    payload: specialties,
  };
}

/** specialty-lit */

const specialtyList = new FirebaseList(
  {
    onAdd: createSpecialtySuccess,
    onChange: updateSpecialtySuccess,
    onRemove: removeSpecialtySuccess,
    onLoad: loadSpecialtiesSuccess,
  },
  Specialty
);

/** */

export function loadSpecialties() {
  return (dispatch) => {
    specialtyList.path = `knowledge/specialties`;
    specialtyList.subscribe(dispatch);
  };
}

export function unloadSpecialties() {
  specialtyList.unsubscribe();
  return {
    type: UNLOAD_SPECIALTIES,
  };
}
