/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
import React from "react";
import DispenseRequestActions from "../InputBox/PrescriptionInput/DispenseRequestActions";

const PharmacyItemList = ({
  pharmacyItems,
  showReadyAmount = false,
  showDispensedAmount = false,
  dispenseRequestActions = {
    markDispenseRequestAsCancelled: () => {},
    markDispenseRequestAsOnHold: () => {},
    markDispenseRequestAsCompleted: () => {},
  },
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <table
        className="bp3-html-table .modifier .bp3-interactive"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th>SKU</th>
            <th>Name</th>
            <th>Quantity</th>
            {showDispensedAmount && <th>Dispensed Quantity</th>}
            {showReadyAmount && <th>Ready Quantity</th>}
          </tr>
        </thead>
        <tbody>
          {pharmacyItems.map((item) => {
            return (
              <PharmacyItemRow
                key={`${item.sku}${item.associatedDispenseRequest?.dispenseRequest?.key}${item.associatedDispenseRequest?.dispenseRequest?.quantity}`}
                item={item}
                dispenseRequestActions={dispenseRequestActions}
                showReadyAmount={showReadyAmount}
                showDispensedAmount={showDispensedAmount}
                associatedDispenseRequest={
                  item.associatedDispenseRequest
                    ? item.associatedDispenseRequest.dispenseRequest
                    : undefined
                }
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const getDispensedQuantity = ({ dispenseRequests }) => {
  let dispensedQuantity = 0;
  for (const dispenseRequestKey in dispenseRequests) {
    const dispenseRequest = dispenseRequests[dispenseRequestKey];
    if (
      dispenseRequest.status === "on-hold" ||
      dispenseRequest.status === "completed"
    ) {
      dispensedQuantity += parseInt(dispenseRequest.quantity, 10);
    }
  }
  return dispensedQuantity;
};

const PharmacyItemRow = ({
  item,
  associatedDispenseRequest,
  dispenseRequestActions,
  showDispensedAmount,
  showReadyAmount,
}) => {
  item.dispensedQuantity = getDispensedQuantity({
    dispenseRequests: item.dispenseRequests,
  });

  return (
    <tr>
      <td>{item.sku}</td>
      <td>{item.description}</td>
      <td>{item.quantity}</td>
      {showDispensedAmount && (
        <td>
          {item.dispensedQuantity}/{item.quantity}
        </td>
      )}
      {showReadyAmount && (
        <td>{item.associatedDispenseRequest.dispenseRequest.quantity} </td>
      )}
      <td style={{ width: 300 }}>
        <DispenseRequestActions
          dispenseItem={item}
          associatedDispenseRequest={associatedDispenseRequest}
          dispenseRequestActions={dispenseRequestActions}
        />
      </td>
    </tr>
  );
};

export default PharmacyItemList;
