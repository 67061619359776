import { List, Record } from "immutable";
import {
  CREATE_ACCOUNT_SUCCESS,
  REMOVE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  LOAD_ACCOUNTS_SUCCESS,
} from "./action-types";

export const AccountsState = new Record({
  // Accounts
  list: new List(),
  loaded: false,
});

export function sessionsReducer(
  state = new AccountsState(),
  { payload, type }
) {
  switch (type) {
    case CREATE_ACCOUNT_SUCCESS:
      return state.merge({
        list: state.list.unshift(payload),
      });

    case REMOVE_ACCOUNT_SUCCESS:
      return state.merge({
        list: state.list.filter((account) => account.key !== payload.key),
      });

    case UPDATE_ACCOUNT_SUCCESS:
      return state.merge({
        loaded: true,
        list: state.list.map((account) => {
          return account.key === payload.key ? payload : account;
        }),
      });

    case LOAD_ACCOUNTS_SUCCESS:
      return state.merge({
        list: new List(payload),
        loaded: true,
      });

    default:
      return state;
  }
}
