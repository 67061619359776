import { MenuItem } from "@blueprintjs/core";
import React from "react";

const ObservationTypesOptions = ({
  selectedObservations,
  observationTypes,
  searchObservationTerm,
  onSelectObservationTypeOption,
}) => {
  if (observationTypes) {
    const selectedObservationKeys =
      selectedObservations !== undefined
        ? selectedObservations.map((selectedObservation) => {
            return selectedObservation.observationType.key;
          })
        : [];

    const filteredObservations = observationTypes
      .filter((observationType) => {
        if (searchObservationTerm === "") {
          return true;
        }
        if (
          Boolean(observationType.displayName) &&
          Boolean(observationType.displayName.en) &&
          observationType.displayName.en
            .toLowerCase()
            .includes(searchObservationTerm.toLowerCase())
        ) {
          return true;
        }
        return false;
      })
      .filter((observationType2) => {
        if (selectedObservationKeys.includes(observationType2.key)) {
          return false;
        }
        return true;
      });

    if (filteredObservations.length === 0) {
      return <p>No Results</p>;
    }

    return filteredObservations.map((observationType) => {
      return (
        <MenuItem
          key={observationType.key}
          text={observationType.displayName.en}
          onClick={() => {
            onSelectObservationTypeOption(observationType);
          }}
        />
      );
    });
  }
  return <p>No Options</p>;
};

export default ObservationTypesOptions;
