import React, { useState } from "react";
import moment from "moment";

import {
  Dialog,
  Classes,
  H4,
  TextArea,
  Intent,
  FormGroup,
  Divider,
  Spinner,
} from "@blueprintjs/core";
import { TimePrecision, DatePicker } from "@blueprintjs/datetime";
import Notification from "../Notification";
import ReminderTypeSelect from "../ReminderTypeSelect";

const timeNow = new Date();
timeNow.setSeconds(0, 0);

const PatientReminderOverlay = ({
  isOpen,
  onClose,
  createPatientReminder,
  activeHealthcareService,
  practitionerSpeciality,
}) => {
  const [reminderType, setReminderType] = useState("me");
  const [note, setNote] = useState("");
  const [isCustomTime, setIsCustomTime] = useState(false);
  const [reminderCustomTime, setReminderCustomTime] = useState(
    moment(new Date()).add(1, "days").toDate()
  );
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () => {
    setReminderType("me");
    setNote("");
    setIsCustomTime(false);
    setReminderCustomTime(moment(new Date()).add(1, "days").toDate());
    onClose();
  };

  const timeOptions = [
    {
      title: "In a week",
      time: moment().add(1, "weeks"),
    },
    {
      title: "In a month",
      time: moment().add(1, "months"),
    },
    {
      title: "In 3 months",
      time: moment().add(3, "months"),
    },
  ];

  const onSubmit = async ({ dateTime }) => {
    setIsLoading(true);
    try {
      await createPatientReminder({
        reminderType,
        note,
        reminderTime: moment.unix(dateTime).toDate(),
      });
      setIsLoading(false);
      closeModal();
      Notification.Success("Reminder created successfully");
    } catch (error) {
      setIsLoading(false);
      Notification.Error(error.message);
    }
  };

  const handleReminderTimePressed = (time) => {
    onSubmit({ dateTime: time.unix() });
  };

  return (
    <Dialog
      className="bp3-dark"
      isOpen={isOpen}
      onClose={() => {
        closeModal();
      }}
      icon="time"
      title="Remind me about this patient"
    >
      <style type="text/css">
        .hidden {"{ display:none; }"}
        .centered{" "}
        {"{ display:flex; flex-direction:column; align-items:center; }"}
      </style>

      <div
        style={{ marginBottom: 0, minHeight: 200 }}
        className={Classes.DIALOG_BODY}
      >
        {isLoading ? (
          <div
            style={{ height: 200, display: "flex", justifyContent: "center" }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            <ReminderTypeSelect
              type="patientReminders"
              setReminderType={setReminderType}
              practitionerSpeciality={practitionerSpeciality}
              activeHealthcareService={activeHealthcareService}
            />

            <div style={{ marginTop: 15 }}>
              <H4>Note</H4>
              <TextArea
                style={{ minWidth: "100%" }}
                intent={Intent.PRIMARY}
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </div>

            <div style={{ marginTop: 15, marginBottom: 15 }}>
              {timeOptions.map((timeOption) => {
                return (
                  <ReminderTimeButton
                    key={timeOption.title}
                    leftText={timeOption.title}
                    rightText={timeOption.time.format("D MMM YYYY")}
                    clickHandler={() => {
                      handleReminderTimePressed(timeOption.time);
                    }}
                  />
                );
              })}
              {!isCustomTime && (
                <ReminderTimeButton
                  leftText="Custom time"
                  clickHandler={() => {
                    setIsCustomTime(true);
                  }}
                />
              )}
            </div>

            {isCustomTime && (
              <>
                <Divider />

                <div style={{ marginTop: 15 }}>
                  <FormGroup
                    label="Choose a custom time"
                    helperText="Scheduled time in (Riyadh Time GMT+3)"
                  >
                    <div style={{ marginBottom: 10 }}>
                      <DatePicker
                        minDate={
                          new Date(
                            moment(timeNow)
                              .add(1, "days")
                              .toDate()
                              .setHours(0, 0, 0, 0)
                          )
                        }
                        maxDate={new Date(moment().add(2, "year").valueOf())}
                        className="centered"
                        name="confirmedDatetime"
                        onChange={(newDate) => {
                          if (newDate !== null) {
                            setReminderCustomTime(newDate);
                          }
                        }}
                        value={reminderCustomTime}
                        timePrecision={TimePrecision.MINUTE}
                      />
                    </div>
                    {reminderCustomTime !== undefined && (
                      <ReminderTimeButton
                        leftText={`In ${moment
                          .duration(moment(timeNow).diff(reminderCustomTime))
                          .humanize()}`}
                        rightText={moment(reminderCustomTime).format(
                          "D MMM YYYY H:mm"
                        )}
                        clickHandler={() => {
                          handleReminderTimePressed(moment(reminderCustomTime));
                        }}
                      />
                    )}
                  </FormGroup>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
};

export default PatientReminderOverlay;

export const ReminderTimeButton = ({ leftText, rightText, clickHandler }) => {
  return (
    <button
      type="button"
      className="bp3-button"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 8,
      }}
      onClick={clickHandler}
    >
      <div className="bp3-ui-text" style={{ fontWeight: 600 }}>
        {leftText}
      </div>
      {rightText && (
        <div
          className="bp3-ui-text bp3-text-disabled"
          style={{ fontWeight: 600 }}
        >
          {rightText}
        </div>
      )}
    </button>
  );
};
