import React, { useCallback, useMemo, useState } from "react";
import { H1, Tabs, Tab } from "@blueprintjs/core";
import { List } from "immutable";
import moment from "moment";
import { PatientCard } from ".";
import SkeletonList from "../SkeletonList";

const getDayTimestamp = (date) => {
  return date.setHours(0, 0, 0, 0);
};

const isCheckedIn = (communicationRequestsList) => {
  return communicationRequestsList.some(
    (request) => request.payload && request.payload.status === "arrived"
  );
};

const sortCheckedInPatientsToTop = (patientA, patientB) => {
  if (patientA.isCheckedIn && !patientB.isCheckedIn) return -1;
  if (!patientA.isCheckedIn && patientB.isCheckedIn) return 1;
  return 0;
};

export default function PatientsSection({
  profiles,
  isProfilesLoaded,
  communicationRequests,
  isCommunicationRequestsLoaded,
  selectPatient,
  selectedPatient,
  pairedPatients,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState("today");
  const isDataLoaded = isProfilesLoaded && isCommunicationRequestsLoaded;

  const getCommunicationRequestsFromID = useCallback(
    (patientID) => {
      return communicationRequests.filter((communicationRequest) => {
        return communicationRequest.subject === patientID;
      });
    },
    [communicationRequests]
  );

  const generateProfilesListFromCommunicationRequests = useMemo(() => {
    if (!isCommunicationRequestsLoaded || !isProfilesLoaded) {
      return {};
    }

    const result = {};

    communicationRequests.forEach((communicationRequest) => {
      const requestDay = getDayTimestamp(
        communicationRequest.occurrenceDateTime.toDate()
      );
      if (result[requestDay] === undefined) {
        result[requestDay] = {};
      }

      const patient = profiles && profiles[communicationRequest.subject];

      if (!patient) {
        // console.log(
        //   `patient with id ${communicationRequest.subject} does not exist in activeHealthcareService`
        // );
        return;
      }

      if (!result[requestDay][communicationRequest.subject]) {
        result[requestDay][communicationRequest.subject] = {
          patient,
          communicationRequests: [communicationRequest],
        };
      } else {
        const patientRequests =
          result[requestDay][communicationRequest.subject]
            .communicationRequests;
        result[requestDay][communicationRequest.subject] = {
          patient,
          communicationRequests: [communicationRequest, ...patientRequests],
        };
      }
    });
    return result;
  }, [
    communicationRequests,
    isCommunicationRequestsLoaded,
    isProfilesLoaded,
    profiles,
  ]);

  const profilesByCommunicationRequestObject =
    generateProfilesListFromCommunicationRequests;

  const getTodayPatients = () => {
    const todayObject =
      profilesByCommunicationRequestObject[`${getDayTimestamp(new Date())}`];
    if (todayObject === undefined) {
      return new List();
    }
    return Object.values(todayObject).map((o) => {
      return {
        profile: o.patient,
        isCheckedIn: isCheckedIn(o.communicationRequests),
      };
    });
  };

  /**
   * Deduplicate multiple pairings with same patient
   * Add communication Requests and Profile
   */
  const myPatientsWithProfiles = useMemo(() => {
    return pairedPatients
      .reduce((result, pairing) => {
        const patientAddedAlready = result.some(
          (pair) => pair.patientID === pairing.patientID
        );
        return !patientAddedAlready ? [...result, pairing] : result;
      }, [])
      .map((pairing) => {
        const patientCommunicationRequests = getCommunicationRequestsFromID(
          pairing.patientID
        );
        return {
          profile: profiles && profiles[pairing.patientID],
          isCheckedIn: isCheckedIn(patientCommunicationRequests),
          communicationRequests: patientCommunicationRequests,
        };
      })
      .filter((pairing) => pairing.profile);
  }, [getCommunicationRequestsFromID, pairedPatients, profiles]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          marginBottom: "1.23em",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <H1>Patients</H1>
        </div>
      </div>
      <div style={{ overflowY: "scroll", paddingRight: 17 }}>
        <Tabs
          id="TabsExample"
          onChange={(tabID) => {
            setSelectedTab(tabID);
          }}
          selectedTabId={selectedTab}
        >
          <Tab
            id="today"
            title="Today"
            panel={
              <TodayTab
                patients={getTodayPatients()}
                searchTerm={searchTerm}
                isDataLoaded={isDataLoaded}
                selectedPatient={selectedPatient}
                selectPatient={selectPatient}
              />
            }
          />
          <Tab
            id="all"
            title="All"
            panel={
              <AllTab
                profilesByCommunicationRequestObject={
                  profilesByCommunicationRequestObject
                }
                searchTerm={searchTerm}
                isDataLoaded={isDataLoaded}
                selectedPatient={selectedPatient}
                selectPatient={selectPatient}
              />
            }
            panelClassName="ember-panel"
          />
          <Tab
            id="my-patients"
            title="My Patients"
            panel={
              <MyPatientsTab
                patients={myPatientsWithProfiles}
                searchTerm={searchTerm}
                isDataLoaded={isDataLoaded}
                selectedPatient={selectedPatient}
                selectPatient={selectPatient}
              />
            }
          />
          <Tabs.Expander />
          <input
            className="bp3-input"
            type="text"
            placeholder="Search by ID, Name, Phone"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </Tabs>
      </div>
    </div>
  );
}

const NoReminders = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="bp3-text-disabled">No reminders</div>
    </div>
  );
};

const filterProfilesForSearch = ({ patients, searchTerm, anonymize }) => {
  return patients.filter((patient) => {
    if (searchTerm === "") {
      return true;
    }
    if (
      !anonymize &&
      patient.profile.fullName &&
      patient.profile.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return true;
    }
    if (
      !anonymize &&
      patient.profile.name &&
      patient.profile.name.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return true;
    }
    if (
      !anonymize &&
      patient.profile.phoneNumber &&
      patient.profile.phoneNumber.includes(searchTerm)
    ) {
      return true;
    }
    if (patient.profile.key && patient.profile.key.includes(searchTerm)) {
      return true;
    }
    return false;
  });
};

const MyPatientsTab = ({
  patients,
  searchTerm,
  isDataLoaded,
  selectedPatient,
  selectPatient,
}) => {
  const renderedPatients = filterProfilesForSearch({ patients, searchTerm });
  if (isDataLoaded && renderedPatients.size === 0) {
    return <NoReminders />;
  }

  return (
    <>
      <div className="scrollable" style={{ overflow: "scroll" }}>
        {!isDataLoaded && (
          <div>
            <SkeletonList width={295} height={58} numOfElements={5} />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isDataLoaded &&
            renderedPatients.sort(sortCheckedInPatientsToTop).map((patient) => {
              return (
                <PatientCard
                  key={patient.profile.key}
                  profile={patient.profile}
                  selectPatient={selectPatient}
                  isSelected={
                    selectedPatient.profile &&
                    patient.profile.key === selectedPatient.profile.key
                  }
                  isCheckedIn={patient.isCheckedIn}
                  displayName
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

const TodayTab = ({
  patients,
  searchTerm,
  isDataLoaded,
  selectedPatient,
  selectPatient,
}) => {
  const renderedPatients = filterProfilesForSearch({
    patients,
    searchTerm,
    anonymize: true,
  });

  if (isDataLoaded && renderedPatients.size === 0) {
    return <NoReminders />;
  }

  return (
    <>
      <div className="scrollable" style={{ overflow: "scroll" }}>
        {!isDataLoaded && (
          <div>
            <SkeletonList width={295} height={58} numOfElements={5} />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isDataLoaded &&
            renderedPatients.sort(sortCheckedInPatientsToTop).map((patient) => {
              return (
                <PatientCard
                  key={patient.profile.key}
                  profile={patient.profile}
                  selectPatient={selectPatient}
                  isSelected={
                    selectedPatient.profile &&
                    patient.profile.key === selectedPatient.profile.key
                  }
                  isCheckedIn={patient.isCheckedIn}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

const AllTab = ({
  profilesByCommunicationRequestObject,
  searchTerm,
  isDataLoaded,
  selectedPatient,
  selectPatient,
}) => {
  if (
    isDataLoaded &&
    Object.values(profilesByCommunicationRequestObject).length === 0
  ) {
    return <NoReminders />;
  }

  return (
    <>
      <div className="scrollable" style={{ overflow: "scroll" }}>
        {!isDataLoaded && (
          <div>
            <SkeletonList width={295} height={58} numOfElements={5} />
          </div>
        )}
        {isDataLoaded &&
          Object.keys(profilesByCommunicationRequestObject)
            .sort(
              (timestampA, timestampB) =>
                parseInt(timestampA, 10) - parseInt(timestampB, 10)
            )
            .map((timestamp) => {
              const getTimeText = () => {
                if (`${getDayTimestamp(new Date())}` === timestamp) {
                  return "Today";
                }
                return moment
                  .unix(parseInt(timestamp, 10) / 1000)
                  .format("D MMM. YYYY");
              };

              const patients = Object.values(
                profilesByCommunicationRequestObject[timestamp]
              ).map((object) => ({
                profile: object.patient,
                isCheckedIn: isCheckedIn(object.communicationRequests),
              }));

              const renderedPatients = filterProfilesForSearch({
                patients,
                searchTerm,
                anonymize: true,
              });

              if (renderedPatients.length === 0) {
                return <></>;
              }

              return (
                <div key={timestamp} style={{ marginBottom: 10 }}>
                  <div
                    style={{ fontWeight: 700 }}
                    className="bp3-text-disabled"
                  >
                    {getTimeText()}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {renderedPatients
                      .sort(sortCheckedInPatientsToTop)
                      .map((patient) => {
                        return (
                          <PatientCard
                            key={`${patient.profile.key}${patient.profile.timestamp}`}
                            profile={patient.profile}
                            selectPatient={selectPatient}
                            isSelected={
                              selectedPatient.profile &&
                              patient.profile.key ===
                                selectedPatient.profile.key
                            }
                            isCheckedIn={patient.isCheckedIn}
                          />
                        );
                      })}
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};
