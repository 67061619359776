import React from "react";
import { Tag } from "@blueprintjs/core";

const ServiceProviderConversationText = ({
  text,
  intent,
  practitioner,
  time,
}) => {
  const practitionerName = () => {
    if (practitioner) {
      return `${practitioner} - `;
    }
    return "";
  };

  return (
    <>
      <Tag
        className="chatbox-item item-doctor"
        large
        interactive={false}
        round={false}
        multiline
        intent={intent}
      >
        {text}
      </Tag>
      <div
        className="bp3-text-small bp3-text-muted"
        style={{
          marginLeft: "10px",
          marginTop: "-5px",
        }}
      >
        {`${practitionerName(practitioner)} ${time}`}
      </div>
    </>
  );
};

export default ServiceProviderConversationText;
