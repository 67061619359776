import { firestore } from "../firebase";
import * as t from "../communicationRequests/action-types";
import { ReduxDispatch } from "../types/redux";
import { AppointmentCommunicationRequest } from "../types/communicationRequest";

let unsubscribe: () => void;

function subscribeCommunicationRequest(communicationRequestID: string) {
  return (dispatch: ReduxDispatch) => {
    const query = firestore
      .collection("communicationRequests")
      .doc(communicationRequestID);
    unsubscribe = query.onSnapshot((doc) => {
      dispatch({
        type: t.LOAD_APPOINTMENT_COMMUNICATION_REQUESTS_SUCCESS,
        payload: { ...doc.data(), id: doc.id },
      });
      doc.data();
    });
  };
}

export function loadData(communicationRequestID: string) {
  return (dispatch: ReduxDispatch) => {
    subscribeCommunicationRequest(communicationRequestID)(dispatch);
  };
}

export function updateCommunicationRequest({
  communicationRequestID,
  update,
}: {
  communicationRequestID: string;
  update: object;
}) {
  return async () => {
    await firestore
      .collection("communicationRequests")
      .doc(communicationRequestID)
      .update(update);
  };
}

export function unloadData() {
  return (dispatch: ReduxDispatch) => {
    if (unsubscribe) unsubscribe();
    dispatch({
      type: t.UNLOAD_APPOINTMENT_COMMUNICATION_REQUESTS_SUCCESS,
    });
  };
}

export function updateProviderJoinedStatus(communicationRequestMap: any) {
  return async () => {
    try {
      const communicationRequest =
        communicationRequestMap.toJS() as AppointmentCommunicationRequest;
      const {
        id: communicationRequestID,
        payload,
        subject,
        recipient,
        requester: practitionerID,
      } = communicationRequest;
      const { practitionerRole, healthcareService } = recipient;
      const { appointmentID } = payload;

      const batch = firestore.batch();

      const encounterRef = firestore.collection("encounters").doc();
      batch.set(encounterRef, {
        appointmentID,
        class: "virtual",
        status: "in-progress",
        statusHistory: {
          "in-progress": new Date(),
        },
        subject,
        period: {
          startAt: new Date(),
        },
        participant: {
          practitionerID,
          practitionerRoleID: practitionerRole,
          healthcareServiceID: healthcareService,
        },
      });

      const appointmentRef = firestore
        .collection("appointments")
        .doc(appointmentID);
      batch.update(appointmentRef, {
        encounterID: encounterRef.id,
        status: "fulfilled",
        "statusHistory.fulfilled": new Date(),
      });

      const communicationRequestRef = firestore
        .collection("communicationRequests")
        .doc(communicationRequestID);
      batch.update(communicationRequestRef, {
        "payload.status": "provider-joined",
        "payload.encounterID": encounterRef.id,
      });
      await batch.commit();
    } catch (err) {
      // console.log("error", err);
    }
  };
}
