import { List, Record } from "immutable";

import {
  LOAD_SPECIALTIES_SUCCESS,
  CREATE_SPECIALTY_SUCCESS,
} from "./action-types";

export const SpecialtiesState = new Record({
  list: new List(),
});
export function specialtiesReducer(
  state = new SpecialtiesState(),
  { payload, type }
) {
  switch (type) {
    case LOAD_SPECIALTIES_SUCCESS:
      return state.set("list", new List(payload));

    case CREATE_SPECIALTY_SUCCESS:
      return state.set("list", state.list.unshift(payload));

    default:
      return state;
  }
}
