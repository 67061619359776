function getBookings(state) {
  return state.bookings;
}

export function getBookingSlots(state) {
  return getBookings(state).bookingSlots;
}

export function getBookingsLoaded(state) {
  return getBookings(state).loaded;
}

export function getIsCreatingSlots(state) {
  return getBookings(state).isCreating;
}

export function getPractitionerRole(state) {
  return state.auth.practitionerRole;
}

export function getIsUpdatingAvailability(state) {
  return getBookings(state).isUpdatingAvailability;
}

export function getIsChangingDuration(state) {
  return getBookings(state).isChangingDuration;
}

export function getToggledSlots(state) {
  return getBookings(state).toggledSlots;
}

export function getShifts(state) {
  const { practitionerRole } = state.auth.toJS();
  if (!practitionerRole) return null;
  const { shifts } = practitionerRole;

  return shifts || {};
}

export function getIsUpdatingShifts(state) {
  return getBookings(state).isUpdatingShifts;
}
