import { List, Record, Map, fromJS } from "immutable";
import { SIGN_OUT_SUCCESS } from "../auth/action-types";
import {
  SELECT_ACCOUNT,
  SELECT_PROFILE,
  SEND_ANSWER,
  TOGGLE_EDIT,
  END_SESSION,
  UPDATE_INBOX,
  SELECT_INBOX,
  SET_LOADED,
  CLEAR_ACTIVE_ACCOUNT_AND_SESSION,
  LOAD_LAB_TESTS,
  SET_WEEK_AVG_RESPONSE_TIME,
} from "./action-types";

export const AccountsState = new Record({
  // Accounts
  list: new List(),
  selectedAccount: undefined,
  selectedSession: undefined,
  selectedProfile: undefined,
  selectedSpeciality: "primary-care",

  // inboxes
  activeInbox: null,
  practitionerWeeklyAverageResponseTime: null,
  inboxes: new Map(),
  labTests: new List(),

  // System
  edit: false,
  loaded: false,
});

export function inboxReducer(state = new AccountsState(), { payload, type }) {
  // flags the responded session so it doesn't
  // become visible after responding to a question
  const processInboxSessions = () => {
    const { activeInbox } = state;
    const inboxes = state.inboxes.toJS();
    const { sessionKey, userID } = payload;
    if (
      sessionKey &&
      userID &&
      inboxes &&
      inboxes[activeInbox] &&
      inboxes[activeInbox].inboxObject
    ) {
      delete inboxes[activeInbox].inboxObject.activeSessions[
        `${payload.userID}+${payload.sessionKey}`
      ];
    }
    return inboxes;
  };

  switch (type) {
    case SELECT_ACCOUNT:
      return state.merge(
        fromJS({
          selectedAccount: payload.account,
          selectedSession: payload.sessionKey,
        })
      );

    case SELECT_PROFILE:
      return state.merge({
        selectedProfile: payload,
      });

    case CLEAR_ACTIVE_ACCOUNT_AND_SESSION:
      return state.merge({
        selectedSession: undefined,
        selectedAccount: undefined,
      });

    case SEND_ANSWER:
      return state.merge(
        fromJS({
          edit: false,
          selectedAccount: null,
          selectedSession: null,
          inboxes: processInboxSessions(),
        })
      );

    case SIGN_OUT_SUCCESS:
      return new AccountsState();

    case TOGGLE_EDIT:
      return state.merge({
        edit: !state.edit,
      });

    case END_SESSION:
      return state.merge({
        selectedAccount: null,
        edit: false,
      });

    case UPDATE_INBOX:
      return state.merge({
        inboxes: state.inboxes.merge(
          fromJS({
            [payload.inboxKey]: {
              inboxKey: payload.inboxKey,
              inboxName: payload.inboxName,
              inboxObject: payload.inboxObject,
            },
          })
        ),
      });

    case SELECT_INBOX:
      return state.merge({
        activeInbox: payload.inboxKey,
      });

    case SET_LOADED:
      return state.merge({
        loaded: true,
      });

    case LOAD_LAB_TESTS:
      return state.merge(
        fromJS({
          labTests: payload.labTests,
        })
      );

    case SET_WEEK_AVG_RESPONSE_TIME:
      return state.merge(
        fromJS({
          practitionerWeeklyAverageResponseTime:
            payload.practitionerWeeklyAverageResponseTime,
        })
      );

    default:
      return state;
  }
}
