const firebaseConfig = {
  apiKey: "AIzaSyAKofVGhp0qa0h-LHhAO72NYXXqI3FBSTI",
  authDomain: "nala-native.firebaseapp.com",
  databaseURL: "https://nala-native.firebaseio.com",
  projectId: "nala-native",
  storageBucket: "nala-native.appspot.com",
  messagingSenderId: "473365896948",
};

export default firebaseConfig;
