import * as t from "./action-types";

import { FirebaseList } from "../firebase";
import Practitioner from "./practitioner";

export function createPractitionerSuccess(practitioner) {
  return {
    type: t.CREATE_PRACTITIONER_SUCCESS,
    payload: practitioner,
  };
}

export function removePractitionerSuccess(practitioner) {
  return {
    type: t.REMOVE_PRACTITIONER_SUCCESS,
    payload: practitioner,
  };
}

export function updatePractitionerSuccess(practitioner) {
  return {
    type: t.UPDATE_PRACTITIONER_SUCCESS,
    payload: practitioner,
  };
}

export function loadPractitionersSuccess(practitioners) {
  return {
    type: t.LOAD_PRACTITIONERS_SUCCESS,
    payload: practitioners,
  };
}

const practitionerList = new FirebaseList(
  {
    onAdd: createPractitionerSuccess,
    onChange: updatePractitionerSuccess,
    onRemove: removePractitionerSuccess,
    onLoad: loadPractitionersSuccess,
  },
  Practitioner
);

export function loadPractitioners() {
  return (dispatch) => {
    practitionerList.path = `practitioners/`;
    practitionerList.subscribe(dispatch);
  };
}

export function unloadPractitioners() {
  return (dispatch) => {
    practitionerList.unsubscribe();
    dispatch({
      type: t.UNLOAD_PRACTITIONERS_SUCCESS,
    });
  };
}
