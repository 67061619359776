import { Program } from "nala-shared/typings/general/program";
import { ReducerState } from "../types/redux";

function getSettings(state: ReducerState) {
  return state.settings;
}

export function getProfile(state: ReducerState) {
  return getSettings(state).profile;
}

export function getIsUpdatingProfile(state: ReducerState) {
  return getSettings(state).isUpdatingProfile;
}

export function getProfileLoaded(state: ReducerState) {
  return getSettings(state).loaded;
}

export function getIsUpdatingRole(state: ReducerState) {
  return getSettings(state).isUpdatingRole;
}

export function getProgramConfigs(state: ReducerState) {
  return getSettings(state).programConfigs;
}

export function getParticipatingPrograms(state: ReducerState) {
  const { practitionerRole } = state.auth.toJS();
  const programConfigs = getProgramConfigs(state);

  if (!practitionerRole || !programConfigs.count()) return null;
  const { participatingPrograms } = practitionerRole;
  const { speciality } = state.auth.practitionerRole.toJSON();

  // Remove programs not available for speciality
  Object.keys(participatingPrograms).forEach((program) => {
    const programConfig = programConfigs.get(program as Program);
    if (!programConfig) return;
    if (!programConfig.get("acceptableSpecialities").includes(speciality))
      delete participatingPrograms[program];
  });

  return participatingPrograms || {};
}

export function getPractitionerRole(state: ReducerState) {
  return state.auth.practitionerRole;
}

export function getAssignedPatients(state: ReducerState) {
  return getSettings(state).assignedPatients;
}
