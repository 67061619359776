export default (program) => {
  switch (program) {
    case "primary-care":
      return "Primary Care Program";
    case "mental-health":
      return "Mental Health Program";
    case "diabetes-and-hypertension":
      return "Diabetes and Hypertension";
    case "wasfaty-program":
      return "Wasfaty Program";
    default:
      return "";
  }
};
