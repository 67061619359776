import { InputGroup, Radio, RadioGroup } from "@blueprintjs/core";
import React from "react";

const ObservationValueInput = ({
  selectedObservation,
  setSelectedValue,
  selectedValue,
}) => {
  const isPercentage =
    selectedObservation &&
    Boolean(selectedObservation.updateRules) &&
    selectedObservation.updateRules.isPercentage === true;

  const isNumber =
    selectedObservation &&
    Boolean(selectedObservation.updateRules) &&
    selectedObservation.updateRules.type === "number";

  const isBoolean =
    selectedObservation &&
    Boolean(selectedObservation.updateRules) &&
    selectedObservation.updateRules.type === "boolean";

  if (isBoolean) {
    return (
      <div style={{ marginTop: 5 }}>
        <RadioGroup
          inline
          onChange={(e) => {
            setSelectedValue(e.currentTarget.value);
          }}
          selectedValue={selectedValue}
        >
          <Radio label="True" value="true" />
          <Radio label="False" value="false" />
        </RadioGroup>
      </div>
    );
  }

  return (
    <InputGroup
      type={isNumber ? "number" : "text"}
      disabled={selectedObservation === undefined}
      title="Value"
      placeholder=""
      value={selectedValue}
      onChange={(e) => {
        if (isPercentage && isNumber) {
          if (
            parseInt(e.target.value, 10) > 100 ||
            parseInt(e.target.value, 10) < 0
          ) {
            return;
          }
        }
        setSelectedValue(e.target.value);
      }}
    />
  );
};

export default ObservationValueInput;
