import React, { useState } from "react";
import { Alignment, ButtonGroup, AnchorButton } from "@blueprintjs/core";
import PatientReminderOverlay from "./PatientReminderOverlay";
import ObservationAlertOverlay from "./ObservationAlertOverlay";
import { isSecondaryProfile } from "../../../utils";

const PatientActionButtons = ({
  patient,
  createPatientReminder,
  createObservationAlert,
  activeHealthcareService,
  practitionerSpeciality,
}) => {
  const { observations } = patient;
  const [isPatientReminderOverlayVisible, setPatientReminderOverlayVisible] =
    useState(false);
  const [isObservationAlertOverlayVisible, setObservationAlertOverlayVisible] =
    useState(false);

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        {!isSecondaryProfile(patient.key) && (
          <ButtonGroup
            style={{ minWidth: 200 }}
            fill
            alignText={Alignment.LEFT}
            vertical
            minimal
          >
            <AnchorButton
              icon="time"
              rightIcon="chevron-right"
              onClick={(e) => {
                e.stopPropagation();
                setPatientReminderOverlayVisible(true);
              }}
            >
              Remind me about this patient
            </AnchorButton>
          </ButtonGroup>
        )}
      </div>
      {observations !== null &&
        observations !== undefined &&
        !isSecondaryProfile(patient.key) && (
          <div style={{ marginTop: 20 }}>
            <ButtonGroup
              style={{ minWidth: 200 }}
              fill
              alignText={Alignment.LEFT}
              vertical
              minimal
            >
              <AnchorButton
                icon="warning-sign"
                rightIcon="chevron-right"
                onClick={(e) => {
                  e.stopPropagation();
                  setObservationAlertOverlayVisible(true);
                }}
              >
                Set observation alert
              </AnchorButton>
            </ButtonGroup>
          </div>
        )}
      <PatientReminderOverlay
        createPatientReminder={createPatientReminder}
        isOpen={isPatientReminderOverlayVisible}
        onClose={() => {
          setPatientReminderOverlayVisible(false);
        }}
        activeHealthcareService={activeHealthcareService}
        practitionerSpeciality={practitionerSpeciality}
      />
      {observations !== undefined && (
        <ObservationAlertOverlay
          createObservationAlert={createObservationAlert}
          observations={observations}
          isOpen={isObservationAlertOverlayVisible}
          onClose={() => {
            setObservationAlertOverlayVisible(false);
          }}
          activeHealthcareService={activeHealthcareService}
          practitionerSpeciality={practitionerSpeciality}
        />
      )}
    </div>
  );
};

export default PatientActionButtons;
