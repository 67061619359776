import { fromJS, List, Record } from "immutable";
import * as t from "./action-types";

export const CommunicationRequestsState = new Record({
  // Accounts
  communicationRequestsList: new List(),
  loaded: false,
  appointmentCommunicationRequest: new Map(),
});

export function communicationRequestsReducer(
  state = new CommunicationRequestsState(),
  { payload, type }
) {
  switch (type) {
    case t.LOAD_COMMUNICATION_REQUESTS_SUCCESS:
      return state.merge({
        communicationRequestsList: new List(payload),
        loaded: true,
      });

    case t.CREATE__COMMUNICATION_REQUESTS_SUCCESS:
      return state.merge({
        communicationRequestsList:
          state.communicationRequestsList.unshift(payload),
      });

    case t.REMOVE_COMMUNICATION_REQUESTS_SUCCESS:
      return state.merge({
        communicationRequestsList: state.communicationRequestsList.filter(
          (communicationRequest) => communicationRequest.key !== payload.key
        ),
      });

    case t.UPDATE__COMMUNICATION_REQUESTS_SUCCESS:
      return state.merge({
        communicationRequestsList: state.communicationRequestsList.map(
          (communicationRequest) => {
            return communicationRequest.key === payload.key
              ? payload
              : communicationRequest;
          }
        ),
      });

    case t.LOAD_APPOINTMENT_COMMUNICATION_REQUESTS_SUCCESS:
      return state.merge(
        fromJS({
          appointmentCommunicationRequest: payload,
        })
      );

    case t.UNLOAD_APPOINTMENT_COMMUNICATION_REQUESTS_SUCCESS:
      return state.merge({
        appointmentCommunicationRequest: new Map(),
      });

    default:
      return state;
  }
}
