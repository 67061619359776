// import observationTypeList from "./observationType-list";
import { FirebaseList } from "../firebase";
import ObservationType from "./observationType";

import {
  CREATE_OBSERVATION_TYPE_SUCCESS,
  REMOVE_OBSERVATION_TYPE_SUCCESS,
  UPDATE_OBSERVATION_TYPE_SUCCESS,
  LOAD_OBSERVATION_TYPES_SUCCESS,
  LOAD_OBSERVATION_TYPES_ERROR,
  UNLOAD_OBSERVATION_TYPES,
} from "./action-types";

export function createObservationTypeSuccess(observationType) {
  return {
    type: CREATE_OBSERVATION_TYPE_SUCCESS,
    payload: observationType,
  };
}

export function removeObservationTypeSuccess(observationType) {
  return {
    type: REMOVE_OBSERVATION_TYPE_SUCCESS,
    payload: observationType,
  };
}

export function updateObservationTypeSuccess(observationType) {
  return {
    type: UPDATE_OBSERVATION_TYPE_SUCCESS,
    payload: observationType,
  };
}

export function loadObservationTypesSuccess(observationTypes) {
  return {
    type: LOAD_OBSERVATION_TYPES_SUCCESS,
    payload: observationTypes,
  };
}

export function loadObservationTypesError(observationTypes) {
  return {
    type: LOAD_OBSERVATION_TYPES_ERROR,
    payload: observationTypes,
  };
}

/** observationType-lit */

const observationTypeList = new FirebaseList(
  {
    onAdd: createObservationTypeSuccess,
    onChange: updateObservationTypeSuccess,
    onRemove: removeObservationTypeSuccess,
    onLoad: loadObservationTypesSuccess,
  },
  ObservationType
);

/** */

export function loadObservationTypes() {
  return (dispatch) => {
    observationTypeList.path = `knowledge/observationTypes`;
    observationTypeList.subscribe(dispatch);
  };
}

export function unloadObservationTypes() {
  observationTypeList.unsubscribe();
  return {
    type: UNLOAD_OBSERVATION_TYPES,
  };
}
