const RecommendedAnswers = [
  {
    title: "متى بدأت الحالة؟",
    name: "since-when",
    userRecommendations: {
      userInput: {
        recommendations: {
          1: { title: "أقل من يوم", value: "أقل من يوم" },
          2: { title: "من يوم إلى أسبوع", value: "من يوم إلى أسبوع" },
          3: { title: "من أسبوع إلى شهر", value: "من أسبوع إلى شهر" },
          4: { title: "من شهر إلى سنة", value: "من شهر إلى سنة" },
          5: { title: "من سنة أو أكثر", value: "من سنة أو أكثر" },
        },
        freeText: true,
        title: "اكتب متى بدأت معك الحالة",
      },
      flags: {
        isSinceWhen: true,
      },
    },
  },
  {
    title: "هل يمكنك توضيح السؤال؟",
    name: "clarify-please",
    userRecommendations: {
      flags: {
        isClarifyPlease: true,
      },
    },
  },
  {
    title: "أنا دائمًا في خدمتك إذا كان لديك سؤال آخر",
    name: "end-conversation",
    userRecommendations: {
      userInput: {
        // recommendations: {
        //   1: {title: 'لا', value: 'لا', isEndConversation: true}
        // },
        freeText: true,
        title: "اكتب سؤالك",
      },
    },
  },
];

export default RecommendedAnswers;
