import { Record } from "immutable";

const ObservationType = new Record({
  key: null,
  displayName: {
    ar: null,
    en: null,
  },
  updateRules: null,
});

export default ObservationType;
