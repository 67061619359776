import { firebaseDb } from "../firebase";
import { communicationRequestsActions } from "../communicationRequests";

export function createObservationAlert({
  note,
  reminderType,
  observation,
  formula,
  dateTime,
  patientID,
  alertEmergencyContact,
}) {
  return async (dispatch, getState) => {
    const { id, activeHealthcareService, practitionerRole } = getState().auth;

    const practitionerRoleKey = practitionerRole
      ? practitionerRole.get("key")
      : undefined;
    const practitionerSpeciality = practitionerRole
      ? practitionerRole.get("speciality")
      : undefined;

    if (reminderType === "remindSpeciality" && !practitionerSpeciality) {
      throw new Error("practitioner has no speciality");
    }

    if (reminderType === "remindMe" && !practitionerRoleKey) {
      throw new Error("practitioner has no practitionerRole");
    }

    const recipient = communicationRequestsActions.buildRecipientObject({
      reminderType,
      healthcareService: activeHealthcareService,
      practitionerRole: practitionerRoleKey,
      speciality: practitionerSpeciality,
    });

    const observationAlertDatabaseKey = await firebaseDb
      .ref(`profiles/${patientID}/observationAlerts/${observation}`)
      .push().key;

    await firebaseDb
      .ref(
        `profiles/${patientID}/observationAlerts/${observation}/${observationAlertDatabaseKey}`
      )
      .set({
        note,
        requester: id,
        recipient,
        formula,
        dateTime,
        alertEmergencyContact,
      });
  };
}

export function removeObservationAlert({
  observationAlertKey,
  patientKey,
  observationKey,
}) {
  return async () => {
    await firebaseDb
      .ref(
        `profiles/${patientKey}/observationAlerts/${observationKey}/${observationAlertKey}`
      )
      .remove();
  };
}
