function getMedicationsReducer(state) {
  return state.medications;
}

export function getMedications(state) {
  return getMedicationsReducer(state).medications.toArray();
}

export function getIsMedicationsLoaded(state) {
  return getMedicationsReducer(state).loaded;
}
