import React, { useEffect, useState } from "react";
import { NonIdealState, Tab, Tabs, Spinner } from "@blueprintjs/core";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import ProfilePanel from "../../components/ProfilePanel";
import ProgramsPanel from "../../components/ProgramsPanel";

import {
  settingsActions,
  getProfileLoaded,
  getProfile,
  getIsUpdatingProfile,
  getIsUpdatingRole,
  getParticipatingPrograms,
  getPractitionerRole,
  getAssignedPatients,
} from "../../../settings";

const SettingsPage = ({
  practitionerRole,
  profileLoaded,
  profile,
  isUpdatingProfile,
  loadData,
  unloadData,
  updateProfile,
  isUpdatingRole,
  participatingPrograms,
  updateParticipatingPrograms,
  assignedPatients,
}) => {
  const [selectedTab, setSelectedTab] = useState("profile");
  const handleTabChange = (tabId) => {
    setSelectedTab(tabId);
  };

  useEffect(() => {
    loadData();
    return () => {
      unloadData();
    };
  }, [loadData, unloadData, practitionerRole]);

  return (
    <div
      style={{ padding: 15, flex: 1, alignItems: "center" }}
      className="scrollable"
    >
      {(!profileLoaded || !participatingPrograms) && (
        <NonIdealState>
          <Spinner size={Spinner.SIZE_STANDARD} intent="primary" />
        </NonIdealState>
      )}
      {profileLoaded && participatingPrograms && (
        <Tabs
          id="TabsExample"
          onChange={handleTabChange}
          selectedTabId={selectedTab}
          vertical
          animate
        >
          <Tab
            id="profile"
            title="Profile"
            panel={
              <ProfilePanel
                profile={profile}
                updateProfile={updateProfile}
                isUpdatingProfile={isUpdatingProfile}
              />
            }
          />
          <Tab
            id="roles"
            title="Roles"
            panel={
              <ProgramsPanel
                isUpdatingRole={isUpdatingRole}
                participatingPrograms={participatingPrograms}
                updateParticipatingPrograms={updateParticipatingPrograms}
                assignedPatients={assignedPatients}
              />
            }
            panelClassName="ember-panel"
          />
        </Tabs>
      )}
    </div>
  );
};

const mapStateToProps = createSelector(
  getProfileLoaded,
  getProfile,
  getIsUpdatingProfile,
  getIsUpdatingRole,
  getParticipatingPrograms,
  getPractitionerRole,
  getAssignedPatients,
  (
    profileLoaded,
    profile,
    isUpdatingProfile,
    isUpdatingRole,
    participatingPrograms,
    practitionerRole,
    assignedPatients
  ) => ({
    profileLoaded,
    profile,
    isUpdatingProfile,
    isUpdatingRole,
    participatingPrograms,
    practitionerRole,
    assignedPatients,
  })
);
const mapDispatchToProps = {
  ...settingsActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
