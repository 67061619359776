/* eslint-disable jsx-a11y/anchor-is-valid */
// Disabled because changing to button causes style issue
import React from "react";
import { Button, Intent } from "@blueprintjs/core";
import SessionBox from "./SessionBox";
import InputBox from "../InputBox";

const InboxRespondBox = ({
  storagePathResolver,
  selectedSession,
  sendAction,
  toggleEdit,
  conditions,
  medications,
  specialties,
  endSession,
  selectedSessionPreviousSession,
  practitioners,
  changeSpeciality,
  activeInbox,
  clearActiveAccountAndSession,
  edit,
  hideSession,
  labTests,
  createPatientReminder,
  activeHealthcareService,
  practitionerSpeciality,
  createObservationAlert,
  observationTypes,
  addObservation,
}) => {
  const getQuestions = (session) => {
    if (session.questions && typeof session.questions === "object") {
      return Object.values(session.questions).sort((questionA, questionB) => {
        return questionA.sent - questionB.sent;
      });
    }
    return [];
  };

  const isResponded = () => {
    if (selectedSession) {
      const { questions } = selectedSession;
      if (typeof questions === "object") {
        const lastQuestionKey = Object.keys(questions).sort(
          (questionAKey, questionBKey) => {
            return questions[questionBKey].sent - questions[questionAKey].sent;
          }
        )[0];

        const lastQuestion = questions ? questions[lastQuestionKey] : null;
        if (lastQuestion) {
          return !!lastQuestion.answer;
        }
        return false;
      }
      return false;
    }
    return false;
  };

  return (
    <>
      <InboxBreadCrumbs
        activeInbox={activeInbox}
        clearActiveAccountAndSession={clearActiveAccountAndSession}
        selectedSession={selectedSession}
      />
      <div className="respond-box scrollable">
        <SessionBox
          createObservationAlert={createObservationAlert}
          createPatientReminder={createPatientReminder}
          activeHealthcareService={activeHealthcareService}
          practitionerSpeciality={practitionerSpeciality}
          storagePathResolver={storagePathResolver}
          questions={
            selectedSession ? getQuestions(selectedSession) : undefined
          }
          selectedSession={selectedSession}
          patient={{
            ...selectedSession.patient,
            key: selectedSession.patientID,
            observations: selectedSession.observations,
          }}
          responded={false}
          sendAction={sendAction}
          edit={edit}
          toggleEdit={toggleEdit}
          conditions={conditions}
          onSave={endSession}
          previousSessions={selectedSessionPreviousSession}
          practitioners={practitioners}
          labTests={labTests}
          observationTypes={observationTypes}
          addObservation={addObservation}
        />
        {!isResponded() ? (
          <InputBox
            patientID={selectedSession.patientID}
            source="session"
            activeHealthcareService={activeHealthcareService}
            practitionerSpeciality={practitionerSpeciality}
            selectedSpeciality={selectedSession.speciality}
            sendAction={sendAction}
            changeSpeciality={changeSpeciality}
            medications={medications}
            conditions={conditions}
            labTests={labTests}
            specialties={specialties}
          />
        ) : (
          <div>
            <Button
              onClick={() => {
                hideSession();
              }}
              large
              intent={Intent.SUCCESS}
              icon="tick"
              style={{ width: "100%", marginTop: 20 }}
            >
              Hide
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export const InboxBreadCrumbs = ({
  activeInbox,
  clearActiveAccountAndSession,
  selectedSession,
}) => {
  return (
    <div
      style={{
        backgroundColor: "#30404D",
        padding: 3,
        paddingLeft: 15,
      }}
    >
      <ul className="bp3-breadcrumbs">
        <li>
          <a
            type="button"
            onClick={() => {
              clearActiveAccountAndSession();
            }}
            style={{ margin: 0 }}
            className="bp3-breadcrumb"
          >
            {activeInbox.inboxName.length > 30
              ? `${activeInbox.inboxName.slice(0, 30)}...`
              : activeInbox.inboxName}
          </a>
        </li>
        <li>
          <span className="bp3-breadcrumb bp3-breadcrumb-current">
            {selectedSession.sessionTitle.length > 30
              ? `${selectedSession.sessionTitle.slice(0, 30)}...`
              : selectedSession.sessionTitle}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default InboxRespondBox;
