import React, { useEffect } from "react";
import { Card, NonIdealState, Spinner, Button } from "@blueprintjs/core";
import { BOLD } from "@blueprintjs/icons/lib/esm/generated/iconNames";

const moment = require("moment");

// const QuestionSound = new Audio("definite.mp3");

const SessionCard = ({
  sessionKey,
  accountKey,
  lastQuestionSentTime,
  questionContent,
  selectSession,
  isEmergencyRequest,
}) => {
  // calculate the sum of the UTF-16 code unit value of the last 3 characters of accountKey and % 4
  const slotNumber =
    (accountKey.charCodeAt(accountKey.length - 1) +
      accountKey.charCodeAt(accountKey.length - 2) +
      accountKey.charCodeAt(accountKey.length - 3)) %
    4;
  let slotLetter;
  if (slotNumber === 0) slotLetter = "A";
  else if (slotNumber === 1) slotLetter = "B";
  else if (slotNumber === 2) slotLetter = "C";
  else slotLetter = "D";

  const colors = {
    NORMAL: "#5C7080",
    WARNING: "#D9822B",
    ALERT: "#DB3737",
  };
  const hoursSince = moment.duration(new Date() - lastQuestionSentTime).hours();
  const nonAlertColor = hoursSince >= 2 ? colors.WARNING : colors.NORMAL;
  const colorCode = hoursSince >= 6 ? colors.ALERT : nonAlertColor;

  const cardStyle = {
    borderLeftColor: isEmergencyRequest ? colors.ALERT : colorCode,
  };

  return (
    <Card
      className="account-card"
      onClick={() => {
        selectSession({ accountKey, sessionKey });
      }}
      style={cardStyle}
      interactive
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            whiteSpace: "nowrap",
            paddingRight: "15px",
          }}
        >
          <p
            style={{
              fontWeight: BOLD,
              whiteSpace: "nowrap",
            }}
          >
            {!!lastQuestionSentTime && moment(lastQuestionSentTime).fromNow()}
            {isEmergencyRequest && (
              <Button
                icon="warning-sign"
                intent="danger"
                small
                style={{ marginLeft: 10 }}
              >
                Emergency
              </Button>
            )}
          </p>
          <p
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {questionContent.length > 30
              ? `${questionContent.slice(0, 30)}...`
              : questionContent}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {slotLetter}&nbsp;
        </div>
      </div>
    </Card>
  );
};

const SessionCards = ({
  activeSessions,
  loaded,
  selectSession,
  loadAverageResponseTime,
  practitionerWeeklyAverageResponseTime,
  inboxType,
}) => {
  useEffect(() => {
    if (activeSessions === null && inboxType === "PractitionerRole") {
      loadAverageResponseTime();
    }
  }, [activeSessions, loadAverageResponseTime, inboxType]);

  const sessionsElement = () => {
    return Object.values(activeSessions)
      .sort((sessionA, sessionB) => {
        return sessionA.lastQuestionSentTime - sessionB.lastQuestionSentTime;
      })
      .map((sessionObject) => {
        const {
          asknalaAccount,
          asknalaSession,
          lastQuestionContent,
          firstQuestionContent,
          lastQuestionSentTime,
          emergencyRequest,
        } = sessionObject;

        return (
          <SessionCard
            key={asknalaSession}
            accountKey={asknalaAccount}
            sessionKey={asknalaSession}
            questionContent={firstQuestionContent || lastQuestionContent}
            lastQuestionSentTime={lastQuestionSentTime}
            selectSession={selectSession}
            isEmergencyRequest={emergencyRequest}
          />
        );
      });
  };

  const renderActiveSessions = () => {
    // no active sessions
    if (
      (loaded && activeSessions === null) ||
      (activeSessions && Object.keys(activeSessions).length === 0)
    ) {
      if (
        practitionerWeeklyAverageResponseTime &&
        inboxType === "PractitionerRole"
      ) {
        return (
          <NonIdealState>
            <p style={{ fontSize: 34, fontWeight: 900 }}>
              {moment
                .duration(practitionerWeeklyAverageResponseTime)
                .humanize()
                .toUpperCase()}
            </p>
            <p style={{ fontSize: 20, opacity: 0.6, fontWeight: 200 }}>
              Weekly Average Response Time
            </p>
          </NonIdealState>
        );
      }
      return (
        <NonIdealState
          icon="updated"
          title="No More Questions"
          description="You have responded to all questions. Wait until the next one."
        />
      );
    }

    // loading
    if (!loaded && activeSessions === undefined) {
      return (
        <NonIdealState>
          <Spinner size={Spinner.SIZE_STANDARD} intent="primary" />
        </NonIdealState>
      );
    }

    if (loaded && activeSessions) {
      return sessionsElement();
    }

    return <></>;
  };

  return (
    <div
      className="accounts-container scrollable"
      style={{
        flexDirection: "column",
        flex: 1,
      }}
    >
      {renderActiveSessions()}
    </div>
  );
};

export default SessionCards;
