import React, { useState } from "react";
import { Button, RadioGroup, Radio, Intent, Callout } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";

const TimeSlotInput = ({
  isCreating,
  createTimeSlots = () => null,
  shifts,
}) => {
  const [duration, setDuration] = useState(10);
  const areShiftsAvailable = shifts && Object.keys(shifts).length;

  return (
    <div style={{ padding: 20, display: "flex", flexDirection: "row" }}>
      <form
        style={{ fontSize: 12, width: "40%" }}
        onSubmit={(event) => {
          event.preventDefault();
          if (duration) createTimeSlots(duration);
        }}
      >
        <RadioGroup
          helperText="Only for days after latest appointment"
          label="Create slots duration (minutes) per patient:"
          labelFor="duration"
          onChange={(event) => {
            setDuration(Number(event.target.value));
          }}
          selectedValue={duration}
          inline
        >
          <Radio label="10 minutes (6 patients per hour)" value={10} />
          <Radio label="15 minutes (4 patients per hour)" value={15} />
          <Radio label="20 minutes (3 patients per hour)" value={20} />
          <Radio label="30 minutes (2 patients per hour)" value={30} />
          <Radio label="60 minutes (1 patient per hour)" value={60} />
        </RadioGroup>

        <Tooltip2
          content={<span>Please set your weekly shifts first</span>}
          placement="top"
          disabled={areShiftsAvailable}
        >
          <Button
            text="Create Slots"
            intent={Intent.SUCCESS}
            type="submit"
            loading={isCreating}
            disabled={!areShiftsAvailable}
          />
        </Tooltip2>
      </form>
      <div style={{ display: "inline-block", marginLeft: 15 }}>
        <Callout intent="primary" title="Please Note">
          <ul style={{ listStyle: "circle", marginLeft: 15, paddingRight: 10 }}>
            <li>We’re creating slots for 14 upcoming days including today 🗓</li>
          </ul>
        </Callout>
      </div>
    </div>
  );
};

export default TimeSlotInput;
