import { List, Record } from "immutable";
import * as t from "./action-types";

export const DispenseRequestsState = new Record({
  // Accounts
  dispenseRequestsList: new List(),
  loaded: false,
  pharmacyOrderInvoices: List(),
  loadedPharmacyOrderInvoices: false,
});

export function dispenseRequestsReducer(
  state = new DispenseRequestsState(),
  { payload, type }
) {
  switch (type) {
    case t.LOAD_DISPENSE_REQUESTS_SUCCESS:
      return state.merge({
        dispenseRequestsList: new List(payload),
        loaded: true,
      });

    case t.CREATE_DISPENSE_REQUESTS_SUCCESS:
      return state.merge({
        dispenseRequestsList: state.dispenseRequestsList.unshift(payload),
      });

    case t.REMOVE_DISPENSE_REQUESTS_SUCCESS:
      return state.merge({
        dispenseRequestsList: state.dispenseRequestsList.filter(
          (dispenseRequest) => dispenseRequest.key !== payload.key
        ),
      });

    case t.UPDATE_DISPENSE_REQUESTS_SUCCESS:
      return state.merge({
        dispenseRequestsList: state.dispenseRequestsList.map(
          (dispenseRequest) => {
            return dispenseRequest.key === payload.key
              ? payload
              : dispenseRequest;
          }
        ),
      });

    case t.LOAD_PHARMACY_ORDER_INVOICES_SUCCESS:
      return state.merge({
        pharmacyOrderInvoices: List(payload),
        loadedPharmacyOrderInvoices: true,
      });

    case t.CREATE_PHARMACY_ORDER_INVOICES_SUCCESS:
      return state.merge({
        pharmacyOrderInvoices: state.pharmacyOrderInvoices.unshift(payload),
      });

    case t.REMOVE_PHARMACY_ORDER_INVOICES_SUCCESS:
      return state.merge({
        pharmacyOrderInvoices: state.pharmacyOrderInvoices.filter(
          (pharmacyOrderInvoice) => pharmacyOrderInvoice.key !== payload.key
        ),
      });

    case t.UPDATE_PHARMACY_ORDER_INVOICES_SUCCESS:
      return state.merge({
        pharmacyOrderInvoices: state.pharmacyOrderInvoices.map(
          (pharmacyOrderInvoice) => {
            return pharmacyOrderInvoice.key === payload.key
              ? payload
              : pharmacyOrderInvoice;
          }
        ),
      });

    default:
      return state;
  }
}
