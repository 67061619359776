import React, { useState, useEffect } from "react";
import { Button, Intent, FormGroup, InputGroup } from "@blueprintjs/core";

const ProfilePanel = ({
  profile,
  updateProfile = () => null,
  isUpdatingProfile,
}) => {
  const [errors, setErrors] = useState({});
  // inputs that have been changed
  // const [touched, setTouched] = useState({});
  const [inputChanged, setInputChanged] = useState(false);

  const initialValues = {
    nameAR: profile.getIn(["name", "ar"]),
    nameEN: profile.getIn(["name", "en"]),
    givenNameAR: profile.getIn(["name", "givenName", "ar"]),
    givenNameEN: profile.getIn(["name", "givenName", "en"]),
  };

  // Check validity of form on load
  useEffect(() => {
    Object.keys(initialValues).forEach((key) => {
      if (!initialValues[key])
        setErrors((prev) => ({
          ...prev,
          [key]: true,
        }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    updateProfile({
      name: {
        ar: event.target.nameAR.value,
        en: event.target.nameEN.value,
      },
      givenName: {
        ar: event.target.givenNameAR.value,
        en: event.target.givenNameEN.value,
      },
    });
    setInputChanged(false);
    // setTouched({});
  };

  const onChangeCheckValidity = (event) => {
    setInputChanged(true);
    const { name, value } = event.target;

    // setTouched((prev) => ({
    //   ...prev,
    //   [name]: true,
    // }));

    const minLength = 1;
    if (value.length < minLength)
      setErrors((prev) => ({
        ...prev,
        [name]: true,
      }));
    else
      setErrors((prev) => {
        if (Object.keys(prev).includes(name)) {
          const { [name]: remove, ...rest } = prev;
          return rest;
        }
        return prev;
      });
  };

  const getInputIntent = (name) => {
    return name in errors && inputChanged;
  };

  return (
    <div>
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={onSubmit}
        onChange={onChangeCheckValidity}
      >
        <FormGroup
          label="Full name in Arabic"
          labelFor="name-ar"
          inline
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <InputGroup
            name="nameAR"
            id="name-ar"
            placeholder="د. أحمد محمد"
            defaultValue={profile.getIn(["name", "ar"])}
            intent={getInputIntent("nameAR") ? "danger" : "primary"}
          />
        </FormGroup>
        <FormGroup
          label="Full name in English"
          labelFor="name-en"
          inline
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <InputGroup
            name="nameEN"
            type="text"
            id="name-en"
            placeholder="Dr. Ahmad Muhammad"
            defaultValue={profile.getIn(["name", "en"])}
            intent={getInputIntent("nameEN") ? "danger" : "primary"}
          />
        </FormGroup>

        <FormGroup
          label="Given name in Arabic"
          labelFor="given-name-ar"
          inline
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <InputGroup
            name="givenNameAR"
            type="text"
            id="given-name-ar"
            placeholder="أحمد"
            defaultValue={profile.getIn(["name", "givenName", "ar"])}
            intent={getInputIntent("givenNameAR") ? "danger" : "primary"}
          />
        </FormGroup>
        <FormGroup
          label="Given name in English"
          labelFor="given-name-en"
          inline
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <InputGroup
            name="givenNameEN"
            type="text"
            id="given-name-en"
            placeholder="Ahmad"
            defaultValue={profile.getIn(["name", "givenName", "en"])}
            intent={getInputIntent("givenNameEN") ? "danger" : "primary"}
          />
        </FormGroup>

        <Button
          disabled={Object.keys(errors).length || !inputChanged}
          text="Save"
          intent={Intent.SUCCESS}
          type="submit"
          loading={isUpdatingProfile}
        />
      </form>
    </div>
  );
};

export default ProfilePanel;
