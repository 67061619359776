import React from "react";
import { ButtonGroup, Button, Intent, Alignment } from "@blueprintjs/core";

import PropTypes from "prop-types";

const EndNoteGroup = ({
  toggleConfirming,
  setConfirmingText,
  setConfirmingIntent,
  setIsDoctorNote,
  // confirmingText,
  // isEndingWithQuestionMark,
  // setIsEndingWithQuestionMark,
}) => (
  <ButtonGroup fill large={false}>
    <Button
      icon="annotation"
      intent={Intent.NONE}
      onClick={() => {
        toggleConfirming(true);
        setConfirmingText("Send as Doctor Note");
        setConfirmingIntent(Intent.NONE);
        setIsDoctorNote(true);
      }}
    >
      Send as Doctor Note
    </Button>
  </ButtonGroup>
);

const SendAnotherQuestion = ({
  // toggleConfirming,
  // setConfirmingText,
  // setConfirmingIntent,
  // isEndingWithQuestionMark,
  // setIsEndingWithQuestionMark,
  // isMultipleQuestions,
  AddAnotherInput,
  areInputsEndingWithQuestionMark,
  areAllInputsCharacterCountUnderLimit,
}) => (
  <ButtonGroup fill large={false}>
    <Button
      icon={areInputsEndingWithQuestionMark ? "add" : null}
      intent={areInputsEndingWithQuestionMark ? Intent.SUCCESS : Intent.NONE}
      onClick={() => {
        AddAnotherInput();
      }}
      disabled={
        !areInputsEndingWithQuestionMark ||
        !areAllInputsCharacterCountUnderLimit
      }
    >
      {areInputsEndingWithQuestionMark
        ? "Add Another Question"
        : "You have to end all questions with a question mark “?” to be able to add another question or send questions"}
    </Button>
  </ButtonGroup>
);

const StandardGroup = ({
  toggleConfirming,
  setConfirmingText,
  setConfirmingIntent,
  setShowConditionSelector,
  setShowSpecialtyReferralSelector,
  setIsSpecialtyReferral,
  setIsEmergency,
  setIsLabTestRequest,
  setIsStartVideoCall,
  // setShowVideoCallSettings,
  setShowLabTestRequestForm,
  setIsPrescription,
  selectedSpeciality,
  isPatientAction,
  isSessionAction,
  setIsSessionAction,
  isSecondaryProfile,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ButtonGroup fill large={false}>
        {!isSessionAction ? (
          <>
            <Button
              icon="plus"
              intent={Intent.PRIMARY}
              onClick={() => {
                setIsSessionAction(true);
              }}
            >
              Create New Action
            </Button>
            <Button
              icon="mobile-video"
              intent={Intent.PRIMARY}
              onClick={() => {
                setIsStartVideoCall(true);
                toggleConfirming(true);
                setConfirmingText("Request an Appointment");
                setConfirmingIntent(Intent.PRIMARY);
                // setShowVideoCallSettings(true);
                // setActionType(ACTION_TYPES.START_VIDEO);
              }}
            >
              Request Appointment
            </Button>
          </>
        ) : (
          <>
            {!isPatientAction && (
              <Button
                icon="mobile-video"
                intent={Intent.NONE}
                onClick={() => {
                  setIsStartVideoCall(true);
                  toggleConfirming(true);
                  setConfirmingText("Propose an Appointment");
                  setConfirmingIntent(Intent.PRIMARY);
                }}
              >
                Propose Appointment
              </Button>
            )}
            <Button
              icon="send-to"
              intent={Intent.NONE}
              onClick={() => {
                toggleConfirming(true);
                setIsEmergency(true);
                setConfirmingText("Send to Emergency");
                setConfirmingIntent(Intent.DANGER);
              }}
            >
              Emergency
            </Button>
            <Button
              icon="book"
              intent={Intent.NONE}
              onClick={() => {
                toggleConfirming(true);
                setShowConditionSelector(true);
                setConfirmingText("Add Treatment Plan");
                setConfirmingIntent(Intent.PRIMARY);
              }}
            >
              Treatment Plan
            </Button>
            <Button
              icon="book"
              intent={Intent.NONE}
              onClick={() => {
                toggleConfirming(true);
                setIsLabTestRequest(true);
                setShowLabTestRequestForm(true);
                setConfirmingText("Request Lab Test");
                setConfirmingIntent(Intent.PRIMARY);
              }}
            >
              Request Lab Test
            </Button>
            <Button
              icon="arrow-right"
              intent={Intent.NONE}
              onClick={() => {
                toggleConfirming(true);
                setIsSpecialtyReferral(true);
                setShowSpecialtyReferralSelector(true);
                setConfirmingText("Confirm Referral");
                setConfirmingIntent(Intent.PRIMARY);
              }}
            >
              Refer
            </Button>
            {selectedSpeciality !== "mental-health" && !isSecondaryProfile && (
              <Button
                icon="prescription"
                intent={Intent.NONE}
                onClick={() => {
                  toggleConfirming(true);
                  setIsPrescription(true);
                  setConfirmingText("Add Prescription");
                  setConfirmingIntent(Intent.PRIMARY);
                }}
              >
                Prescription
              </Button>
            )}
            {isPatientAction && (
              <Button
                icon="undo"
                intent={Intent.NONE}
                onClick={() => {
                  setIsSessionAction(false);
                }}
              >
                Cancel
              </Button>
            )}
          </>
        )}
      </ButtonGroup>
    </div>
  );
};

const ConfirmingGroup = ({
  toggleConfirming,
  setShowConditionSelector,
  setShowSpecialtyReferralSelector,
  setIsStartVideoCall,
  setIsSpecialtyReferral,
  isEmergency,
  setIsEmergency,
  // setShowVideoCallSettings,
  setIsDoctorNote,
  setIsLabTestRequest,
  confirmingText,
  confirmingIntent,
  isAllowedConfirm,
  onConfirm,
  isConfirmLoading,
  setShowLabTestRequestForm,
  // isEndingWithQuestionMark,
  // setIsEndingWithQuestionMark,
  setIsPrescription,
}) => (
  <ButtonGroup fill large={false} alignText={Alignment.LEFT}>
    <Button icon="blank" intent={confirmingIntent} disabled style={{ flex: 2 }}>
      {`Are you sure that you want to ${confirmingText}?${
        (isEmergency &&
          " An alert will be sent to the patient's emergency contact") ||
        ""
      }`}
    </Button>
    <Button
      icon="confirm"
      intent={confirmingIntent}
      minimal={false}
      disabled={!isAllowedConfirm && !isConfirmLoading}
      onClick={() => {
        onConfirm({});
      }}
      loading={isConfirmLoading}
    >
      Confirm
    </Button>
    <Button
      icon="undo"
      minimal
      onClick={() => {
        onConfirm({ canceled: true });
        toggleConfirming(false);
        setShowConditionSelector(false);
        setShowSpecialtyReferralSelector(false);
        setIsSpecialtyReferral(false);
        setIsEmergency(false);
        // setShowVideoCallSettings(false);
        setIsStartVideoCall(false);
        setIsDoctorNote(false);
        setIsLabTestRequest(false);
        setShowLabTestRequestForm(false);
        setIsPrescription(false);
      }}
    >
      Cancel
    </Button>
  </ButtonGroup>
);

const EndButtons = ({
  selectedSpeciality,
  showEndNote,
  hideButtons,
  isConfirming,
  isAllowedConfirm,
  toggleConfirming,
  confirmingText,
  setConfirmingText,
  confirmingIntent,
  setIsDoctorNote,
  setConfirmingIntent,
  setShowConditionSelector,
  setShowSpecialtyReferralSelector,
  isEmergency,
  setIsEmergency,
  setIsSpecialtyReferral,
  setIsStartVideoCall,
  setIsLabTestRequest,
  // setShowVideoCallSettings,
  onConfirm,
  onCancel,
  isEndingWithQuestionMark,
  setIsEndingWithQuestionMark,
  isMultipleQuestions,
  AddAnotherInput,
  areInputsEndingWithQuestionMark,
  areAllInputsCharacterCountUnderLimit,
  isConfirmLoading,
  setShowLabTestRequestForm,
  setIsPrescription,
  isPatientAction,
  isSessionAction,
  setIsSessionAction,
  isSecondaryProfile,
}) => {
  if (isConfirming) {
    return (
      <ConfirmingGroup
        toggleConfirming={toggleConfirming}
        setShowConditionSelector={setShowConditionSelector}
        setShowSpecialtyReferralSelector={setShowSpecialtyReferralSelector}
        isEmergency={isEmergency}
        setIsEmergency={setIsEmergency}
        setIsSpecialtyReferral={setIsSpecialtyReferral}
        setIsStartVideoCall={setIsStartVideoCall}
        setIsLabTestRequest={setIsLabTestRequest}
        // setShowVideoCallSettings={setShowVideoCallSettings}
        confirmingText={confirmingText}
        confirmingIntent={confirmingIntent}
        setIsDoctorNote={setIsDoctorNote}
        isAllowedConfirm={isAllowedConfirm}
        onConfirm={onConfirm}
        onCancel={onCancel}
        isEndingWithQuestionMark={isEndingWithQuestionMark}
        setIsEndingWithQuestionMark={setIsEndingWithQuestionMark}
        isConfirmLoading={isConfirmLoading}
        setShowLabTestRequestForm={setShowLabTestRequestForm}
        setIsPrescription={setIsPrescription}
      />
    );
  }
  if (areInputsEndingWithQuestionMark) {
    return (
      <SendAnotherQuestion
        toggleConfirming={toggleConfirming}
        setConfirmingText={setConfirmingText}
        setConfirmingIntent={setConfirmingIntent}
        confirmingText={confirmingText}
        isEndingWithQuestionMark={isEndingWithQuestionMark}
        setIsEndingWithQuestionMark={setIsEndingWithQuestionMark}
        isMultipleQuestions={isMultipleQuestions}
        AddAnotherInput={AddAnotherInput}
        areInputsEndingWithQuestionMark={areInputsEndingWithQuestionMark}
        areAllInputsCharacterCountUnderLimit={
          areAllInputsCharacterCountUnderLimit
        }
      />
    );
  }
  if (isMultipleQuestions) {
    return (
      <SendAnotherQuestion
        toggleConfirming={toggleConfirming}
        setConfirmingText={setConfirmingText}
        setConfirmingIntent={setConfirmingIntent}
        confirmingText={confirmingText}
        isEndingWithQuestionMark={isEndingWithQuestionMark}
        setIsEndingWithQuestionMark={setIsEndingWithQuestionMark}
        isMultipleQuestions={isMultipleQuestions}
        AddAnotherInput={AddAnotherInput}
        areInputsEndingWithQuestionMark={areInputsEndingWithQuestionMark}
      />
    );
  }
  if (showEndNote) {
    return (
      <EndNoteGroup
        toggleConfirming={toggleConfirming}
        setIsDoctorNote={setIsDoctorNote}
        setConfirmingText={setConfirmingText}
        setConfirmingIntent={setConfirmingIntent}
        confirmingText={confirmingText}
        isEndingWithQuestionMark={isEndingWithQuestionMark}
        setIsEndingWithQuestionMark={setIsEndingWithQuestionMark}
      />
    );
  }
  if (!hideButtons) {
    return (
      <StandardGroup
        selectedSpeciality={selectedSpeciality}
        toggleConfirming={toggleConfirming}
        setConfirmingText={setConfirmingText}
        setConfirmingIntent={setConfirmingIntent}
        setShowConditionSelector={setShowConditionSelector}
        setShowSpecialtyReferralSelector={setShowSpecialtyReferralSelector}
        setIsSpecialtyReferral={setIsSpecialtyReferral}
        setIsEmergency={setIsEmergency}
        setIsStartVideoCall={setIsStartVideoCall}
        setIsLabTestRequest={setIsLabTestRequest}
        // setShowVideoCallSettings={setShowVideoCallSettings}
        setShowLabTestRequestForm={setShowLabTestRequestForm}
        setIsPrescription={setIsPrescription}
        isPatientAction={isPatientAction}
        isSessionAction={isSessionAction}
        setIsSessionAction={setIsSessionAction}
        isSecondaryProfile={isSecondaryProfile}
      />
    );
  }
  return <div />;
};

EndButtons.propTypes = {
  showEndNote: PropTypes.bool,
  hideButtons: PropTypes.bool,
  isConfirming: PropTypes.bool,
  isAllowedConfirm: PropTypes.bool,
  toggleConfirming: PropTypes.func,
  confirmingText: PropTypes.string,
  setIsDoctorNote: PropTypes.func,
  setConfirmingText: PropTypes.func,
  confirmingIntent: PropTypes.string,
  setConfirmingIntent: PropTypes.func,
  setIsEmergency: PropTypes.func,
  onConfirm: PropTypes.func,
  setIsPrescription: PropTypes.func,
};

export default EndButtons;
