export const CREATE_PRACTITIONER_SUCCESS =
  "practitioners/CREATE_PRACTITIONER_SUCCESS";
export const REMOVE_PRACTITIONER_SUCCESS =
  "practitioners/REMOVE_PRACTITIONER_SUCCESS";
export const UPDATE_PRACTITIONER_SUCCESS =
  "practitioners/UPDATE_PRACTITIONER_SUCCESS";
export const LOAD_PRACTITIONERS_SUCCESS =
  "practitioners/LOAD_PRACTITIONERS_SUCCESS";
export const UNLOAD_PRACTITIONERS_SUCCESS =
  "practitioners/UNLOAD_PRACTITIONERS_SUCCESS";
